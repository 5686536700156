import Req from "src/interceptors/token-interceptor";

const CarService = (function () {
  function _getAllCar() {
    return Req.get(`/api/vehicle_types`);
  }
  function _getCar(id) {
    return Req.get("/api/vehicle_types/" + id);
  }
  function _addCar(data) {
    return Req.post("/api/vehicle_types", data);
  }
  function _editCar(data, id) {
    return Req.put("/api/vehicle_types/" + id, data);
  }
  function _deleteCar(id) {
    return Req.delete("/api/vehicle_types/" + id);
  }
  function _deleteMultipleCar(ids) {
    return Req.post("/api/vehicle_types/delete", { ids: ids });
  }
  return {
    getAllCar: _getAllCar,
    getCar: _getCar,
    addCar: _addCar,
    editCar: _editCar,
    deleteCar: _deleteCar,
    deleteMultipleCar: _deleteMultipleCar,
  };
})();
export default CarService;
