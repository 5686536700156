import React, { Component } from "react";

import * as Mui from "@material-ui/core";
import PageMeta from "src/components/pages-meta";
import { Link } from "react-router-dom";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/inner-hero-banner.jpg";
import { IconCheckO } from "src/components/svg";

class TermsCondition extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Read the terms and conditions of our chauffeured vehicle service before you book one of our drivers, including our cancellation policy and booking rules" />

        <section className="inner-banner">
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Terms & Conditions
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Terms & Conditions
            </Mui.Typography>
          </Mui.Container>
        </section>

        <section className="section-default pt-0">
          <Mui.Container maxWidth="md">
            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
              The following terms and conditions apply to all bookings. By confirming your booking, you accept these
              terms and conditions.
            </Mui.Typography>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              All bookings
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Shangri-La Chauffeurs hold the right to accept bookings and all the bookings are subject to
                      availability.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You are fully responsible for providing correct booking information, including time, pick-up
                      location and drop-off location.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You are responsible for providing the correct number of passengers and the total number of Luggage
                      to avoid any delays. In addition, you are required to inform usof any extra-large luggage or
                      unusual items, like a golf bag or large box. This is required as all vehicle might not be able to
                      accommodate your luggage requirements
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      We allow an additional 15 minutes from agreed pick-up time for you to arrive at the pick-up
                      location. Anything after 15 minutes, will have additional charges as‘waiting time’. This condition
                      does not apply to the airport transfers please refer to the Airport Transfers section below.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You must notify us of any changes to your booking 24 hours before your pick-uptime/event. All
                      changes will be subject to availability.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Shangri-La Chauffeurs are not responsible for any travel delays caused by external factors. This
                      includes traffic, road closures, accidents and natural disasters.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      If you require additional stops during your travel that were not part of the original booking, you
                      will be charged for any additional levy.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      A 30% surcharge applies to all transfers between 11:00pm and 5:00am (23:00hours – 05:00 hours).
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Our driver has the right to terminate travel without refund in the event of any verbal or physical
                      abuse from the client(s).
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Airport transfers
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You will meet your driver at the designated chauffeur bay in the airport terminal.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You need to meet your driver within 30 minutes of a domestic flight landing and 60 minutes of an
                      international flight landing.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      If the wait time exceeds these time frames, parking charges will apply or we may cancel the
                      booking (unless extra time has already been agreed upon).
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      All flight landing times are based on the local terminal board. Complimentary waiting time begins
                      from the moment the plane lands, regardless of any flightdelays.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Weddings, events and tours
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      We require a 50% deposit of the total price/package when you book. This depositis non-refundable
                      and non-transferable.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You will need to pay the balance in full 48 hours before the event. We have the right to cancel
                      your booking without refunding your deposit if the full balance is not paid in this timeframe.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      If the duration of the event exceeds the agreed booking time, you will need to pay any additional
                      charges to cover the cost of our services for the entire event.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Vehicles
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Our drivers work on strict schedules. You do not have the right to hold the vehicle under any
                      circumstances.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Unless specified during the booking, Shangri-La Chauffeurs will provide thestandard vehicle of any
                      model and any color currently available.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      We will do our best to provide the requested vehicle. However, if an unexpected event occurs –
                      such as a mechanical breakdown or delay – we will provide the next best available vehicle.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You must not consume food or beverages in any of our vehicles.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Smoking is strictly prohibited in all our vehicles.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      You will have to pay for any damage caused as a direct result of your booking.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Credit cards
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      A 5% surcharge is applied to the total price of any booking paid via credit card.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      Shangri-La Chauffeurs is not liable for any charges issued by your card provider.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Cancellation and no-shows
            </Mui.Typography>
            <Mui.List dense={true}>
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      All deposits for major events, including weddings and tours, are non-refundable and
                      non-transferable under any circumstances.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      On any general bookings (e.g. airport transfers, business transfers, daily point-to-point
                      transfers) There will be no charges on any cancellation before 24 hours of the scheduled transfer.
                      However , 100% cancellation charges will be applied within 12 hours. * Any booking cancelled after
                      18:00 for next following day time booked from 00:00 to 10:00 will be applied full charge of your
                      transfer cost.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      If you cancel a major booking (e.g. weddings, birthdays, special events) within 24 hours of the
                      event, you will be charged the full booking fee.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
                      No-shows will incur a full charge.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-3">
              Privacy
            </Mui.Typography>
            <Mui.Typography component="span" variant="h4" className="text-color-grey font-weight-normal">
              Shangri-La Chauffeurs collects your information when you visit our website and when you book a service
              with us.{" "}
              <Link className="text-color-primary" to={`/privacy-policy`}>
                Read our privacy policy
              </Link>{" "}
              for more information.
            </Mui.Typography>
          </Mui.Container>
        </section>
      </WebsiteLayout>
    );
  }
}
export default Mui.withWidth()(TermsCondition);
