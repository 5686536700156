import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toolBarConfig } from "src/configs/constant";
import defaultAvatar from "src/assets/images/user-avatar.svg";
import { IconChevronDown, IconLogout } from "src/components/svg";
import SideNavTriggerCollapse from "./side-nav-trigger-collapse";
import SideNavTriggerMobile from "./side-nav-trigger-mobile";

class Topnav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    return (
      <>
        <Mui.AppBar
          className={`cms-header ${toolBarConfig.style === "fixed" ? "clipped" : ""}`}
          position={toolBarConfig.style}
        >
          <Mui.Container className="container-fluid m-0" maxWidth={false}>
            <Mui.Toolbar>
              {toolBarConfig.style === "fixed" ? (
                <>
                  <figure className="toolbar-logo clipped">
                    <img src={toolBarConfig.primaryLogo} alt="Logo" />
                  </figure>
                </>
              ) : null}

              <SideNavTriggerCollapse toggleSideNav={this.props.toggleSideNav} />

              <SideNavTriggerMobile toggleMobileSideNav={this.props.toggleMobileSideNav} />

              <Mui.Box display="flex" alignItems="center" className="right-nav">
                <Mui.Box
                  display="flex"
                  alignItems="center"
                  className="ml-2 p-0"
                  onClick={this.openMenu}
                  component={Mui.Button}
                >
                  <Mui.Avatar
                    src={defaultAvatar}
                    alt={this.props.user?.firstName || ""}
                    className="background-color-white"
                  />
                  {Mui.isWidthDown("md", this.props.width) ? null : (
                    <>
                      <Mui.Typography className="font-weight-normal ml-2 text-color-toolbar">
                        {this.props.user?.firstName || ""} {this.props.user?.lastName || ""}
                      </Mui.Typography>
                      <span className="ml-1 p-0 line-height-null svg-size-small svg-color-toolbar-icon">
                        <IconChevronDown />
                      </span>
                    </>
                  )}
                </Mui.Box>
              </Mui.Box>
            </Mui.Toolbar>
          </Mui.Container>
        </Mui.AppBar>
        <Mui.Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.closeMenu}
          elevation={0}
          classes={{ paper: "user-dropdown" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Mui.Divider light={true} />
          <Mui.MenuItem onClick={this.closeMenu} to={"/logout"} component={Link}>
            <span className="icon">
              <IconLogout />
            </span>
            Logout
          </Mui.MenuItem>
        </Mui.Popover>
      </>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return {
    user,
  };
};
export default Mui.withWidth()(connect(mapStateToProps, {})(Topnav));
