import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import CarList from "./list";
import EditCar from "./edit";
import ViewCar from "./view";

function Car() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/car" component={CarList} />
        <ContentRoute path="/car/add" component={EditCar} />
        <ContentRoute path="/car/edit/:id" component={EditCar} />
        <ContentRoute path="/car/view/:id" component={ViewCar} />
      </Switch>
    </Suspense>
  );
}

export default Car;
