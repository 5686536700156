import React from "react";

const IconClock = () => {
  return (
    <svg height="22" viewBox="0 0 74 74" width="22">
      <path d="m37 72a35 35 0 1 1 35-35 35.04 35.04 0 0 1 -35 35zm0-68a33 33 0 1 0 33 33 33.037 33.037 0 0 0 -33-33z" />
      <path d="m37 65a28 28 0 1 1 28-28 28.031 28.031 0 0 1 -28 28zm0-54a26 26 0 1 0 26 26 26.029 26.029 0 0 0 -26-26z" />
      <path d="m37 18.875a1 1 0 0 1 -1-1v-2.875a1 1 0 0 1 2 0v2.875a1 1 0 0 1 -1 1z" />
      <path d="m37 60a1 1 0 0 1 -1-1v-2.875a1 1 0 0 1 2 0v2.875a1 1 0 0 1 -1 1z" />
      <path d="m50.523 24.477a1 1 0 0 1 -.707-1.707l2.033-2.033a1 1 0 0 1 1.414 1.414l-2.033 2.033a1 1 0 0 1 -.707.293z" />
      <path d="m21.443 53.556a1 1 0 0 1 -.707-1.707l2.033-2.033a1 1 0 0 1 1.414 1.414l-2.033 2.033a1 1 0 0 1 -.707.293z" />
      <path d="m59 38h-2.875a1 1 0 0 1 0-2h2.875a1 1 0 0 1 0 2z" />
      <path d="m17.875 38h-2.875a1 1 0 0 1 0-2h2.875a1 1 0 0 1 0 2z" />
      <path d="m52.557 53.556a1 1 0 0 1 -.707-.293l-2.034-2.033a1 1 0 0 1 1.414-1.414l2.033 2.033a1 1 0 0 1 -.707 1.707z" />
      <path d="m23.477 24.477a1 1 0 0 1 -.707-.293l-2.033-2.033a1 1 0 0 1 1.414-1.414l2.033 2.033a1 1 0 0 1 -.707 1.707z" />
      <path d="m31.438 43.563a1 1 0 0 1 -.707-1.707l5.269-5.27v-13.586a1 1 0 0 1 2 0v14a1 1 0 0 1 -.293.707l-5.562 5.563a1 1 0 0 1 -.707.293z" />
    </svg>
  );
};

export default IconClock;
