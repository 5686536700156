import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/inner-hero-banner.jpg";
import { IconCheckO } from "src/components/svg";

class PrivacyPolicy extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta
          description="
Read our privacy policy to find out how we collect, use and store your personal information, and how you can access or change any information we hold"
        />

        <section className="inner-banner">
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Privacy Policy
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Privacy Policy
            </Mui.Typography>
          </Mui.Container>
        </section>

        <section className="section-default pt-0">
          <Mui.Container maxWidth="md">
            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              The following privacy policy applies to Shangri-La Chauffers
              <br />
              <a href="https://www.shangrilachauffeurs.com.au" className="text-color-primary">
                www.shangrilachauffeurs.com.au
              </a>
            </Mui.Typography>

            <Mui.Typography component="h2" variant="h3" className="mt-4 mb-2">
              Collection of your information and its uses
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              Shangri-La Chauffeurs collects your information when you visit our website and when you book a service
              with us.
            </Mui.Typography>

            <Mui.Typography component="h2" variant="h3" className="mt-4 mb-2">
              What information we collect
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              We collect information from you when you book with us and complete the booking form, including name,
              address, mobile number and email address.
            </Mui.Typography>

            <Mui.Typography component="h2" variant="h3" className="mt-4 mb-2">
              How we use your information
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              We use your information to:
            </Mui.Typography>

            <Mui.List dense={true} className="font-size-md-2x">
              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
                      process and complete your transaction and the services you require
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
                      send you your booking conformation and other related items
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
                      contact you to promote our services and update you about a service, i.e. confirmation of booking
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>

              <Mui.ListItem className="mb-2">
                <Mui.ListItemAvatar className="align-self-start">
                  <Mui.Avatar className="background-color-primary svg-color-white" style={{ width: 35, height: 35 }}>
                    <IconCheckO />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  primary={
                    <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
                      register your accounts with Shangri-La Chauffeurs; for example, if you are a regular Shangri-La
                      Chauffeurs customer and have opened a personal account with us to regulate your transfer services.
                    </Mui.Typography>
                  }
                />
              </Mui.ListItem>
            </Mui.List>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-2">
              Disclosing your information
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              We will not disclose any of your information to a third party until and unless it is required to complete
              your booking.
            </Mui.Typography>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-2">
              More information
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              Please contact us if you have any queries or complaints about the personal information that we hold about
              you or the way we handle that personal information.
            </Mui.Typography>

            <Mui.Typography component="h3" variant="h3" className="mt-4 mb-2">
              Contact details:
            </Mui.Typography>
            <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal">
              Privacy Officer <br />
              Shangri-La Chauffeurs
              <br />
              Mail: 745 High Street, Preston Victoria 3072
              <br />
              Phone: 0404 377 511
            </Mui.Typography>
          </Mui.Container>
        </section>
      </WebsiteLayout>
    );
  }
}
export default Mui.withWidth()(PrivacyPolicy);
