import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import DashboardView from "./dashboard";
import UserView from "./user";
import ClientView from "./client";
import CarView from "./car";
import CarPriceView from "./car-price";
import BookingView from "./booking";
import RouteView from "./route";
import DriverView from "./driver";
import ScheduleView from "./schedule";
import CouponView from "./coupon";
import InvoiceView from "./invoice";
import ConsolidateInvoiceView from "./consolidate-invoice";
import AdditionalChargeView from "./additional-charge";

export default function Base() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/dashboard" component={DashboardView} />
        <ContentRoute path="/user" component={UserView} />
        <ContentRoute path="/client" component={ClientView} />
        <ContentRoute path="/car" component={CarView} />
        <ContentRoute path="/car-price" component={CarPriceView} />
        <ContentRoute path="/booking" component={BookingView} />
        <ContentRoute path="/invoice" component={InvoiceView} />
        <ContentRoute path="/consolidate-invoice" component={ConsolidateInvoiceView} />
        <ContentRoute path="/additional-charge" component={AdditionalChargeView} />
        <ContentRoute path="/route" component={RouteView} />
        <ContentRoute path="/driver" component={DriverView} />
        <ContentRoute path="/schedule" component={ScheduleView} />
        <ContentRoute path="/coupon" component={CouponView} />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
