import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GET_PRICE_LIST, EDIT_PRICE } from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import PriceService from "src/services/price-service";
import { getPriceListSuccess, getPriceListError, editPriceSuccess, editPriceError } from "./action";

export function* watchGetPriceList() {
  yield takeEvery(GET_PRICE_LIST, getPriceListAc);
}

const getPriceListAsync = async () => {
  return PriceService.getPriceList();
};

function* getPriceListAc() {
  try {
    const response = yield call(getPriceListAsync);
    if (response.data.success) {
      yield put(getPriceListSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getPriceListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getPriceListError(error.response.data.message));
  }
}

export function* watchEditPrice() {
  yield takeEvery(EDIT_PRICE, editPrice);
}

const editPriceAsync = async (data, id) => {
  return PriceService.editPrice(data, id);
};

function* editPrice({ payload }) {
  try {
    const response = yield call(editPriceAsync, payload.priceData, payload.priceId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editPriceSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editPriceError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editPriceError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetPriceList), fork(watchEditPrice)]);
}
