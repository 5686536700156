import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Formik, Form, FieldArray, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from "src/components/breadcrumb";
import { addSchedule } from "src/reduxs/actions";
import { IconAdd, IconTrash, IconCalendar } from "src/components/svg";

class AddSchedule extends Component {
  constructor(props) {
    super(props);
    this.schema = Yup.object().shape({
      scheduleDetail: Yup.array().of(
        Yup.object().shape({
          startDate: Yup.string().required("Enter a valid date").nullable(),
          endDate: Yup.string()
            .required("Enter a valid date")
            .nullable()
            .when("startDate", {
              is: (value) => value?.length > 0,
              then: Yup.string()
                .nullable()
                .test(
                  "is-greater",
                  "End-Date should be same/greater than Start-Date",
                  function (value) {
                    const { startDate } = this.parent;
                    return moment(value, "YYYY-MM-DD").isSameOrAfter(
                      moment(startDate, "YYYY-MM-DD")
                    );
                  }
                ),
              otherwise: Yup.string().required("Enter a valid date").nullable(),
            }),
          startTime: Yup.string()
            .test(
              "is-greater",
              "Start-Time should be greater than Current-Time",
              function (value) {
                if (value) {
                  let current = moment().format("YYYY-MM-DD HH:mm");
                  const { startDate } = this.parent;
                  return moment(
                    startDate + " " + value,
                    "YYYY-MM-DD HH:mm"
                  ).isAfter(moment(current, "YYYY-MM-DD HH:mm"));
                } else {
                  return true;
                }
              }
            )
            .nullable(),
          endTime: Yup.string().when("startTime", {
            is: (value) => value?.length > 0,
            then: Yup.string()
              .required("Enter a valid time")
              .nullable()
              .test(
                "is-greater",
                "End-Time should be greater than Start-Date-Time",
                function (value) {
                  const { startDate, endDate, startTime } = this.parent;
                  return moment(
                    endDate + " " + value,
                    "YYYY-MM-DD HH:mm"
                  ).isAfter(
                    moment(startDate + " " + startTime, "YYYY-MM-DD HH:mm")
                  );
                }
              ),
            otherwise: Yup.string().nullable(),
          }),
          message: Yup.string().required("Enter valid message").nullable(),
        })
      ),
    });
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.addSchedule(values, this.props.history);
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Schedular Management"}
          paths={[
            {
              title: "Schedular",
              page: `/schedule`,
            },
            {
              title: "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            scheduleDetail: [
              {
                startDate: null,
                startTime: "",
                endDate: null,
                endTime: "",
                message: "",
              },
            ],
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Card className="default-card">
                <FieldArray
                  name="scheduleDetail"
                  render={(arrayHelpers) => (
                    <>
                      <Mui.Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        className="mb-4"
                      >
                        <Mui.Typography
                          component="h4"
                          variant="h4"
                          className="font-weight-medium"
                        >
                          Add Booking Off Schedule
                        </Mui.Typography>

                        <Mui.Button
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={() => {
                            arrayHelpers.push({
                              startDate: null,
                              startTime: "",
                              endDate: null,
                              endTime: "",
                              message: "",
                            });
                          }}
                          className={`flex-shrink-0 pl-1 ${
                            Mui.isWidthDown("xs", props.width) ? "" : "ml-2"
                          }`}
                        >
                          Add New
                          <span className="ml-1 line-height-null">
                            <IconAdd />
                          </span>
                        </Mui.Button>
                      </Mui.Box>

                      <Mui.Grid container spacing={4} alignItems="start">
                        {props.values?.scheduleDetail?.map((item, index) => (
                          <Mui.Grid key={index} item xs={12} md={6}>
                            <Mui.Box
                              className="mb-2 pt-3 pl-3 pr-3 border-1 border-color-fade-blue border-rad-10"
                              width="100%"
                            >
                              <Mui.Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                className="mb-3"
                              >
                                <Mui.Typography
                                  component="h5"
                                  variant="h5"
                                  className="ml-neg-2 pl-2 pr-2 background-color-white text-color-secondary font-weight-medium"
                                >
                                  Schedule {index + 1}
                                </Mui.Typography>

                                {index !== 0 ? (
                                  <Mui.Button
                                    color="secondary"
                                    size="small"
                                    disableElevation
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                    className={`flex-shrink-0 pl-1 ${
                                      Mui.isWidthDown("xs", props.width)
                                        ? ""
                                        : "ml-2"
                                    }`}
                                  >
                                    Remove
                                    <span className="ml-1 line-height-null">
                                      <IconTrash />
                                    </span>
                                  </Mui.Button>
                                ) : null}
                              </Mui.Box>

                              <Mui.Grid
                                container
                                spacing={3}
                                alignItems="start"
                              >
                                <React.Fragment>
                                  <Mui.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="form-group"
                                  >
                                    <Field
                                      name={`scheduleDetail.${index}.startDate`}
                                    >
                                      {({ field, meta }) => (
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            className="date-time-picker"
                                            InputProps={{ readOnly: true }}
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            fullWidth
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            minDate={new Date()}
                                            label={"Start Date"}
                                            value={
                                              props.values.scheduleDetail?.[
                                                index
                                              ]?.startDate
                                            }
                                            onChange={(date) => {
                                              props.setFieldValue(
                                                `scheduleDetail.${index}.startDate`,
                                                moment(date).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            keyboardIcon={<IconCalendar />}
                                            helperText={
                                              meta.touched && meta.error
                                                ? meta.error
                                                : null
                                            }
                                            error={
                                              meta.touched && meta.error
                                                ? true
                                                : false
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      )}
                                    </Field>
                                  </Mui.Grid>

                                  <Mui.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="form-group"
                                  >
                                    <Field
                                      name={`scheduleDetail.${index}.endDate`}
                                    >
                                      {({ field, meta }) => (
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            className="date-time-picker"
                                            InputProps={{ readOnly: true }}
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            fullWidth
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            minDate={
                                              props.values.scheduleDetail?.[
                                                index
                                              ]?.startDate
                                                ? props.values.scheduleDetail?.[
                                                    index
                                                  ]?.startDate
                                                : new Date()
                                            }
                                            label={"End Date"}
                                            value={
                                              props.values.scheduleDetail?.[
                                                index
                                              ]?.endDate
                                            }
                                            onChange={(date) => {
                                              props.setFieldValue(
                                                `scheduleDetail.${index}.endDate`,
                                                moment(date).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            keyboardIcon={<IconCalendar />}
                                            helperText={
                                              meta.touched && meta.error
                                                ? meta.error
                                                : null
                                            }
                                            error={
                                              meta.touched && meta.error
                                                ? true
                                                : false
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      )}
                                    </Field>
                                  </Mui.Grid>

                                  <Mui.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="time-picker form-group"
                                  >
                                    <Field
                                      name={`scheduleDetail.${index}.startTime`}
                                    >
                                      {({ field, meta }) => (
                                        <Mui.FormControl
                                          error={
                                            meta.touched && meta.error
                                              ? true
                                              : false
                                          }
                                          variant={"outlined"}
                                        >
                                          <Mui.InputLabel shrink={true}>
                                            Start Time
                                          </Mui.InputLabel>

                                          <TimePicker
                                            showSecond={false}
                                            allowEmpty={false}
                                            className=""
                                            popupClassName=""
                                            onChange={(value) => {
                                              props.setFieldValue(
                                                `scheduleDetail.${index}.startTime`,
                                                value.format("HH:mm:ss")
                                              );
                                            }}
                                            format={"HH:mm"}
                                            minuteStep={5}
                                            inputReadOnly
                                          />

                                          {meta.touched && meta.error ? (
                                            <Mui.FormHelperText error>
                                              {meta.touched && meta.error
                                                ? meta.error
                                                : null}
                                            </Mui.FormHelperText>
                                          ) : null}
                                        </Mui.FormControl>
                                      )}
                                    </Field>
                                  </Mui.Grid>

                                  <Mui.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="time-picker form-group"
                                  >
                                    <Field
                                      name={`scheduleDetail.${index}.endTime`}
                                    >
                                      {({ field, meta }) => (
                                        <Mui.FormControl
                                          error={
                                            meta.touched && meta.error
                                              ? true
                                              : false
                                          }
                                          variant={"outlined"}
                                        >
                                          <Mui.InputLabel shrink={true}>
                                            End Time
                                          </Mui.InputLabel>

                                          <TimePicker
                                            showSecond={false}
                                            allowEmpty={false}
                                            className=""
                                            popupClassName=""
                                            onChange={(value) => {
                                              props.setFieldValue(
                                                `scheduleDetail.${index}.endTime`,
                                                value.format("HH:mm:ss")
                                              );
                                            }}
                                            format={"HH:mm"}
                                            minuteStep={5}
                                            inputReadOnly
                                          />

                                          {meta.touched && meta.error ? (
                                            <Mui.FormHelperText error>
                                              {meta.touched && meta.error
                                                ? meta.error
                                                : null}
                                            </Mui.FormHelperText>
                                          ) : null}
                                        </Mui.FormControl>
                                      )}
                                    </Field>
                                  </Mui.Grid>

                                  <Mui.Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className={`form-group`}
                                  >
                                    <Field
                                      name={`scheduleDetail.${index}.message`}
                                    >
                                      {({ field, meta }) => (
                                        <Mui.TextField
                                          label={"Message"}
                                          multiline
                                          rows={1}
                                          InputLabelProps={{ shrink: true }}
                                          variant="outlined"
                                          placeholder=""
                                          helperText={
                                            meta.touched && meta.error
                                              ? meta.error
                                              : null
                                          }
                                          error={
                                            meta.touched && meta.error
                                              ? true
                                              : false
                                          }
                                          {...field}
                                        />
                                      )}
                                    </Field>
                                  </Mui.Grid>
                                </React.Fragment>
                              </Mui.Grid>
                            </Mui.Box>
                          </Mui.Grid>
                        ))}
                      </Mui.Grid>
                    </>
                  )}
                />
              </Mui.Card>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={
                  Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                }
                className="mt-5 "
              >
                <Mui.Button
                  className={`btn-default ${
                    Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                  }`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && (
                    <Mui.CircularProgress color="secondary" size={24} />
                  )}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${
                    Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                  }`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/schedule"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ schedule }) => {
  const { success, message, loading, error } = schedule;
  return {
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  addSchedule,
})(Mui.withWidth()(AddSchedule));
