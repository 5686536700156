export const GET_ALL_CAR = "GET_ALL_CAR";
export const GET_ALL_CAR_SUCCESS = "GET_ALL_CAR_SUCCESS";
export const GET_ALL_CAR_ERROR = "GET_ALL_CAR_ERROR";
export const GET_CAR_LIST = "GET_CAR_LIST";
export const GET_CAR_LIST_SUCCESS = "GET_CAR_LIST_SUCCESS";
export const GET_CAR_LIST_ERROR = "GET_CAR_LIST_ERROR";
export const ADD_CAR = "ADD_CAR";
export const ADD_CAR_SUCCESS = "ADD_CAR_SUCCESS";
export const ADD_CAR_ERROR = "ADD_CAR_ERROR";
export const GET_CAR = "GET_CAR";
export const GET_CAR_SUCCESS = "GET_CAR_SUCCESS";
export const GET_CAR_ERROR = "GET_CAR_ERROR";
export const EDIT_CAR = "EDIT_CAR";
export const EDIT_CAR_SUCCESS = "EDIT_CAR_SUCCESS";
export const EDIT_CAR_ERROR = "EDIT_CAR_ERROR";
export const DELETE_CAR = "DELETE_CAR";
export const DELETE_CAR_SUCCESS = "DELETE_CAR_SUCCESS";
export const DELETE_CAR_ERROR = "DELETE_CAR_ERROR";
export const DELETE_MULTIPLE_CAR = "DELETE_MULTIPLE_CAR";
export const DELETE_MULTIPLE_CAR_SUCCESS = "DELETE_MULTIPLE_CAR_SUCCESS";
export const DELETE_MULTIPLE_CAR_ERROR = "DELETE_MULTIPLE_CAR_ERROR";
export const RESET_CAR = "RESET_CAR";

export const getAllCar = () => ({
  type: GET_ALL_CAR,
  payload: {},
});

export const getAllCarSuccess = (cars) => ({
  type: GET_ALL_CAR_SUCCESS,
  payload: cars,
});

export const getAllCarError = (error) => ({
  type: GET_ALL_CAR_ERROR,
  payload: error,
});

export const getCarList = (dbParam) => ({
  type: GET_CAR_LIST,
  payload: { dbParam },
});

export const getCarListSuccess = (carList) => ({
  type: GET_CAR_LIST_SUCCESS,
  payload: carList,
});

export const getCarListError = (error) => ({
  type: GET_CAR_LIST_ERROR,
  payload: error,
});

export const addCar = (carData, history) => ({
  type: ADD_CAR,
  payload: { carData, history },
});

export const addCarSuccess = (success, message) => ({
  type: ADD_CAR_SUCCESS,
  payload: { success, message },
});

export const addCarError = (error) => ({
  type: ADD_CAR_ERROR,
  payload: error,
});

export const getCar = (carId) => ({
  type: GET_CAR,
  payload: { carId },
});

export const getCarSuccess = (carData) => ({
  type: GET_CAR_SUCCESS,
  payload: carData,
});

export const getCarError = (error) => ({
  type: GET_CAR_ERROR,
  payload: error,
});

export const editCar = (carId, carData, history) => ({
  type: EDIT_CAR,
  payload: { carId, carData, history },
});

export const editCarSuccess = (success, message) => ({
  type: EDIT_CAR_SUCCESS,
  payload: { success, message },
});

export const editCarError = (error) => ({
  type: EDIT_CAR_ERROR,
  payload: error,
});

export const deleteCar = (carId) => ({
  type: DELETE_CAR,
  payload: { carId },
});

export const deleteCarSuccess = (success, message) => ({
  type: DELETE_CAR_SUCCESS,
  payload: { success, message },
});

export const deleteCarError = (error) => ({
  type: DELETE_CAR_ERROR,
  payload: error,
});

export const deleteMultipleCar = (carIds) => ({
  type: DELETE_MULTIPLE_CAR,
  payload: { carIds },
});

export const deleteMultipleCarSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_CAR_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleCarError = (error) => ({
  type: DELETE_MULTIPLE_CAR_ERROR,
  payload: error,
});

export const resetCar = () => ({
  type: RESET_CAR,
  payload: {},
});
