import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { toast } from "react-toastify";
import TimePicker from "rc-time-picker";
import { Link } from "react-router-dom";
import {
  IconCarFront,
  IconClock,
  IconInfo,
  // IconRate,
  IconRoute,
} from "src/components/svg";
import GoogleAutocomplete from "src/components/autocomplete";
import ToastElement from "src/components/toast";
import { disableDate, disableTime, isBetweenStartEndDate } from "src/helpers/util";
class BookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datepicker: false,
      date: null,
      time: null,
      route: null,
      duration: { id: "01:00:00", name: "1 hour(s)" },
      disableHours: [],
    };
    this.schema = Yup.object().shape({
      pickupDate: Yup.string().required("Enter a valid date").nullable(),
      pickupTime: Yup.string()
        .required("Enter a valid time")
        .nullable()
        .when("pickupDate", {
          is: (value) => value?.length > 0,
          then: Yup.string().test("is-minimum", "Pick-Up Time minimum restriction of 3 hours", function (value) {
            const { pickupDate } = this.parent;
            let el = moment(pickupDate + " " + value, "YYYY-MM-DD HH:mm:ss");
            let now = moment();
            if (el.isSameOrBefore(now)) {
              return false;
            }
            var duration = moment.duration(el.diff(now));
            var hr = duration.asHours();
            return hr >= 3;
          }),
          otherwise: Yup.string().required("Enter a valid time").nullable(),
        }),
      pickupLocation: Yup.string().when("type", {
        is: (value) => value === "distance" || value === "hourly",
        then: Yup.string().required("Enter a valid location").nullable(),
        otherwise: Yup.string(),
      }),
      dropOffLocation: Yup.string().when("type", {
        is: (value) => value === "distance" || value === "hourly",
        then: Yup.string().required("Enter a valid location").nullable(),
        otherwise: Yup.string(),
      }),
      durationInHour: Yup.string().when("type", {
        is: (value) => value === "hourly",
        then: Yup.string().required("Select a valid duration").nullable(),
        otherwise: Yup.string(),
      }),
      routeId: Yup.string().when("type", {
        is: (value) => value === "flat_rate",
        then: Yup.string().required("Select valid route").nullable(),
        otherwise: Yup.string(),
      }),
    });
  }

  disableScheduleDate = (date) => {
    return disableDate(this.props.schedules, date);
  };

  // getDisabledMinutes = (selectedHour) => {};

  onSubmit = async (values) => {
    // Check If Its Active Hour
    if (!disableDate(this.props.schedules, values.pickupDate)) {
      // Check if distance is over 100 km
      if (values.type === "distance" || values.type === "hourly") {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: values.pickupLocation,
            destination: values.dropOffLocation,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const route = result.routes[0];
              let el = 0;
              for (let i = 0; i < route.legs.length; i++) {
                el += route.legs[i].distance.value;
              }
              if ((el / 1000).toFixed(2) >= 100) {
                toast.info(
                  <ToastElement
                    type="info"
                    message={
                      <span>
                        Please contact +61 426 292 525 for booking over 100 km or you can fill the form{" "}
                        <Link to={`/contact`} className="text-color-grey">
                          here
                        </Link>{" "}
                        to get in touch with us!
                      </span>
                    }
                  />,
                  {
                    containerId: "default",
                    autoClose: 12000,
                  }
                );
              } else {
                this.props.onNavigate(values);
              }
            } else {
              // Error
              toast.error(
                <ToastElement
                  type="error"
                  message={
                    "There was an issue fetching directions. Proper routes was not found. Please try again changing your pick-up/drop-off location."
                  }
                />,
                {
                  containerId: "default",
                  autoClose: 8000,
                }
              );
            }
          }
        );
      } else {
        this.props.onNavigate(values);
      }
    }
  };

  render() {
    return (
      <Mui.Grid container>
        <Mui.Grid item xs={12} md={this.props.layout === "full" ? 12 : 10}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              type: "distance",
              pickupDate: null,
              pickupTime: "",
              pickupLocation: "",
              dropOffLocation: "",
              durationInHour: "01:00:00",
              routeId: "",
            }}
            validationSchema={this.schema}
            onSubmit={this.onSubmit}
          >
            {(props) => (
              <Form className="mt-5 default-form">
                <Mui.Box display="flex" className="form-tab-style">
                  <Mui.Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => props.setFieldValue("type", "distance")}
                    className={`${props.values.type === "distance" ? "active" : null}`}
                    classes={{
                      label: `${Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : "row"}`,
                    }}
                  >
                    <span className="line-height-null">
                      <IconRoute />
                    </span>
                    Point to Point
                  </Mui.Button>

                  <Mui.Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => props.setFieldValue("type", "hourly")}
                    className={`${props.values.type === "hourly" ? "active" : null}`}
                    classes={{
                      label: `${Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : "row"}`,
                    }}
                  >
                    <span className="line-height-null">
                      <IconClock />
                    </span>
                    Hourly
                  </Mui.Button>

                  {/* <Mui.Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => props.setFieldValue("type", "flat_rate")}
                    className={`${
                      props.values.type === "flat_rate" ? "active" : null
                    }`}
                    classes={{
                      label: `${
                        Mui.isWidthDown("xs", this.props.width)
                          ? "flex-direction-column"
                          : "row"
                      }`,
                    }}
                  >
                    <span className="line-height-null">
                      <IconRate />
                    </span>
                    Flat Rate
                  </Mui.Button> */}
                </Mui.Box>

                <Mui.Paper
                  className={`form-tab-content-style ${
                    this.props.theme === "dark" ? "default-card-dark-card" : "default-card"
                  }`}
                >
                  <Mui.Grid container spacing={4}>
                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={this.props.layout === "full" ? 6 : 2}
                      className={`${this.props.theme === "dark" ? "form-group-dark" : "form-group"}`}
                    >
                      <Field name="pickupDate">
                        {({ field, meta }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="date-time-picker"
                              InputProps={{ readOnly: true }}
                              autoOk
                              disableToolbar
                              variant="inline"
                              fullWidth
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              margin="normal"
                              minDate={new Date()}
                              onClick={() => this.setState({ datepicker: true })}
                              onClose={() => this.setState({ datepicker: false })}
                              open={this.state.datepicker}
                              label={
                                <>
                                  Pick-Up Date{" "}
                                  <Mui.Tooltip
                                    className="tooltip-default"
                                    arrow
                                    placement="bottom-start"
                                    title={"The date when your journey will start"}
                                  >
                                    <span>
                                      <IconInfo />
                                    </span>
                                  </Mui.Tooltip>
                                </>
                              }
                              value={this.state.date}
                              onChange={(date) => {
                                this.setState({ date: date });
                                this.setState({ disableHours: disableTime(this.props.schedules, date) });
                                props.setFieldValue("pickupDate", moment(date).format("YYYY-MM-DD"));
                                props.setFieldValue("pickupTime", "");
                                this.setState({ time: null });
                              }}
                              keyboardIcon={null}
                              helperText={meta.touched && meta.error ? meta.error : null}
                              error={meta.touched && meta.error ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              shouldDisableDate={this.disableScheduleDate}
                              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                const date = moment(day).format("YYYY-MM-DD");
                                const isOff = isInCurrentMonth && isBetweenStartEndDate(this.props.schedules, date);
                                return (
                                  <Mui.Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    badgeContent={isOff ? "❌" : undefined}
                                  >
                                    {dayComponent}
                                  </Mui.Badge>
                                );
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      </Field>
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={this.props.layout === "full" ? 6 : 2}
                      className={`time-picker ${this.props.theme === "dark" ? "form-group-dark" : "form-group"}`}
                    >
                      <Field name="pickupTime">
                        {({ field, meta }) => (
                          <Mui.FormControl error={meta.touched && meta.error ? true : false} variant={"outlined"}>
                            <Mui.InputLabel shrink={true}>
                              <>
                                Pick-Up Time{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"The time when your journey will start"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            </Mui.InputLabel>

                            <TimePicker
                              showSecond={false}
                              allowEmpty={false}
                              className=""
                              value={this.state.time}
                              popupClassName=""
                              onChange={(value) => {
                                const offHrs = this.state.disableHours;
                                const hr = Number(value.format("H"));
                                if (!offHrs.includes(hr)) {
                                  this.setState({ time: value });
                                  props.setFieldValue("pickupTime", value.format("HH:mm:ss"));
                                } else {
                                  this.setState({ time: null });
                                  props.setFieldValue("pickupTime", "");
                                }
                              }}
                              format={"HH:mm"}
                              minuteStep={5}
                              inputReadOnly
                              disabledHours={() => this.state.disableHours}
                              // disabledMinutes={this.getDisabledMinutes()}
                            />

                            {meta.touched && meta.error ? (
                              <Mui.FormHelperText error>
                                {meta.touched && meta.error ? meta.error : null}
                              </Mui.FormHelperText>
                            ) : null}
                          </Mui.FormControl>
                        )}
                      </Field>
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={this.props.layout === "full" ? 6 : props.values.type === "distance" ? 4 : 3}
                      className={`${this.props.theme === "dark" ? "form-group-dark" : "form-group"} ${
                        props.values.type === "flat_rate" ? "d-none" : ""
                      }`}
                    >
                      <Field name="pickupLocation">
                        {({ field, meta }) => (
                          <GoogleAutocomplete
                            label={
                              <>
                                Pick-Up Location{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"The address where your journey will start"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            }
                            variant={"outlined"}
                            placeholder="Enter a Location"
                            touched={meta.touched}
                            error={meta.error}
                            callback={(description) => {
                              props.setFieldValue("pickupLocation", description || "");
                            }}
                          />
                        )}
                      </Field>
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={this.props.layout === "full" ? 6 : props.values.type === "distance" ? 4 : 3}
                      className={`${this.props.theme === "dark" ? "form-group-dark" : "form-group"} ${
                        props.values.type === "flat_rate" ? "d-none" : ""
                      }`}
                    >
                      <Field name="dropOffLocation">
                        {({ field, meta }) => (
                          <GoogleAutocomplete
                            label={
                              <>
                                Drop-Off Location{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"The address where your journey will end"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            }
                            variant={"outlined"}
                            placeholder="Enter a Location"
                            touched={meta.touched}
                            error={meta.error}
                            callback={(description) => {
                              props.setFieldValue("dropOffLocation", description || "");
                            }}
                          />
                        )}
                      </Field>
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={this.props.layout === "full" ? 6 : 2}
                      className={`${this.props.theme === "dark" ? "form-group-dark" : "form-group"} ${
                        props.values.type === "hourly" ? "" : "d-none"
                      }`}
                    >
                      <Field name="durationInHour">
                        {({ field, meta }) => (
                          <>
                            <Autocomplete
                              options={[
                                { id: "01:00:00", name: "1 hour(s)" },
                                { id: "02:00:00", name: "2 hour(s)" },
                                { id: "03:00:00", name: "3 hour(s)" },
                                { id: "04:00:00", name: "4 hour(s)" },
                                { id: "05:00:00", name: "5 hour(s)" },
                                { id: "06:00:00", name: "6 hour(s)" },
                                { id: "07:00:00", name: "7 hour(s)" },
                                { id: "08:00:00", name: "8 hour(s)" },
                                { id: "09:00:00", name: "9 hour(s)" },
                                { id: "10:00:00", name: "10 hour(s)" },
                              ]}
                              autoHighlight
                              autoComplete={true}
                              disableClearable={true}
                              getOptionLabel={(option) => (option ? option.name : "")}
                              value={this.state.duration}
                              onChange={(event, selectedOption) => {
                                this.setState({ duration: selectedOption });
                                props.setFieldValue("durationInHour", selectedOption?.id || "");
                              }}
                              renderOption={(option, { selected }) => (
                                <div className="select-item">{option ? option.name : ""}</div>
                              )}
                              getOptionSelected={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <Mui.TextField
                                  {...params}
                                  error={meta.touched && meta.error ? true : false}
                                  placeholder={""}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "nofill",
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  label={
                                    <>
                                      Duration In Hr{" "}
                                      <Mui.Tooltip
                                        className="tooltip-default"
                                        arrow
                                        placement="bottom-start"
                                        title={"Duration of the journey"}
                                      >
                                        <span>
                                          <IconInfo />
                                        </span>
                                      </Mui.Tooltip>
                                    </>
                                  }
                                />
                              )}
                            />
                            {meta.touched && meta.error ? (
                              <Mui.FormHelperText error>
                                {meta.touched && meta.error ? meta.error : null}
                              </Mui.FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className={`${this.props.theme === "dark" ? "form-group-dark" : "form-group"} ${
                        props.values.type === "flat_rate" ? "" : "d-none"
                      }`}
                    >
                      <Field name="routeId">
                        {({ field, meta }) => (
                          <>
                            <Autocomplete
                              options={this.props.routeList || []}
                              autoHighlight
                              autoComplete={true}
                              disableClearable={true}
                              getOptionLabel={(option) => (option ? option.name : "")}
                              value={this.state.route}
                              onChange={(event, selectedOption) => {
                                this.setState({ route: selectedOption });
                                props.setFieldValue("routeId", selectedOption?.id || "");
                              }}
                              renderOption={(option, { selected }) => (
                                <div className="select-item">{option ? option.name : ""}</div>
                              )}
                              getOptionSelected={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <Mui.TextField
                                  {...params}
                                  error={meta.touched && meta.error ? true : false}
                                  placeholder={""}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "nofill",
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  label={
                                    <>
                                      Route{" "}
                                      <Mui.Tooltip
                                        className="tooltip-default"
                                        arrow
                                        placement="bottom-start"
                                        title={"Route"}
                                      >
                                        <span>
                                          <IconInfo />
                                        </span>
                                      </Mui.Tooltip>
                                    </>
                                  }
                                />
                              )}
                            />
                            {meta.touched && meta.error ? (
                              <Mui.FormHelperText error>
                                {meta.touched && meta.error ? meta.error : null}
                              </Mui.FormHelperText>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Button
                    type="submit"
                    className="btn-default"
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    Book Now
                    <IconCarFront />
                  </Mui.Button>
                </Mui.Paper>
              </Form>
            )}
          </Formik>
        </Mui.Grid>
      </Mui.Grid>
    );
  }
}
export default Mui.withWidth()(BookNow);
