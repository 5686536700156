import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import Breadcrumb from "src/components/breadcrumb";
import { getRoute } from "src/reduxs/actions";
import { Link } from "react-router-dom";
import { IconChevronLeft, IconColorDescription, IconPin, IconRate } from "src/components/svg";
import Map from "./map";
import { formatCurrency } from "src/helpers/util";
class ViewRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getRoute(this.props.match.params.id);
    }
  }

  render() {
    return (
      <>
        <Breadcrumb
          title="Route Management"
          paths={[
            {
              title: "Route",
              page: `/route`,
            },
            {
              title: "View",
            },
          ]}
        />

        <Mui.Card className="default-card spacing-lg">
          <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
            <Mui.IconButton
              className="border-1 border-color-grey"
              type="button"
              component={Link}
              to={"/route"}
              size="small"
              style={{ padding: 6, marginRight: 15 }}
            >
              <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
                <IconChevronLeft />
              </Mui.Box>
            </Mui.IconButton>

            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
              Route Information
            </Mui.Typography>

            <Mui.Button
              className={`btn-default ${Mui.isWidthDown("sm", this.props.width) ? "mt-2" : "ml-2"}`}
              color="primary"
              type="button"
              variant="outlined"
              disableElevation
              component={Link}
              to={`/route/edit/${this.props.match.params.id}`}
            >
              Edit
            </Mui.Button>
          </Mui.Box>

          <Mui.Grid container spacing={6}>
            <Mui.Grid item xs={12} md={7}>
              <Mui.Typography component="h1" variant="h1" className="mt-2 mb-3 font-weight-semi-bold">
                {this.props.routeData?.name || ""}
              </Mui.Typography>

              <Mui.List>
                <Mui.ListItem className="mb-3 p-0">
                  <Mui.ListItemAvatar className="svg-size-flexible align-self-start">
                    <Mui.Avatar>
                      <IconColorDescription />
                    </Mui.Avatar>
                  </Mui.ListItemAvatar>

                  <Mui.ListItemText
                    className="m-0"
                    primary={
                      <Mui.Typography
                        component="span"
                        variant="body1"
                        className="mt-1 mb-1 d-block text-color-grey text-transform-uppercase font-weight-medium"
                      >
                        Description
                      </Mui.Typography>
                    }
                    secondary={
                      <Mui.Typography component="span" variant="subtitle2" className="font-weight-normal">
                        {this.props.routeData?.description || "No Description available"}
                      </Mui.Typography>
                    }
                  />
                </Mui.ListItem>

                <Mui.ListItem className="mb-3 p-0">
                  <Mui.ListItemAvatar className="svg-size-flexible align-self-start">
                    <Mui.Avatar className="background-color-secondary-light svg-size-small svg-color-secondary">
                      <IconPin />
                    </Mui.Avatar>
                  </Mui.ListItemAvatar>

                  <Mui.ListItemText
                    className="m-0"
                    primary={
                      <Mui.Typography
                        component="span"
                        variant="body1"
                        className="mt-1 mb-1 d-block text-color-grey text-transform-uppercase font-weight-medium"
                      >
                        Waypoints
                      </Mui.Typography>
                    }
                    secondary={this.props.routeData?.routeWaypoint?.map((waypoint, i) => (
                      <Mui.Chip
                        key={i}
                        avatar={<Mui.Avatar component="span">{i + 1}</Mui.Avatar>}
                        label={waypoint.location}
                        clickable
                        color="secondary"
                        variant="outlined"
                        component="span"
                        className={`h-auto ${Mui.isWidthDown("xs", this.props.width) ? "mb-1" : "mr-1 mb-1"}`}
                        classes={{ label: "white-space-normal" }}
                      />
                    ))}
                  />
                </Mui.ListItem>

                <Mui.ListItem className="mb-3 p-0">
                  <Mui.ListItemAvatar className="svg-size-flexible align-self-start">
                    <Mui.Avatar className="background-color-color1-light svg-size-small">
                      <IconRate />
                    </Mui.Avatar>
                  </Mui.ListItemAvatar>

                  <Mui.ListItemText
                    className="m-0"
                    primary={
                      <Mui.Typography
                        component="span"
                        variant="body1"
                        className="mt-1 mb-1 d-block text-color-grey text-transform-uppercase font-weight-medium"
                      >
                        Price Information
                      </Mui.Typography>
                    }
                    secondary={this.props.routeData?.routeDetail?.map((item, j) => (
                      <Mui.Box key={j} display="flex" flexWrap="wrap" component="span" className="text-color-default">
                        <Mui.Box width={`${Mui.isWidthDown("xs", this.props.width) ? "100%" : "25%"}`} component="span">
                          <Mui.Typography component="span" variant="body1" className="font-weight-medium">
                            {item.vehicleType?.name || ""}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Box width={`${Mui.isWidthDown("xs", this.props.width) ? "100%" : "70%"}`} component="span">
                          <Mui.Typography component="span" variant="h5">
                            {formatCurrency(item.price)}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Divider light={true} className="w-100 mt-1 mb-1" />
                      </Mui.Box>
                    ))}
                  />
                </Mui.ListItem>
              </Mui.List>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={5}>
              <Map values={this.props.routeData || {}} />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>
      </>
    );
  }
}
const mapStateToProps = ({ route }) => {
  const { routeData, success, message, loading, error } = route;
  return { routeData, success, message, loading, error };
};
export default connect(mapStateToProps, { getRoute })(Mui.withWidth()(ViewRoute));
