import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { getAllRoute, filterCar, getSchedular, calculatePrice, resetPrice } from "src/reduxs/actions";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import BookNow from "src/components/book-now";
import Quote from "src/components/quote";
import heroBannerImg from "src/assets/images/website/hero-banner.jpg";
import aboutImg1 from "src/assets/images/website/about-image-1.jpg";
import aboutImg2 from "src/assets/images/website/about-image-2.jpg";
import featureImg1 from "src/assets/images/website/feature-image-1.jpg";
import featureImg2 from "src/assets/images/website/feature-image-2.jpg";
import featureImg3 from "src/assets/images/website/feature-image-3.jpg";
import featureImg4 from "src/assets/images/website/feature-image-4.jpg";
import featureImg5 from "src/assets/images/website/feature-image-5.jpg";
import { IconArrowRight } from "src/components/svg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { quoteModal: false };
    this.featureSlideRef = React.createRef();
    this.testimonialSlideRef = React.createRef();
  }

  componentDidMount() {
    this.props.getAllRoute();
    this.props.filterCar({});
    this.props.getSchedular();
  }

  onNavigate = (values) => {
    // this.props.history.push({
    //   pathname: "/ride",
    //   state: { rideDetail: values },
    // });
    this.props.history.push({
      pathname: "/contact",
    });
  };

  onFilter = (routeId = null) => {
    this.props.filterCar({
      routeId: routeId,
    });
  };

  calculateBookingPrice = (values) => {
    this.props.calculatePrice(values);
  };

  render() {
    const featureSlideOption = {
      type: "loop",
      perPage: 3,
      perMove: 1,
      gap: "3rem",
      pagination: false,
      autoplay: true,
      breakpoints: {
        640: {
          perPage: 1,
        },
      },
    };

    const testimonialSlideOption = {
      type: "loop",
      // perPage: Mui.isWidthDown("xs", this.props.width) ? 1 : 2,
      perPage: 1,
      perMove: 1,
      gap: 0,
      pagination: false,
      autoplay: false,
      // focus: "center",
      // trimSpace: true,
    };

    return (
      <WebsiteLayout>
        <PageMeta />

        <section id="home" className="hero-banner">
          <img src={heroBannerImg} alt="Hero Banner" className="img-fluid-cover" />
          <Mui.Container maxWidth="md" className="pos-relative">
            <Mui.Typography
              component="h1"
              variant="h1"
              // font-Tangerine
              className="mt-5 font-weight-light text-color-white"
            >
              We'll get you there in style <br />
              <span className="font-weight-medium">on time,</span> every time!
            </Mui.Typography>

            {/* <Mui.Button
              component={Link}
              to={"/our-fleet"}
              className="mt-5 mb-5 pl-0 text-transform-none text-color-white"
            >
              Explore Now
              <span className="ml-2 svg-color-white line-height-null">
                <IconArrowRight />
              </span>
            </Mui.Button> */}
            <div className="mt-5 mb-5  btn-get-quote-wrap">
              <Mui.Button
                className="text-transform-none text-color-white btn-get-quote"
                variant="contained"
                color="secondary"
                component={Link}
                to="/contact"
                // onClick={() => this.setState({ quoteModal: true })}
              >
                GET A QUICK QUOTE
                <span className="ml-2 svg-size-small svg-color-white line-height-null">
                  <IconArrowRight />
                </span>
              </Mui.Button>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            <BookNow onNavigate={this.onNavigate} routeList={this.props.routes} schedules={this.props.schedules} />
          </Mui.Container>
        </section>

        <section id="about" className="about-area">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={6}>
                <Mui.Typography
                  component="h2"
                  variant="h2"
                  className={`section-title ${Mui.isWidthDown("xs", this.props.width) ? "text-center" : ""}`}
                >
                  <Mui.Typography component="span" variant="h6" className="d-block">
                    About Us
                  </Mui.Typography>
                  What can you expect from us?
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h5"
                  className="text-color-grey font-weight-normal line-height-2x"
                >
                  Shangri-La Chauffeurs aims to give customers a luxurious and comfortable experience. Our customer is
                  our main priority. We help business people, tourists, wedding parties, couples and families reach
                  their destination feeling relaxed and refreshed. Since 2013, we’ve gained an impressive reputation for
                  our reliability and attention to detail. We are always there when you need us – on time, every time.
                  Our exceptional drivers are fully licensed and trained, with safety as their first priority. They are
                  always very well presented, respectful and discreet. You’ll find Shangri-La Chauffeurs in every major
                  city, including Perth, Brisbane, Adelaide, Sydney, Melbourne and the Gold Coast. Let us take you where
                  you need to go – in style!
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Hidden smDown>
                <Mui.Grid item xs={12} md={6}>
                  <figure className="stacked-img">
                    <img src={aboutImg2} alt="About section" className="img-2" />
                    <img src={aboutImg1} alt="About section" className="img-1" />
                  </figure>
                </Mui.Grid>
              </Mui.Hidden>
            </Mui.Grid>
          </Mui.Container>
        </section>

        <section id="feature" className="feature-area">
          <Mui.Container maxWidth="md">
            <Mui.Typography component="h2" variant="h2" className="section-title">
              <Mui.Typography component="span" variant="h6" className="d-block">
                Features
              </Mui.Typography>
              See What We Can Do for You
            </Mui.Typography>

            <Splide options={featureSlideOption}>
              <SplideSlide>
                <figure className="feature-item">
                  <img src={featureImg5} alt="" />
                  <Mui.Box className="feature-item__content">
                    <Mui.Typography component="h3" variant="h3">
                      Airport Transfer
                    </Mui.Typography>
                    <Mui.Typography component="p" variant="body1" className="mt-1">
                      Get to and from your domestic or international flight on time, relaxed and in style with our
                      luxury vehicles.
                    </Mui.Typography>
                    <Mui.Button
                      variant="outlined"
                      color="primary"
                      className="mt-2"
                      component={Link}
                      to="/airport-transfers"
                    >
                      View More
                    </Mui.Button>
                  </Mui.Box>
                </figure>
              </SplideSlide>

              <SplideSlide>
                <figure className="feature-item">
                  <img src={featureImg2} alt="Business Transfers" />
                  <Mui.Box component="figcaption" className="feature-item__content">
                    <Mui.Typography component="h3" variant="h3">
                      Business Transfers
                    </Mui.Typography>
                    <Mui.Typography component="p" variant="body1" className="mt-1">
                      Just relax or prepare for the next meeting while a chauffeur drives you to your destination in our
                      deluxe corporate vehicles.
                    </Mui.Typography>
                    <Mui.Button
                      variant="outlined"
                      color="primary"
                      className="mt-2"
                      component={Link}
                      to="/business-transfers"
                    >
                      View More
                    </Mui.Button>
                  </Mui.Box>
                </figure>
              </SplideSlide>

              <SplideSlide>
                <figure className="feature-item">
                  <img src={featureImg4} alt="Weddings" />
                  <Mui.Box component="figcaption" className="feature-item__content">
                    <Mui.Typography component="h3" variant="h3">
                      Weddings
                    </Mui.Typography>
                    <Mui.Typography component="p" variant="body1" className="mt-1">
                      Make a grand entrance on your big day in a luxury vehicle driven by one of our friendly and
                      impeccably dressed drivers.
                    </Mui.Typography>
                    <Mui.Button
                      variant="outlined"
                      color="primary"
                      className="mt-2"
                      component={Link}
                      to="/wedding-vechile"
                    >
                      View More
                    </Mui.Button>
                  </Mui.Box>
                </figure>
              </SplideSlide>

              <SplideSlide>
                <figure className="feature-item">
                  <img src={featureImg1} alt="Tour" />
                  <Mui.Box component="figcaption" className="feature-item__content">
                    <Mui.Typography component="h3" variant="h3">
                      Tour
                    </Mui.Typography>
                    <Mui.Typography component="p" variant="body1" className="mt-1">
                      Be spontaneous! Take a tour of your favourite city in comfort and style with our chauffeured
                      luxury vehicles.
                    </Mui.Typography>
                    <Mui.Button variant="outlined" color="primary" className="mt-2" component={Link} to="/tour">
                      View More
                    </Mui.Button>
                  </Mui.Box>
                </figure>
              </SplideSlide>

              <SplideSlide>
                <figure className="feature-item">
                  <img src={featureImg3} alt="Special Events" />
                  <Mui.Box component="figcaption" className="feature-item__content">
                    <Mui.Typography component="h3" variant="h3">
                      Special Events
                    </Mui.Typography>
                    <Mui.Typography component="p" variant="body1" className="mt-1">
                      Add some fun and style to your events. Arrive in one of our deluxe, chauffeur-driven vehicles and
                      make an impression!
                    </Mui.Typography>
                    <Mui.Button
                      variant="outlined"
                      color="primary"
                      className="mt-2"
                      component={Link}
                      to="/special-event"
                    >
                      View More
                    </Mui.Button>
                  </Mui.Box>
                </figure>
              </SplideSlide>
            </Splide>
          </Mui.Container>
        </section>

        <section id="testimonial" className="testimonial-area">
          <Mui.Container maxWidth="md">
            <Mui.Typography component="h2" variant="h2" className="section-title">
              What Our Client Says
              <Mui.Typography component="span" variant="h6" className="d-block">
                Our client send us bunch of smiles with our services and love them.
              </Mui.Typography>
            </Mui.Typography>

            <Splide
              options={testimonialSlideOption}
              className="testimonial-slider"
              renderControls={() => (
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <IconArrowRight />
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <IconArrowRight />
                  </button>
                </div>
              )}
            >
              <SplideSlide className="testimonial-slide">
                <figure>
                  <img src={require("../assets/images/logo-small.svg").default} alt="user" />
                </figure>
                <div className="box">
                  <Mui.Typography component="h3" variant="h3">
                    Lawrence Christensen
                    <Mui.Typography component="small" variant="body2">
                      Coles Senior Consultant
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography component="p" variant="body1">
                    I have been a client of Manjil’s for several years, I have always found his service tone efficient
                    and courteous and in particular reliable if you arrange to be collected from the airport or you need
                    to be at an important appointment , you can be confident that you will be met on time,
                  </Mui.Typography>
                </div>
              </SplideSlide>

              <SplideSlide className="testimonial-slide">
                <figure>
                  <img src={require("../assets/images/logo-small.svg").default} alt="user" />
                </figure>

                <div className="box">
                  <Mui.Typography component="h3" variant="h3">
                    Allison Davis
                  </Mui.Typography>
                  <Mui.Typography component="p" variant="body1">
                    “I have engaged the services of Shangri-La Chauffeurs both professionally and personally for the
                    past 7 years and cannot recommend them highly enough.
                    <br /> Whether it be for transportation to and from the airport for travel, or locally for an event,
                    Shangri-La Chauffeurs is without a doubt my preferred chauffeur company.
                    <br /> They are always punctual and provide an exceptionally clean service with reasonable prices.
                    Manjil is exceptionally professional.
                    <br /> I have used various providers over the years and Shangri-La Chauffeurs is by far the best I
                    have used.”
                  </Mui.Typography>
                </div>
              </SplideSlide>

              <SplideSlide className="testimonial-slide">
                <figure>
                  <img src={require("../assets/images/logo-small.svg").default} alt="user" />
                </figure>{" "}
                <div className="box">
                  <Mui.Typography component="h3" variant="h3">
                    David Negri
                    <Mui.Typography component="small" variant="body2">
                      Regional Sales Director
                    </Mui.Typography>
                    <Mui.Typography component="small" variant="body2">
                      Software AG
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography component="p" variant="body1">
                    I would highly recommend using Manjil’s service, he and his team are punctual, professional, always
                    have clean cars and can always be counted on to offer a superior service.
                  </Mui.Typography>
                </div>
              </SplideSlide>

              <SplideSlide className="testimonial-slide">
                <figure>
                  <img src={require("../assets/images/logo-small.svg").default} alt="user" />
                </figure>
                <div className="box">
                  <Mui.Typography component="h3" variant="h3">
                    Terence Swampillai
                    <Mui.Typography component="small" variant="body2">
                      Alliance Management
                    </Mui.Typography>
                    <Mui.Typography component="small" variant="body2">
                      Oneworld Project Director at Malaysia Airlines
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography component="p" variant="body1">
                    I have used the services of Shangri-La Chauffeurs on more than a couple of occasions in Melbourne,
                    the latest being with regard to the transportation of the next of kin of accident victims. I have
                    found their services exemplary and beyond reproach. I dealt directly with the Managing Director, Mr.
                    Manjil Pradhan, who personally ensured that my transportation needs were met to the satisfaction of
                    my clients. I highly recommend their services without hesitation.
                  </Mui.Typography>
                </div>
              </SplideSlide>

              <SplideSlide className="testimonial-slide">
                <figure>
                  <img src={require("../assets/images/logo-small.svg").default} alt="user" />
                </figure>
                <div className="box">
                  <Mui.Typography component="h3" variant="h3">
                    Stephen Mott
                    <Mui.Typography component="small" variant="body2">
                      Braywin (Vic) Pty Ltd
                    </Mui.Typography>
                    <Mui.Typography component="small" variant="body2">
                      Managing Director
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography component="p" variant="body1">
                    Manjil and his Team have been looking after my family for nearly Ten (10) Years. He is absolutely
                    brilliant and I can understand why People such as Eddie McGuire and Dita Von Tease use his services.
                    We used to go to the Races every week, and Business and Travel with him. Around 200 Pick ups per
                    year. We have never had a problem and his Service is second to none. Manjil is such a nice Guy too.
                    Never late once at the Races to pick us up. Other Companies do not even know where to collect you
                    from!
                    <br /> I cannot recommend them and Manjil any higher.
                  </Mui.Typography>
                </div>
              </SplideSlide>
            </Splide>
          </Mui.Container>
        </section>

        <Quote
          open={this.state.quoteModal}
          close={() => {
            this.setState({ quoteModal: false });
            this.onFilter();
            this.props.resetPrice();
          }}
          carList={this.props.carList}
          routeList={this.props.routes}
          onFilter={this.onFilter}
          schedules={this.props.schedules}
          priceData={this.props.priceData}
          calculateBookingPrice={this.calculateBookingPrice}
        />
      </WebsiteLayout>
    );
  }
}
const mapStateToProps = ({ route, ride, shared }) => {
  const { routes } = route;
  const { carList, priceData } = ride;
  const { schedules } = shared;
  return { routes, carList, schedules, priceData };
};

export default connect(mapStateToProps, {
  getAllRoute,
  filterCar,
  getSchedular,
  calculatePrice,
  resetPrice,
})(Mui.withWidth()(Home));
