import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getUserUpcomingBookingList,
  getUserCompletedBookingList,
  getAllBooking,
  mergeInvoice,
  resetConsolidateInvoice,
} from "src/reduxs/actions";
// import carPlaceholder from "src/assets/images/car.svg";
import Pagination from "src/components/datatable/pagination";
import Search from "src/components/search";
import BookingCard from "src/components/booking-card";
import DataTable from "src/components/datatable";
import { IconInvoice } from "src/components/svg";
import Invoice from "./invoice";
class MyBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      search: "",
      search1: "",
      page: 1,
      page1: 1,
      pageSize: 10,
      pageSize1: 10,
      typingTimeout: 0,
      typingTimeout1: 0,
      ids: null,
      openAlert: false,
    };
    this.searchFields =
      "booking_number:like;type:like;pickup_date:like;pickup_location:like;drop_off_location:like;vehicleType.name:like;route.name:like;driver.name:like;";
  }

  componentDidMount() {
    this.props.getUserUpcomingBookingList({ userId: this.props.userId });
    this.props.getUserCompletedBookingList({ userId: this.props.userId });
  }

  toggle = (event, value) => {
    this.setState({ tab: value });
    if (value === 0) {
      this.props.getUserUpcomingBookingList({ userId: this.props.userId });
    } else if (value === 1) {
      this.props.getUserCompletedBookingList({ userId: this.props.userId });
    } else if (value === 2) {
      this.props.getAllBooking({ userId: this.props.userId });
    }
  };

  handleChangePage = (page) => {
    this.setState({ page: page });
    this.props.getUserUpcomingBookingList({
      userId: this.props.userId,
      search: this.state.search,
      searchFields: this.searchFields,
      page: page,
      pageSize: this.state.pageSize,
    });
  };

  handleChangePage1 = (page) => {
    this.setState({ page1: page });
    this.props.getUserCompletedBookingList({
      userId: this.props.userId,
      search: this.state.search1,
      searchFields: this.searchFields,
      page: page,
      pageSize: this.state.pageSize1,
    });
  };

  handleChangePageSize = (pageSize) => {
    this.setState({ pageSize: pageSize });
    this.props.getUserUpcomingBookingList({
      userId: this.props.userId,
      search: this.state.search,
      searchFields: this.searchFields,
      page: 1,
      pageSize: pageSize,
    });
  };

  handleChangePageSize1 = (pageSize) => {
    this.setState({ pageSize1: pageSize });
    this.props.getUserCompletedBookingList({
      userId: this.props.userId,
      search: this.state.search1,
      searchFields: this.searchFields,
      page: 1,
      pageSize: pageSize,
    });
  };

  handleSearch = (event) => {
    let value = event.target.value;
    this.setState({ search: value });
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    let _self = this;
    this.setState((prevState) => ({
      typingTimeout: setTimeout(function () {
        _self.props.getUserUpcomingBookingList({
          userId: _self.props.userId,
          search: value,
          searchFields: _self.searchFields,
          page: prevState.page,
          pageSize: prevState.pageSize,
        });
      }, 1000),
    }));
  };

  handleSearch1 = (event) => {
    let value = event.target.value;
    this.setState({ search1: value });
    if (this.state.typingTimeout1) {
      clearTimeout(this.state.typingTimeout1);
    }
    let _self = this;
    this.setState((prevState) => ({
      typingTimeout: setTimeout(function () {
        _self.props.getUserCompletedBookingList({
          userId: _self.props.userId,
          search: value,
          searchFields: _self.searchFields,
          page: prevState.page1,
          pageSize: prevState.pageSize1,
        });
      }, 1000),
    }));
  };

  handleOpenAlert = (ids) => {
    this.setState({ openAlert: true, ids: ids });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, ids: null });
  };

  onMerge = (values) => {
    if (!this.props.downloading) {
      values.ids = this.state.ids;
      values.userId = this.props.userId;
      this.props.mergeInvoice(values);
    }
  };

  render() {
    return (
      <>
        <Mui.Box display="flex" flexDirection={Mui.isWidthDown("sm", this.props.width) ? "column" : "row"}>
          <Mui.Tabs
            value={this.state.tab}
            onChange={this.toggle}
            variant="scrollable"
            indicatorColor="primary"
            scrollButtons={Mui.isWidthUp("md", this.props.width) ? "auto" : "on"}
            className="default-tab-outlined"
          >
            <Mui.Tab label={"Upcoming Booking"} />
            <Mui.Tab label={"Previous Booking"} />
            <Mui.Tab label={"All Booking"} />
          </Mui.Tabs>

          <Mui.Box className="mb-4 ml-auto">
            {this.state.tab === 0 && <Search value={this.state.search} onSearch={this.handleSearch} />}

            {this.state.tab === 1 && <Search value={this.state.search1} onSearch={this.handleSearch1} />}
          </Mui.Box>
        </Mui.Box>

        {this.state.tab === 0 ? (
          <>
            <Mui.Grid container spacing={2} className="space-all">
              {this.props.upcomingBookingList?.data?.map((item, index) => {
                return (
                  <Mui.Grid key={index} item xs={12} lg={6}>
                    <BookingCard
                      key={index}
                      data={item}
                      UI="outlined"
                      classes="h-100"
                      component={Link}
                      to={`/booking/view/${item.id}`}
                    />
                  </Mui.Grid>
                );
              })}
            </Mui.Grid>

            {this.props.upcomingBookingList?.data?.length === 0 ? (
              <Mui.Typography component="h6" variant="h6" className="text-center font-weight-medium text-color-muted">
                No upcoming bookings
              </Mui.Typography>
            ) : null}

            <Mui.Box className="mt-5">
              <Pagination
                page={this.state.page}
                totalPage={this.props.upcomingBookingList?.meta?.pages || 0}
                totalItem={this.props.upcomingBookingList?.meta?.total || 0}
                start={this.props.upcomingBookingList?.meta?.start || 0}
                end={this.props.upcomingBookingList?.meta?.end || 0}
                handleChangePage={(event, newPage) => this.handleChangePage(newPage)}
                handleChangePageSize={(event) => this.handleChangePageSize(parseInt(event.target.value, 10))}
                pageSize={this.state.pageSize}
              />
            </Mui.Box>
          </>
        ) : null}

        {this.state.tab === 1 ? (
          <>
            <Mui.Grid container spacing={2} className="space-all">
              {this.props.completedBookingList?.data?.map((item, index) => {
                return (
                  <Mui.Grid key={index} item xs={12} lg={6}>
                    <BookingCard
                      data={item}
                      UI="outlined"
                      classes="h-100"
                      component={Link}
                      to={`/booking/view/${item.id}`}
                    />
                  </Mui.Grid>
                );
              })}
            </Mui.Grid>

            {this.props.completedBookingList?.data?.length === 0 ? (
              <Mui.Typography component="h6" variant="h6" className="text-center font-weight-medium text-color-muted">
                No previous bookings
              </Mui.Typography>
            ) : null}
            <Mui.Box className="mt-5">
              <Pagination
                page={this.state.page1}
                totalPage={this.props.completedBookingList?.meta?.pages || 0}
                totalItem={this.props.completedBookingList?.meta?.total || 0}
                start={this.props.completedBookingList?.meta?.start || 0}
                end={this.props.completedBookingList?.meta?.end || 0}
                handleChangePage={(event, newPage) => this.handleChangePage1(newPage)}
                handleChangePageSize={(event) => this.handleChangePageSize1(parseInt(event.target.value, 10))}
                pageSize={this.state.pageSize1}
              />
            </Mui.Box>
          </>
        ) : null}

        {this.state.tab === 2 ? (
          <>
            <DataTable
              title="Merge Invoice"
              headCells={[
                {
                  id: "bookingNumber",
                  sortKey: "booking_number",
                  label: "BOOKING NUMBER",
                },
                {
                  id: "date",
                  sortKey: "pickup_date",
                  label: "PICK-UP DATE",
                },
                {
                  id: "vehicleType",
                  sortKey: "vehicle_type_id",
                  label: "VEHICLE TYPE",
                },
                {
                  id: "status",
                  sortKey: "status",
                  label: "BOOKING STATUS",
                },
                {
                  id: "paymentStatus",
                  sortKey: "payment_status",
                  label: "PAYMENT STATUS",
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: "Created Date",
                },
              ]}
              rows={this.props.bookings || []}
              enablePagination={false}
              handleOpenAlert1={this.handleOpenAlert}
              enableCheckbox={true}
              enableSearch={false}
              hideAction={true}
              tableStyle="default"
              bulkSelectionIcon={<IconInvoice />}
              bulkSelectionSuccess={this.props.mergeInvoiceSuccess}
            />

            <Invoice
              open={this.state.openAlert}
              close={this.handleCloseAlert}
              onSubmit={this.onMerge}
              loading={this.props.downloading}
              success={this.props.mergeInvoiceSuccess}
              reset={this.props.resetConsolidateInvoice}
            />
          </>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = ({ user, booking, consolidateInvoice }) => {
  const { upcomingBookingList, completedBookingList, success, message, loading, error } = user;
  const { bookings } = booking;
  const { downloading, success: mergeInvoiceSuccess } = consolidateInvoice;
  return {
    upcomingBookingList,
    completedBookingList,
    success,
    message,
    loading,
    error,
    bookings,
    downloading,
    mergeInvoiceSuccess,
  };
};
export default connect(mapStateToProps, {
  getUserUpcomingBookingList,
  getUserCompletedBookingList,
  getAllBooking,
  mergeInvoice,
  resetConsolidateInvoice,
})(Mui.withWidth()(MyBooking));
