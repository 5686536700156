import React from "react";

const IconBriefcase = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 512 512"
    >
      <path d="M472,128H352V72a24.032,24.032,0,0,0-24-24H184a24.032,24.032,0,0,0-24,24v56H40A32.042,32.042,0,0,0,8,160V432a32.042,32.042,0,0,0,32,32H472a32.042,32.042,0,0,0,32-32V160A32.042,32.042,0,0,0,472,128ZM64,448H40a16.021,16.021,0,0,1-16-16V160a16.021,16.021,0,0,1,16-16H64Zm48,0H80V144h32ZM176,72a8.011,8.011,0,0,1,8-8H328a8.011,8.011,0,0,1,8,8v56H320V88a8,8,0,0,0-8-8H200a8,8,0,0,0-8,8v40H176ZM304,96v32H208V96ZM184,200.94l24,12V296H160V212.94ZM384,448H128V144h48v43.06l-27.58,13.78A8.011,8.011,0,0,0,144,208v96a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V208a8.011,8.011,0,0,0-4.42-7.16L192,187.06V144H384Zm48,0H400V144h32Zm56-16a16.021,16.021,0,0,1-16,16H448V144h24a16.021,16.021,0,0,1,16,16Z" />
    </svg>
  );
};

export default IconBriefcase;
