import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import ScheduleList from "./list";
import AddSchedule from "./add";

function Schedule() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/schedule" component={ScheduleList} />
        <ContentRoute path="/schedule/add" component={AddSchedule} />
      </Switch>
    </Suspense>
  );
}

export default Schedule;
