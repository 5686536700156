import Req from "src/interceptors/token-interceptor";

const RideService = (function () {
  function _bookRide(data) {
    return Req.post("/api/bookings/create", data);
  }
  function _rebookRide(data) {
    return Req.post(`/api/bookings/rebook/${data.id}`, data);
  }
  function _filterCar(param) {
    return Req.get(
      `/api/vehicle_types/filter?passenger=${param.passenger || ""}&luggage=${
        param.luggage || ""
      }&routeId=${param.routeId || ""}`
    );
  }
  function _calculatePrice(data) {
    return Req.post(`/api/bookings/calculatePrice`, data);
  }
  function _applyCoupon(code, data) {
    return Req.post(`/api/coupons/apply/${code}`, data);
  }
  function _getUserCard(id) {
    return Req.get(`/api/users/card/${id}`);
  }
  return {
    bookRide: _bookRide,
    rebookRide: _rebookRide,
    filterCar: _filterCar,
    calculatePrice: _calculatePrice,
    applyCoupon: _applyCoupon,
    getUserCard: _getUserCard,
  };
})();
export default RideService;
