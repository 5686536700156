export const GET_ALL_COUPON = "GET_ALL_COUPON";
export const GET_ALL_COUPON_SUCCESS = "GET_ALL_COUPON_SUCCESS";
export const GET_ALL_COUPON_ERROR = "GET_ALL_COUPON_ERROR";
export const GET_COUPON_LIST = "GET_COUPON_LIST";
export const GET_COUPON_LIST_SUCCESS = "GET_COUPON_LIST_SUCCESS";
export const GET_COUPON_LIST_ERROR = "GET_COUPON_LIST_ERROR";
export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_ERROR = "ADD_COUPON_ERROR";
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_ERROR = "GET_COUPON_ERROR";
export const EDIT_COUPON = "EDIT_COUPON";
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS";
export const EDIT_COUPON_ERROR = "EDIT_COUPON_ERROR";
export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_ERROR = "DELETE_COUPON_ERROR";
export const DELETE_MULTIPLE_COUPON = "DELETE_MULTIPLE_COUPON";
export const DELETE_MULTIPLE_COUPON_SUCCESS = "DELETE_MULTIPLE_COUPON_SUCCESS";
export const DELETE_MULTIPLE_COUPON_ERROR = "DELETE_MULTIPLE_COUPON_ERROR";
export const RESET_COUPON = "RESET_COUPON";

export const getAllCoupon = () => ({
  type: GET_ALL_COUPON,
  payload: {},
});

export const getAllCouponSuccess = (coupons) => ({
  type: GET_ALL_COUPON_SUCCESS,
  payload: coupons,
});

export const getAllCouponError = (error) => ({
  type: GET_ALL_COUPON_ERROR,
  payload: error,
});

export const getCouponList = (dbParam) => ({
  type: GET_COUPON_LIST,
  payload: { dbParam },
});

export const getCouponListSuccess = (couponList) => ({
  type: GET_COUPON_LIST_SUCCESS,
  payload: couponList,
});

export const getCouponListError = (error) => ({
  type: GET_COUPON_LIST_ERROR,
  payload: error,
});

export const addCoupon = (couponData, history) => ({
  type: ADD_COUPON,
  payload: { couponData, history },
});

export const addCouponSuccess = (success, message) => ({
  type: ADD_COUPON_SUCCESS,
  payload: { success, message },
});

export const addCouponError = (error) => ({
  type: ADD_COUPON_ERROR,
  payload: error,
});

export const getCoupon = (couponId) => ({
  type: GET_COUPON,
  payload: { couponId },
});

export const getCouponSuccess = (couponData) => ({
  type: GET_COUPON_SUCCESS,
  payload: couponData,
});

export const getCouponError = (error) => ({
  type: GET_COUPON_ERROR,
  payload: error,
});

export const editCoupon = (couponId, couponData, history) => ({
  type: EDIT_COUPON,
  payload: { couponId, couponData, history },
});

export const editCouponSuccess = (success, message) => ({
  type: EDIT_COUPON_SUCCESS,
  payload: { success, message },
});

export const editCouponError = (error) => ({
  type: EDIT_COUPON_ERROR,
  payload: error,
});

export const deleteCoupon = (couponId) => ({
  type: DELETE_COUPON,
  payload: { couponId },
});

export const deleteCouponSuccess = (success, message) => ({
  type: DELETE_COUPON_SUCCESS,
  payload: { success, message },
});

export const deleteCouponError = (error) => ({
  type: DELETE_COUPON_ERROR,
  payload: error,
});

export const deleteMultipleCoupon = (couponIds) => ({
  type: DELETE_MULTIPLE_COUPON,
  payload: { couponIds },
});

export const deleteMultipleCouponSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_COUPON_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleCouponError = (error) => ({
  type: DELETE_MULTIPLE_COUPON_ERROR,
  payload: error,
});

export const resetCoupon = () => ({
  type: RESET_COUPON,
  payload: {},
});
