import { defaultCurrency } from "src/configs/constant";
import moment from "moment";

export const formatCurrency = (value) => {
  let sym = defaultCurrency.symbol || "";
  let pos = defaultCurrency.symbolPosition || "before";
  let val = value ? numberWithCommas(value) : "0";
  if (pos === "before") {
    return `${sym} ${val}`;
  } else {
    return `${val} ${sym}`;
  }
};

export const numberWithCommas = (x) => {
  return x.toLocaleString();
};

export const parseMessage = (message) => {
  var text = "";
  if (typeof message == "object") {
    for (let key in message) {
      if (Array.isArray(message[key])) {
        // eslint-disable-next-line no-loop-func
        message[key].forEach((elem) => {
          text += elem;
        });
      } else if (typeof message[key] == "string") {
        text += message[key];
      } else {
        text += JSON.stringify(message[key]);
      }
    }
  } else if (typeof message == "string") {
    text = message;
  } else {
    text += JSON.stringify(message);
  }
  return text;
};

export const extractValue = (arr, prop) => {
  // extract value from property
  let extractedValue = arr.map((item) => item[prop]);
  return extractedValue;
};

export const disableDate = (schedules, date) => {
  if (!schedules?.length) return false;
  const a = moment(date).format("YYYY-MM-DD");
  for (let i = 0; i < schedules.length; i++) {
    const schedule = schedules[i];
    const b = moment(schedule.start_date).format("YYYY-MM-DD");
    const c = moment(schedule.end_date).format("YYYY-MM-DD");
    if (a === b) {
      if (moment(schedule.start_time, "HH:mm:ss").format("HH:mm") === "00:00") return true;
    } else if (a === c) {
      if (moment(schedule.end_time, "HH:mm:ss").format("HH:mm") === "23:55") return true;
    } else if (moment(a).isBetween(b, c, undefined)) {
      return true;
    }
  }
  return false;
};

export const isBetweenStartEndDate = (schedules, date) => {
  if (!schedules?.length) return false;
  const a = moment(date).format("YYYY-MM-DD");
  for (let i = 0; i < schedules.length; i++) {
    const schedule = schedules[i];
    const b = moment(schedule.start_date).format("YYYY-MM-DD");
    const c = moment(schedule.end_date).format("YYYY-MM-DD");
    if (moment(a).isBetween(b, c, undefined, "[]")) return true;
  }
  return false;
};

export const disableTime = (schedules, date) => {
  if (!schedules?.length) return [];
  const a = moment(date).format("YYYY-MM-DD");
  for (let i = 0; i < schedules.length; i++) {
    const hr = [];
    const schedule = schedules[i];
    const b = moment(schedule.start_date).format("YYYY-MM-DD");
    const c = moment(schedule.end_date).format("YYYY-MM-DD");
    if (a === b && a === c) {
      // 1 date
      for (
        var j = moment(schedule.start_time, "HH:mm:ss").format("H");
        j <= moment(schedule.end_time, "HH:mm:ss").format("H");
        j++
      ) {
        hr.push(Number(j));
      }
      return hr;
    } else if (a === b) {
      for (var k = moment(schedule.start_time, "HH:mm:ss").format("H"); k <= 23; k++) {
        hr.push(Number(k));
      }
      return hr;
    } else if (a === c) {
      for (var o = 0; o <= moment(schedule.end_time, "HH:mm:ss").format("H"); o++) {
        hr.push(Number(o));
      }
      return hr;
    }
  }
  return [];
};

export const durationHourList = [
  { id: "01:00:00", name: "1 hour(s)" },
  { id: "02:00:00", name: "2 hour(s)" },
  { id: "03:00:00", name: "3 hour(s)" },
  { id: "04:00:00", name: "4 hour(s)" },
  { id: "05:00:00", name: "5 hour(s)" },
  { id: "06:00:00", name: "6 hour(s)" },
  { id: "07:00:00", name: "7 hour(s)" },
  { id: "08:00:00", name: "8 hour(s)" },
  { id: "09:00:00", name: "9 hour(s)" },
  { id: "10:00:00", name: "10 hour(s)" },
];

export const noPassenger = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "13", name: "13" },
  { id: "14", name: "14" },
  { id: "15", name: "15" },
  { id: "16", name: "16" },
  { id: "17", name: "17" },
  { id: "18", name: "18" },
  { id: "19", name: "19" },
  { id: "20", name: "20" },
  { id: "21", name: "21" },
  { id: "22", name: "22" },
  { id: "23", name: "23" },
  { id: "24", name: "24" },
  { id: "25", name: "25" },
  { id: "26", name: "26" },
  { id: "27", name: "27" },
  { id: "28", name: "28" },
  { id: "29", name: "29" },
  { id: "30", name: "30" },
];

export const noLuggage = [
  { id: "1", name: "N/A" },
  { id: "1", name: "Carry on only" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8+" },
];
