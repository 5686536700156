import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { IdleTimeoutManager } from "idle-timer-manager";
import { withRouter } from "react-router-dom";
import PageMeta from "src/components/pages-meta";
import { toolBarConfig, sideNavConfig } from "src/configs/constant";
import Topnav from "./topnav";
import Sidenav from "./sidenav";
class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDrawerOpen: false,
      drawerCollapse: false,
      drawerHover: false,
    };
  }

  _onIdle() {}

  hanndelMobileDrawerOpenToggle = () => {
    this.setState({ mobileDrawerOpen: !this.state.mobileDrawerOpen });
  };

  hanndelDrawerCollapseToggle = () => {
    this.setState({ drawerCollapse: !this.state.drawerCollapse });
  };

  onHoverDrawer = (hover) => {
    if (this.state.drawerCollapse && sideNavConfig.expandOnHover && !sideNavConfig.hoverSubMenu) {
      if (hover === "enter") {
        this.setState({ drawerHover: true });
      } else if (hover === "leave") {
        this.setState({ drawerHover: false });
      }
    }
  };

  checkSmallDevices = () => {
    if (Mui.isWidthUp("lg", this.props.width)) {
      this.setState({ drawerCollapse: sideNavConfig.collapseState.lg });
    } else if (Mui.isWidthUp("md", this.props.width)) {
      this.setState({ drawerCollapse: sideNavConfig.collapseState.md });
    } else {
      this.setState({ drawerCollapse: false });
    }
  };

  componentDidMount() {
    this.checkSmallDevices();
    this.manager = new IdleTimeoutManager({
      timeout: 600, // 10 min
      onExpired: (time) => {
        this._onIdle();
      },
    });

    // Set Side Nav Layout Colors
    document.documentElement.style.setProperty("--side-nav-bg", sideNavConfig.bgColor);
    document.documentElement.style.setProperty("--side-nav-text-color", sideNavConfig.textColor);
    document.documentElement.style.setProperty("--side-nav-icon-color", sideNavConfig.iconColor);
    document.documentElement.style.setProperty("--side-nav-active-color", sideNavConfig.activeColor);
    document.documentElement.style.setProperty("--side-nav-hover-submenu-color", sideNavConfig.hoverSubmenubg);

    // Set Top Bar Layout Colors
    document.documentElement.style.setProperty("--toolbar-bg", toolBarConfig.bgColor);
    document.documentElement.style.setProperty("--toolbar-text-color", toolBarConfig.textColor);
    document.documentElement.style.setProperty("--toolbar-icon-color", toolBarConfig.iconColor);
  }

  componentWillUnmount() {
    this.manager.clear();
  }

  render() {
    return (
      <>
        <PageMeta />

        <Mui.Drawer
          className={`cms-drawer ${this.state.drawerCollapse ? "collapsed" : "expanded"} ${
            this.state.drawerHover ? "hover-expand" : ""
          }`}
          variant={Mui.isWidthUp("md", this.props.width) ? "permanent" : "temporary"}
          onMouseEnter={() => this.onHoverDrawer("enter")}
          onMouseLeave={() => this.onHoverDrawer("leave")}
          open={this.state.mobileDrawerOpen}
          onClose={this.hanndelMobileDrawerOpenToggle}
          anchor="left"
        >
          <Sidenav location={this.props.location} closeDrawer={this.hanndelMobileDrawerOpenToggle} />
        </Mui.Drawer>

        <main className={`cms-drawer-content ${this.state.drawerCollapse ? "collapsed" : "expanded"}`}>
          <Topnav
            toggleSideNav={this.hanndelDrawerCollapseToggle}
            toggleMobileSideNav={this.hanndelMobileDrawerOpenToggle}
          />

          {toolBarConfig.style === "fixed" ? <Mui.Toolbar className="placeholder-toolbar" /> : null}

          <Mui.Container className="cms-body container-fluid" maxWidth={false}>
            {this.props.children}
          </Mui.Container>
        </main>
      </>
    );
  }
}

export default Mui.withWidth()(withRouter(AppLayout));
