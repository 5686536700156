import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import Breadcrumb from "src/components/breadcrumb";
import { getCar, downloadMedia } from "src/reduxs/actions";
import { Link } from "react-router-dom";
import {
  // IconClock,
  // IconRoute,
  IconChevronLeft,
  IconUsers,
  IconBriefcase,
  IconDownload,
} from "src/components/svg";
import carPlaceholder from "src/assets/images/car.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import fileIcon from "src/assets/images/icon-file.svg";
import moment from "moment";
class ViewCar extends Component {
  constructor(props) {
    super(props);
    this.mainSlideRef = React.createRef();
    this.thumbSlideRef = React.createRef();
  }

  componentDidMount() {
    // Set the sync target right after the component is mounted.
    this.mainSlideRef.current.sync(this.thumbSlideRef.current.splide);
    if (this.props.match.params.id) {
      this.props.getCar(this.props.match.params.id);
    }
  }

  render() {
    const mainSlideOption = {
      type: "fade",
      perPage: 1,
      perMove: 1,
      gap: "1rem",
      pagination: false,
    };

    const thumbSlideOption = {
      type: "loop",
      rewind: true,
      gap: "1rem",
      pagination: false,
      fixedWidth: 210,
      fixedHeight: 120,
      cover: true,
      focus: "center",
      arrows: false,
      isNavigation: true,
      updateOnMove: true,
    };

    return (
      <>
        <Breadcrumb
          title="Vehicle Type Management"
          paths={[
            {
              title: "Vehicle Type",
              page: `/car`,
            },
            {
              title: "View",
            },
          ]}
        />
        <Mui.Card className="default-card car-detail-section mt-0 spacing-lg mb-4">
          <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
            <Mui.IconButton
              className="border-1 border-color-grey"
              type="button"
              component={Link}
              to={"/car"}
              size="small"
              style={{ padding: 6, marginRight: 15 }}
            >
              <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
                <IconChevronLeft />
              </Mui.Box>
            </Mui.IconButton>

            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
              Vehicle Type Information
            </Mui.Typography>

            <Mui.Button
              className={`btn-default ${Mui.isWidthDown("sm", this.props.width) ? "mt-2" : "ml-2"}`}
              color="primary"
              type="button"
              variant="outlined"
              disableElevation
              component={Link}
              to={`/car/edit/${this.props.match.params.id}`}
            >
              Edit
            </Mui.Button>
          </Mui.Box>

          <Mui.Grid container spacing={6}>
            <Mui.Grid item xs={12} md={6}>
              <Mui.Typography component="h1" variant="h1" className="title">
                {this.props.carData?.name || ""}
              </Mui.Typography>

              <Mui.Box width="100%" display="flex" className="car-status primary">
                <Mui.Typography>
                  <IconUsers />
                  {this.props.carData?.maxPassenger || 0} Passenger
                </Mui.Typography>

                <Mui.Typography>
                  <IconBriefcase />
                  {this.props.carData?.maxLuggage || 0} Luggage
                </Mui.Typography>

                {/* <Mui.Typography>
                  <IconClock />$ 0 per Hour
                </Mui.Typography>

                <Mui.Typography>
                  <IconRoute />$ 0 per Km
                </Mui.Typography> */}
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  Engine:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.engine || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  INTERIOR COLOR:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.interiorColor || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  EXTERIOR COLOR:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.exteriorColor || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  POWER:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.power || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  FUEL TYPE:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.fuelType || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1">
                <Mui.Typography component="label" variant="subtitle1">
                  TRANSMISSION:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.transmission || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box display="flex" className="mt-1 mb-5">
                <Mui.Typography component="label" variant="subtitle1">
                  EXTRAS:
                </Mui.Typography>
                <Mui.Typography component="h6" variant="subtitle1" className="word-break-all">
                  {this.props.carData?.extra || "-"}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6}>
              <Splide options={mainSlideOption} ref={this.mainSlideRef}>
                {this.props.carData?.vehicleTypePhoto?.length > 0 ? (
                  <>
                    {this.props.carData?.vehicleTypePhoto?.map((img, j) => (
                      <SplideSlide className="car-views" key={j}>
                        <img className="img-fluid" src={img.url} alt="" />
                      </SplideSlide>
                    ))}
                  </>
                ) : null}
              </Splide>

              <Splide className="slide-thumb" options={thumbSlideOption} ref={this.thumbSlideRef}>
                {this.props.carData?.vehicleTypePhoto?.length > 0 ? (
                  <>
                    {this.props.carData?.vehicleTypePhoto?.map((img, j) => (
                      <SplideSlide className="car-views" key={j}>
                        <img className="img-fluid" src={img.url} alt="" />
                      </SplideSlide>
                    ))}
                  </>
                ) : null}
              </Splide>

              {this.props.carData?.vehicleTypePhoto?.length === 0 ? (
                <figure className="car-placeholder">
                  <img className="img-fluid" src={carPlaceholder} alt="" />
                </figure>
              ) : null}

              <Mui.Typography component="p" variant="h5" className="mt-3 mb-1 text-color-grey line-height-2x">
                Description
              </Mui.Typography>
              <Mui.Typography component="p" variant="subtitle2" className="text-color-2 line-height-2x">
                {this.props.carData?.description || "-"}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>
        {this.props.carData?.vehicleTypeDocument?.length > 0 ? (
          <Mui.Card className="default-card spacing-md pb-5">
            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium mb-3 mt-2">
              Vehicle Documents
            </Mui.Typography>
            <Mui.TableContainer className="default-datatable">
              <Mui.Table size={"medium"}>
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell className="font-weight-normal ">S.N</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal "></Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal ">File</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal ">Created Date</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal text-left">Download</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {this.props.carData?.vehicleTypeDocument?.map((item, index) => (
                    <Mui.TableRow key={index}>
                      <Mui.TableCell className="text-transform-capitalize">{index + 1}</Mui.TableCell>
                      <Mui.TableCell>
                        <figure className="svg-size-compact" style={{ width: 40, height: 40 }}>
                          {item.mimeType.includes("image") ? (
                            <img width="100%" className="img-fluid-cover" src={item.url} alt="" />
                          ) : (
                            <img width="100%" className="img-fluid-contain" src={fileIcon} alt="" />
                          )}
                        </figure>
                      </Mui.TableCell>
                      <Mui.TableCell>{item.fileName}</Mui.TableCell>
                      <Mui.TableCell>{moment(item.createdAt).format("Do MMM YYYY HH:mm")}</Mui.TableCell>
                      <Mui.TableCell className="text-left">
                        <Mui.IconButton
                          onClick={() => this.props.downloadMedia(item.id, item.fileName, item.mimeType)}
                          color="primary"
                          className="btn-close"
                        >
                          <IconDownload />
                        </Mui.IconButton>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
          </Mui.Card>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = ({ car }) => {
  const { carData, success, message, loading, error } = car;
  return { carData, success, message, loading, error };
};
export default connect(mapStateToProps, { getCar, downloadMedia })(Mui.withWidth()(ViewCar));
