import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_UPCOMING_BOOKING_LIST,
  GET_COMPLETED_BOOKING_LIST,
  GET_ALL_BOOKING,
  GET_BOOKING_LIST,
  GET_EVENT_LIST,
  ADD_BOOKING,
  GET_BOOKING,
  EDIT_BOOKING,
  MODIFY_BOOKING,
  CHANGE_BOOKING_STATUS,
  ASSIGN_BOOKING_DRIVER,
  CHARGE_CARD,
  GET_BOOKING_COST,
  MODIFY_BOOKING_COST,
  SEND_BOOKING_EMAIL,
  DELETE_BOOKING,
  DELETE_MULTIPLE_BOOKING,
} from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import { parseMessage } from "src/helpers/util";
import BookingService from "src/services/booking-service";
import TableDataService from "src/services/table-data-service";
import {
  getUpcomingBookingListSuccess,
  getUpcomingBookingListError,
  getCompletedBookingListSuccess,
  getCompletedBookingListError,
  getAllBookingSuccess,
  getAllBookingError,
  getBookingList,
  getBookingListSuccess,
  getBookingListError,
  getEventListSuccess,
  getEventListError,
  addBookingSuccess,
  addBookingError,
  getBooking,
  getBookingSuccess,
  getBookingError,
  editBookingSuccess,
  editBookingError,
  modifyBookingSuccess,
  modifyBookingError,
  changeBookingStatusSuccess,
  changeBookingStatusError,
  assignBookingDriverSuccess,
  assignBookingDriverError,
  chargeCardSuccess,
  chargeCardError,
  getBookingCostSuccess,
  getBookingCostError,
  modifyBookingCostSuccess,
  modifyBookingCostError,
  sendBookingEmailSuccess,
  sendBookingEmailError,
  deleteBookingSuccess,
  deleteBookingError,
  deleteMultipleBookingSuccess,
  deleteMultipleBookingError,
} from "./action";

export function* watchGetAllBooking() {
  yield takeEvery(GET_ALL_BOOKING, getAllBooking);
}

const getAllBookingAsync = async (param) => {
  return BookingService.getAllBooking(param);
};

function* getAllBooking({ payload }) {
  try {
    const response = yield call(getAllBookingAsync, payload.param);
    if (response.data.success) {
      yield put(getAllBookingSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllBookingError(error.response.data.message));
  }
}

export function* watchGetUpcomingBookingList() {
  yield takeEvery(GET_UPCOMING_BOOKING_LIST, getUpcomingBookingList);
}

const getUpcomingBookingListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "bookings/upcoming",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getUpcomingBookingList({ payload }) {
  try {
    const response = yield call(getUpcomingBookingListAsync, payload.upcomingParam);
    if (response.data.success) {
      yield put(getUpcomingBookingListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getUpcomingBookingListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getUpcomingBookingListError(error.response.data.message));
  }
}

export function* watchGetCompletedBookingList() {
  yield takeEvery(GET_COMPLETED_BOOKING_LIST, getCompletedBookingList);
}

const getCompletedBookingListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "bookings/completed",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getCompletedBookingList({ payload }) {
  try {
    const response = yield call(getCompletedBookingListAsync, payload.completedParam);
    if (response.data.success) {
      yield put(getCompletedBookingListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getCompletedBookingListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getCompletedBookingListError(error.response.data.message));
  }
}

export function* watchGetBookingList() {
  yield takeEvery(GET_BOOKING_LIST, getBookingListAc);
}

const getBookingListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "bookings",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.fromDate || "",
    dbParam?.toDate || "",
    "",
    dbParam?.day || ""
  );
};

function* getBookingListAc({ payload }) {
  try {
    const response = yield call(getBookingListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getBookingListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getBookingListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getBookingListError(error.response.data.message));
  }
}

export function* watchGetEventList() {
  yield takeEvery(GET_EVENT_LIST, getEventList);
}

const getEventListAsync = async (param) => {
  return BookingService.getEventList(param);
};

function* getEventList({ payload }) {
  try {
    const response = yield call(getEventListAsync, payload.eventParam);
    if (response.data.success) {
      yield put(getEventListSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getEventListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getEventListError(error.response.data.message));
  }
}

export function* watchAddBooking() {
  yield takeEvery(ADD_BOOKING, addBooking);
}

const addBookingAsync = async (data) => {
  return BookingService.addBooking(data);
};

function* addBooking({ payload }) {
  try {
    const response = yield call(addBookingAsync, payload.bookingData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addBookingSuccess(response.data.success, response.data.message, response.data?.data?.id || ""));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addBookingError(error.response.data.message));
  }
}

export function* watchGetBooking() {
  yield takeEvery(GET_BOOKING, getBookingAc);
}

const getBookingAsync = async (id) => {
  return BookingService.getBooking(id);
};

function* getBookingAc({ payload }) {
  try {
    const response = yield call(getBookingAsync, payload.bookingId);
    if (response.data.success) {
      yield put(getBookingSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getBookingError(error.response.data.message));
  }
}

export function* watchEditBooking() {
  yield takeEvery(EDIT_BOOKING, editBooking);
}

const editBookingAsync = async (data, id) => {
  return BookingService.editBooking(data, id);
};

function* editBooking({ payload }) {
  try {
    const response = yield call(editBookingAsync, payload.bookingData, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editBookingSuccess(response.data.success, response.data.message, payload.bookingId));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editBookingError(error.response.data.message));
  }
}

export function* watchModifyBooking() {
  yield takeEvery(MODIFY_BOOKING, modifyBooking);
}

const modifyBookingAsync = async (data, id) => {
  return BookingService.modifyBooking(data, id);
};

function* modifyBooking({ payload }) {
  try {
    const response = yield call(modifyBookingAsync, payload.bookingData, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(modifyBookingSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(modifyBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(modifyBookingError(error.response.data.message));
  }
}

export function* watchChangeBookingStatus() {
  yield takeEvery(CHANGE_BOOKING_STATUS, changeBookingStatus);
}

const changeBookingStatusAsync = async (data, id) => {
  return BookingService.changeBookingStatus(data, id);
};

function* changeBookingStatus({ payload }) {
  try {
    const response = yield call(changeBookingStatusAsync, payload.statusData, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(changeBookingStatusSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      yield put(getBooking(payload.bookingId));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(changeBookingStatusError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(changeBookingStatusError(error.response.data.message));
  }
}

export function* watchGetBookingCost() {
  yield takeEvery(GET_BOOKING_COST, getBookingCost);
}

const getBookingCostAsync = async (data) => {
  return BookingService.getBookingCost(data);
};

function* getBookingCost({ payload }) {
  try {
    const response = yield call(getBookingCostAsync, payload.bookingData);
    if (response.data.success) {
      yield put(getBookingCostSuccess(response.data.data, response.data.success));
    } else {
      yield put(getBookingCostError(response.data.message));
    }
  } catch (error) {
    yield put(getBookingCostError(error.response.data.message));
  }
}

export function* watchModifyBookingCost() {
  yield takeEvery(MODIFY_BOOKING_COST, modifyBookingCost);
}

const modifyBookingCostAsync = async (id, data) => {
  return BookingService.modifyBookingCost(id, data);
};

function* modifyBookingCost({ payload }) {
  try {
    const response = yield call(modifyBookingCostAsync, payload.bookingId, payload.costParam);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(modifyBookingCostSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      yield put(getBooking(payload.bookingId));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(modifyBookingCostError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(modifyBookingCostError(error.response.data.message));
  }
}

export function* watchAssignBookingDriver() {
  yield takeEvery(ASSIGN_BOOKING_DRIVER, assignBookingDriver);
}

const assignBookingDriverAsync = async (data, id) => {
  return BookingService.assignBookingDriver(data, id);
};

function* assignBookingDriver({ payload }) {
  try {
    const response = yield call(assignBookingDriverAsync, payload.driverData, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(assignBookingDriverSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      if (payload.driverData?.list) yield put(getBookingList({}));
      // Fetch updated booking data
      if (payload.driverData?.view) yield put(getBooking(payload.bookingId));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(assignBookingDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(assignBookingDriverError(error.response.data.message));
  }
}

export function* watchChargeCard() {
  yield takeEvery(CHARGE_CARD, chargeCard);
}

const chargeCardAsync = async (id) => {
  return BookingService.chargeCard(id);
};

function* chargeCard({ payload }) {
  try {
    const response = yield call(chargeCardAsync, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(chargeCardSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      yield put(getBooking(payload.bookingId));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(chargeCardError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(chargeCardError(error.response.data.message));
  }
}

export function* watchSendBookingEmail() {
  yield takeEvery(SEND_BOOKING_EMAIL, sendBookingEmail);
}

const sendBookingEmailAsync = async (data, id) => {
  return BookingService.sendBookingEmail(data, id);
};

function* sendBookingEmail({ payload }) {
  try {
    const response = yield call(sendBookingEmailAsync, payload.emailParam, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(sendBookingEmailSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(sendBookingEmailError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(sendBookingEmailError(error.response.data.message));
  }
}

export function* watchDeleteBooking() {
  yield takeEvery(DELETE_BOOKING, deleteBooking);
}

const deleteBookingAsync = async (id) => {
  return BookingService.deleteBooking(id);
};

function* deleteBooking({ payload }) {
  try {
    const response = yield call(deleteBookingAsync, payload.bookingId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteBookingSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      yield put(getBookingList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteBookingError(error.response.data.message));
  }
}

export function* watchDeleteMultipleBooking() {
  yield takeEvery(DELETE_MULTIPLE_BOOKING, deleteMultipleBooking);
}

const deleteMultipleBookingAsync = async (ids) => {
  return BookingService.deleteMultipleBooking(ids);
};

function* deleteMultipleBooking({ payload }) {
  try {
    const response = yield call(deleteMultipleBookingAsync, payload.bookingIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleBookingSuccess(response.data.success, response.data.message));
      // Fetch updated booking list
      yield put(getBookingList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleBookingError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleBookingError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUpcomingBookingList),
    fork(watchGetCompletedBookingList),
    fork(watchGetAllBooking),
    fork(watchGetBookingList),
    fork(watchGetEventList),
    fork(watchAddBooking),
    fork(watchGetBooking),
    fork(watchEditBooking),
    fork(watchModifyBooking),
    fork(watchChangeBookingStatus),
    fork(watchAssignBookingDriver),
    fork(watchChargeCard),
    fork(watchGetBookingCost),
    fork(watchModifyBookingCost),
    fork(watchSendBookingEmail),
    fork(watchDeleteBooking),
    fork(watchDeleteMultipleBooking),
  ]);
}
