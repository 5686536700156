import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getConsolidateInvoiceList,
  downloadConsolidateInvoice,
  deleteConsolidateInvoice,
  deleteMultipleConsolidateInvoice,
  resetConsolidateInvoice,
} from "src/reduxs/actions";
import DataTable from "src/components/datatable";
import Breadcrumb from "src/components/breadcrumb";
import { Alert } from "src/components/dialog";

class ConsolidateInvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      deleteIds: null,
      openAlert: false,
      openAlert1: false,
    };
    this.searchFields = "invoice_number:like;user_id:like;";
  }

  componentDidMount() {
    this.props.getConsolidateInvoiceList({});
  }

  onDelete = () => {
    if (!this.props.loading1) {
      this.props.deleteConsolidateInvoice(this.state.deleteId);
    }
  };

  onDeleteAll = () => {
    if (!this.props.loading1) {
      this.props.deleteMultipleConsolidateInvoice(this.state.deleteIds);
    }
  };

  handleOpenAlert = (id) => {
    this.setState({ openAlert: true, deleteId: id });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, deleteId: null });
  };

  handleOpenAlert1 = (ids) => {
    this.setState({ openAlert1: true, deleteIds: ids });
  };

  handleCloseAlert1 = () => {
    this.setState({ openAlert1: false, deleteIds: null });
  };

  onChange = (search, sortOrder, page, pageSize, activeCol, fromDate, toDate) => {
    this.props.getConsolidateInvoiceList({
      search: search,
      searchFields: this.searchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  onDownload = (id) => {
    if (!this.props.downloading) {
      this.props.downloadConsolidateInvoice(id);
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Consolidate Invoice Management"}
          paths={[
            {
              title: "Consolidate Invoice",
            },
          ]}
        />

        <DataTable
          title="Consolidate Invoice"
          headCells={[
            {
              id: "invoiceNumber",
              sortKey: "invoice_number",
              label: "Invoice No",
            },
            {
              id: "invoiceUser",
              sortKey: "user_id",
              label: "Customer",
            },
            {
              id: "invoiceBooking",
              label: "Bookings",
            },
            {
              id: "createdAt",
              sortKey: "created_at",
              label: "Created Date",
            },
          ]}
          rows={this.props.consolidateInvoiceList?.data || []}
          totalPage={this.props.consolidateInvoiceList?.meta?.pages || 0}
          totalItem={this.props.consolidateInvoiceList?.meta?.total || 0}
          start={this.props.consolidateInvoiceList?.meta?.start || 0}
          end={this.props.consolidateInvoiceList?.meta?.end || 0}
          loading={this.props.loading}
          route={"/consolidate-invoice"}
          permission={{ download: 1, delete: 1 }}
          onChange={this.onChange}
          handleOpenAlert={this.handleOpenAlert}
          handleOpenAlert1={this.handleOpenAlert1}
          enableDateRangeFilter={true}
          history={this.props.history}
          onDownload={this.onDownload}
          actionStyle="icons"
        />

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onDelete}
          title="Invoice Delete"
          info="Are you sure to permanently delete this invoice?"
          awaitingInfo="Invoice is deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetConsolidateInvoice}
        />

        <Alert
          open={this.state.openAlert1}
          close={this.handleCloseAlert1}
          action={this.onDeleteAll}
          title="Invoices Delete"
          info="Are you sure to permanently delete these invoices?"
          awaitingInfo="Invoices are deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetConsolidateInvoice}
        />
      </>
    );
  }
}
const mapStateToProps = ({ consolidateInvoice }) => {
  const { consolidateInvoiceList, loading1, loading, downloading, success, error } = consolidateInvoice;
  return { consolidateInvoiceList, loading1, loading, downloading, success, error };
};

export default connect(mapStateToProps, {
  getConsolidateInvoiceList,
  downloadConsolidateInvoice,
  deleteConsolidateInvoice,
  deleteMultipleConsolidateInvoice,
  resetConsolidateInvoice,
})(ConsolidateInvoiceList);
