import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import moment from "moment";
import { IconClock, IconRoute } from "src/components/svg";

const Map = (props) => {
  const [directions, setDirections] = useState(null);
  const directionsService = new window.google.maps.DirectionsService();

  const filter = (arr) => {
    const el = JSON.stringify(arr);
    let newArr = JSON.parse(el);
    newArr = arr.slice(1, -1);
    return newArr.map(({ id, routeId, ...rest }) => ({ ...rest }));
  };

  useEffect(() => {
    if (props.values.pickupLocation) {
      directionsService.route(
        {
          origin: props.values.pickupLocation,
          destination: props.values.dropOffLocation ? props.values.dropOffLocation : props.values.pickupLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          waypoints: props.values?.waypoint?.map(({ id, bookingId, ...rest }) => ({ ...rest })) || [],
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const route = result.routes[0];
            let el = 0;
            let elt = 0;
            for (let i = 0; i < route.legs.length; i++) {
              el += route.legs[i].distance.value;
              elt += route.legs[i].duration.value;
            }
            props.setFieldValue("totalDistance", (el / 1000).toFixed(2));
            props.setFieldValue(
              "totalTime",
              moment.utc(moment.duration(elt, "seconds").as("milliseconds")).format("HH:mm:ss")
            );
          } else {
            console.error(`Error fetching directions ${result}`);
          }
        }
      );
    } else {
      // Reset direction
      setDirections(null);
      props.setFieldValue("totalDistance", 0);
      props.setFieldValue("totalTime", "00:00:00");
    }
    if (props.values.routeId) {
      // Set direction, totalDistance and totalTime according to route
      let ol = props.routes?.find((x) => x.id === props.values.routeId)?.routeWaypoint;
      if (ol?.length > 0 && ol[0].location) {
        directionsService.route(
          {
            origin: ol[0].location,
            destination: ol[ol.length - 1].location,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
            waypoints: filter(ol),
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error(`Error fetching directions ${result}`);
            }
          }
        );
      }
      props.setFieldValue("totalDistance", props.routes?.find((x) => x.id === props.values.routeId)?.totalDistance);
      props.setFieldValue("totalTime", props.routes?.find((x) => x.id === props.values.routeId)?.totalTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.pickupLocation, props.values.dropOffLocation, props.values.waypoint, props.values.routeId]);

  const MapWithAMarker = withGoogleMap(() => (
    <GoogleMap defaultZoom={13}>{directions && <DirectionsRenderer directions={directions} />}</GoogleMap>
  ));
  return (
    <Mui.Paper
      className={`p-0 border-rad-0 shadow-none overflow-hidden ${Mui.isWidthDown("sm", props.width) ? "mt-5" : ""}`}
    >
      <MapWithAMarker
        containerElement={<div className="map-item" />}
        mapElement={
          <div
            className="map-item"
            style={{
              height: Mui.isWidthDown("sm", props.width) ? "30rem" : "435px",
            }}
          />
        }
      />

      <Mui.Box display="flex" justifyContent="space-between" className="p-2 background-color-color1">
        <Mui.Box display="flex" alignItems="center" className="svg-color-primary svg-size-small-2x">
          <IconRoute />
          <Mui.Typography component="h3" variant="h3" className="ml-2 font-weight-medium text-color-primary">
            <Mui.Typography component="span" variant="body1" className="d-block text-color-white">
              Total Distance
            </Mui.Typography>
            {props.values.totalDistance} km
          </Mui.Typography>
        </Mui.Box>

        <Mui.Box display="flex" alignItems="center" className="svg-color-primary  svg-size-small-2x">
          <IconClock />
          <Mui.Typography component="h3" variant="h3" className="ml-2 font-weight-medium text-color-primary">
            <Mui.Typography component="span" variant="body1" className="d-block text-color-white">
              APPROX ETA
            </Mui.Typography>
            {moment(props.values.totalTime, "HH:mm:ss").format("H")}h{" "}
            {moment(props.values.totalTime, "HH:mm:ss").format("m")}m
          </Mui.Typography>
        </Mui.Box>
      </Mui.Box>
      {/* Show info if totalDistance > 100 km */}
      {(props.values.type === "distance" || props.values.type === "hourly") &&
      parseFloat(props.values.totalDistance) >= 100 ? (
        <Mui.Box display="flex" justifyContent="center" className="background-color-color1">
          <Mui.Typography component="h4" variant="h4" className="text-color-danger font-weight-normal">
            Total Distance over 100 km!
          </Mui.Typography>
        </Mui.Box>
      ) : null}
    </Mui.Paper>
  );
};

export default Mui.withWidth()(Map);
