export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";
export const RESET_CLIENT = "RESET_CLIENT";

export const getClientList = (dbParam) => ({
  type: GET_CLIENT_LIST,
  payload: { dbParam },
});

export const getClientListSuccess = (clientList) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload: clientList,
});

export const getClientListError = (error) => ({
  type: GET_CLIENT_LIST_ERROR,
  payload: error,
});

export const resetClient = () => ({
  type: RESET_CLIENT,
  payload: {},
});
