import {
  GET_UPCOMING_BOOKING_LIST,
  GET_UPCOMING_BOOKING_LIST_SUCCESS,
  GET_UPCOMING_BOOKING_LIST_ERROR,
  GET_COMPLETED_BOOKING_LIST,
  GET_COMPLETED_BOOKING_LIST_SUCCESS,
  GET_COMPLETED_BOOKING_LIST_ERROR,
  GET_ALL_BOOKING,
  GET_ALL_BOOKING_SUCCESS,
  GET_ALL_BOOKING_ERROR,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_ERROR,
  GET_EVENT_LIST,
  GET_EVENT_LIST_SUCCESS,
  GET_EVENT_LIST_ERROR,
  ADD_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_ERROR,
  GET_BOOKING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  EDIT_BOOKING,
  EDIT_BOOKING_SUCCESS,
  EDIT_BOOKING_ERROR,
  MODIFY_BOOKING,
  MODIFY_BOOKING_SUCCESS,
  MODIFY_BOOKING_ERROR,
  CHANGE_BOOKING_STATUS,
  CHANGE_BOOKING_STATUS_SUCCESS,
  CHANGE_BOOKING_STATUS_ERROR,
  ASSIGN_BOOKING_DRIVER,
  ASSIGN_BOOKING_DRIVER_SUCCESS,
  ASSIGN_BOOKING_DRIVER_ERROR,
  CHARGE_CARD,
  CHARGE_CARD_SUCCESS,
  CHARGE_CARD_ERROR,
  GET_BOOKING_COST,
  GET_BOOKING_COST_SUCCESS,
  GET_BOOKING_COST_ERROR,
  MODIFY_BOOKING_COST,
  MODIFY_BOOKING_COST_SUCCESS,
  MODIFY_BOOKING_COST_ERROR,
  SEND_BOOKING_EMAIL,
  SEND_BOOKING_EMAIL_SUCCESS,
  SEND_BOOKING_EMAIL_ERROR,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  DELETE_MULTIPLE_BOOKING,
  DELETE_MULTIPLE_BOOKING_SUCCESS,
  DELETE_MULTIPLE_BOOKING_ERROR,
  RESET_BOOKING_COST,
  RESET_BOOKING,
} from "src/reduxs/actions";

const INIT_STATE = {
  param: null,
  dbParam: null,
  upcomingParam: null,
  completedParam: null,
  eventParam: null,
  emailParam: null,
  costParam: null,
  bookings: null,
  bookingList: null,
  upcomingBookingList: null,
  completedBookingList: null,
  eventList: null,
  bookingData: null,
  bookingCost: null,
  statusData: null,
  driverData: null,
  bookingId: null,
  responseBookingId: null,
  bookingIds: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  error: null,
};

const bookingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UPCOMING_BOOKING_LIST:
      return {
        ...state,
        loading: true,
        bookingData: null,
        error: null,
      };
    case GET_UPCOMING_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingBookingList: action.payload,
        error: null,
      };
    case GET_UPCOMING_BOOKING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        upcomingBookingList: null,
        error: action.payload,
      };
    case GET_COMPLETED_BOOKING_LIST:
      return {
        ...state,
        loading: true,
        bookingData: null,
        error: null,
      };
    case GET_COMPLETED_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        completedBookingList: action.payload,
        error: null,
      };
    case GET_COMPLETED_BOOKING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        completedBookingList: null,
        error: action.payload,
      };
    case GET_ALL_BOOKING:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        error: null,
      };
    case GET_ALL_BOOKING_ERROR:
      return {
        ...state,
        bookings: null,
        error: action.payload,
      };
    case GET_BOOKING_LIST:
      return {
        ...state,
        loading: true,
        bookingId: null,
        bookingData: null,
        bookingIds: null,
        error: null,
      };
    case GET_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingList: action.payload,
        error: null,
      };
    case GET_BOOKING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        bookingList: null,
        error: action.payload,
      };
    case GET_EVENT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        eventList: action.payload,
        error: null,
      };
    case GET_EVENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        eventList: null,
        error: action.payload,
      };
    case ADD_BOOKING:
      return { ...state, loading: true, error: null };
    case ADD_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        responseBookingId: action.payload.responseBookingId,
        error: null,
      };
    case ADD_BOOKING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        responseBookingId: null,
        error: action.payload,
      };
    case GET_BOOKING:
      return { ...state, bookingCost: null, error: null };
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        bookingData: action.payload,
        error: null,
      };
    case GET_BOOKING_ERROR:
      return {
        ...state,
        bookingData: null,
        bookingCost: null,
        error: action.payload,
      };
    case EDIT_BOOKING:
      return { ...state, loading: true, error: null };
    case EDIT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        responseBookingId: action.payload.responseBookingId,
        error: null,
      };
    case EDIT_BOOKING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        responseBookingId: null,
        error: action.payload,
      };
    case MODIFY_BOOKING:
      return { ...state, loading: true, error: null };
    case MODIFY_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case MODIFY_BOOKING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case CHANGE_BOOKING_STATUS:
      return { ...state, loading: true, error: null };
    case CHANGE_BOOKING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case CHANGE_BOOKING_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ASSIGN_BOOKING_DRIVER:
      return { ...state, loading: true, error: null };
    case ASSIGN_BOOKING_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ASSIGN_BOOKING_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case CHARGE_CARD:
      return { ...state, loading1: true, error: null };
    case CHARGE_CARD_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case CHARGE_CARD_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_BOOKING_COST:
      return { ...state, loading2: true, error: null };
    case GET_BOOKING_COST_SUCCESS:
      return {
        ...state,
        loading2: false,
        bookingCost: action.payload,
        error: null,
      };
    case GET_BOOKING_COST_ERROR:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      };
    case MODIFY_BOOKING_COST:
      return { ...state, loading1: true, error: null };
    case MODIFY_BOOKING_COST_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case MODIFY_BOOKING_COST_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case SEND_BOOKING_EMAIL:
      return { ...state, loading1: true, error: null };
    case SEND_BOOKING_EMAIL_SUCCESS:
      return {
        ...state,
        loading1: false,
        success1: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case SEND_BOOKING_EMAIL_ERROR:
      return {
        ...state,
        loading1: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case DELETE_BOOKING:
      return { ...state, loading1: true, error: null };
    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_BOOKING_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_BOOKING:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_BOOKING_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_BOOKING_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_BOOKING_COST:
      return {
        ...state,
        bookingCost: null,
      };
    case RESET_BOOKING:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        success: false,
        responseBookingId: null,
        success1: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default bookingReducer;
