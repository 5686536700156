import React from "react";

const IconCloudUpload = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 48.69 33.36">
      <path
        id="Path_3850"
        dataname="Path 3850"
        d="M1869.719-67.65a9.32,9.32,0,0,1-8.64,9.31H1849.81a.851.851,0,0,1-.851-.851v-7.488a.851.851,0,0,1,.851-.851h3.537a.851.851,0,0,0,.6-1.452l-7.468-7.476a.852.852,0,0,0-1.2,0l-7.476,7.476a.851.851,0,0,0,.6,1.452h3.535a.851.851,0,0,1,.851.851v7.488a.851.851,0,0,1-.851.851h-11.289a9.106,9.106,0,0,1-2.22-.44,9.343,9.343,0,0,1-6.4-8.87,9.3,9.3,0,0,1,3.88-7.57v-.01a7.865,7.865,0,0,1-.04-.84,8.162,8.162,0,0,1,8.16-8.16c.22,0,.44.01.65.03a12.88,12.88,0,0,1,11.2-6.5,12.863,12.863,0,0,1,11.19,6.5c.21-.02.43-.03.65-.03a8.164,8.164,0,0,1,8.17,8.16,7.928,7.928,0,0,1-.04.85A9.323,9.323,0,0,1,1869.719-67.65Z"
        transform="translate(-1821.529 91.2)"
        stroke="rgba(45,107,234,0.04)"
        strokeWidth="1"
      />
    </svg>
  );
};

export default IconCloudUpload;
