import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import DriverList from "./list";
import EditDriver from "./edit";
import ViewDriver from "./view";

function Driver() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/driver" component={DriverList} />
        <ContentRoute path="/driver/add" component={EditDriver} />
        <ContentRoute path="/driver/edit/:id" component={EditDriver} />
        <ContentRoute path="/driver/view/:id" component={ViewDriver} />
      </Switch>
    </Suspense>
  );
}

export default Driver;
