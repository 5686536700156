export const FILTER_CAR = "FILTER_CAR";
export const FILTER_CAR_SUCCESS = "FILTER_CAR_SUCCESS";
export const FILTER_CAR_ERROR = "FILTER_CAR_ERROR";
export const GET_USER_CARD = "GET_USER_CARD";
export const GET_USER_CARD_SUCCESS = "GET_USER_CARD_SUCCESS";
export const GET_USER_CARD_ERROR = "GET_USER_CARD_ERROR";
export const BOOK_RIDE = "BOOK_RIDE";
export const BOOK_RIDE_SUCCESS = "BOOK_RIDE_SUCCESS";
export const BOOK_RIDE_ERROR = "BOOK_RIDE_ERROR";
export const REBOOK_RIDE = "REBOOK_RIDE";
export const REBOOK_RIDE_SUCCESS = "REBOOK_RIDE_SUCCESS";
export const REBOOK_RIDE_ERROR = "REBOOK_RIDE_ERROR";
export const CALCULATE_PRICE = "CALCULATE_PRICE";
export const CALCULATE_PRICE_SUCCESS = "CALCULATE_PRICE_SUCCESS";
export const CALCULATE_PRICE_ERROR = "CALCULATE_PRICE_ERROR";
export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_ERROR = "APPLY_COUPON_ERROR";
export const RESET_RIDE = "RESET_RIDE";
export const RESET_PRICE = "RESET_PRICE";
export const RESET_COUPON = "RESET_COUPON";
export const RESET_USER_CARD = "RESET_USER_CARD";

export const filterCar = (param) => ({
  type: FILTER_CAR,
  payload: { param },
});

export const filterCarSuccess = (carList) => ({
  type: FILTER_CAR_SUCCESS,
  payload: carList,
});

export const filterCarError = (error) => ({
  type: FILTER_CAR_ERROR,
  payload: error,
});

export const getUserCard = (userId) => ({
  type: GET_USER_CARD,
  payload: { userId },
});

export const getUserCardSuccess = (userCard) => ({
  type: GET_USER_CARD_SUCCESS,
  payload: userCard,
});

export const getUserCardError = (error) => ({
  type: GET_USER_CARD_ERROR,
  payload: error,
});

export const bookRide = (rideData, history) => ({
  type: BOOK_RIDE,
  payload: { rideData, history },
});

export const bookRideSuccess = (success, message) => ({
  type: BOOK_RIDE_SUCCESS,
  payload: { success, message },
});

export const bookRideError = (error) => ({
  type: BOOK_RIDE_ERROR,
  payload: error,
});

export const rebookRide = (rebookData) => ({
  type: REBOOK_RIDE,
  payload: { rebookData },
});

export const rebookRideSuccess = (success, message) => ({
  type: REBOOK_RIDE_SUCCESS,
  payload: { success, message },
});

export const rebookRideError = (error) => ({
  type: REBOOK_RIDE_ERROR,
  payload: error,
});

export const calculatePrice = (rideData) => ({
  type: CALCULATE_PRICE,
  payload: { rideData },
});

export const calculatePriceSuccess = (priceData, success1) => ({
  type: CALCULATE_PRICE_SUCCESS,
  payload: { priceData, success1 },
});

export const calculatePriceError = (error1) => ({
  type: CALCULATE_PRICE_ERROR,
  payload: error1,
});

export const applyCoupon = (coupon, rideData) => ({
  type: APPLY_COUPON,
  payload: { coupon, rideData },
});

export const applyCouponSuccess = (priceData, success2) => ({
  type: APPLY_COUPON_SUCCESS,
  payload: { priceData, success2 },
});

export const applyCouponError = (error2) => ({
  type: APPLY_COUPON_ERROR,
  payload: error2,
});

export const resetPrice = () => ({
  type: RESET_PRICE,
  payload: {},
});

export const resetCoupon = () => ({
  type: RESET_COUPON,
  payload: {},
});

export const resetRide = () => ({
  type: RESET_RIDE,
  payload: {},
});

export const resetUserCard = () => ({
  type: RESET_USER_CARD,
  payload: {},
});
