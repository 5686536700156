import React, { useState } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/inner-banner-2.jpg";
import { IconAdd } from "src/components/svg";

const FAQ = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <WebsiteLayout>
      <PageMeta />

      <section className="inner-banner">
        <img src={bannerImg} alt="" />

        <Mui.Container maxWidth="md" className="inner-banner__content">
          <Mui.Breadcrumbs className="text-color-white">
            <Mui.Link component={Link} to={"/"} className="text-color-white">
              Home
            </Mui.Link>
            <Mui.Link underline="none" className="text-color-white">
              FAQ
            </Mui.Link>
          </Mui.Breadcrumbs>

          <Mui.Typography component="h1" variant="h2">
            Frequently Asked <br /> Questions
          </Mui.Typography>
        </Mui.Container>
      </section>
      <section className="section-default pt-0">
        <Mui.Container maxWidth="md">
          {/* <Mui.Typography
            component="h4"
            variant="h2"
            align="center"
            className="text-white"
            style={{
              fontSize: "8rem",
              textTransform: "uppercase",
              margin: "8rem 0 2rem",
              lineHeight: 0.5,
            }}
          >
            Under{" "}
            <Mui.Typography
              component="span"
              style={{ display: "block", fontSize: "6rem", fontWeight: 600 }}
            >
              Construction
            </Mui.Typography>
          </Mui.Typography>

          <Mui.Box maxWidth="100%" width="768px" mt={3} mx="auto" pt={3} borderTop="1px solid #fff">
            <Mui.Typography
              align="center"
              className="text-white"
              style={{ fontSize: "4rem" }}
            >
              COMING SOON
            </Mui.Typography>
          </Mui.Box> */}

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              What health and safety measures have for COVID-19?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              We have implemented all the safety measures as outlined by the State Health Department. Our vehicles are
              cleaned and sanitized after each transfer to minimize the risk of COVID-19 spread.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>What happens if the flight is delayed?</Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Our drivers are trained and instructed to monitor and track the flight arrival schedule. We will pick you
              up on your arrival, regardless if your flight is earlier or delayed. However, onceyour flight is landed
              and for some reason you were delayed on customs clearance, the driverwill wait for a maximum of 1 hr.
              Exceeding this timeframe will result in additional fees such as waiting and parking fees. In-between we will
              try to get in touch with you and make enquiries to understand the reason for the delays. If we were unable
              to get in touch with you after 1 hour, the driver would leave the airport. We strongly suggest you contact
              us if there is a delay in the customs or baggage area for more than 1 hour to avoid a driver leaving the
              airport.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              Where do I find my chauffeur on our arrival at the airport?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Your chauffeur will be waiting for you in the chauffeur meeting point with your name sign. If you have
              difficulties locating your driver please contact us immediately, and one of our customer service officers
              will be able to help you.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>Are there any credit card fees?</Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Yes, if you want to pay by credit card, there will be a 5% surcharge. However, if you would like to avoid
              any surcharge you can make a payment to our bank account provided on your invoice.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              Do I get charged if I have to cancel my bookings?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Cancellation prior to 24 hours will not incur any cancellation charges or make changes to your upcoming
              bookings. However, this must be communicated to us via phone or email. Any cancellation within 24 hours is
              fully charged.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              Are we allowed to eat, drink or smoke in the vehicle?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              You are allowed to have hot or cold drinks, but we encourage you to take caution to avoid any damages. You
              will be liable for the cost of repairs if the damages were directly caused by your action. Any alcoholic
              drinks, food and smoke are strictly prohibited.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              What happens if my flight details are changed?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Please reach out to us as soon as possible once you are aware of any changes and we will make necessary
              amendments for you.
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion
            className="accordion-default dark"
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <Mui.AccordionSummary expandIcon={<IconAdd />}>
              Do your Chauffeur provide any Luggage assistance?
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              Yes, our chauffeurs are always happy to offer you any help on luggage or any assistance to assist you in
              your travel.
            </Mui.AccordionDetails>
          </Mui.Accordion>
        </Mui.Container>
      </section>
    </WebsiteLayout>
  );
};

export default Mui.withWidth()(FAQ);
