import React, { Component } from "react";
import { connect } from "react-redux";
import PageMeta from "src/components/pages-meta";
import { verifyUser } from "src/reduxs/actions";
import { LayoutSplashScreen } from "src/configs/core";
class Verify extends Component {
  constructor(props) {
    super(props);
    this.props.verifyUser(this.props.match.params.token, this.props.history);
  }

  render() {
    return (
      <>
        <PageMeta />
        <LayoutSplashScreen />
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, {
  verifyUser,
})(Verify);
