import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import Breadcrumb from "src/components/breadcrumb";
import { Alert } from "src/components/dialog";
import {
  getBooking,
  addBooking,
  editBooking,
  getAllRoute,
  getAllUser,
  getAllCoupon,
  getAllAdditionalCharge,
  filterCar,
  getUserCard,
  resetUserCard,
  getBookingCost,
  resetBookingCost,
  sendBookingEmail,
  resetBooking,
} from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import Map from "./map";
import Location from "./location";
import Vehicle from "./vehicle";
import Contact from "./contact";
import AdditionalCharge from "./additional-charge";
import Payment from "./payment";

const EditBooking = (props) => {
  const formikRef = useRef();
  const [openAlert, setOpenAlert] = useState(false);
  const schema = Yup.object().shape({
    type: Yup.string().required("Select type").nullable(),
    pickupDate: Yup.string().required("Enter a valid date").nullable(),
    pickupTime: Yup.string().required("Enter a valid time").nullable(),
    pickupLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    dropOffLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    durationInHour: Yup.string().when("type", {
      is: (value) => value === "hourly",
      then: Yup.string().required("Select a valid duration").nullable(),
      otherwise: Yup.string(),
    }),
    routeId: Yup.string().when("type", {
      is: (value) => value === "flat_rate",
      then: Yup.string().required("Select valid route").nullable(),
      otherwise: Yup.string(),
    }),
    flightType: Yup.string()
      .nullable()
      .when("pickupLocation", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-valid", "Please provide flight information", function (value) {
          const { type, pickupLocation } = this.parent;
          if (type === "distance" && pickupLocation?.match(/airport/i) && !value) {
            return false;
          }
          return true;
        }),
        otherwise: Yup.string().nullable(),
      }),
    flightNumber: Yup.string().when("flightType", {
      is: (value) => value === "domestic" || value === "international",
      then: Yup.string().required("Enter a valid flight number").nullable(),
      otherwise: Yup.string(),
    }),
    waypoint: Yup.array().of(
      Yup.object().shape({
        location: Yup.string().required("Enter a valid location").nullable(),
      })
    ),
    additionalCharge: Yup.array().of(
      Yup.object().shape({
        additionalChargeId: Yup.string().required("Select additional charge").nullable(),
        value: Yup.string()
          .required("Required")
          .test("check-price", "Invalid value", function (value) {
            return value > 0;
          }),
      })
    ),
    noPassenger: Yup.string().required("Select a valid value").nullable(),
    noLuggage: Yup.string().required("Select a valid value").nullable(),
    vehicleTypeId: Yup.string().required("Select a car").nullable(),
    userId: Yup.string().required("Select a user").nullable(),
    firstName: Yup.string()
      .required("Enter your first name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    lastName: Yup.string()
      .required("Enter your last name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    email: Yup.string().required("Enter a valid email").email("Invalid email address").nullable(),
    phone: Yup.string()
      .required("Enter a valid contact")
      .min(8, "Atleast 8 digits")
      .max(10, "Cannot exceed 10 digits")
      .nullable(),
    alternatePhone: Yup.string().min(8, "Atleast 8 digits").max(10, "Cannot exceed 10 digits").nullable(),
  });

  useEffect(() => {
    props.getAllRoute();
    props.getAllUser();
    props.getAllCoupon();
    props.getAllAdditionalCharge();
    props.resetUserCard();
    props.resetBookingCost();
    if (props.match.params.id) {
      props.getBooking(props.match.params.id);
    } else {
      props.filterCar({
        passenger: 1,
        luggage: 1,
        routeId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.bookingData?.id) {
      props.filterCar({
        passenger: props.bookingData?.noPassenger || 1,
        luggage: props.bookingData?.noLuggage || 1,
        routeId: props.bookingData?.routeId || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookingData]);

  const onFilter = (passenger, luggage, routeId = null) => {
    props.filterCar({
      passenger: passenger,
      luggage: luggage,
      routeId: routeId,
    });
  };

  const resetPriceCalculation = () => {
    props.resetBookingCost();
  };

  const calculateBookingPrice = (values) => {
    if (!props.loading2) {
      props.getBookingCost(values);
    }
  };

  const onSubmit = (values) => {
    let bookingTotal = props.bookingCost?.total || 0;
    let total = parseFloat(bookingTotal) - parseFloat(values.deposit);
    if (Number(total) < 0) {
      toast.error(<ToastElement type="error" message={"Not allowed, total cost cannot be less than 0"} />, {
        containerId: "default",
      });
    } else {
      values.subTotal = props.bookingCost?.subTotal || values?.subTotal || 0;
      values.total = props.bookingCost?.total || values?.total || 0;
      values.couponId = props.bookingCost?.couponId || values?.couponId || "";
      values.coupon = props.bookingCost?.coupon || values?.coupon || "";
      values.couponDiscount = props.bookingCost?.couponDiscount || values?.couponDiscount || 0;
      if (!props.loading) {
        if (props.match.params.id) {
          props.editBooking(props.match.params.id, values, props.history);
        } else {
          props.addBooking(values, props.history);
        }
      }
    }
  };

  useEffect(() => {
    if (props.success && props.responseBookingId) {
      // send email popup
      setOpenAlert(true);
    }
  }, [props.success, props.responseBookingId]);

  return (
    <>
      <Breadcrumb
        title={"Booking Management"}
        paths={[
          {
            title: "Booking",
            page: `/booking`,
          },
          {
            title: props.match.params.id ? "Edit" : "Add",
          },
        ]}
      />

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          referenceNumber: props.location?.state?.returnRideDetail?.referenceNumber
            ? props.location?.state?.returnRideDetail?.referenceNumber
            : props.match.params.id
            ? props.bookingData?.referenceNumber || ""
            : "",
          type: props.location?.state?.returnRideDetail?.type
            ? props.location?.state?.returnRideDetail?.type
            : props.match.params.id
            ? props.bookingData?.type || "distance"
            : "distance",
          pickupDate: props.location?.state?.returnRideDetail?.pickupDate
            ? props.location?.state?.returnRideDetail?.pickupDate
            : props.match.params.id
            ? props.bookingData?.pickupDate || null
            : null,
          pickupTime: props.location?.state?.returnRideDetail?.pickupTime
            ? props.location?.state?.returnRideDetail?.pickupTime
            : props.match.params.id
            ? props.bookingData?.pickupTime || ""
            : "",
          pickupLocation: props.location?.state?.returnRideDetail?.pickupLocation
            ? props.location?.state?.returnRideDetail?.pickupLocation
            : props.match.params.id
            ? props.bookingData?.pickupLocation || ""
            : "",
          waypoint: props.location?.state?.returnRideDetail?.bookingWaypoint
            ? props.location?.state?.returnRideDetail?.bookingWaypoint
            : props.match.params.id
            ? props.bookingData?.bookingWaypoint || []
            : [],
          additionalCharge: props.location?.state?.returnRideDetail?.bookingAdditionalCharge
            ? props.location?.state?.returnRideDetail?.bookingAdditionalCharge
            : props.match.params.id
            ? props.bookingData?.bookingAdditionalCharge || []
            : [],
          dropOffLocation: props.location?.state?.returnRideDetail?.dropOffLocation
            ? props.location?.state?.returnRideDetail?.dropOffLocation
            : props.match.params.id
            ? props.bookingData?.dropOffLocation || ""
            : "",
          durationInHour: props.location?.state?.returnRideDetail?.durationInHour
            ? props.location?.state?.returnRideDetail?.durationInHour
            : props.match.params.id
            ? props.bookingData?.durationInHour || "01:00:00"
            : "01:00:00",
          routeId: props.location?.state?.returnRideDetail?.routeId
            ? props.location?.state?.returnRideDetail?.routeId
            : props.match.params.id
            ? props.bookingData?.routeId || ""
            : "",
          flightType: props.match.params.id ? props.bookingData?.flightType || "" : "",
          flightNumber: props.match.params.id ? props.bookingData?.flightNumber || "" : "",
          totalDistance: props.location?.state?.returnRideDetail?.totalDistance
            ? props.location?.state?.returnRideDetail?.totalDistance
            : props.match.params.id
            ? props.bookingData?.totalDistance || 0
            : 0,
          totalTime: props.location?.state?.returnRideDetail?.totalTime
            ? props.location?.state?.returnRideDetail?.totalTime
            : props.match.params.id
            ? props.bookingData?.totalTime || "00:00:00"
            : "00:00:00",
          subTotal: props.location?.state?.returnRideDetail?.subTotal
            ? props.location?.state?.returnRideDetail?.subTotal
            : props.match.params.id
            ? props.bookingData?.subTotal || 0
            : 0,
          total: props.location?.state?.returnRideDetail?.total
            ? props.location?.state?.returnRideDetail?.total
            : props.match.params.id
            ? props.bookingData?.total || 0
            : 0,
          deposit: props.match.params.id ? props.bookingData?.deposit || 0 : 0,
          couponId: props.location?.state?.returnRideDetail?.couponId
            ? props.location?.state?.returnRideDetail?.couponId
            : props.match.params.id
            ? props.bookingData?.couponId || ""
            : "",
          coupon: props.location?.state?.returnRideDetail?.coupon
            ? props.location?.state?.returnRideDetail?.coupon
            : props.match.params.id
            ? props.bookingData?.coupon || ""
            : "",
          couponDiscount: props.location?.state?.returnRideDetail?.couponDiscount
            ? props.location?.state?.returnRideDetail?.couponDiscount
            : props.match.params.id
            ? props.bookingData?.couponDiscount || 0
            : 0,
          noPassenger: props.location?.state?.returnRideDetail?.noPassenger
            ? props.location?.state?.returnRideDetail?.noPassenger
            : props.match.params.id
            ? props.bookingData?.noPassenger || "1"
            : "1",
          noLuggage: props.location?.state?.returnRideDetail?.noLuggage
            ? props.location?.state?.returnRideDetail?.noLuggage
            : props.match.params.id
            ? props.bookingData?.noLuggage || "1"
            : "1",
          extraLargeLuggage: props.location?.state?.returnRideDetail?.extraLargeLuggage
            ? props.location?.state?.returnRideDetail?.extraLargeLuggage
            : props.match.params.id
            ? props.bookingData?.extraLargeLuggage
            : 0,
          vehicleTypeId: props.location?.state?.returnRideDetail?.vehicleTypeId
            ? props.location?.state?.returnRideDetail?.vehicleTypeId
            : props.match.params.id
            ? props.bookingData?.vehicleTypeId || ""
            : "",
          babySeatRearFacing: props.location?.state?.returnRideDetail?.babySeatRearFacing
            ? props.location?.state?.returnRideDetail?.babySeatRearFacing
            : props.match.params.id
            ? props.bookingData?.babySeatRearFacing
            : 0,
          childSeatForwardFacing: props.location?.state?.returnRideDetail?.childSeatForwardFacing
            ? props.location?.state?.returnRideDetail?.childSeatForwardFacing
            : props.match.params.id
            ? props.bookingData?.childSeatForwardFacing
            : 0,
          boosterSeat: props.location?.state?.returnRideDetail?.boosterSeat
            ? props.location?.state?.returnRideDetail?.boosterSeat
            : props.match.params.id
            ? props.bookingData?.boosterSeat
            : 0,
          other: props.location?.state?.returnRideDetail?.other
            ? props.location?.state?.returnRideDetail?.other
            : props.match.params.id
            ? props.bookingData?.other
            : 0,
          firstName: props.location?.state?.returnRideDetail?.firstName
            ? props.location?.state?.returnRideDetail?.firstName
            : props.match.params.id
            ? props.bookingData?.firstName || ""
            : "",
          lastName: props.location?.state?.returnRideDetail?.lastName
            ? props.location?.state?.returnRideDetail?.lastName
            : props.match.params.id
            ? props.bookingData?.lastName || ""
            : "",
          email: props.location?.state?.returnRideDetail?.email
            ? props.location?.state?.returnRideDetail?.email
            : props.match.params.id
            ? props.bookingData?.email || ""
            : "",
          phone: props.location?.state?.returnRideDetail?.phone
            ? props.location?.state?.returnRideDetail?.phone
            : props.match.params.id
            ? props.bookingData?.phone || ""
            : "",
          passengerNames: props.location?.state?.returnRideDetail?.passengerNames
            ? props.location?.state?.returnRideDetail?.passengerNames
            : props.match.params.id
            ? props.bookingData?.passengerNames || ""
            : "",
          passengerPhones: props.location?.state?.returnRideDetail?.passengerPhones
            ? props.location?.state?.returnRideDetail?.passengerPhones
            : props.match.params.id
            ? props.bookingData?.passengerPhones || ""
            : "",
          alternatePhone: props.location?.state?.returnRideDetail?.alternatePhone
            ? props.location?.state?.returnRideDetail?.alternatePhone
            : props.match.params.id
            ? props.bookingData?.alternatePhone || ""
            : "",
          comment: props.location?.state?.returnRideDetail?.comment
            ? props.location?.state?.returnRideDetail?.comment
            : props.match.params.id
            ? props.bookingData?.comment || ""
            : "",
          paymentStatus: props.match.params.id ? props.bookingData?.paymentStatus : "hold",
          additionalComment: props.location?.state?.returnRideDetail?.additionalComment
            ? props.location?.state?.returnRideDetail?.additionalComment
            : props.match.params.id
            ? props.bookingData?.additionalComment || ""
            : "",
          userId: props.location?.state?.returnRideDetail?.userId
            ? props.location?.state?.returnRideDetail?.userId
            : props.match.params.id
            ? props.bookingData?.userId || ""
            : "",
          enablePayment: props.location?.state?.returnRideDetail?.enablePayment
            ? props.location?.state?.returnRideDetail?.enablePayment
            : props.match.params.id
            ? props.bookingData?.enablePayment
            : 0,
          useCardOnFile: props.location?.state?.returnRideDetail?.useCardOnFile
            ? props.location?.state?.returnRideDetail?.useCardOnFile
            : props.match.params.id
            ? props.bookingData?.useCardOnFile
            : 0,
          paymentToken: "",
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, errors, resetForm, setFieldValue, setTouched, getFieldMeta }) => (
          <Form className="default-form">
            <Mui.Grid container spacing={2}>
              <Mui.Grid item xs={12} md={6}>
                <Location
                  values={values}
                  errors={errors}
                  getFieldMeta={getFieldMeta}
                  setFieldValue={setFieldValue}
                  routeList={props.routes}
                  onFilter={onFilter}
                  getUserCard={props.getUserCard}
                  calculateBookingPrice={calculateBookingPrice}
                  resetPriceCalculation={resetPriceCalculation}
                />
                <Vehicle
                  values={values}
                  setFieldValue={setFieldValue}
                  carList={props.carList}
                  onFilter={onFilter}
                  resetPriceCalculation={resetPriceCalculation}
                />

                <Contact values={values} setFieldValue={setFieldValue} setTouched={setTouched} userList={props.users} />
              </Mui.Grid>
              <Mui.Grid item xs={12} md={6}>
                <Mui.Hidden smDown>
                  <Map values={values} routes={props.routes} setFieldValue={setFieldValue} />
                </Mui.Hidden>
                <AdditionalCharge
                  values={values}
                  setFieldValue={setFieldValue}
                  additionalChargeList={props.additionalCharges}
                />
                <Payment
                  coupons={props.coupons}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  bookingCost={props.bookingCost}
                  userCard={props.userCard}
                  loading={props.loading}
                  formikRef={formikRef}
                  bookingId={props.match?.params?.id || ""}
                  additionalChargeList={props.additionalCharges}
                />
                <Mui.Hidden mdUp>
                  <Map values={values} routes={props.routes} setFieldValue={setFieldValue} />
                </Mui.Hidden>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Box
              width="100%"
              display="flex"
              flexDirection={Mui.isWidthDown("xs", props.width) ? "column" : "row"}
              className="mt-5"
            >
              <Mui.Button
                className={`btn-default ${Mui.isWidthDown("xs", props.width) ? "mb-3" : "mr-3"}`}
                color="primary"
                type="button"
                variant="outlined"
                disableElevation
                component={Link}
                to={"/booking"}
              >
                Back
              </Mui.Button>
              <Mui.Button
                className="font-weight-normal"
                type="button"
                variant="text"
                disableElevation
                onClick={resetForm}
              >
                Reset
              </Mui.Button>
            </Mui.Box>
          </Form>
        )}
      </Formik>
      <Alert
        open={openAlert}
        close={() => {
          setOpenAlert(false);
          props.history.push(`/booking/view/${props.responseBookingId}`);
        }}
        action={() => {
          if (props.responseBookingId && !props.loading1) {
            props.sendBookingEmail(props.responseBookingId, { action: props.match.params.id ? "update" : "create" });
          }
        }}
        title="Send email to customer"
        info="Are you sure to perform the action?"
        awaitingInfo="Sending email..."
        actionBtnLabel="Send"
        loading={props.loading1}
        success={props.success1}
        reset={props.resetBooking}
      />
    </>
  );
};
const mapStateToProps = ({ booking, ride, route, user, coupon, additionalCharge }) => {
  const {
    bookingData,
    bookingCost,
    success,
    message,
    loading,
    loading1,
    loading2,
    success1,
    error,
    responseBookingId,
  } = booking;
  const { carList, userCard } = ride;
  const { routes } = route;
  const { users } = user;
  const { coupons } = coupon;
  const { additionalCharges } = additionalCharge;
  return {
    carList,
    userCard,
    routes,
    users,
    coupons,
    additionalCharges,
    bookingData,
    bookingCost,
    success,
    message,
    loading,
    error,
    responseBookingId,
    loading1,
    loading2,
    success1,
  };
};
export default connect(mapStateToProps, {
  getBooking,
  addBooking,
  editBooking,
  getAllRoute,
  filterCar,
  getAllUser,
  getAllCoupon,
  getAllAdditionalCharge,
  getUserCard,
  resetUserCard,
  getBookingCost,
  resetBookingCost,
  sendBookingEmail,
  resetBooking,
})(Mui.withWidth()(EditBooking));
