import Req from "src/interceptors/token-interceptor";

const AdditionalChargeService = (function () {
  function _getAllAdditionalCharge() {
    return Req.get(`/api/additional_charges`);
  }
  function _getAdditionalCharge(id) {
    return Req.get("/api/additional_charges/" + id);
  }
  function _addAdditionalCharge(data) {
    return Req.post("/api/additional_charges", data);
  }
  function _editAdditionalCharge(data, id) {
    return Req.put("/api/additional_charges/" + id, data);
  }
  function _deleteAdditionalCharge(id) {
    return Req.delete("/api/additional_charges/" + id);
  }
  function _deleteMultipleAdditionalCharge(ids) {
    return Req.post("/api/additional_charges/delete", { ids: ids });
  }
  return {
    getAllAdditionalCharge: _getAllAdditionalCharge,
    getAdditionalCharge: _getAdditionalCharge,
    addAdditionalCharge: _addAdditionalCharge,
    editAdditionalCharge: _editAdditionalCharge,
    deleteAdditionalCharge: _deleteAdditionalCharge,
    deleteMultipleAdditionalCharge: _deleteMultipleAdditionalCharge,
  };
})();
export default AdditionalChargeService;
