import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { getRoute, addRoute, editRoute, getAllCar } from "src/reduxs/actions";
import Breadcrumb from "src/components/breadcrumb";
import { InputField, TextareaField, SelectField } from "src/components/form";
import { IconAdd, IconTrash } from "src/components/svg";
import { extractValue } from "src/helpers/util";
import { defaultCurrency } from "src/configs/constant";
import GoogleAutocomplete from "src/components/autocomplete";
import Map from "./map";
class EditRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.schema = Yup.object().shape({
      name: Yup.string()
        .required("Please enter route name")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      routeWaypoint: Yup.array().of(
        Yup.object().shape({
          location: Yup.string().required("Enter a valid location").nullable(),
        })
      ),
      routeDetail: Yup.array().of(
        Yup.object().shape({
          vehicleTypeId: Yup.string().required("Select a vehicle").nullable(),
          price: Yup.string()
            .required("Enter price")
            .test("check-price", "Invalid value", function (value) {
              return value >= 0;
            })
            .nullable(),
        })
      ),
    });
  }

  componentDidMount() {
    this.props.getAllCar();
    if (this.props.match.params.id) {
      this.props.getRoute(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editRoute(this.props.match.params.id, values, this.props.history);
      } else {
        this.props.addRoute(values, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Route Management"}
          paths={[
            {
              title: "Route",
              page: `/route`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: this.props.match.params.id ? this.props.routeData?.name || "" : "",
            description: this.props.match.params.id ? this.props.routeData?.description || "" : "",
            totalDistance: this.props.match.params.id ? this.props.routeData?.totalDistance || 0 : 0,
            totalTime: this.props.match.params.id ? this.props.routeData?.totalTime || "00:00:00" : "00:00:00",
            routeWaypoint: this.props.match.params.id
              ? this.props.routeData?.routeWaypoint || [{ location: "" }]
              : [{ location: "" }],
            routeDetail: this.props.match.params.id
              ? this.props.routeData?.routeDetail || [{ vehicleTypeId: "", price: "" }]
              : [{ vehicleTypeId: "", price: "" }],
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Grid container spacing={3} wrap="wrap">
                <Mui.Grid item xs={12} md={7}>
                  <Mui.Card className="default-card">
                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} md={12} lg={8}>
                        <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                          Route Information
                        </Mui.Typography>

                        <Mui.Grid container spacing={4}>
                          <Mui.Grid item xs={12} className="form-group">
                            <InputField name="name" type="text" label="Name" />
                          </Mui.Grid>

                          <Mui.Grid item xs={12} className="form-group">
                            <TextareaField name="description" label="Description" />
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Divider light={true} className="mt-3 mb-5" />

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} md={12} lg={8}>
                        <FieldArray
                          name="routeDetail"
                          render={(arrayHelpers) => (
                            <>
                              <Mui.Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                className="mb-4"
                              >
                                <Mui.Typography component="h4" variant="h4" className="font-weight-medium">
                                  Price Information
                                </Mui.Typography>

                                <Mui.IconButton
                                  type="button"
                                  size="small"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      vehicleTypeId: "",
                                      price: "",
                                    });
                                  }}
                                  variant="outlined"
                                  className="btn-icon-square"
                                >
                                  <IconAdd />
                                </Mui.IconButton>
                              </Mui.Box>

                              <Mui.Grid container spacing={4} alignItems="start">
                                {props.values?.routeDetail?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <Mui.Grid item xs={12} sm={6} className="form-group">
                                      <SelectField
                                        name={`routeDetail.${index}.vehicleTypeId`}
                                        label="Select Vehicle Type"
                                        options={this.props.cars || []}
                                        readonlyArr={extractValue(props.values.routeDetail, "vehicleTypeId")}
                                      />
                                    </Mui.Grid>

                                    <Mui.Grid item xs={12} sm={4} className="form-group">
                                      <InputField
                                        name={`routeDetail.${index}.price`}
                                        type="number"
                                        label="Enter Vehicle Price"
                                        InputProps={{
                                          startAdornment: (
                                            <Mui.InputAdornment position="start">
                                              {defaultCurrency.symbol}
                                            </Mui.InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Mui.Grid>

                                    <Mui.Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      className={Mui.isWidthDown("xs", this.props.width) ? "mb-5" : ""}
                                    >
                                      {index !== 0 ? (
                                        <Mui.IconButton
                                          size="small"
                                          onClick={() => arrayHelpers.remove(index)}
                                          variant="outlined"
                                          className="btn-icon-square border-color-danger svg-color-danger"
                                        >
                                          <IconTrash />
                                        </Mui.IconButton>
                                      ) : null}
                                    </Mui.Grid>
                                  </React.Fragment>
                                ))}
                              </Mui.Grid>
                            </>
                          )}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Card>
                </Mui.Grid>

                <Mui.Grid
                  item
                  xs={12}
                  md={5}
                  className={`pos-relative ${Mui.isWidthDown("sm", this.props.width) ? "mt-5" : ""}`}
                >
                  <FieldArray
                    name="routeWaypoint"
                    render={(arrayHelpers) => (
                      <>
                        <Mui.Button
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={() => {
                            arrayHelpers.push({ location: "" });
                            this.setState({
                              expanded: true,
                            });
                          }}
                          className={`pos-absolute pos-top-per-0 pos-right-per-0 mr-4 z-index-1 ${
                            Mui.isWidthUp("sm", this.props.width) ? "mt-2 pl-1" : "mt-3 p-0 w-min-netural"
                          }`}
                        >
                          {Mui.isWidthUp("sm", this.props.width) ? "Add New" : null}

                          <span className={`line-height-null ${Mui.isWidthUp("sm", this.props.width) ? "ml-1" : null}`}>
                            <IconAdd />
                          </span>
                        </Mui.Button>

                        <Mui.Accordion
                          className="accordion-default"
                          expanded={this.state.expanded}
                          onChange={() =>
                            this.setState({
                              expanded: !this.state.expanded,
                            })
                          }
                        >
                          <Mui.AccordionSummary
                            classes={{
                              content: "justify-content-between align-items-center",
                            }}
                          >
                            <Mui.Typography component="h4" variant="h4" className="font-weight-medium">
                              Waypoints{" "}
                              <span className="text-color-primary">({props.values?.routeWaypoint?.length || 0})</span>
                            </Mui.Typography>
                          </Mui.AccordionSummary>

                          <Mui.AccordionDetails>
                            <Mui.Grid container spacing={4} wrap="wrap">
                              {props.values?.routeWaypoint?.map((item, index) => (
                                <React.Fragment key={index}>
                                  <Mui.Grid item xs={10} md={10} className="form-group">
                                    <GoogleAutocomplete
                                      label={"Waypoint"}
                                      variant={"outlined"}
                                      placeholder="Enter a Waypoint"
                                      touched={props.touched?.routeWaypoint?.[index]?.location}
                                      error={props.errors?.routeWaypoint?.[index]?.location}
                                      defaultValue={props.values?.routeWaypoint?.[index]?.location}
                                      callback={(description) => {
                                        props.setFieldValue(`routeWaypoint.${index}.location`, description || "");
                                      }}
                                    />
                                  </Mui.Grid>
                                  <Mui.Grid item xs={2} md={2} className="form-group">
                                    {index !== 0 ? (
                                      <Mui.IconButton
                                        size="small"
                                        onClick={() => arrayHelpers.remove(index)}
                                        variant="outlined"
                                        className="btn-icon-square border-color-danger svg-color-danger"
                                      >
                                        <IconTrash />
                                      </Mui.IconButton>
                                    ) : null}
                                  </Mui.Grid>
                                </React.Fragment>
                              ))}
                            </Mui.Grid>
                          </Mui.AccordionDetails>
                        </Mui.Accordion>
                      </>
                    )}
                  />
                  <Map values={props.values} setFieldValue={props.setFieldValue} />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5 "
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/route"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ route, car }) => {
  const { routeData, success, message, loading, error } = route;
  const { cars } = car;
  return {
    cars,
    routeData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getRoute,
  addRoute,
  editRoute,
  getAllCar,
})(Mui.withWidth()(EditRoute));
