import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { toast } from "react-toastify";
import {
  IconClock,
  IconInfo,
  IconRoute,
  IconClose,
  IconChevronRight,
  IconCarFront,
  IconMapPin,
} from "src/components/svg";
import GoogleAutocomplete from "src/components/autocomplete";
import { formatCurrency } from "src/helpers/util";
import ToastElement from "src/components/toast";
class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      pickupLocation: null,
      dropOffLocation: null,
      datepicker: false,
      date: null,
      time: null,
      car: null,
      route: null,
      flightType: null,
      flightNumber: null,
      duration: { id: "01:00:00", name: "1 hour(s)" },
      quoted: false,
      totalDistance: 0,
      totalTime: "00:00:00",
    };

    this.schema = Yup.object().shape({
      type: Yup.string().required("Select service").nullable(),
      vehicleTypeId: Yup.string().required("Select a car").nullable(),
      pickupDate: Yup.string().required("Enter a valid date").nullable(),
      pickupTime: Yup.string()
        .required("Enter a valid time")
        .nullable()
        .when("pickupDate", {
          is: (value) => value?.length > 0,
          then: Yup.string().test("is-minimum", "Pick-Up Time minimum restriction of 3 hours", function (value) {
            const { pickupDate } = this.parent;
            let el = moment(pickupDate + " " + value, "YYYY-MM-DD HH:mm:ss");
            let now = moment();
            if (el.isSameOrBefore(now)) {
              return false;
            }
            var duration = moment.duration(el.diff(now));
            var hr = duration.asHours();
            return hr >= 3;
          }),
          otherwise: Yup.string().required("Enter a valid time").nullable(),
        }),
      pickupLocation: Yup.string().when("type", {
        is: (value) => value === "distance" || value === "hourly",
        then: Yup.string().required("Enter a valid location").nullable(),
        otherwise: Yup.string(),
      }),
      dropOffLocation: Yup.string().when("type", {
        is: (value) => value === "distance" || value === "hourly",
        then: Yup.string().required("Enter a valid location").nullable(),
        otherwise: Yup.string(),
      }),
      durationInHour: Yup.string().when("type", {
        is: (value) => value === "hourly",
        then: Yup.string().required("Select a valid duration").nullable(),
        otherwise: Yup.string(),
      }),
      routeId: Yup.string().when("type", {
        is: (value) => value === "flat_rate",
        then: Yup.string().required("Select valid route").nullable(),
        otherwise: Yup.string(),
      }),
      flightType: Yup.string()
        .nullable()
        .when("pickupLocation", {
          is: (value) => value?.length > 0,
          then: Yup.string().test("is-valid", "Please provide flight information", function (value) {
            const { type, pickupLocation } = this.parent;
            if (type === "distance" && pickupLocation?.match(/airport/i) && !value) {
              return false;
            }
            return true;
          }),
          otherwise: Yup.string().nullable(),
        }),
      flightNumber: Yup.string().when("flightType", {
        is: (value) => value === "domestic" || value === "international",
        then: Yup.string().required("Enter a valid flight number").nullable(),
        otherwise: Yup.string(),
      }),
    });
  }

  onSubmit = (values) => {
    if (values.type === "distance") {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: values.pickupLocation,
          destination: values.dropOffLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const route = result.routes[0];
            let el = 0;
            let elt = 0;
            for (let i = 0; i < route.legs.length; i++) {
              el += route.legs[i].distance.value;
              elt += route.legs[i].duration.value;
            }

            values.totalDistance = (el / 1000).toFixed(2);
            this.props.calculateBookingPrice(values);
            this.setState({
              type: values.type,
              flightType: values.pickupLocation?.match(/airport/i) ? values.flightType : null,
              flightNumber: values.pickupLocation?.match(/airport/i) ? values.flightNumber : null,
              quoted: true,
              pickupLocation: values.pickupLocation,
              dropOffLocation: values.dropOffLocation,
              totalDistance: (el / 1000).toFixed(2),
              totalTime: moment.utc(moment.duration(elt, "seconds").as("milliseconds")).format("HH:mm:ss"),
            });
          } else {
            // Error
            toast.error(
              <ToastElement
                type="error"
                message={
                  "There was an issue fetching directions. Proper routes was not found. Please try again changing your pick-up/drop-off location."
                }
              />,
              {
                containerId: "default",
                autoClose: 8000,
              }
            );
          }
        }
      );
    }
    if (values.type === "hourly") {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: values.pickupLocation,
          destination: values.dropOffLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const route = result.routes[0];
            let el = 0;
            let elt = 0;
            for (let i = 0; i < route.legs.length; i++) {
              el += route.legs[i].distance.value;
              elt += route.legs[i].duration.value;
            }

            this.props.calculateBookingPrice(values);
            this.setState({
              type: values.type,
              flightType: null,
              flightNumber: null,
              quoted: true,
              pickupLocation: values.pickupLocation,
              dropOffLocation: values.dropOffLocation,
              totalDistance: (el / 1000).toFixed(2),
              totalTime: moment.utc(moment.duration(elt, "seconds").as("milliseconds")).format("HH:mm:ss"),
            });
          } else {
            // Error
            toast.error(
              <ToastElement
                type="error"
                message={
                  "There was an issue fetching directions. Proper routes was not found. Please try again changing your pick-up/drop-off location."
                }
              />,
              {
                containerId: "default",
                autoClose: 8000,
              }
            );
          }
        }
      );
    }
    if (values.type === "flat_rate") {
      this.props.calculateBookingPrice(values);
      this.setState({
        type: values.type,
        flightType: null,
        flightNumber: null,
        quoted: true,
        totalDistance: this.props.routeList?.find((x) => x.id === values.routeId)?.totalDistance,
        totalTime: this.props.routeList?.find((x) => x.id === values.routeId)?.totalTime,
      });
    }
  };

  onClose = () => {
    this.props.close();
    this.setState({
      type: null,
      pickupLocation: null,
      dropOffLocation: null,
      datepicker: false,
      date: null,
      time: null,
      car: null,
      route: null,
      flightType: null,
      flightNumber: null,
      duration: { id: "01:00:00", name: "1 hour(s)" },
      quoted: false,
      totalDistance: 0,
      totalTime: "00:00:00",
    });
  };

  handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      this.onClose();
    }
  };

  render() {
    return (
      <Mui.Dialog
        open={this.props.open}
        onClose={this.handleClose}
        maxWidth={false}
        classes={{
          paper: `get-quote-dialog ${this.state.quoted ? "showing-result" : ""}`,
        }}
      >
        <Mui.IconButton onClick={this.onClose} color="primary" className="btn-close">
          <IconClose />
        </Mui.IconButton>

        <Mui.Typography component="h2" variant="h2" className="gradent-title">
          Get a quote
        </Mui.Typography>
        <div className={`animate-from-bottom ${this.state.quoted ? "shrink-left" : ""}`}>
          <Mui.Typography component="h4" variant="h4" className="mb-2 font-weight-medium">
            With more details comes a better quote
          </Mui.Typography>

          <Formik
            enableReinitialize={true}
            initialValues={{
              type: "distance",
              pickupDate: null,
              pickupTime: "",
              vehicleTypeId: "",
              pickupLocation: "",
              dropOffLocation: "",
              durationInHour: "01:00:00",
              routeId: "",
              flightType: "",
              flightNumber: "",
              totalDistance: 0,
            }}
            validationSchema={this.schema}
            onSubmit={this.onSubmit}
          >
            {(props) => (
              <Form className="default-form">
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className={`mb-1 font-weight-normal ${
                    props.touched.type && props.errors.type ? "text-color-danger" : null
                  }`}
                >
                  Select Service
                </Mui.Typography>
                <MuiLab.ToggleButtonGroup
                  exclusive
                  value={props.values.type}
                  onChange={(event, value) => {
                    props.setFieldValue("type", value);
                    this.props.onFilter();
                  }}
                  aria-label="service type"
                  className="select-service-btn"
                >
                  <MuiLab.ToggleButton value="distance" aria-label="distance">
                    Point to Point
                  </MuiLab.ToggleButton>
                  <MuiLab.ToggleButton value="hourly" aria-label="hourly">
                    Hourly
                  </MuiLab.ToggleButton>
                  {/* <MuiLab.ToggleButton value="flat_rate" aria-label="flat rate">
                    Flat Rate
                  </MuiLab.ToggleButton> */}
                </MuiLab.ToggleButtonGroup>

                <Mui.Grid container spacing={4}>
                  <Mui.Grid
                    item
                    xs={12}
                    sm={12}
                    className={`form-group-dark mb-2 ${props.values.type === "flat_rate" ? "" : "d-none"}`}
                  >
                    <Field name="routeId">
                      {({ field, meta }) => (
                        <>
                          <MuiLab.Autocomplete
                            options={this.props.routeList || []}
                            autoHighlight
                            autoComplete={true}
                            disableClearable={true}
                            getOptionLabel={(option) => (option ? option.name : "")}
                            value={this.state.route}
                            onChange={(event, selectedOption) => {
                              this.setState({
                                route: selectedOption,
                                car: null,
                              });
                              props.setFieldValue("routeId", selectedOption?.id || "");
                              props.setFieldValue("vehicleTypeId", "");
                              this.props.onFilter(selectedOption?.id);
                            }}
                            renderOption={(option, { selected }) => (
                              <div className="select-item">{option ? option.name : ""}</div>
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                error={meta.touched && meta.error ? true : false}
                                placeholder={""}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "nofill",
                                }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label={
                                  <>
                                    Route{" "}
                                    <Mui.Tooltip
                                      className="tooltip-default"
                                      arrow
                                      placement="bottom-start"
                                      title={"Route"}
                                    >
                                      <span>
                                        <IconInfo />
                                      </span>
                                    </Mui.Tooltip>
                                  </>
                                }
                              />
                            )}
                          />
                          {meta.touched && meta.error ? (
                            <Mui.FormHelperText error>
                              {meta.touched && meta.error ? meta.error : null}
                            </Mui.FormHelperText>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} sm={props.values.type === "hourly" ? 6 : 12} className="form-group-dark mb-2">
                    <Field name="vehicleTypeId">
                      {({ field, meta }) => (
                        <>
                          <MuiLab.Autocomplete
                            options={this.props.carList || []}
                            autoHighlight
                            autoComplete={true}
                            disableClearable={true}
                            getOptionLabel={(option) => (option ? option.name : "")}
                            value={this.state.car}
                            onChange={(event, selectedOption) => {
                              this.setState({ car: selectedOption });
                              props.setFieldValue("vehicleTypeId", selectedOption?.id || "");
                            }}
                            renderOption={(option, { selected }) => (
                              <div className="select-item">{option ? option.name : ""}</div>
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                error={meta.touched && meta.error ? true : false}
                                placeholder={""}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "nofill",
                                }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label={
                                  <>
                                    Select Car{" "}
                                    <Mui.Tooltip
                                      className="tooltip-default"
                                      arrow
                                      placement="bottom-start"
                                      title={"Select Car"}
                                    >
                                      <span>
                                        <IconInfo />
                                      </span>
                                    </Mui.Tooltip>
                                  </>
                                }
                              />
                            )}
                          />
                          {meta.touched && meta.error ? (
                            <Mui.FormHelperText error>
                              {meta.touched && meta.error ? meta.error : null}
                            </Mui.FormHelperText>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    className={`form-group-dark  mb-2 ${props.values.type === "hourly" ? "" : "d-none"}`}
                  >
                    <Field name="durationInHour">
                      {({ field, meta }) => (
                        <>
                          <MuiLab.Autocomplete
                            options={[
                              { id: "01:00:00", name: "1 hour(s)" },
                              { id: "02:00:00", name: "2 hour(s)" },
                              { id: "03:00:00", name: "3 hour(s)" },
                              { id: "04:00:00", name: "4 hour(s)" },
                              { id: "05:00:00", name: "5 hour(s)" },
                              { id: "06:00:00", name: "6 hour(s)" },
                              { id: "07:00:00", name: "7 hour(s)" },
                              { id: "08:00:00", name: "8 hour(s)" },
                              { id: "09:00:00", name: "9 hour(s)" },
                              { id: "10:00:00", name: "10 hour(s)" },
                            ]}
                            autoHighlight
                            autoComplete={true}
                            disableClearable={true}
                            getOptionLabel={(option) => (option ? option.name : "")}
                            value={this.state.duration}
                            onChange={(event, selectedOption) => {
                              this.setState({ duration: selectedOption });
                              props.setFieldValue("durationInHour", selectedOption?.id || "");
                            }}
                            renderOption={(option, { selected }) => (
                              <div className="select-item">{option ? option.name : ""}</div>
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                error={meta.touched && meta.error ? true : false}
                                placeholder={""}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "nofill",
                                }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label={
                                  <>
                                    Duration In Hours{" "}
                                    <Mui.Tooltip
                                      className="tooltip-default"
                                      arrow
                                      placement="bottom-start"
                                      title={"Duration of the journey"}
                                    >
                                      <span>
                                        <IconInfo />
                                      </span>
                                    </Mui.Tooltip>
                                  </>
                                }
                              />
                            )}
                          />
                          {meta.touched && meta.error ? (
                            <Mui.FormHelperText error>
                              {meta.touched && meta.error ? meta.error : null}
                            </Mui.FormHelperText>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} sm={6} className="form-group-dark mb-2">
                    <Field name="pickupDate">
                      {({ field, meta }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className="date-time-picker"
                            InputProps={{ readOnly: true }}
                            autoOk
                            disableToolbar
                            variant="inline"
                            fullWidth
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            minDate={new Date()}
                            onClick={() => this.setState({ datepicker: true })}
                            onClose={() => this.setState({ datepicker: false })}
                            open={this.state.datepicker}
                            label={
                              <>
                                Pick-Up Date{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"The date when your journey will start"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            }
                            value={this.state.date}
                            onChange={(date) => {
                              this.setState({ date: date });
                              props.setFieldValue("pickupDate", moment(date).format("YYYY-MM-DD"));
                            }}
                            keyboardIcon={null}
                            helperText={meta.touched && meta.error ? meta.error : null}
                            error={meta.touched && meta.error ? true : false}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} sm={6} className="time-picker form-group-dark mb-2">
                    <Field name="pickupTime">
                      {({ field, meta }) => (
                        <Mui.FormControl error={meta.touched && meta.error ? true : false} variant={"outlined"}>
                          <Mui.InputLabel shrink={true}>
                            <>
                              Pick-Up Time{" "}
                              <Mui.Tooltip
                                className="tooltip-default"
                                arrow
                                placement="bottom-start"
                                title={"The time when your journey will start"}
                              >
                                <span>
                                  <IconInfo />
                                </span>
                              </Mui.Tooltip>
                            </>
                          </Mui.InputLabel>
                          <TimePicker
                            showSecond={false}
                            allowEmpty={false}
                            className=""
                            popupClassName=""
                            onChange={(value) => {
                              this.setState({ time: value });
                              props.setFieldValue("pickupTime", value.format("HH:mm:ss"));
                            }}
                            format={"HH:mm"}
                            minuteStep={5}
                            inputReadOnly
                          />
                          {meta.touched && meta.error ? (
                            <Mui.FormHelperText error>
                              {meta.touched && meta.error ? meta.error : null}
                            </Mui.FormHelperText>
                          ) : null}
                        </Mui.FormControl>
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    className={`form-group-dark mb-2 ${props.values.type === "flat_rate" ? "d-none" : ""}`}
                  >
                    <Field name="pickupLocation">
                      {({ field, meta }) => (
                        <GoogleAutocomplete
                          label={
                            <>
                              Pick-Up Location{" "}
                              <Mui.Tooltip
                                className="tooltip-default"
                                arrow
                                placement="bottom-start"
                                title={"The address where your journey will start"}
                              >
                                <span>
                                  <IconInfo />
                                </span>
                              </Mui.Tooltip>
                            </>
                          }
                          variant={"outlined"}
                          placeholder="Enter a Location"
                          touched={meta.touched}
                          error={meta.error}
                          callback={(description) => {
                            props.setFieldValue("pickupLocation", description || "");
                          }}
                        />
                      )}
                    </Field>
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    className={`form-group-dark mb-2${props.values.type === "flat_rate" ? "d-none" : ""}`}
                  >
                    <Field name="dropOffLocation">
                      {({ field, meta }) => (
                        <GoogleAutocomplete
                          label={
                            <>
                              Drop-Off Location{" "}
                              <Mui.Tooltip
                                className="tooltip-default"
                                arrow
                                placement="bottom-start"
                                title={"The address where your journey will end"}
                              >
                                <span>
                                  <IconInfo />
                                </span>
                              </Mui.Tooltip>
                            </>
                          }
                          variant={"outlined"}
                          placeholder="Enter a Location"
                          touched={meta.touched}
                          error={meta.error}
                          callback={(description) => {
                            props.setFieldValue("dropOffLocation", description || "");
                          }}
                        />
                      )}
                    </Field>
                  </Mui.Grid>
                </Mui.Grid>

                {props.values.type === "distance" && props.values.pickupLocation?.match(/airport/i) ? (
                  <Mui.List className="list-switch">
                    {props.errors?.flightType ? (
                      <Mui.Typography component="h5" variant="h5" className="mb-2 text-color-danger font-weight-normal">
                        {props.errors?.flightType}
                      </Mui.Typography>
                    ) : null}
                    {/* Domestic Flight */}
                    <Mui.ListItem>
                      <Mui.ListItemIcon>
                        <IconMapPin />
                      </Mui.ListItemIcon>
                      <Mui.ListItemText primary="Domestic Flight" />
                      <Mui.ListItemSecondaryAction>
                        <Mui.Switch
                          edge="end"
                          checked={props.values.flightType === "domestic" ? true : false}
                          onChange={(event) =>
                            props.setFieldValue("flightType", event.target.checked ? "domestic" : "")
                          }
                          color="primary"
                          inputProps={{
                            "aria-labelledby": "switch-list-label-domestic",
                          }}
                        />
                      </Mui.ListItemSecondaryAction>
                    </Mui.ListItem>
                    <Mui.ListItem className={`${props.values.flightType === "domestic" ? "" : "d-none"}`}>
                      <Mui.Box className="form-group-dark mt-0 mb-0">
                        <Field name="flightNumber">
                          {({ field, meta }) => (
                            <Mui.TextField
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              placeholder="Flight Number"
                              helperText={meta.touched && meta.error ? meta.error : null}
                              error={meta.touched && meta.error ? true : false}
                              {...field}
                            />
                          )}
                        </Field>
                      </Mui.Box>
                    </Mui.ListItem>

                    {/* International Flight */}
                    <Mui.ListItem>
                      <Mui.ListItemIcon>
                        <IconRoute />
                      </Mui.ListItemIcon>
                      <Mui.ListItemText primary="International Flight" />
                      <Mui.ListItemSecondaryAction>
                        <Mui.Switch
                          edge="end"
                          checked={props.values.flightType === "international" ? true : false}
                          onChange={(event) =>
                            props.setFieldValue("flightType", event.target.checked ? "international" : "")
                          }
                          color="primary"
                          inputProps={{
                            "aria-labelledby": "switch-list-label-international",
                          }}
                        />
                      </Mui.ListItemSecondaryAction>
                    </Mui.ListItem>
                    <Mui.ListItem className={`${props.values.flightType === "international" ? "" : "d-none"}`}>
                      <Mui.Box className="form-group-dark mt-0 mb-0">
                        <Field name="flightNumber">
                          {({ field, meta }) => (
                            <Mui.TextField
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              placeholder="Flight Number"
                              helperText={meta.touched && meta.error ? meta.error : null}
                              error={meta.touched && meta.error ? true : false}
                              {...field}
                            />
                          )}
                        </Field>
                      </Mui.Box>
                    </Mui.ListItem>

                    <Mui.Typography component="h5" variant="h5" className={`mt-1 text-color-white font-weight-normal`}>
                      * Parking charges for domestic & international airport pick-up.{" "}
                      <Mui.Tooltip
                        className="tooltip-default svg-color-primary svg-size-small"
                        arrow
                        placement="bottom-start"
                        title={"$5 for 30min, $10 up-to 60min & $35 from 60min to 3hr of waiting."}
                      >
                        <span>
                          <IconInfo />
                        </span>
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.List>
                ) : null}

                <Mui.Box display="flex" justifyContent="center" mt={4}>
                  <Mui.Button
                    type="submit"
                    className="btn-default"
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    Get a quote
                  </Mui.Button>
                </Mui.Box>
              </Form>
            )}
          </Formik>
        </div>

        {this.state.quoted ? (
          <Mui.Box className="quote-result-view">
            <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
              <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
                Quote Result
              </Mui.Typography>
            </Mui.Box>

            {(this.state.type === "distance" || this.state.type === "hourly") &&
            parseFloat(this.state.totalDistance) >= 100 ? (
              <Mui.Box flexDirection="column">
                <Mui.Typography component="h1" variant="h1" className="font-weight-medium text-color-primary">
                  <Mui.Typography component="span" variant="h1" className="d-block text-color-white">
                    Contact
                  </Mui.Typography>
                  +61 426 292 525
                </Mui.Typography>

                <Mui.Typography component="p" variant="h6" className="mt-1 mb-4 text-color-grey font-weight-normal">
                  Please contact service provider for booking over 100 km or you can fill the form{" "}
                  <Link to={`/contact`} className="text-color-grey">
                    here
                  </Link>{" "}
                  to get in touch with us!
                </Mui.Typography>
              </Mui.Box>
            ) : (
              <Mui.Box flexDirection="column">
                <Mui.Typography component="h1" variant="h1" className="font-weight-medium text-color-primary">
                  <Mui.Typography component="span" variant="h1" className="d-block text-color-white">
                    Total Cost
                  </Mui.Typography>
                  {formatCurrency(this.props.priceData?.total || 0)}
                </Mui.Typography>

                <Mui.Typography component="p" variant="h6" className="mt-1 mb-4 text-color-grey font-weight-normal">
                  Note: Off pick surcharges may apply depending upon your time selection.
                </Mui.Typography>
              </Mui.Box>
            )}

            <Mui.Box display="flex">
              <Mui.Box
                display="flex"
                alignItems="flex-start"
                className="svg-color-primary svg-size-small-2x mr-4"
                mb={3}
              >
                <IconRoute />
                <Mui.Typography component="h5" variant="h5" className="ml-2 font-weight-medium text-color-primary">
                  <Mui.Typography component="span" variant="body1" className="d-block text-color-white">
                    Total Distance
                  </Mui.Typography>
                  {this.state.totalDistance} km
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box
                display="flex"
                alignItems="flex-start"
                className="svg-color-primary svg-size-small-2x mr-4"
                mb={3}
              >
                <IconClock />
                <Mui.Typography component="h5" variant="h5" className="ml-2 font-weight-medium text-color-primary">
                  <Mui.Typography component="span" variant="body1" className="d-block text-color-white">
                    APPROX ETA
                  </Mui.Typography>
                  {moment(this.state.totalTime, "HH:mm:ss").format("H")}h{" "}
                  {moment(this.state.totalTime, "HH:mm:ss").format("m")}m
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>

            <Mui.Box display="flex" alignItems="flex-start" className="svg-color-primary svg-size-small-2x" mb={3}>
              <IconCarFront />
              <Mui.Typography component="h5" variant="h5" className="ml-2 font-weight-medium text-color-primary">
                <Mui.Typography component="span" variant="body1" className="d-block text-color-white">
                  Vehicle
                </Mui.Typography>
                {this.state.car?.name || ""}
              </Mui.Typography>
            </Mui.Box>
            {(this.state.type === "distance" || this.state.type === "hourly") &&
            parseFloat(this.state.totalDistance) >= 100 ? null : (
              <Mui.Button
                type="button"
                className="mt-3 btn-default"
                color="primary"
                variant="contained"
                disableElevation
                component={Link}
                to={{
                  pathname: "/ride",
                  state: {
                    rideDetail: {
                      type: this.state.type,
                      flightType: this.state.flightType,
                      flightNumber: this.state.flightNumber,
                      pickupDate: moment(this.state.date).format("YYYY-MM-DD"),
                      pickupTime: moment(this.state.time).format("HH:mm:ss"),
                      pickupLocation: this.state.pickupLocation,
                      dropOffLocation: this.state.dropOffLocation,
                      durationInHour: this.state.duration?.id || "01:00:00",
                      routeId: this.state.route?.id || "",
                      totalDistance: this.state.totalDistance,
                      totalTime: this.state.totalTime,
                      vehicleTypeId: this.state.car?.id || "",
                    },
                  },
                }}
              >
                Continue with quote
                <span className="line-height-null ml-1 svg-color-white">
                  <IconChevronRight />
                </span>
              </Mui.Button>
            )}
          </Mui.Box>
        ) : null}
      </Mui.Dialog>
    );
  }
}
export default Quote;
