import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/airport-transfers.jpg";
import { IconCarFront } from "src/components/svg";
import airportTransferImage1 from "src/assets/images/website/services/airport-transfer-1.jpg";
import airportTransferImage2 from "src/assets/images/website/services/airport-transfer-2.jpg";
import airportTransferImage3 from "src/assets/images/website/services/airport-transfer-3.jpg";

class AirportTransfers extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Our luxurious and comfortable airport transfer service for domestic and international flights in Sydney, Melbourne, Adelaide, Brisbane, Perth and the Gold Coast" />

        <section className="inner-banner" style={{ height: "42vh" }}>
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Airport Transfers
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Airport Transfers
            </Mui.Typography>
          </Mui.Container>
        </section>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  At Shangri-La Chauffeurs, we’re known for our extremely
                  reliable airport transport service. You can count on us to
                  always be there – on time, every time!
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We can do pick-ups and drop-offs at major airports around
                  Australia, including Sydney, Melbourne, Adelaide, Brisbane,
                  Perth and the Gold Coast.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Just book your driver before you travel and then sit back and
                  relax! All our drivers are well trained and fully licensed to
                  drive your vehicle. They’re also impeccably dressed and highly
                  professional.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our stylish and comfortable airport transport is perfect for
                  corporate flyers, overseas or interstate visitors, hospital
                  patients and FIFO (fly in, fly out) workers.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={airportTransferImage3}
                  alt="Airport Transfers"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={airportTransferImage2}
                  alt="Airport Transfers"
                />
              </Mui.Grid>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our professional drivers are local to the area, so they know
                  the quickest routes to get you to your plane on time. You will
                  never miss a flight because of us. We’ve had thousands of
                  happy customers over the years – and many have returned
                  because we never let them down.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  And when you land, your chauffeur will be waiting, ready to
                  stow your luggage and take you to your destination. While
                  you’re flying, they will check for any flight delays and
                  adjust the schedule accordingly. We will always be there when
                  you need us!
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We promise you a luxurious ride in a stylish vehicle and air
                  conditioned comfort. We have an impressive range of cars,
                  including standard sedans, Euro luxury sedans, premium Euro
                  luxury cars and people movers.
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  If you have business to conduct and people to call on the
                  trip, you’ll find it easy to work in the peace and quiet. All
                  our chauffers maintain strict confidentiality. And if you’re
                  travelling with colleagues, you can order a limousine with
                  enough room for everyone to spread out and work.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Travelling is stressful enough – let us take care of the
                  traffic and parking. We’ll drop you right at the doorstep so
                  you’ll arrive feeling fresh and ready to go.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Book one of our drivers for any hour of the day, 365 days a
                  year. We looking forward to assisting you.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                cclassName={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={airportTransferImage1}
                  alt="Airport Transfers"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box display="flex" justifyContent="center" mt={10} mb={10}>
          <Mui.Button
            component={Link}
            // to={"/ride"}
            to="/contact"
            className="btn-default"
            color="primary"
            variant="contained"
            disableElevation
          >
            Book Now
            <span className="ml-1 line-height-null">
              <IconCarFront />
            </span>
          </Mui.Button>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}

export default Mui.withWidth()(AirportTransfers);
