import React from "react";

const IconChevronRight = () => {
  return (
    <svg width="22" height="22" version="1.1" viewBox="0 0 407.436 407.436">
      <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 " />
    </svg>
  );
};

export default IconChevronRight;
