/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/views/auth/index`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute } from "./configs/core";
import { AppLayout } from "./layouts";
import LandingView from "./views";
import RideView from "./views/ride";
import AboutView from "./views/about";
import FaqView from "./views/faq";
// import OurFleetView from "./views/our-fleet";
// import OurFleetDetailView from "./views/our-fleet-detail";
import TermsConditionView from "./views/terms-condition";
import PrivacyPolicy from "./views/privacy-policy";
import ContactView from "./views/contact";
import NotFoundView from "./views/not-found";
import MyAccountView from "./views/my-account";
import BaseView from "./views/app";
import AuthView from "./views/auth";
import IconView from "./views/icon";
import LogoutView from "./views/auth/logout";
import AirportTransfers from "./views/our-services/airport-transfer";
import BusinessTransfers from "./views/our-services/business-transfer";
import WeddingVehicles from "./views/our-services/wedding-vechile";
import Tour from "./views/our-services/tour";
import SpecialEvent from "./views/our-services/special-event";
import { ToastContainer } from "react-toastify";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useLocation } from "react-router-dom";

export default function Routes() {
  const { isAuthorized, isSuperadmin } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isSuperadmin: auth.user?.roleId === 1,
    }),
    shallowEqual
  );

  if (isSuperadmin) {
    document.querySelector("body").style.backgroundColor = "#E8E8E8";
  } else {
    document.querySelector("body").style.backgroundColor = "#1A2225";
  }

  const location = useLocation();

  return (
    <>
      <Switch>
        <ContentRoute exact path={`/`} component={LandingView} />
        <ContentRoute path={`/about`} component={AboutView} />
        <ContentRoute path={`/faq`} component={FaqView} />
        <ContentRoute path={`/ride`} component={RideView} />
        <ContentRoute path={`/contact`} component={ContactView} />
        <ContentRoute path={`/airport-transfers`} component={AirportTransfers} />
        <ContentRoute path={`/business-transfers`} component={BusinessTransfers} />
        <ContentRoute path={`/wedding-vechile`} component={WeddingVehicles} />
        <ContentRoute path={`/wedding-vechile`} component={WeddingVehicles} />
        <ContentRoute path={`/tour`} component={Tour} />
        <ContentRoute path={`/special-event`} component={SpecialEvent} />
        <ContentRoute path={`/terms-condition`} component={TermsConditionView} />
        <ContentRoute path={`/privacy-policy`} component={PrivacyPolicy} />
        {/* <ContentRoute path={`/our-fleet`} component={OurFleetView} />
        <ContentRoute
          path={`/our-fleet-detail/:slug`}
          component={OurFleetDetailView}
        /> */}
        <Route path="/icon" component={IconView} />
        <Route path="/404" component={NotFoundView} />
        <Route path="/logout" component={LogoutView} />

        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthView />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}

        {!isAuthorized ? (
          /*Redirect to `/` when user is not authorized*/
          <Redirect to="/" />
        ) : (
          <>
            {!isSuperadmin ? (
              <Switch>
                <ContentRoute path={`/my-account`} component={MyAccountView} />
                <Redirect to="/404" />
              </Switch>
            ) : (
              <AppLayout>
                <BaseView />
              </AppLayout>
            )}
          </>
        )}
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        enableMultiContainer
        containerId={"default"}
      />
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
        enableMultiContainer
        containerId={"custom"}
      />
      {location?.pathname?.includes("/booking") ||
      location?.pathname?.includes("/driver") ||
      location?.pathname?.includes("/invoice") ||
      location?.pathname?.includes("/route") ||
      location?.pathname?.includes("/car-price") ||
      location?.pathname?.includes("/car") ||
      location?.pathname?.includes("/user") ||
      location?.pathname?.includes("/dashboard") ? null : (
        <MessengerCustomerChat
          pageId="402715893218165"
          // shouldShowDialog={true}
          appId="205681564776822"
          themeColor="#d5ab53"
        />
      )}
    </>
  );
}
