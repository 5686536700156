import * as Yup from "yup";
import moment from "moment";

const schema = [
  Yup.object().shape({
    pickupDate: Yup.string().required("Enter a valid date").nullable(),
    pickupTime: Yup.string()
      .required("Enter a valid time")
      .nullable()
      .when("pickupDate", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-minimum", "Pick-Up Time minimum restriction of 3 hours", function (value) {
          const { pickupDate } = this.parent;
          let el = moment(pickupDate + " " + value, "YYYY-MM-DD HH:mm:ss");
          let now = moment();
          if (el.isSameOrBefore(now)) {
            return false;
          }
          var duration = moment.duration(el.diff(now));
          var hr = duration.asHours();
          return hr >= 3;
        }),
        otherwise: Yup.string().required("Enter a valid time").nullable(),
      }),
    pickupLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    dropOffLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    durationInHour: Yup.string().when("type", {
      is: (value) => value === "hourly",
      then: Yup.string().required("Select a valid duration").nullable(),
      otherwise: Yup.string(),
    }),
    routeId: Yup.string().when("type", {
      is: (value) => value === "flat_rate",
      then: Yup.string().required("Select valid route").nullable(),
      otherwise: Yup.string(),
    }),
    flightType: Yup.string()
      .nullable()
      .when("pickupLocation", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-valid", "Please provide flight information", function (value) {
          const { type, pickupLocation } = this.parent;
          if (type === "distance" && pickupLocation?.match(/airport/i) && !value) {
            return false;
          }
          return true;
        }),
        otherwise: Yup.string().nullable(),
      }),
    flightNumber: Yup.string().when("flightType", {
      is: (value) => value === "domestic" || value === "international",
      then: Yup.string().required("Enter a valid flight number").nullable(),
      otherwise: Yup.string(),
    }),
    waypoint: Yup.array().of(
      Yup.object().shape({
        location: Yup.string().required("Enter a valid location").nullable(),
      })
    ),
  }),
  Yup.object().shape({
    noPassenger: Yup.string().required("Select a valid value").nullable(),
    noLuggage: Yup.string().required("Select a valid value").nullable(),
    vehicleTypeId: Yup.string().required("Select a car").nullable(),
  }),
  Yup.object().shape({
    firstName: Yup.string()
      .required("Enter your first name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    lastName: Yup.string()
      .required("Enter your last name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    email: Yup.string().required("Enter a valid email").email("Invalid email address").nullable(),
    phone: Yup.string()
      .required("Enter a valid contact")
      .min(8, "Atleast 8 digits")
      .max(10, "Cannot exceed 10 digits")
      .nullable(),
    alternatePhone: Yup.string().min(8, "Atleast 8 digits").max(10, "Cannot exceed 10 digits").nullable(),
  }),
];
export default schema;
