export const GET_PRICE_LIST = "GET_PRICE_LIST";
export const GET_PRICE_LIST_SUCCESS = "GET_PRICE_LIST_SUCCESS";
export const GET_PRICE_LIST_ERROR = "GET_PRICE_LIST_ERROR";
export const EDIT_PRICE = "EDIT_PRICE";
export const EDIT_PRICE_SUCCESS = "EDIT_PRICE_SUCCESS";
export const EDIT_PRICE_ERROR = "EDIT_PRICE_ERROR";

export const getPriceList = () => ({
  type: GET_PRICE_LIST,
  payload: {},
});

export const getPriceListSuccess = (priceList) => ({
  type: GET_PRICE_LIST_SUCCESS,
  payload: priceList,
});

export const getPriceListError = (error) => ({
  type: GET_PRICE_LIST_ERROR,
  payload: error,
});

export const editPrice = (priceId, priceData) => ({
  type: EDIT_PRICE,
  payload: { priceId, priceData },
});

export const editPriceSuccess = (success, message) => ({
  type: EDIT_PRICE_SUCCESS,
  payload: { success, message },
});

export const editPriceError = (error) => ({
  type: EDIT_PRICE_ERROR,
  payload: error,
});

