import {
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_ERROR,
  RESET_CLIENT,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  clientList: null,
  loading: false,
  error: null,
};

const clientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientList: action.payload,
        error: null,
      };
    case GET_CLIENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        clientList: null,
        error: action.payload,
      };
    case RESET_CLIENT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default clientReducer;
