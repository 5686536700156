import React from "react";

const IconDriver = () => {
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 512 512">
      <g transform="translate(1 1)">
        <g>
          <g>
            <path
              d="M485.4,411.16c-50.93-27.008-89.996-42.152-124.772-47.958l3.599-4.948c1.707-1.707,2.56-4.267,1.707-6.827
            l-8.067-32.267c20.803-29.854,32.748-67.112,31.96-102.56v-50.76c0.579-0.417,1.149-0.847,1.707-1.293
            c11.093-9.387,17.067-23.04,17.067-36.693c0-64-52.053-116.053-115.2-116.053h-75.947c-64,0-116.053,52.053-116.907,115.2
            c0,14.507,5.973,27.307,17.067,36.693c0.279,0.215,0.57,0.415,0.853,0.624V216.6c0,35.558,12.446,72.939,33.597,102.839
            l-7.997,31.987c0,1.707,0,4.267,1.707,6.827l3.599,4.948c-34.776,5.807-73.843,20.95-124.772,47.958
            C8.387,418.84-1,435.053-1,452.973v36.693c0,5.12,3.413,8.533,8.533,8.533h494.933c5.12,0,8.533-3.413,8.533-8.533v-36.693
            C511,435.907,500.76,419.693,485.4,411.16z M344.6,336.067l3.413,15.36l-8.717,11.969c-0.556,0.455-1.071,1.006-1.523,1.684
            l-46.933,64.853l-23.009-51.982c24.824-2.986,47.238-14.489,65.882-31.265C337.457,343.385,341.093,339.844,344.6,336.067z
             M380.44,150.893c-0.224,0.196-0.458,0.378-0.687,0.567c-0.959,0.223-1.89,0.582-2.726,1.14
            c-3.692,2.769-7.634,4.53-11.689,5.303c-3.216,0.493-6.488,0.449-9.644-0.183c-61.203-11.621-123.109-12.69-182.526-3.21
            c-6.594,0.953-13.167,2.019-19.714,3.21c-1.051,0.117-2.102,0.181-3.151,0.202c-6.86-0.123-13.508-2.029-19.036-6.175
            c-5.12-3.413-8.533-8.533-10.24-13.653l36.693-6.827c64-11.947,129.707-11.947,195.413,0l36.407,6.773
            C387.651,142.871,384.504,147.337,380.44,150.893z M216.6,28.867h75.947c52.343,0,95.272,40.762,98.752,92.233l-35.605-6.046
            c-67.413-11.947-135.68-11.947-201.387,0l-35.605,6.046C122.179,69.629,165.075,28.867,216.6,28.867z M135.533,173.08
            c6.827,1.707,14.507,2.56,22.187,0.853c31.861-5.947,64.144-8.932,96.64-8.958c32.5,0.026,65.21,3.01,97.92,8.958
            c2.331,0.583,4.261,0.764,6.068,0.823c5.372,0.407,10.986-0.393,16.118-1.676v43.52c0,37.209-12.764,70.274-31.929,95.407
            l-0.498,0.166c-0.853,0.853-1.707,1.707-2.56,2.56c-4.293,5.724-8.824,10.943-13.558,15.649
            c-21.188,19.74-46.685,31.284-70.921,31.284c0,0-0.001,0-0.001,0c-0.887,0-1.772-0.018-2.657-0.046
            c-14.418-0.496-29.207-5.012-43.208-12.837c-13.895-7.982-26.966-19.49-38.614-34.051c-0.853-0.853-1.707-1.707-2.56-2.56
            l-0.498-0.166c-19.164-25.134-31.929-58.199-31.929-95.407V173.08z M242.169,377.951l-23.009,51.982l-46.933-64.853
            c-0.272-0.272-0.572-0.541-0.884-0.806l-9.356-12.848l3.413-15.36c1.154,1.243,2.325,2.454,3.506,3.646
            C188.983,360.249,214.035,374.568,242.169,377.951z M493.933,481.133H297.667h-281.6v-28.16c0-11.093,5.973-21.333,16.213-26.453
            c54.393-28.896,93.548-43.399,128.428-47.731l53.332,73.331c1.34,2.011,3.215,2.959,5.608,3.277
            c0.404,0.08,0.811,0.136,1.218,0.136c0,0,0.853,0,0.853,0.853c2.56,0,5.973-2.56,6.827-5.12l26.501-59.628l11.291,24.767
            l15.115,34.008c0.853,2.56,3.413,5.12,6.827,5.12h0.853c2.56,0,5.12-1.707,6.827-3.413l53.883-74.09
            c34.757,5.245,74.615,19.737,127.877,47.636c10.24,5.973,16.213,16.213,16.213,27.307V481.133z"
            />
            <path
              d="M237.933,97.133h34.133c5.12,0,8.533-3.413,8.533-8.533V45.933c0-5.12-3.413-8.533-8.533-8.533h-34.133
            c-5.12,0-8.533,3.413-8.533,8.533V88.6C229.4,93.72,232.813,97.133,237.933,97.133z M246.467,54.467h17.067v25.6h-17.067V54.467z
            "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconDriver;
