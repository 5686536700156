import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Field } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import car from "src/assets/images/car.svg";
import {
  IconInfo,
  // IconCheck,
  IconBriefcase,
  IconUsers,
  // IconAdd,
  IconEdit,
} from "src/components/svg";
import { formatCurrency, noPassenger, noLuggage } from "src/helpers/util";
class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passenger: { id: "1", name: "1" },
      luggage: { id: "1", name: "1" },
      // chlidSeat: false,
    };
  }
  render() {
    return (
      <Mui.Grid container spacing={4}>
        <Mui.Grid item xs={12} md={3}>
          <Mui.Paper className="default-card-dark-card mb-3 p-2 border-1 border-color-primary-dark shadow-none">
            <Mui.Typography className="mb-3 text-color-white font-weight-normal" component="h2" variant="h2">
              Summary
            </Mui.Typography>

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                SERVICE TYPE
              </Mui.Typography>
              {this.props.values.type === "distance" ? "Point to Point" : null}
              {this.props.values.type === "hourly" ? "Hourly" : null}
              {this.props.values.type === "flat_rate" ? "Flat Rate" : null}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                REFERENCE NUMBER
              </Mui.Typography>
              {this.props.values.referenceNumber || "-"}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP LOCATION
              </Mui.Typography>
              {this.props.values.pickupLocation}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DROP-OFF LOCATION
              </Mui.Typography>
              {this.props.values.dropOffLocation || "-"}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "" : "d-none"
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                ROUTE
              </Mui.Typography>
              {this.props.routes?.find((x) => x.id === this.props.values.routeId)?.name || ""}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP DATE, TIME
              </Mui.Typography>
              {moment(this.props.values.pickupDate).format("DD/MM/YYYY")},{" "}
              {moment(this.props.values.pickupTime, "HH:mm:ss").format("HH:mm")}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "hourly" ? "" : "d-none"
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DURATION IN HOUR
              </Mui.Typography>
              {moment(this.props.values.durationInHour, "HH:mm:ss").format("H")} hour(s)
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "hourly" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                TOTAL DISTANCE
              </Mui.Typography>
              {this.props.values.totalDistance} km
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                APPROX ETA
              </Mui.Typography>
              {moment(this.props.values.totalTime, "HH:mm:ss").format("H")}h{" "}
              {moment(this.props.values.totalTime, "HH:mm:ss").format("m")}m
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-success" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-success" component="span" variant="body2">
                TOTAL
              </Mui.Typography>
              {formatCurrency(this.props.priceData?.total)}
            </Mui.Typography>
            {!this.props.userCard?.patron ? (
              <>
                <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />
                <Mui.Typography className="mb-1 font-weight-normal text-color-success" component="h6" variant="h5">
                  <Mui.Typography className="d-block text-color-success" component="span" variant="body2">
                    TO PAY (20% Deposit)
                  </Mui.Typography>
                  {formatCurrency(
                    this.props.priceData?.total > 0 ? ((20 / 100) * this.props.priceData?.total).toFixed(2) : 0
                  )}
                </Mui.Typography>
              </>
            ) : null}
          </Mui.Paper>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={9}>
          <Mui.Paper className="default-card-dark-card shadow-none mb-5">
            <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
              Vehicles Filter
            </Mui.Typography>

            <Mui.Grid container spacing={3} alignItems="flex-start">
              <Mui.Grid
                item
                xs={12}
                sm={this.props.values.extraLargeLuggage ? 2 : 4}
                className={`form-group-dark ${this.props.values.extraLargeLuggage ? "" : "horizontal"}`}
              >
                <Field name="noPassenger">
                  {({ field, meta }) => (
                    <>
                      <Autocomplete
                        options={noPassenger}
                        autoHighlight
                        autoComplete={true}
                        disableClearable={true}
                        getOptionLabel={(option) => (option ? option.name : "")}
                        value={this.state.passenger}
                        onChange={(event, selectedOption) => {
                          this.setState({ passenger: selectedOption });
                          this.props.setFieldValue("noPassenger", selectedOption?.id || "");
                          this.props.setFieldValue("vehicleTypeId", "");
                          this.props.resetPriceCalculation();
                          this.props.onFilter(
                            selectedOption?.id || "",
                            this.props.values.noLuggage,
                            this.props.values.routeId || ""
                          );
                        }}
                        renderOption={(option, { selected }) => (
                          <div className="select-item">{option ? option.name : ""}</div>
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            error={meta.touched && meta.error ? true : false}
                            placeholder={""}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "nofill",
                            }}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label={
                              <>
                                Passengers{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"Number of passengers"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            }
                          />
                        )}
                      />
                      {meta.touched && meta.error ? (
                        <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
                      ) : null}
                    </>
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={12}
                sm={this.props.values.extraLargeLuggage ? 2 : 4}
                className={`form-group-dark ${this.props.values.extraLargeLuggage ? "" : "horizontal"}`}
              >
                <Field name="noLuggage">
                  {({ field, meta }) => (
                    <>
                      <Autocomplete
                        options={noLuggage}
                        autoHighlight
                        autoComplete={true}
                        disableClearable={true}
                        getOptionLabel={(option) => (option ? option.name : "")}
                        value={this.state.luggage}
                        onChange={(event, selectedOption) => {
                          this.setState({ luggage: selectedOption });
                          this.props.setFieldValue("noLuggage", selectedOption?.id || "");
                          this.props.setFieldValue("vehicleTypeId", "");
                          this.props.resetPriceCalculation();
                          this.props.onFilter(
                            this.props.values.noPassenger,
                            selectedOption?.id || "",
                            this.props.values.routeId || ""
                          );
                        }}
                        renderOption={(option, { selected }) => (
                          <div className="select-item">{option ? option.name : ""}</div>
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            error={meta.touched && meta.error ? true : false}
                            placeholder={""}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "nofill",
                            }}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            label={
                              <>
                                Luggages{" "}
                                <Mui.Tooltip
                                  className="tooltip-default"
                                  arrow
                                  placement="bottom-start"
                                  title={"Number of luggages"}
                                >
                                  <span>
                                    <IconInfo />
                                  </span>
                                </Mui.Tooltip>
                              </>
                            }
                          />
                        )}
                      />
                      {meta.touched && meta.error ? (
                        <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
                      ) : null}
                    </>
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={12}
                sm={this.props.values.extraLargeLuggage ? 8 : 4}
                className={`form-group-dark pos-relative ${this.props.values.extraLargeLuggage ? "" : "horizontal"}`}
              >
                <Field name="extraLargeLuggage">
                  {({ field, meta }) => (
                    <Mui.FormControlLabel
                      classes={{ label: "w-100 d-flex text-color-primary" }}
                      className={`default-checkbox-dark d-flex m-0 translate-y-0
                      ${this.props.values.extraLargeLuggage ? "pos-absolute pos-top-neg-4 pos-left-per-0" : ""}`}
                      control={
                        <Mui.Checkbox
                          {...field}
                          classes={{
                            root: this.props.values.extraLargeLuggage ? "scale-60" : "",
                          }}
                          checked={this.props.values.extraLargeLuggage ? true : false}
                          onChange={(event) => {
                            this.props.setFieldValue("extraLargeLuggage", event.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                      }
                      label="Extra Large Luggage"
                    />
                  )}
                </Field>

                {this.props.values.extraLargeLuggage ? (
                  <Field name="comment">
                    {({ field, meta }) => (
                      <Mui.TextField
                        // label={"Extra Large Luggage"}
                        InputLabelProps={{ shrink: true, className: "ml-3" }}
                        variant="outlined"
                        placeholder="eg) Golf bag, XX large luggage"
                        helperText={meta.touched && meta.error ? meta.error : null}
                        error={meta.touched && meta.error ? true : false}
                        {...field}
                      />
                    )}
                  </Field>
                ) : (
                  ""
                )}
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Box display="flex" alignItems="center" className="mt-1 mb-1">
              <Mui.Typography className="text-color-white font-weight-normal mb-1" component="h5" variant="h5">
                Additional child seat
              </Mui.Typography>

              {/* <Mui.IconButton
                className="btn-icon-square ml-2"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() =>
                  this.setState((prevstate) => ({
                    chlidSeat: !prevstate.chlidSeat,
                  }))
                }
              >
                <IconAdd />
              </Mui.IconButton> */}
            </Mui.Box>

            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={4} className={`${Mui.isWidthDown("xs", this.props.width) ? "mb-1" : ""}`}>
                <Field name="babySeatRearFacing">
                  {({ field, meta }) => (
                    <Mui.FormControlLabel
                      classes={{ label: "w-100 d-flex" }}
                      className="default-checkbox-dark d-flex align-items-start mr-0"
                      control={
                        <Mui.Checkbox
                          classes={{ root: "pt-0" }}
                          {...field}
                          checked={this.props.values.babySeatRearFacing ? true : false}
                          onChange={(event) => {
                            this.props.setFieldValue("babySeatRearFacing", event.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <>
                          <Mui.Typography component="span" className="w-100 d-flex justify-content-between">
                            Baby seat Rear facing (ages 0-1)
                            <span className="flex-shrink-0 ml-1 text-color-primary">$ 20</span>
                          </Mui.Typography>
                        </>
                      }
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={4} className={`${Mui.isWidthDown("xs", this.props.width) ? "mb-1" : ""}`}>
                <Field name="childSeatForwardFacing">
                  {({ field, meta }) => (
                    <Mui.FormControlLabel
                      classes={{ label: "w-100 d-flex" }}
                      className="default-checkbox-dark d-flex align-items-start mr-0"
                      control={
                        <Mui.Checkbox
                          classes={{ root: "pt-0" }}
                          {...field}
                          checked={this.props.values.childSeatForwardFacing ? true : false}
                          onChange={(event) => {
                            this.props.setFieldValue("childSeatForwardFacing", event.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <>
                          <Mui.Typography component="span" className="w-100 d-flex justify-content-between">
                            Child seat forward facing (ages 1-3)
                            <span className="flex-shrink-0 ml-1 text-color-primary">$ 20</span>
                          </Mui.Typography>
                        </>
                      }
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={4} className={`${Mui.isWidthDown("xs", this.props.width) ? "mb-1" : ""}`}>
                <Field name="boosterSeat">
                  {({ field, meta }) => (
                    <Mui.FormControlLabel
                      classes={{ label: "w-100 d-flex" }}
                      className="default-checkbox-dark d-flex align-items-start mr-0"
                      control={
                        <Mui.Checkbox
                          classes={{ root: "pt-0" }}
                          {...field}
                          checked={this.props.values.boosterSeat ? true : false}
                          onChange={(event) => {
                            this.props.setFieldValue("boosterSeat", event.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <>
                          <Mui.Typography component="span" className="w-100 d-flex justify-content-between">
                            Booster seat (ages 3-6)
                            <span className="flex-shrink-0 ml-1 text-color-primary">$ 20</span>
                          </Mui.Typography>
                        </>
                      }
                    />
                  )}
                </Field>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Typography component="h6" variant="h6" className="mt-2 mb-1 text-color-white font-weight-normal">
              Note:
            </Mui.Typography>
            <ul className="m-0 pl-2 text-color-white">
              <li>Babies and Children are also counted as Passengers.</li>
            </ul>
          </Mui.Paper>

          {this.props.loading ? (
            <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="p-5">
              <Mui.CircularProgress color="primary" size={54} />
              <Mui.Typography component="h5" variant="h5" className="mt-2 font-weight-normal">
                Fetching Cars
              </Mui.Typography>
            </Mui.Box>
          ) : (
            <>
              {/* Selected Car UI */}
              {this.props.values.vehicleTypeId ? (
                <Mui.Paper className="default-card-dark-card pos-relative border-1 border-color-success background-color-success-fade shadow-none mb-5 z-index-1">
                  <Mui.Box display="flex" alignItems="center" className="mb-2">
                    <Mui.Typography className="text-color-white font-weight-normal mr-auto" component="h5" variant="h5">
                      Selected Vehicle:{" "}
                      <span className="text-color-success">
                        {this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.name || ""}
                      </span>
                    </Mui.Typography>

                    <Mui.IconButton
                      className="btn-icon-square ml-2"
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        this.props.setFieldValue("vehicleTypeId", "");
                        this.props.resetPriceCalculation();
                      }}
                    >
                      <IconEdit />
                    </Mui.IconButton>
                  </Mui.Box>

                  <Mui.Grid container spacing={3} className="list-car text-color-white">
                    <Mui.Grid item xs={12} md={9}>
                      {/* <Mui.Typography
                        className="price font-weight-normal mt-0 mb-1"
                        component="h2"
                        variant="h2"
                      >
                        {this.props.values.type === "distance"
                          ? formatCurrency(
                              this.props.carList?.find(
                                (x) => x.id === this.props.values.vehicleTypeId
                              )?.vehicleCategoryPrice?.["5KmPrice"] || 0
                            )
                          : null}
                        {this.props.values.type === "hourly"
                          ? formatCurrency(
                              this.props.carList?.find(
                                (x) => x.id === this.props.values.vehicleTypeId
                              )?.vehicleCategoryPrice?.perHour || 0
                            )
                          : null}
                        {this.props.values.type === "flat_rate"
                          ? formatCurrency(
                              this.props.routes
                                ?.find(
                                  (x) => x.id === this.props.values.routeId
                                )
                                ?.routeDetail?.find(
                                  (x) =>
                                    x.vehicleTypeId ===
                                    this.props.values.vehicleTypeId
                                )?.price
                            )
                          : null}
                      </Mui.Typography> */}

                      <p className="text-color-muted">
                        {this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.description}
                      </p>

                      <Mui.Box display="flex" alignItems="center" className="mt-2 list-car__status">
                        <Mui.Typography component="p" className="font-size-lg">
                          Max
                        </Mui.Typography>
                        <IconUsers />{" "}
                        <span>
                          {this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.maxPassenger}
                        </span>
                        <IconBriefcase />{" "}
                        <span>
                          {this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.maxLuggage}
                        </span>
                      </Mui.Box>
                    </Mui.Grid>

                    <Mui.Grid item xs={12} md={3}>
                      <figure
                        className="list-car__img h-100 pos-absolute pos-top-per-0 pos-right-per-0 z-index--1"
                        style={{ opacity: 0.1 }}
                      >
                        <img
                          src={
                            this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)
                              ?.vehicleTypePhoto?.[0]?.url || car
                          }
                          alt={this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.name}
                          className="border-0"
                          style={{ height: "100%" }}
                        />
                      </figure>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Paper>
              ) : (
                <>
                  {this.props.errors?.vehicleTypeId ? (
                    <Mui.Typography
                      component="h5"
                      variant="h5"
                      className="d-flex align-items-center mb-5 p-1 pl-2 border-rad-4 border-1 border-color-danger text-color-danger svg-color-danger font-weight-normal"
                    >
                      <span className="mr-2">
                        <IconInfo />
                      </span>
                      {this.props.errors?.vehicleTypeId}
                    </Mui.Typography>
                  ) : null}
                  {this.props.carList?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Mui.Grid container spacing={3} className="list-car">
                        <Mui.Grid item xs={12} sm={4}>
                          <figure className="list-car__img">
                            <img src={item.vehicleTypePhoto?.[0]?.url || car} alt={item.name} />
                          </figure>
                        </Mui.Grid>

                        <Mui.Grid item xs={12} sm={8}>
                          <Mui.Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            className="mb-1"
                          >
                            <Mui.Typography className="font-weight-normal" component="h4" variant="h4">
                              {item.name}
                              <Mui.Typography className="d-block font-size-md" component="span">
                                {item?.vehicleCategory?.name}
                              </Mui.Typography>
                            </Mui.Typography>

                            <Mui.Button
                              type="button"
                              variant="contained"
                              color="primary"
                              large="true"
                              onClick={() => {
                                window.scrollTo(0, 375);
                                this.props.setFieldValue("vehicleTypeId", item.id);
                              }}
                              // className={`${
                              //   this.props.values.vehicleTypeId === item.id
                              //     ? "active"
                              //     : null
                              // }`}
                            >
                              Select
                              {/* {" "}{this.props.values.vehicleTypeId === item.id ? (
                            <IconCheck />
                          ) : null} */}
                            </Mui.Button>
                          </Mui.Box>

                          {/* <Mui.Typography
                            className="price font-weight-normal"
                            component="h2"
                            variant="h2"
                          >
                            {this.props.values.type === "distance"
                              ? formatCurrency(
                                  item?.vehicleCategoryPrice?.["5KmPrice"] || 0
                                )
                              : null}
                            {this.props.values.type === "hourly"
                              ? formatCurrency(
                                  item?.vehicleCategoryPrice?.perHour || 0
                                )
                              : null}
                            {this.props.values.type === "flat_rate"
                              ? formatCurrency(
                                  this.props.routes
                                    ?.find(
                                      (x) => x.id === this.props.values.routeId
                                    )
                                    ?.routeDetail?.find(
                                      (x) => x.vehicleTypeId === item.id
                                    )?.price
                                )
                              : null}
                          </Mui.Typography> */}

                          <p className="text-color-muted">{item.description}</p>

                          <Mui.Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            className="mt-4 list-car__status"
                          >
                            <Mui.Typography component="p" className="font-size-lg">
                              Max
                            </Mui.Typography>
                            <IconUsers /> <span>{item.maxPassenger}</span>
                            <IconBriefcase /> <span>{item.maxLuggage}</span>
                          </Mui.Box>
                        </Mui.Grid>
                      </Mui.Grid>

                      <Mui.Divider
                        light={true}
                        className="mt-5 mb-5"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
                      />
                    </React.Fragment>
                  ))}
                </>
              )}

              <Mui.Typography component="p" variant="h6" className="text-color-grey font-weight-normal">
                Note: Incase of unforeseen circumstances like mechanical issue of your selected vehicle, we will be
                accommodating you with an alternative vehicle of similar category.
              </Mui.Typography>
            </>
          )}
        </Mui.Grid>
      </Mui.Grid>
    );
  }
}

export default Mui.withWidth()(Vehicle);
