import Req from "src/interceptors/token-interceptor";

const ScheduleService = (function () {
  function _getSchedule(id) {
    return Req.get("/api/schedules/" + id);
  }
  function _addSchedule(data) {
    return Req.post("/api/schedules", data);
  }
  function _editSchedule(data, id) {
    return Req.put("/api/schedules/" + id, data);
  }
  function _deleteSchedule(id) {
    return Req.delete("/api/schedules/" + id);
  }
  return {
    getSchedule: _getSchedule,
    addSchedule: _addSchedule,
    editSchedule: _editSchedule,
    deleteSchedule: _deleteSchedule,
  };
})();
export default ScheduleService;
