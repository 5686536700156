import Req from "src/interceptors/token-interceptor";

const RouteService = (function () {
  function _getAllRoute() {
    return Req.get(`/api/routes/all`);
  }
  function _getRoute(id) {
    return Req.get("/api/routes/" + id);
  }
  function _addRoute(data) {
    return Req.post("/api/routes", data);
  }
  function _editRoute(data, id) {
    return Req.put("/api/routes/" + id, data);
  }
  function _deleteRoute(id) {
    return Req.delete("/api/routes/" + id);
  }
  function _deleteMultipleRoute(ids) {
    return Req.post("/api/routes/delete", { ids: ids });
  }
  return {
    getAllRoute: _getAllRoute,
    getRoute: _getRoute,
    addRoute: _addRoute,
    editRoute: _editRoute,
    deleteRoute: _deleteRoute,
    deleteMultipleRoute: _deleteMultipleRoute,
  };
})();
export default RouteService;
