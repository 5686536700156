import React from "react";

const IconCurrencyDolor = () => {
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 511.995 511.995">
      <g>
        <g>
          <path
            d="M278.622,224.006V110.243c24.336,4.105,47.283,14.091,66.901,29.087c3.911,2.682,8.5,4.137,13.251,4.201
          c15.222,0,27.633-12.249,27.794-27.471c0.032-6.528-2.521-12.798-7.11-17.452c-28.344-24.272-63.895-38.589-101.159-40.722V19.749
          c0-10.892-8.823-19.715-19.715-19.715c-0.226-0.032-0.453-0.032-0.679-0.032c-11.053-0.162-20.167,8.662-20.329,19.747v36.844
          c-72.718,5.171-122.166,50.095-122.166,109.885c0,73.364,62.376,93.726,122.166,109.885V405.64
          c-31.544-4.234-61.277-17.323-85.646-37.813c-4.589-3.652-10.278-5.688-16.16-5.817c-14.899,1.034-26.372,13.509-26.179,28.441
          c-0.032,6.528,2.521,12.798,7.11,17.452c33.386,30.057,76.306,47.348,121.197,48.802v35.551c0,0.226,0.032,0.453,0.032,0.679
          c0.517,11.053,9.922,19.585,20.975,19.036c10.892,0,19.715-8.823,19.715-19.715v-36.197
          c88.231-5.817,123.782-59.467,123.782-116.349C402.404,263.436,338.413,240.166,278.622,224.006z M237.9,213.664
          c-35.228-10.342-62.699-21.007-62.699-51.064s24.886-51.711,62.699-54.619V213.664z M278.622,406.286V288.645
          c36.521,10.342,64.961,24.239,64.638,58.174C343.26,371.382,326.454,400.469,278.622,406.286z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconCurrencyDolor;
