import {
  IconDashboard,
  IconUsers,
  IconCarFront,
  IconRoute,
  IconSteering,
  IconCalendar,
  IconRate,
  IconTime,
  IconFile,
  IconCurrencyDolor,
  IconInvoice,
  IconStar,
} from "src/components/svg";

const Menu = [
  {
    title: "Dashboard",
    icon: <IconDashboard />,
    submenu: [],
    path: "/dashboard",
  },
  {
    title: "Customer",
    icon: <IconUsers />,
    submenu: [],
    path: "/user",
  },
  {
    title: "Vehicle Type",
    icon: <IconCarFront />,
    submenu: [],
    path: "/car",
  },
  {
    title: "Vehicle Price",
    icon: <IconRate />,
    submenu: [],
    path: "/car-price",
  },
  {
    title: "Route",
    icon: <IconRoute />,
    submenu: [],
    path: "/route",
  },
  {
    title: "Chauffeur",
    icon: <IconSteering />,
    submenu: [],
    path: "/driver",
  },
  {
    title: "Booking",
    icon: <IconCalendar />,
    submenu: [],
    path: "/booking",
  },
  {
    title: "Invoice",
    icon: <IconCurrencyDolor />,
    submenu: [],
    path: "/invoice",
  },
  {
    title: "Consolidate Invoice",
    icon: <IconInvoice />,
    submenu: [],
    path: "/consolidate-invoice",
  },
  {
    title: "Additional Charge",
    icon: <IconStar />,
    submenu: [],
    path: "/additional-charge",
  },
  {
    title: "Client",
    icon: <IconUsers />,
    submenu: [],
    path: "/client",
  },
  {
    title: "Schedular",
    icon: <IconTime />,
    submenu: [],
    path: "/schedule",
  },
  {
    title: "Coupon",
    icon: <IconFile />,
    submenu: [],
    path: "/coupon",
  },
];
export default Menu;
