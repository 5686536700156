import React from "react";
import * as Mui from "@material-ui/core";
import { InputField, CheckboxField, SelectAutocompleteField, TextareaField } from "src/components/form";

const Contact = ({ values, setFieldValue, setTouched, userList }) => {
  return (
    <Mui.Card className="default-card mt-3">
      <Mui.Box display="flex" flexWrap="wrap">
        <Mui.Typography component="h4" variant="h4" className="mb-4 mr-auto font-weight-medium">
          3. Enter Contact Details
        </Mui.Typography>

        <CheckboxField name="other" label="Booking for Others?" />
      </Mui.Box>

      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <SelectAutocompleteField
            name="userId"
            label="Select User*"
            options={userList}
            callback={(value) => {
              setFieldValue("firstName", userList?.find((x) => x.id === value)?.firstName || "");
              setFieldValue("lastName", userList?.find((x) => x.id === value)?.lastName || "");
              setFieldValue("email", userList?.find((x) => x.id === value)?.email || "");
              setFieldValue("phone", userList?.find((x) => x.id === value)?.phone || "");
              setTouched({}, false);
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField name="firstName" type="text" label="First Name*" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField name="lastName" type="text" label="Last Name*" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField name="email" type="text" label="Email Address*" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField
            name="phone"
            type="text"
            label="Phone Number*"
            InputProps={{
              startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField
            name="alternatePhone"
            type="text"
            label="Alternate Phone Number"
            InputProps={{
              startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
            }}
          />
        </Mui.Grid>
        {values.other ? (
          <>
            <Mui.Grid item xs={12} md={12} lg={12} className="form-group">
              <InputField name="passengerNames" type="text" label="Passenger Names" />
            </Mui.Grid>
            <Mui.Grid item xs={12} md={12} lg={12} className="form-group">
              <InputField name="passengerPhones" type="text" label="Passenger Phone Numbers" />
            </Mui.Grid>
          </>
        ) : null}
        <Mui.Grid item xs={12} md={12} lg={12} className="form-group">
          <TextareaField name="additionalComment" label="Any Additional Comment" />
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Card>
  );
};
export default Contact;
