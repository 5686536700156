import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import mediaSagas from "./media/saga";
import userSagas from "./user/saga";
import clientSagas from "./client/saga";
import carSagas from "./car/saga";
import bookingSagas from "./booking/saga";
import routeSagas from "./route/saga";
import driverSagas from "./driver/saga";
import rideSagas from "./ride/saga";
import sharedSagas from "./shared/saga";
import priceSagas from "./price/saga";
import scheduleSagas from "./schedule/saga";
import couponSagas from "./coupon/saga";
import invoiceSagas from "./invoice/saga";
import consolidateSagas from "./consolidate-invoice/saga";
import additionalChargeSagas from "./additional-charge/saga";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    mediaSagas(),
    userSagas(),
    clientSagas(),
    carSagas(),
    bookingSagas(),
    routeSagas(),
    driverSagas(),
    rideSagas(),
    sharedSagas(),
    priceSagas(),
    scheduleSagas(),
    couponSagas(),
    invoiceSagas(),
    consolidateSagas(),
    additionalChargeSagas(),
  ]);
}
