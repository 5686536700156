import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import BookingList from "./list";
import EditBooking from "./edit/index";
import ViewBooking from "./view/index";

function Booking() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/booking" component={BookingList} />
        <ContentRoute path="/booking/add" component={EditBooking} />
        <ContentRoute path="/booking/edit/:id" component={EditBooking} />
        <ContentRoute path="/booking/view/:id" component={ViewBooking} />
      </Switch>
    </Suspense>
  );
}

export default Booking;
