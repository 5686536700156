import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";

class WebsiteLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { showFooter: true };
  }

  componentDidMount() {
    if (
      window.location.pathname === "/auth/login" ||
      window.location.pathname === "/auth/register" ||
      window.location.pathname === "/auth/forgot-password" ||
      window.location.pathname === "/auth/reset-password"
    ) {
      this.setState({ showFooter: false });
    } else {
      this.setState({ showFooter: true });
    }
  }

  render() {
    return (
      <div className={`main-wrapper ${this.props.classes || ""}`}>
        <Header />

        {this.props.children}

        {this.state.showFooter ? <Footer /> : null}
      </div>
    );
  }
}

export default WebsiteLayout;
