import React from "react";
import * as Mui from "@material-ui/core";
import { InputField, CheckboxField, SelectAutocompleteField } from "src/components/form";
import { noPassenger, noLuggage } from "src/helpers/util";

const Vehicle = (props) => {
  const { values, setFieldValue, carList, onFilter, resetPriceCalculation } = props;
  return (
    <Mui.Card className="default-card mt-3">
      <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
        2. Vehicle Selection
      </Mui.Typography>

      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <SelectAutocompleteField
            name="noPassenger"
            label="Passengers*"
            options={noPassenger}
            callback={(value) => {
              setFieldValue("vehicleTypeId", "");
              resetPriceCalculation();
              onFilter(value, values.noLuggage, values.routeId || "");
            }}
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <SelectAutocompleteField
            name="noLuggage"
            label="Luggages*"
            options={noLuggage}
            callback={(value) => {
              setFieldValue("vehicleTypeId", "");
              resetPriceCalculation();
              onFilter(values.noPassenger, value, values.routeId || "");
            }}
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <SelectAutocompleteField name="vehicleTypeId" label="Select Vehicle*" options={carList} />
        </Mui.Grid>

        <Mui.Grid item xs={12} md={12} lg={6} className="form-group mt-0">
          <CheckboxField name="extraLargeLuggage" label="Any Extra Large Luggage?" />
          {values.extraLargeLuggage ? <InputField name="comment" type="text" placeholder="Extra Luggage Info" /> : null}
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Typography component="h5" variant="h5" className="mt-1 mb-2 font-weight-medium">
        Additional Child Seat
      </Mui.Typography>
      <Mui.Grid container spacing={2} className="space-all">
        <Mui.Grid item xs={12} md={12} lg={4}>
          <CheckboxField name="babySeatRearFacing" label="Baby seat Rear facing (ages 0-1) $ 20" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={12} lg={4}>
          <CheckboxField name="childSeatForwardFacing" label="Child seat forward facing (ages 1-3) $ 20" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={12} lg={4}>
          <CheckboxField name="boosterSeat" label="Booster seat (ages 3-6) $ 20" />
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Card>
  );
};
export default Mui.withWidth()(Vehicle);
