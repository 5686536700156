import React from "react";

const IconRate = () => {
  return (
    <svg height="22" viewBox="0 0 74 74" width="22">
      <path d="m71 58h-68a1 1 0 0 1 -1-1v-10.2a1 1 0 0 1 .8-.98 9 9 0 0 0 0-17.64 1 1 0 0 1 -.8-.98v-10.2a1 1 0 0 1 1-1h68a1 1 0 0 1 1 1v10.2a1 1 0 0 1 -.8.98 9 9 0 0 0 0 17.64 1 1 0 0 1 .8.98v10.2a1 1 0 0 1 -1 1zm-67-2h66v-8.415a11 11 0 0 1 0-21.17v-8.415h-66v8.415a11 11 0 0 1 0 21.17z" />
      <path d="m52 21.636a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z" />
      <path d="m52 30.727a1 1 0 0 1 -1-1v-3.636a1 1 0 1 1 2 0v3.636a1 1 0 0 1 -1 1z" />
      <path d="m52 39.818a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.637a1 1 0 0 1 -1 .999z" />
      <path d="m52 48.909a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z" />
      <path d="m52 58a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z" />
      <path d="m20.929 47.542a1 1 0 0 1 -.707-1.707l18.532-18.542a1 1 0 1 1 1.414 1.414l-18.532 18.543a1 1 0 0 1 -.707.292z" />
      <path d="m36.381 48.019a4.619 4.619 0 1 1 4.619-4.619 4.625 4.625 0 0 1 -4.619 4.619zm0-7.239a2.619 2.619 0 1 0 2.619 2.62 2.622 2.622 0 0 0 -2.619-2.62z" />
      <path d="m23.619 35.157a4.619 4.619 0 1 1 4.619-4.62 4.625 4.625 0 0 1 -4.619 4.62zm0-7.239a2.619 2.619 0 1 0 2.619 2.619 2.622 2.622 0 0 0 -2.619-2.619z" />
    </svg>
  );
};

export default IconRate;
