import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import { useField } from "formik";
import moment from "moment";
import TimePicker from "rc-time-picker";

const InputTimepickerField = ({ label, callback, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    setSelectedTime(
      field?.value ? moment(moment().format("YYYY-MM-DD") + " " + field.value, "YYYY-MM-DD HH:mm") : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const handleTimeChange = (value) => {
    setSelectedTime(moment(moment().format("YYYY-MM-DD") + " " + value, "YYYY-MM-DD HH:mm:ss"));
    helpers.setValue(value.format("HH:mm:ss"));
    if (callback) {
      callback();
    }
  };

  return (
    <Mui.FormControl error={meta.touched && meta.error ? true : false} variant={"outlined"}>
      <Mui.InputLabel shrink={true}>{label}</Mui.InputLabel>
      <TimePicker
        showSecond={false}
        allowEmpty={false}
        className=""
        popupClassName=""
        onChange={handleTimeChange}
        value={selectedTime}
        format={"HH:mm"}
        minuteStep={5}
        inputReadOnly
      />
      {meta.touched && meta.error ? (
        <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
      ) : null}
    </Mui.FormControl>
  );
};

export default InputTimepickerField;
