import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { getUser, editUser, resetUser } from "src/reduxs/actions";
import { InputField } from "src/components/form";
import ProfileUpload from "src/components/profile-upload";
import GoogleAutocomplete from "src/components/autocomplete";
import { IconEdit } from "src/components/svg";
class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { editMode: false };
    this.schema = Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      firstName: Yup.string()
        .required("First Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      lastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      address: Yup.string().required("Enter a valid address"),
      phone: Yup.string()
        .required("Contact No is required")
        .min(8, "Atleast 8 digits")
        .max(10, "Cannot exceed 10 digits")
        .nullable(),
    });
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.editUser(values.id, values);
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.success !== this.props.success) {
      if (this.props.success) {
        this.setState({ editMode: false });
        this.props.getUser(
          JSON.parse(localStorage.getItem("currentUser")).id,
          true
        );
        this.props.resetUser();
      }
    }
  }

  render() {
    return (
      <>
        {this.state.editMode ? (
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: this.props.userData?.id || "",
              firstName: this.props.userData?.firstName || "",
              lastName: this.props.userData?.lastName || "",
              email: this.props.userData?.email || "",
              phone: this.props.userData?.phone || "",
              roleId: this.props.userData?.roleId || "",
              address: this.props.userData?.address || "",
              userPhoto: this.props.userData?.userPhoto || [],
              isVerified: this.props.userData?.isVerified || 0,
              patron: this.props.userData?.patron || 0,
              showCost: this.props.userData?.showCost || 0,
            }}
            validationSchema={this.schema}
            onSubmit={this.onSubmit}
          >
            {(props) => (
              <Form className="default-form">
                <Mui.Grid container spacing={2}>
                  <Mui.Grid
                    item
                    xs={12}
                    md={3}
                    className={`${
                      Mui.isWidthDown("sm", this.props.width)
                        ? "d-flex justify-content-center"
                        : ""
                    }`}
                  >
                    <Mui.Box
                      display="inline-flex"
                      alignItems="center"
                      position="relative"
                      mb={8}
                    >
                      <ProfileUpload
                        name={props.values.firstName}
                        field="userPhoto"
                        imgArr={props.values.userPhoto}
                        upload={props.setFieldValue}
                        editMode={this.state.editMode}
                      />
                    </Mui.Box>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} md={9} className={`profile-fields`}>
                    <Mui.Box className="form-group-dark">
                      <InputField
                        name="firstName"
                        type="text"
                        label={"First Name"}
                      />
                    </Mui.Box>
                    <Mui.Box className="form-group-dark">
                      <InputField
                        name="lastName"
                        type="text"
                        label={"Last Name"}
                      />
                    </Mui.Box>

                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      className="form-group-dark"
                    >
                      <InputField
                        name="email"
                        type="text"
                        label={this.state.editMode ? "Email" : ""}
                      />
                    </Mui.Box>

                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      className="form-group-dark"
                    >
                      <InputField
                        name="phone"
                        type="number"
                        label={this.state.editMode ? "Phone" : ""}
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">
                              +61
                            </Mui.InputAdornment>
                          ),
                        }}
                      />
                    </Mui.Box>

                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      className="form-group-dark"
                    >
                      <GoogleAutocomplete
                        label={"Address*"}
                        variant={"outlined"}
                        touched={props.touched?.address}
                        error={props.errors?.address}
                        defaultValue={props.values?.address}
                        filterState={false}
                        callback={(description) => {
                          props.setFieldValue(`address`, description || "");
                        }}
                      />
                    </Mui.Box>

                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      className="mt-5"
                    >
                      <Mui.Button
                        className="btn-default svg-size-small mr-4"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={this.props.loading}
                      >
                        Update
                      </Mui.Button>
                      <Mui.Button
                        className="btn-default mr-4"
                        color="primary"
                        type="button"
                        variant="outlined"
                        disableElevation
                        onClick={() => this.setState({ editMode: false })}
                      >
                        Cancel
                      </Mui.Button>

                      <Mui.Button
                        className={`font-weight-normal ${
                          Mui.isWidthDown("xs", this.props.width) ? "mt-2" : ""
                        }`}
                        type="button"
                        variant="text"
                        disableElevation
                        onClick={props.resetForm}
                      >
                        Reset
                      </Mui.Button>
                    </Mui.Box>
                  </Mui.Grid>
                </Mui.Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <Mui.Grid container spacing={2}>
            <Mui.Grid
              item
              xs={12}
              md={3}
              className={`${
                Mui.isWidthDown("sm", this.props.width)
                  ? "d-flex justify-content-center"
                  : ""
              }`}
            >
              <Mui.Box
                display="inline-flex"
                alignItems="center"
                position="relative"
                mb={8}
              >
                <ProfileUpload
                  name={this.props.userData?.firstName}
                  field="userPhoto"
                  imgArr={this.props.userData?.userPhoto}
                  upload={() => {}}
                  editMode={this.state.editMode}
                />

                <Mui.IconButton
                  className="btn-float-edit"
                  onClick={() => this.setState({ editMode: true })}
                >
                  <IconEdit />
                </Mui.IconButton>
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={12}
              md={9}
              className={`profile-fields display-mode`}
            >
              <Mui.Box
                display="flex"
                alignItems="center"
                className="form-group-dark"
              >
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className={`font-weight-normal ${
                    Mui.isWidthDown("xs", this.props.width)
                      ? "d-flex flex-direction-column align-items-center"
                      : ""
                  }`}
                >
                  {this.props.userData?.firstName}{" "}
                  {this.props.userData?.lastName}
                  {this.props.userData?.id ? (
                    <Mui.Typography
                      component="span"
                      variant="body1"
                      className={`status-bg primary ${
                        Mui.isWidthDown("xs", this.props.width)
                          ? "mt-2"
                          : "ml-2 align-self-end"
                      }`}
                    >
                      Account No: {this.props.userData.id || ""}
                    </Mui.Typography>
                  ) : null}
                  {this.props.userData?.patron ? (
                    <Mui.Typography
                      component="span"
                      variant="body1"
                      className={`status-bg primary ${
                        Mui.isWidthDown("xs", this.props.width)
                          ? "mt-2"
                          : "ml-2 align-self-end"
                      }`}
                    >
                      Special Customer
                    </Mui.Typography>
                  ) : null}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box
                display="flex"
                alignItems="center"
                className="form-group-dark"
              >
                <span>Email:</span>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="font-weight-normal"
                >
                  {this.props.userData?.email || ""}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box
                display="flex"
                alignItems="center"
                className="form-group-dark"
              >
                <span>Phone:</span>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="font-weight-normal"
                >
                  +61 {this.props.userData?.phone || ""}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box
                display="flex"
                alignItems="center"
                className="form-group-dark"
              >
                <span>Address:</span>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="font-weight-normal"
                >
                  {this.props.userData?.address || ""}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  const { userData, success, message, loading, error } = user;
  return { userData, success, message, loading, error };
};
export default connect(mapStateToProps, {
  getUser,
  editUser,
  resetUser,
})(Mui.withWidth()(PersonalInfo));
