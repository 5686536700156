import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteFilter = ({ options, value, onFilter, label, disableClearable = true, placeholder, ...props }) => {
  const [selectedVal, setSelectedVal] = useState("");

  useEffect(() => {
    setSelectedVal(options?.find((x) => x.id === Number(value)) || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleSelectChange = (event, selectedOption) => {
    onFilter(selectedOption?.id || "");
  };

  return (
    <Mui.FormControl
      variant="outlined"
      className={`default-form-field tbl-filter-field ${Mui.isWidthDown("xs", props.width) ? "mb-2" : "ml-2"}`}
    >
      <Autocomplete
        options={options || []}
        autoHighlight
        autoComplete={true}
        disableClearable={disableClearable}
        getOptionLabel={(option) => option?.name || option?.bookingNumber || ""}
        value={selectedVal}
        onChange={handleSelectChange}
        renderOption={(option, { selected }) => (
          <div className="select-item">{option?.name || option?.bookingNumber || ""}</div>
        )}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <Mui.TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "nofill",
            }}
            variant="outlined"
          />
        )}
      />
    </Mui.FormControl>
  );
};

export default Mui.withWidth()(AutocompleteFilter);
