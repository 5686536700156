import {
  GET_PRICE_LIST,
  GET_PRICE_LIST_SUCCESS,
  GET_PRICE_LIST_ERROR,
  EDIT_PRICE,
  EDIT_PRICE_SUCCESS,
  EDIT_PRICE_ERROR,
} from "src/reduxs/actions";

const INIT_STATE = {
  param: null,
  priceList: null,
  priceData: null,
  priceId: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const priceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRICE_LIST:
      return {
        ...state,
        loading: true,
        priceData: null,
        priceId: null,
        error: null,
      };
    case GET_PRICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        priceList: action.payload,
        error: null,
      };
    case GET_PRICE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        priceList: null,
        error: action.payload,
      };
    case EDIT_PRICE:
      return { ...state, loading1: true, error: null };
    case EDIT_PRICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PRICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default priceReducer;
