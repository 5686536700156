import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ALL_COUPON,
  GET_COUPON_LIST,
  ADD_COUPON,
  GET_COUPON,
  EDIT_COUPON,
  DELETE_COUPON,
  DELETE_MULTIPLE_COUPON,
} from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import CouponService from "src/services/coupon-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllCouponSuccess,
  getAllCouponError,
  getCouponList,
  getCouponListSuccess,
  getCouponListError,
  addCouponSuccess,
  addCouponError,
  getCouponSuccess,
  getCouponError,
  editCouponSuccess,
  editCouponError,
  deleteCouponSuccess,
  deleteCouponError,
  deleteMultipleCouponSuccess,
  deleteMultipleCouponError,
} from "./action";

export function* watchGetAllCoupon() {
  yield takeEvery(GET_ALL_COUPON, getAllCoupon);
}

const getAllCouponAsync = async () => {
  return CouponService.getAllCoupon();
};

function* getAllCoupon() {
  try {
    const response = yield call(getAllCouponAsync);
    if (response.data.success) {
      yield put(getAllCouponSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllCouponError(error.response.data.message));
  }
}

export function* watchGetCouponList() {
  yield takeEvery(GET_COUPON_LIST, getCouponListAc);
}

const getCouponListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "coupons",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getCouponListAc({ payload }) {
  try {
    const response = yield call(getCouponListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getCouponListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getCouponListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getCouponListError(error.response.data.message));
  }
}

export function* watchAddCoupon() {
  yield takeEvery(ADD_COUPON, addCoupon);
}

const addCouponAsync = async (data) => {
  return CouponService.addCoupon(data);
};

function* addCoupon({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addCouponAsync, payload.couponData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addCouponSuccess(response.data.success, response.data.message));
      history.push(`/coupon`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addCouponError(error.response.data.message));
  }
}

export function* watchGetCoupon() {
  yield takeEvery(GET_COUPON, getCoupon);
}

const getCouponAsync = async (id) => {
  return CouponService.getCoupon(id);
};

function* getCoupon({ payload }) {
  try {
    const response = yield call(getCouponAsync, payload.couponId);
    if (response.data.success) {
      yield put(getCouponSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getCouponError(error.response.data.message));
  }
}

export function* watchEditCoupon() {
  yield takeEvery(EDIT_COUPON, editCoupon);
}

const editCouponAsync = async (data, id) => {
  return CouponService.editCoupon(data, id);
};

function* editCoupon({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(editCouponAsync, payload.couponData, payload.couponId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editCouponSuccess(response.data.success, response.data.message));
      history.push(`/coupon`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editCouponError(error.response.data.message));
  }
}

export function* watchDeleteCoupon() {
  yield takeEvery(DELETE_COUPON, deleteCoupon);
}

const deleteCouponAsync = async (id) => {
  return CouponService.deleteCoupon(id);
};

function* deleteCoupon({ payload }) {
  try {
    const response = yield call(deleteCouponAsync, payload.couponId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteCouponSuccess(response.data.success, response.data.message));
      // Fetch updated coupon list
      yield put(getCouponList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteCouponError(error.response.data.message));
  }
}

export function* watchDeleteMultipleCoupon() {
  yield takeEvery(DELETE_MULTIPLE_COUPON, deleteMultipleCoupon);
}

const deleteMultipleCouponAsync = async (ids) => {
  return CouponService.deleteMultipleCoupon(ids);
};

function* deleteMultipleCoupon({ payload }) {
  try {
    const response = yield call(deleteMultipleCouponAsync, payload.couponIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleCouponSuccess(response.data.success, response.data.message));
      // Fetch updated coupon list
      yield put(getCouponList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleCouponError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllCoupon),
    fork(watchGetCouponList),
    fork(watchAddCoupon),
    fork(watchGetCoupon),
    fork(watchEditCoupon),
    fork(watchDeleteCoupon),
    fork(watchDeleteMultipleCoupon),
  ]);
}
