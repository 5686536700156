import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { uploadMedia, deleteMedia } from "src/reduxs/actions";
import { IconCamera } from "src/components/svg";

class ProfileUpload extends Component {
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      this.props.uploadMedia(file, this.props.field);
    }
  };

  componentDidUpdate(previousProps) {
    if (
      previousProps.mediaData !== this.props.mediaData &&
      this.props.mediaData.field === this.props.field
    ) {
      let arr = [
        {
          id: "",
          name: "1.jpg",
          value: this.props.mediaData.name,
          url: this.props.mediaData.url,
        },
      ];
      this.props.upload(this.props.field, arr);
    }
  }

  render() {
    return (
      <Mui.Box className="profile-img" display="flex" alignItems="center">
        <Mui.Avatar
          alt={this.props.name || ""}
          src={
            this.props.imgArr?.[0]?.url
              ? this.props.imgArr?.[0]?.url
              : "/static/images/avatar/1.jpg"
          }
        />
        {this.props.editMode ? (
          <Mui.IconButton className="btn-update-profile">
            <input type="file" onChange={this.onSelectFile} />
            <IconCamera />
          </Mui.IconButton>
        ) : null}
      </Mui.Box>
    );
  }
}
const mapStateToProps = ({ media }) => {
  const { mediaData, success, message, loading, error } = media;
  return { mediaData, success, message, loading, error };
};

export default connect(mapStateToProps, {
  uploadMedia,
  deleteMedia,
})(ProfileUpload);
