import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import * as Mui from "@material-ui/core";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import { IconCarFront } from "src/components/svg";
import bannerImg from "src/assets/images/website/feature-image-5.jpg";
import businessImage1 from "src/assets/images/website/services/business-transfer-1.jpg";
import businessImage2 from "src/assets/images/website/services/business-transfer-2.jpg";
import businessImage3 from "src/assets/images/website/services/business-transfer-3.jpg";

class BusinessTransfers extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Our professional and luxury corporate transfer service for busy business people will impress clients and get you to your destination on time and in style" />

        <section className="inner-banner" style={{ height: "42vh" }}>
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Business Transfers
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Business Transfers
            </Mui.Typography>
          </Mui.Container>
        </section>

        <Mui.Box id="business" component="section" className="section-default pb-1 p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={Mui.isWidthDown("sm", this.props.width) ? "column-reverse" : "row"}
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  Book one of our drivers for any hour of the day, 365 days a year. We looking forward to assisting you.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  Additionally, it’s exhausting and frustrating trying to navigate city traffic, especially if you’re
                  not familiar with the area. It’s easy to lose hours just getting to and from your destination.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  At Shangri-La Chauffeurs, we know your time is precious, so our professional and well-presented
                  drivers will get you there on time every time.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  Whether you’re visiting office locations or worksites, travelling to client meetings or attending a
                  conference, you can get there in comfort and style in our luxurious vehicles. Just sit back, relax and
                  arrive feeling refreshed.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""}`}
              >
                <img className="img-fluid-cover border-rad-10" src={businessImage1} alt="Business Transfers" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box id="business" component="section" className="section-default pb-1 p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""}`}
              >
                <img className="img-fluid-cover border-rad-10" src={businessImage3} alt="Business Transfers" />
              </Mui.Grid>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  If you’re travelling with clients, why not impress them with a gorgeous limousine? Or, if you’re
                  taking your team, book a people mover with enough room for everyone to spread out and get work done.
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  We have a wide range of high-quality and stylish luxury vehicles, including a standard sedan, Euro
                  luxury sedan and premium Euro luxury. The choice is yours!
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  We know that reliability is the most important factor in business transfers – and we’ll be there for
                  you! Many large companies use our services regularly and continue to trust us because we never let
                  them down.
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box id="business" component="section" className="section-default pb-1 p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={Mui.isWidthDown("sm", this.props.width) ? "column-reverse" : "row"}
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  Our highly professional drivers will always make a great impression. They are impeccably dressed and
                  respectful, and fully licensed and trained to do their job well. As they are locals, they are also
                  experts at finding the best route at any time of the day. Just tell us your schedule and we’ll be
                  there to get you around.
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  Book one of our drivers for any hour of the day, 365 days a year. We look forward to working with you!
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="text-color-grey font-weight-normal mb-0-8">
                  If you’re catching a flight from a major airport, you could also use our{" "}
                  <NavLink exact to={"/airport-transfers"} className="text-color-primary">
                    airport transfers
                  </NavLink>
                  .
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                cclassName={`overflow-hidden ${Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""}`}
              >
                <img className="img-fluid-cover border-rad-10" src={businessImage2} alt="Business Transfers" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box display="flex" justifyContent="center" mt={10} mb={10}>
          <Mui.Button
            component={Link}
            // to={"/ride"}
            to="/contact"
            className="btn-default"
            color="primary"
            variant="contained"
            disableElevation
          >
            Book Now
            <span className="ml-1 line-height-null">
              <IconCarFront />
            </span>
          </Mui.Button>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}

export default Mui.withWidth()(BusinessTransfers);
