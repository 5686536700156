import React from "react";
import {
  SquarePaymentsForm,
  CreditCardInput,
} from "react-square-web-payments-sdk";

const Square = (props) => {
  const appId = process.env.REACT_APP_APPLICATION_ID;
  const locationId = process.env.REACT_APP_LOCATION_ID;

  const cardTokenizeResponseReceived = (values) => {
    if (values.token) {
      props.setFieldValue("paymentToken", values.token);
      setTimeout(() => {
        props.bookNow();
      }, 300);
    }
  };

  return (
    <SquarePaymentsForm
      applicationId={appId}
      locationId={locationId}
      cardTokenizeResponseReceived={cardTokenizeResponseReceived}
    >
      <CreditCardInput />
    </SquarePaymentsForm>
  );
};
export default Square;
