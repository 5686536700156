import {
  GET_ALL_ROUTE,
  GET_ALL_ROUTE_SUCCESS,
  GET_ALL_ROUTE_ERROR,
  GET_ROUTE_LIST,
  GET_ROUTE_LIST_SUCCESS,
  GET_ROUTE_LIST_ERROR,
  ADD_ROUTE,
  ADD_ROUTE_SUCCESS,
  ADD_ROUTE_ERROR,
  GET_ROUTE,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_ERROR,
  EDIT_ROUTE,
  EDIT_ROUTE_SUCCESS,
  EDIT_ROUTE_ERROR,
  DELETE_ROUTE,
  DELETE_ROUTE_SUCCESS,
  DELETE_ROUTE_ERROR,
  DELETE_MULTIPLE_ROUTE,
  DELETE_MULTIPLE_ROUTE_SUCCESS,
  DELETE_MULTIPLE_ROUTE_ERROR,
  RESET_ROUTE,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  routes: null,
  routeList: null,
  routeData: null,
  routeId: null,
  routeIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const routeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ROUTE:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_ROUTE_SUCCESS:
      return {
        ...state,
        routes: action.payload,
        error: null,
      };
    case GET_ALL_ROUTE_ERROR:
      return {
        ...state,
        routes: null,
        error: action.payload,
      };
    case GET_ROUTE_LIST:
      return {
        ...state,
        loading: true,
        routeData: null,
        routeId: null,
        routeIds: null,
        error: null,
      };
    case GET_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        routeList: action.payload,
        error: null,
      };
    case GET_ROUTE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        routeList: null,
        error: action.payload,
      };
    case ADD_ROUTE:
      return { ...state, loading: true, error: null };
    case ADD_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_ROUTE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_ROUTE:
      return { ...state, error: null };
    case GET_ROUTE_SUCCESS:
      return {
        ...state,
        routeData: action.payload,
        error: null,
      };
    case GET_ROUTE_ERROR:
      return {
        ...state,
        routeData: null,
        error: action.payload,
      };
    case EDIT_ROUTE:
      return { ...state, loading: true, error: null };
    case EDIT_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_ROUTE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_ROUTE:
      return { ...state, loading1: true, error: null };
    case DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_ROUTE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_ROUTE:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_ROUTE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_ROUTE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_ROUTE:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default routeReducer;
