import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import Breadcrumb from "src/components/breadcrumb";
import { getCoupon, addCoupon, editCoupon } from "src/reduxs/actions";
import { InputField, StyledSwitch } from "src/components/form";

class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.schema = Yup.object().shape({
      code: Yup.string().required("Code is required"),
      percent: Yup.string().required("Percentage is required").nullable(),
      minPrice: Yup.string().required("Minimum Price is required").nullable(),
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getCoupon(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editCoupon(this.props.match.params.id, values, this.props.history);
      } else {
        this.props.addCoupon(values, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Coupon Management"}
          paths={[
            {
              title: "Coupon",
              page: `/coupon`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            code: this.props.match.params.id ? this.props.couponData?.code || "" : "",
            percent: this.props.match.params.id ? this.props.couponData?.percent || "" : "",
            minPrice: this.props.match.params.id ? this.props.couponData?.minPrice || "" : "",
            status: this.props.match.params.id ? this.props.couponData?.status || 0 : 0,
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Grid container spacing={6}>
                <Mui.Grid item xs={12} md={8} lg={6}>
                  <Mui.Card className="default-card">
                    <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                      Coupon Information
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="code" type="text" label="Code" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField
                          name="percent"
                          type="number"
                          label="Percentage"
                          InputProps={{
                            endAdornment: (
                              <Mui.InputAdornment position="end" classes={{ positionEnd: "mr-1" }}>
                                %
                              </Mui.InputAdornment>
                            ),
                          }}
                        />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField
                          name="minPrice"
                          type="number"
                          label="Minimum Price"
                          InputProps={{
                            startAdornment: <Mui.InputAdornment position="start">$</Mui.InputAdornment>,
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.FormControlLabel
                      classes={{
                        root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                        label: "mr-2",
                      }}
                      control={
                        <StyledSwitch
                          value={props.values.status}
                          checked={props.values.status === 1}
                          onChange={(e) => {
                            props.setFieldValue(`status`, e.target.checked ? 1 : 0);
                          }}
                          name={`status`}
                        />
                      }
                      label={"Status"}
                    />
                  </Mui.Card>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5 mb-5"
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/coupon"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ coupon }) => {
  const { couponData, success, message, loading, error } = coupon;
  return {
    couponData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getCoupon,
  addCoupon,
  editCoupon,
})(Mui.withWidth()(EditCoupon));
