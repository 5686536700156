export const GET_ALL_DRIVER = "GET_ALL_DRIVER";
export const GET_ALL_DRIVER_SUCCESS = "GET_ALL_DRIVER_SUCCESS";
export const GET_ALL_DRIVER_ERROR = "GET_ALL_DRIVER_ERROR";
export const GET_DRIVER_LIST = "GET_DRIVER_LIST";
export const GET_DRIVER_LIST_SUCCESS = "GET_DRIVER_LIST_SUCCESS";
export const GET_DRIVER_LIST_ERROR = "GET_DRIVER_LIST_ERROR";
export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_ERROR = "ADD_DRIVER_ERROR";
export const GET_DRIVER = "GET_DRIVER";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";
export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_ERROR = "EDIT_DRIVER_ERROR";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_ERROR = "DELETE_DRIVER_ERROR";
export const DELETE_MULTIPLE_DRIVER = "DELETE_MULTIPLE_DRIVER";
export const DELETE_MULTIPLE_DRIVER_SUCCESS = "DELETE_MULTIPLE_DRIVER_SUCCESS";
export const DELETE_MULTIPLE_DRIVER_ERROR = "DELETE_MULTIPLE_DRIVER_ERROR";
export const RESET_DRIVER = "RESET_DRIVER";

export const getAllDriver = (param) => ({
  type: GET_ALL_DRIVER,
  payload: { param },
});

export const getAllDriverSuccess = (drivers) => ({
  type: GET_ALL_DRIVER_SUCCESS,
  payload: drivers,
});

export const getAllDriverError = (error) => ({
  type: GET_ALL_DRIVER_ERROR,
  payload: error,
});

export const getDriverList = (dbParam) => ({
  type: GET_DRIVER_LIST,
  payload: { dbParam },
});

export const getDriverListSuccess = (driverList) => ({
  type: GET_DRIVER_LIST_SUCCESS,
  payload: driverList,
});

export const getDriverListError = (error) => ({
  type: GET_DRIVER_LIST_ERROR,
  payload: error,
});

export const addDriver = (driverData, history) => ({
  type: ADD_DRIVER,
  payload: { driverData, history },
});

export const addDriverSuccess = (success, message) => ({
  type: ADD_DRIVER_SUCCESS,
  payload: { success, message },
});

export const addDriverError = (error) => ({
  type: ADD_DRIVER_ERROR,
  payload: error,
});

export const getDriver = (driverId) => ({
  type: GET_DRIVER,
  payload: { driverId },
});

export const getDriverSuccess = (driverData) => ({
  type: GET_DRIVER_SUCCESS,
  payload: driverData,
});

export const getDriverError = (error) => ({
  type: GET_DRIVER_ERROR,
  payload: error,
});

export const editDriver = (driverId, driverData, history) => ({
  type: EDIT_DRIVER,
  payload: { driverId, driverData, history },
});

export const editDriverSuccess = (success, message) => ({
  type: EDIT_DRIVER_SUCCESS,
  payload: { success, message },
});

export const editDriverError = (error) => ({
  type: EDIT_DRIVER_ERROR,
  payload: error,
});

export const deleteDriver = (driverId) => ({
  type: DELETE_DRIVER,
  payload: { driverId },
});

export const deleteDriverSuccess = (success, message) => ({
  type: DELETE_DRIVER_SUCCESS,
  payload: { success, message },
});

export const deleteDriverError = (error) => ({
  type: DELETE_DRIVER_ERROR,
  payload: error,
});

export const deleteMultipleDriver = (driverIds) => ({
  type: DELETE_MULTIPLE_DRIVER,
  payload: { driverIds },
});

export const deleteMultipleDriverSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_DRIVER_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleDriverError = (error) => ({
  type: DELETE_MULTIPLE_DRIVER_ERROR,
  payload: error,
});

export const resetDriver = () => ({
  type: RESET_DRIVER,
  payload: {},
});
