import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { getDriver, addDriver, editDriver } from "src/reduxs/actions";
import Breadcrumb from "src/components/breadcrumb";
import { InputField } from "src/components/form";
import { Alert } from "src/components/dialog";
import FileUpload from "src/components/file-upload";
import Dropzone from "src/components/dropzone";
class EditDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      field: null,
      values: null,
    };
    this.schema = Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      name: Yup.string()
        .required("Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      phone: Yup.string()
        .required("Contact No is required")
        .min(8, "Atleast 8 digits")
        .max(10, "Cannot exceed 10 digits")
        .nullable(),
      drivingLicenceNumber: Yup.string().required("License No is required").nullable(),
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getDriver(this.props.match.params.id);
    }
  }

  handleOpenAlert = () => {
    this.setState({ openAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleSubmit = (values) => {
    if (this.props.match.params.id) {
      if (values.email !== this.props.driverData.email) {
        this.setState({ field: "email", values: values, openAlert: true });
      } else {
        this.setState({ values: values }, () => this.onSubmit());
      }
    } else {
      this.setState({ values: values }, () => this.onSubmit());
    }
  };

  onSubmit = () => {
    let val = this.state.values;
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editDriver(this.props.match.params.id, val, this.props.history);
      } else {
        this.props.addDriver(val, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Chauffeur Management"}
          paths={[
            {
              title: "Chauffeur",
              page: `/driver`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: this.props.match.params.id ? this.props.driverData?.name || "" : "",
            email: this.props.match.params.id ? this.props.driverData?.email || "" : "",
            phone: this.props.match.params.id ? this.props.driverData?.phone || "" : "",
            drivingLicenceNumber: this.props.match.params.id ? this.props.driverData?.drivingLicenceNumber || "" : "",
            dateOfBirth: this.props.match.params.id ? this.props.driverData?.dateOfBirth || "" : "",
            payment: this.props.match.params.id ? this.props.driverData?.payment || "" : "",
            driverPhoto: this.props.match.params.id ? this.props.driverData?.driverPhoto || [] : [],
            driverDocument: this.props.match.params.id ? this.props.driverData?.driverDocument || [] : [],
          }}
          validationSchema={this.schema}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Grid container spacing={6}>
                <Mui.Grid item xs={12} md={7}>
                  <Mui.Card className="default-card">
                    <FileUpload field="driverPhoto" imgArr={props.values.driverPhoto} upload={props.setFieldValue} />

                    <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                      Chauffeur Information
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="name" type="text" label="Name" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="email" type="text" label="Email Address" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField
                          name="phone"
                          type="number"
                          label="Contact No"
                          InputProps={{
                            startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                          }}
                        />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="drivingLicenceNumber" type="text" label="License Number" />
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                      <InputField
                        name="payment"
                        type="number"
                        label="Payment to chauffeur"
                        InputProps={{
                          startAdornment: <Mui.InputAdornment position="start">$</Mui.InputAdornment>,
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Card>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={5} className={Mui.isWidthDown("sm", this.props.width) ? "mt-4" : ""}>
                  <Mui.Card className="default-card">
                    <Mui.Typography component="h4" variant="h4" className="mb-2 font-weight-medium">
                      Upload Documents
                    </Mui.Typography>
                    <Dropzone
                      multiple
                      accept={"image/*,application/pdf,.doc,.docx"}
                      init
                      field="driverDocument"
                      imgArr={props.values?.driverDocument || []}
                      upload={props.setFieldValue}
                    />
                  </Mui.Card>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5 mb-5"
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/driver"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onSubmit}
          title="Edit Chauffeur"
          info={`Are you sure to update ${this.state.field} of this chauffeur?`}
          awaitingInfo="Chauffeur is updating..."
          actionBtnLabel="Yes"
          loading={this.props.loading}
        />
      </>
    );
  }
}
const mapStateToProps = ({ driver }) => {
  const { driverData, success, message, loading, error } = driver;
  return {
    driverData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getDriver,
  addDriver,
  editDriver,
})(Mui.withWidth()(EditDriver));
