import React, { useEffect, useMemo } from "react";
import * as Mui from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SelectField } from "src/components/form";

const Status = (props) => {
  const { bookingData, open, close, onSubmit, loading, success, reset } = props;

  const schema = Yup.object().shape({
    status: Yup.string().required("Select status").nullable(),
  });

  const statusList = useMemo(() => {
    if (bookingData?.status === "pending") {
      return [
        { id: "confirmed", name: "Confirmed" },
        { id: "cancelled", name: "Cancelled" },
      ];
    }
    if (bookingData?.status === "confirmed") {
      return [
        { id: "cancelled", name: "Cancelled" },
        { id: "completed", name: "Completed" },
      ];
    }
    return [];
  }, [bookingData]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      close();
    }
  };

  useEffect(() => {
    if (success) {
      close();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Mui.Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xs"}
      classes={{ paper: "default-alert-dialog" }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          status: "",
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form className="default-form">
            <Mui.DialogContent>
              <Mui.Typography component="h3" variant="h3" className="default-alert-dialog__title">
                Change Status
              </Mui.Typography>

              <Mui.Box display="flex" flexDirection="column">
                <Mui.Box className="form-group">
                  <SelectField name="status" label="Booking Status*" options={statusList} />
                </Mui.Box>
              </Mui.Box>
            </Mui.DialogContent>
            <Mui.DialogActions>
              <Mui.Button type="submit" className="btn-default" color="primary" variant="contained" disableElevation>
                Submit
                {loading && <Mui.CircularProgress color="secondary" size={24} />}
              </Mui.Button>

              <Mui.Button onClick={close} color="primary" className="btn-default" variant="outlined">
                Cancel
              </Mui.Button>
            </Mui.DialogActions>
          </Form>
        )}
      </Formik>
    </Mui.Dialog>
  );
};
export default Status;
