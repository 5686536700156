export const GET_ALL_CONSOLIDATE_INVOICE = "GET_ALL_CONSOLIDATE_INVOICE";
export const GET_ALL_CONSOLIDATE_INVOICE_SUCCESS = "GET_ALL_CONSOLIDATE_INVOICE_SUCCESS";
export const GET_ALL_CONSOLIDATE_INVOICE_ERROR = "GET_ALL_CONSOLIDATE_INVOICE_ERROR";
export const GET_CONSOLIDATE_INVOICE_LIST = "GET_CONSOLIDATE_INVOICE_LIST";
export const GET_CONSOLIDATE_INVOICE_LIST_SUCCESS = "GET_CONSOLIDATE_INVOICE_LIST_SUCCESS";
export const GET_CONSOLIDATE_INVOICE_LIST_ERROR = "GET_CONSOLIDATE_INVOICE_LIST_ERROR";
export const ADD_CONSOLIDATE_INVOICE = "ADD_CONSOLIDATE_INVOICE";
export const ADD_CONSOLIDATE_INVOICE_SUCCESS = "ADD_CONSOLIDATE_INVOICE_SUCCESS";
export const ADD_CONSOLIDATE_INVOICE_ERROR = "ADD_CONSOLIDATE_INVOICE_ERROR";
export const GET_CONSOLIDATE_INVOICE = "GET_CONSOLIDATE_INVOICE";
export const GET_CONSOLIDATE_INVOICE_SUCCESS = "GET_CONSOLIDATE_INVOICE_SUCCESS";
export const GET_CONSOLIDATE_INVOICE_ERROR = "GET_CONSOLIDATE_INVOICE_ERROR";
export const DELETE_CONSOLIDATE_INVOICE = "DELETE_CONSOLIDATE_INVOICE";
export const DELETE_CONSOLIDATE_INVOICE_SUCCESS = "DELETE_CONSOLIDATE_INVOICE_SUCCESS";
export const DELETE_CONSOLIDATE_INVOICE_ERROR = "DELETE_CONSOLIDATE_INVOICE_ERROR";
export const DELETE_MULTIPLE_CONSOLIDATE_INVOICE = "DELETE_MULTIPLE_CONSOLIDATE_INVOICE";
export const DELETE_MULTIPLE_CONSOLIDATE_INVOICE_SUCCESS = "DELETE_MULTIPLE_CONSOLIDATE_INVOICE_SUCCESS";
export const DELETE_MULTIPLE_CONSOLIDATE_INVOICE_ERROR = "DELETE_MULTIPLE_CONSOLIDATE_INVOICE_ERROR";
export const DOWNLOAD_CONSOLIDATE_INVOICE = "DOWNLOAD_CONSOLIDATE_INVOICE";
export const DOWNLOAD_CONSOLIDATE_INVOICE_SUCCESS = "DOWNLOAD_CONSOLIDATE_INVOICE_SUCCESS";
export const DOWNLOAD_CONSOLIDATE_INVOICE_ERROR = "DOWNLOAD_CONSOLIDATE_INVOICE_ERROR";
export const MERGE_INVOICE = "MERGE_INVOICE";
export const MERGE_INVOICE_SUCCESS = "MERGE_INVOICE_SUCCESS";
export const MERGE_INVOICE_ERROR = "MERGE_INVOICE_ERROR";
export const RESET_CONSOLIDATE_INVOICE = "RESET_CONSOLIDATE_INVOICE";

export const getAllConsolidateInvoice = (param) => ({
  type: GET_ALL_CONSOLIDATE_INVOICE,
  payload: { param },
});

export const getAllConsolidateInvoiceSuccess = (consolidateInvoices) => ({
  type: GET_ALL_CONSOLIDATE_INVOICE_SUCCESS,
  payload: consolidateInvoices,
});

export const getAllConsolidateInvoiceError = (error) => ({
  type: GET_ALL_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const getConsolidateInvoiceList = (dbParam) => ({
  type: GET_CONSOLIDATE_INVOICE_LIST,
  payload: { dbParam },
});

export const getConsolidateInvoiceListSuccess = (consolidateInvoiceList) => ({
  type: GET_CONSOLIDATE_INVOICE_LIST_SUCCESS,
  payload: consolidateInvoiceList,
});

export const getConsolidateInvoiceListError = (error) => ({
  type: GET_CONSOLIDATE_INVOICE_LIST_ERROR,
  payload: error,
});

export const addConsolidateInvoice = (consolidateInvoiceData) => ({
  type: ADD_CONSOLIDATE_INVOICE,
  payload: { consolidateInvoiceData },
});

export const addConsolidateInvoiceSuccess = (success, message) => ({
  type: ADD_CONSOLIDATE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const addConsolidateInvoiceError = (error) => ({
  type: ADD_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const getConsolidateInvoice = (consolidateInvoiceId) => ({
  type: GET_CONSOLIDATE_INVOICE,
  payload: { consolidateInvoiceId },
});

export const getConsolidateInvoiceSuccess = (consolidateInvoiceData) => ({
  type: GET_CONSOLIDATE_INVOICE_SUCCESS,
  payload: consolidateInvoiceData,
});

export const getConsolidateInvoiceError = (error) => ({
  type: GET_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const deleteConsolidateInvoice = (consolidateInvoiceId) => ({
  type: DELETE_CONSOLIDATE_INVOICE,
  payload: { consolidateInvoiceId },
});

export const deleteConsolidateInvoiceSuccess = (success, message) => ({
  type: DELETE_CONSOLIDATE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const deleteConsolidateInvoiceError = (error) => ({
  type: DELETE_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const deleteMultipleConsolidateInvoice = (consolidateInvoiceIds) => ({
  type: DELETE_MULTIPLE_CONSOLIDATE_INVOICE,
  payload: { consolidateInvoiceIds },
});

export const deleteMultipleConsolidateInvoiceSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_CONSOLIDATE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleConsolidateInvoiceError = (error) => ({
  type: DELETE_MULTIPLE_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const downloadConsolidateInvoice = (consolidateInvoiceId) => ({
  type: DOWNLOAD_CONSOLIDATE_INVOICE,
  payload: { consolidateInvoiceId },
});

export const downloadConsolidateInvoiceSuccess = (success, message) => ({
  type: DOWNLOAD_CONSOLIDATE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const downloadConsolidateInvoiceError = (error) => ({
  type: DOWNLOAD_CONSOLIDATE_INVOICE_ERROR,
  payload: error,
});

export const mergeInvoice = (mergeInvoiceData) => ({
  type: MERGE_INVOICE,
  payload: { mergeInvoiceData },
});

export const mergeInvoiceSuccess = (success, message) => ({
  type: MERGE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const mergeInvoiceError = (error) => ({
  type: MERGE_INVOICE_ERROR,
  payload: error,
});

export const resetConsolidateInvoice = () => ({
  type: RESET_CONSOLIDATE_INVOICE,
  payload: {},
});
