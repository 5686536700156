import React from "react";
import * as Mui from "@material-ui/core";
import Action from "./action";
import UserAvatarCell from "./user-avatar-cell";
import moment from "moment";

const TableRow = (props) => {
  const {
    isItemSelected,
    labelId,
    row,
    handleClick,
    headCells,
    index,
    handleOpenActionMenu,
    actionMenu,
    actionMenuId,
    handleCloseActionMenu,
    handleOpenAlert,
    handleOpenModal,
    handleCustomAction,
    handleDownload,
    permission,
    route,
    actionStyle,
    enableCheckbox,
    history,
    enableRowClick,
    hideAction,
  } = props;

  return (
    <Mui.TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {enableCheckbox ? (
        <Mui.TableCell
          padding="checkbox"
          className={`${enableRowClick ? "pointer" : ""}`}
          onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
        >
          <Mui.Checkbox
            onClick={(event) => handleClick(event, row.id)}
            checked={isItemSelected}
            color="primary"
            inputProps={{ "aria-labelledby": labelId }}
          />
        </Mui.TableCell>
      ) : null}

      {headCells &&
        headCells.map((column, i) => {
          switch (column.id) {
            case "userAvatar":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  <UserAvatarCell data={row} />
                </Mui.TableCell>
              );
            case "vehicleType":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["vehicleType"] ? row["vehicleType"]["name"] : ""}
                </Mui.TableCell>
              );
            case "role":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["role"]?.["name"] || ""}
                </Mui.TableCell>
              );
            case "vehicleCategory":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["vehicleCategory"]?.["name"] || ""}
                </Mui.TableCell>
              );
            case "status":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  <span
                    className={`text-transform-capitalize status-bg ${row[column.id] === "pending" ? "warn" : ""} ${
                      row[column.id] === "cancelled" ? "danger" : ""
                    } ${row[column.id] === "confirmed" ? "success" : ""} ${
                      row[column.id] === "completed" ? "disabled" : ""
                    }`}
                  >
                    {row[column.id]}
                  </span>
                </Mui.TableCell>
              );
            case "activeInactiveStatus":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  <span className={`text-transform-capitalize status-bg ${row["status"] ? "success" : "warn"}`}>
                    {row["status"] ? "Active" : "Inactive"}
                  </span>
                </Mui.TableCell>
              );
            case "couponMinPrice":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  ${row["minPrice"]}
                </Mui.TableCell>
              );
            case "couponPercent":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["percent"]}%
                </Mui.TableCell>
              );
            case "paymentStatus":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  <span
                    className={`text-transform-capitalize status-bg ${row[column.id] === "hold" ? "disabled" : ""} ${
                      row[column.id] === "deposit" ? "warn" : ""
                    } ${row[column.id] === "paid" ? "success" : ""}`}
                  >
                    {row[column.id]}
                  </span>
                </Mui.TableCell>
              );
            case "date":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {moment(row["pickupDate"]).format("DD/MM/YYYY")}
                </Mui.TableCell>
              );
            case "bookingType":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["type"] === "distance" ? "Point to Point" : null}
                  {row["type"] === "hourly" ? "Hourly" : null}
                  {row["type"] === "flat_rate" ? "Flat Rate" : null}
                </Mui.TableCell>
              );
            case "createdAt":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["createdAt"] ? moment(row["createdAt"]).format("DD/MM/YYYY HH:mm") : ""}
                </Mui.TableCell>
              );
            case "startDate":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["startDate"]
                    ? moment(row["startDate"] + " " + row["startTime"], "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : ""}
                </Mui.TableCell>
              );
            case "endDate":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["endDate"]
                    ? moment(row["endDate"] + " " + row["endTime"], "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")
                    : ""}
                </Mui.TableCell>
              );
            case "fullname":
              return (
                <Mui.TableCell
                  key={i}
                  className={`${enableRowClick ? "pointer" : ""}`}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row["firstName"]} {row["lastName"]}
                </Mui.TableCell>
              );
            case "invoiceBookingNumber":
              return (
                <Mui.TableCell
                  key={i}
                  className="pointer text-color-primary"
                  onClick={() => history.push(`/booking/view/${row?.booking?.id}`)}
                >
                  {row["booking"]?.["bookingNumber"] || ""}
                </Mui.TableCell>
              );
            case "userInvoiceBookingNumber":
              return <Mui.TableCell key={i}>{row["booking"]?.["bookingNumber"] || ""}</Mui.TableCell>;
            case "accountNo":
              return (
                <Mui.TableCell
                  key={i}
                  className="pointer text-color-primary"
                  onClick={() => history.push(`/user/view/${row?.userId}`)}
                >
                  {row["userId"] || ""}
                </Mui.TableCell>
              );
            case "invoiceUser":
              return (
                <Mui.TableCell
                  key={i}
                  className="pointer text-color-primary"
                  onClick={() => history.push(`/user/view/${row.userId}`)}
                >
                  {row["user"]?.["firstName"]} {row["user"]?.["lastName"]} ({row.userId})
                </Mui.TableCell>
              );
            case "invoiceBooking":
              return (
                <Mui.TableCell key={i} width={500}>
                  {row?.consolidateInvoiceBooking?.map((item) => item?.booking?.bookingNumber)?.join(" ,  ")}
                </Mui.TableCell>
              );
            default:
              return (
                <Mui.TableCell
                  className={`${enableRowClick ? "pointer" : ""}`}
                  key={i}
                  onClick={() => (enableRowClick ? history.push(`${route}/view/${row?.id}`) : "")}
                >
                  {row[column.id]}
                </Mui.TableCell>
              );
          }
        })}

      {!hideAction ? (
        <Action
          index={index}
          handleOpenActionMenu={handleOpenActionMenu}
          actionMenu={actionMenu}
          actionMenuId={actionMenuId}
          handleCloseActionMenu={handleCloseActionMenu}
          row={row}
          handleOpenAlert={handleOpenAlert}
          handleOpenModal={handleOpenModal}
          handleDownload={handleDownload}
          handleCustomAction={handleCustomAction}
          permission={permission}
          route={route}
          actionStyle={actionStyle}
        />
      ) : null}
    </Mui.TableRow>
  );
};
export default TableRow;
