import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class Search extends Component {
  render() {
    return (
      <Mui.FormControl
        variant="outlined"
        className={`tbl-filter-field ${
          this.props.fieldTheme === "dark"
            ? "default-form-field-dark"
            : "default-form-field"
        } ${Mui.isWidthDown("xs", this.props.width) ? "mb-2" : ""}`}
      >
        <Mui.OutlinedInput
          value={this.props.value}
          placeholder={"Search"}
          onChange={this.props.onSearch}
          startAdornment={
            <Mui.InputAdornment position="start">
              <SearchIcon />
            </Mui.InputAdornment>
          }
        />
      </Mui.FormControl>
    );
  }
}
export default Mui.withWidth()(Search);
