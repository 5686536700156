import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import moment from "moment";
import * as MuiLab from "@material-ui/lab";
import { Link } from "react-router-dom";
import { getBookingList, getEventList, getAllDriver, assignBookingDriver, resetBooking } from "src/reduxs/actions";
import { formatCurrency } from "src/helpers/util";
import {
  IconInfo,
  IconArrowRight,
  IconCarFront,
  IconChevronRight,
  IconDriver,
  IconCalendar,
  IconTime,
} from "src/components/svg";
import AssignDriver from "./assign-driver";
import Calendar from "./calendar";
// import carPlaceholder from "src/assets/images/car.svg";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: "list",
      page: 1,
      bookingListData: [],
      openAlert: false,
      bookingData: null,
      fromDate: "",
      toDate: "",
      schedules: [],
      dayFilter: null,
    };
    this.searchFields =
      "booking_number:like;type:like;pickup_date:like;first_name:like;last_name:like;email:like;phone:like;";
  }

  componentDidMount() {
    this.props.getBookingList({
      sortOrder: "asc",
      activeCol: "pickup_date",
    });
    this.props.getAllDriver({});
  }

  toggle = (event, value) => {
    if (value !== null) {
      this.setState({ toggle: value });
    }
  };

  handleDatesSet = (info) => {
    if (info.start && info.view.type === "dayGridMonth") {
      this.setState({
        fromDate: moment(info.start).format("YYYY-MM-DD"),
        toDate: moment(info.end).format("YYYY-MM-DD"),
      });
      this.props.getEventList({
        fromDate: moment(info.start).format("YYYY-MM-DD"),
        toDate: moment(info.end).format("YYYY-MM-DD"),
      });
    }
  };

  handleDayFilter = (value = "") => {
    this.setState({
      dayFilter: value,
    });
    this.props.getBookingList({
      search: "",
      searchFields: this.searchFields,
      sortOrder: "asc",
      page: this.state.page,
      pageSize: "",
      activeCol: "pickup_date",
      day: value,
    });
  };

  componentDidUpdate(previousProps) {
    if (previousProps.bookingList !== this.props.bookingList) {
      const el = this.props.bookingList?.data?.reduce((el, item) => {
        const date = moment(item.pickupDate).format("YYYY-MM-DD");
        if (!el[date]) {
          el[date] = [];
        }
        el[date].push(item);
        return el;
      }, {});
      const groupArrays = Object.keys(el).map((date) => {
        return {
          date,
          child: el[date],
        };
      });
      this.setState({ bookingListData: groupArrays });
    }
    if (previousProps.eventList !== this.props.eventList) {
      this.setState({ schedules: [] }, () => {
        this.props.eventList &&
          this.props.eventList.map((item, index) =>
            this.setState((prevState) => ({
              schedules: [
                ...prevState.schedules,
                {
                  end: new Date(moment(item.pickupDate).format("YYYY-MM-DD") + "T" + item.pickupTime),
                  id: item.id,
                  start: new Date(moment(item.pickupDate).format("YYYY-MM-DD") + "T" + item.pickupTime),
                  title: item.bookingNumber,
                  name: item.firstName + " " + item.lastName,
                  status: item.status,
                },
              ],
            }))
          );
      });
    }
  }

  handleOpenAlert = (item) => {
    this.setState({ openAlert: true, bookingData: item });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, bookingData: null });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.props.getBookingList({
      search: "",
      searchFields: this.searchFields,
      sortOrder: "asc",
      page: newPage,
      pageSize: "",
      activeCol: "pickup_date",
    });
  };

  handleSearch = (value) => {
    this.props.getAllDriver({
      search: value,
      searchFields: "name:like;driving_licence_number:like;",
    });
  };

  assign = (id, driverId) => {
    if (!this.props.loading) {
      this.props.assignBookingDriver(id, { driverId: driverId, list: true });
    }
  };

  render() {
    return (
      <>
        <Mui.Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
          className="mb-5"
        >
          <Mui.Typography
            component="h1"
            variant="h1"
            className={`${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : ""}`}
          >
            Welcome {this.props.user?.firstName || ""} !!!
          </Mui.Typography>

          <ToggleButtonGroup value={this.state.toggle} exclusive onChange={this.toggle} aria-label="text alignment">
            <ToggleButton value="list" aria-label="left aligned" className="toggle-btn mr-2">
              <FormatListBulletedIcon />
              <Mui.Typography component="h6" variant="h6" className="ml-1 font-weight-medium">
                List
              </Mui.Typography>
            </ToggleButton>
            <ToggleButton value="calendar" aria-label="centered" className="toggle-btn">
              <IconCalendar />
              <Mui.Typography component="h6" variant="h6" className="ml-1 font-weight-medium">
                Calendar
              </Mui.Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Mui.Box>

        <Mui.Grid container spacing={6} justifyContent="center">
          {this.state.toggle === "list" ? (
            <Mui.Grid item xs={12} md={10} className="pos-relative">
              <Mui.Paper className="default-card p-0">
                <Mui.Grid container className="p-3" gap={2}>
                  <Mui.Grid item xs={12} sm={4}>
                    <Mui.FormControl
                      variant="outlined"
                      className={`${
                        this.props.fieldTheme === "dark" ? "form-group-dark" : "default-form-field"
                      }  tbl-filter-field`}
                    >
                      <Mui.Select
                        classes={{ root: "pl-0 pr-0" }}
                        value={this.state.dayFilter}
                        onChange={(event) => this.handleDayFilter(event.target.value)}
                        startAdornment={
                          <Mui.InputAdornment
                            className={`${this.props.fieldTheme === "dark" ? "text-color-white" : ""}`}
                            position="start"
                          >
                            Days:
                          </Mui.InputAdornment>
                        }
                        endAdornment={
                          this.state.dayFilter ? (
                            <div
                              className={`svg-color-muted pointer mr-4`}
                              onClick={() => {
                                this.setState({ dayFilter: null });
                                this.handleDayFilter();
                              }}
                            >
                              <ClearIcon />
                            </div>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <Mui.MenuItem value={1}>1 Day</Mui.MenuItem>
                        <Mui.MenuItem value={2}>2 Days</Mui.MenuItem>
                        <Mui.MenuItem value={3}>3 Days</Mui.MenuItem>
                        <Mui.MenuItem value={7}>1 Week</Mui.MenuItem>
                        <Mui.MenuItem value={14}>2 Weeks</Mui.MenuItem>
                        <Mui.MenuItem value={30}>1 Month</Mui.MenuItem>
                      </Mui.Select>
                    </Mui.FormControl>
                  </Mui.Grid>
                  <Mui.Grid item className="ml-auto">
                    {this.props.bookingList?.meta?.total > 0 ? (
                      <MuiLab.Pagination
                        page={this.state.page}
                        onChange={this.handleChangePage}
                        count={this.props.bookingList?.meta?.pages || 0}
                        variant="outlined"
                        shape="rounded"
                        className="pagination-float-top"
                      />
                    ) : null}
                  </Mui.Grid>
                </Mui.Grid>

                {this.state.bookingListData?.length === 0 ? (
                  <div className="mb-1">
                    <Mui.Divider light={true} />
                    <Mui.Typography
                      component="h4"
                      variant="h4"
                      className="dashboard-booking-list-card-head text-center"
                    >
                      No Records Found
                    </Mui.Typography>
                    <Mui.Divider light={true} />
                  </div>
                ) : null}

                {this.state.bookingListData?.map((item, i) => {
                  return (
                    <div className="mb-1" key={i}>
                      <Mui.Divider light={true} />
                      <Mui.Typography component="h4" variant="h4" className="dashboard-booking-list-card-head">
                        {moment(item.date).format("Do MMM YYYY")}
                      </Mui.Typography>
                      <Mui.Divider light={true} />

                      {item.child?.map((data, j) => (
                        <Mui.Paper className="dashboard-booking-list-card" key={j}>
                          {/* <Mui.Hidden smDown>
                            <figure>
                              <img
                                src={
                                  data.vehicleType?.vehicleTypePhoto?.[0]?.url
                                    ? data.vehicleType?.vehicleTypePhoto?.[0]
                                        ?.url
                                    : carPlaceholder
                                }
                                alt=""
                              />
                            </figure>
                          </Mui.Hidden> */}
                          <Mui.Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            pl={Mui.isWidthDown("xs", this.props.width) ? 0 : 3}
                          >
                            <Mui.Box
                              display="flex"
                              flexWrap={Mui.isWidthDown("xs", this.props.width) ? "wrap" : "nowrap"}
                            >
                              <Mui.Chip
                                className="pointer"
                                icon={<IconInfo />}
                                label={data.bookingNumber || ""}
                                component={Link}
                                to={`/booking/view/${data.id}`}
                              />
                              <Mui.Chip icon={<IconCalendar />} label={moment(data.pickupDate).format("DD/MM/YYYY")} />
                              <Mui.Chip
                                icon={<IconTime />}
                                label={moment(data.pickupTime, "HH:mm:ss").format("HH:mm")}
                              />

                              <Mui.Typography
                                component="h3"
                                variant="h3"
                                className={`font-weight-medium text-color-primary ${
                                  Mui.isWidthDown("xs", this.props.width) ? "mt-2 mb-2" : "ml-auto"
                                }`}
                              >
                                {formatCurrency(data.total)}
                              </Mui.Typography>
                            </Mui.Box>

                            <Mui.Box
                              className="dashboard-booking-list-card__direction"
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="space-between"
                              flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                            >
                              <Mui.Typography component="p" variant="body1">
                                {data.type === "flat_rate" ? data.route?.name || "-" : data?.pickupLocation || "-"}
                              </Mui.Typography>

                              {data.type !== "flat_rate" ? <IconArrowRight /> : null}

                              <Mui.Typography
                                component="p"
                                variant="body1"
                                className={Mui.isWidthDown("xs", this.props.width) ? "text-left" : "text-right"}
                              >
                                {data.type === "flat_rate" ? "" : data?.dropOffLocation || "-"}
                              </Mui.Typography>
                            </Mui.Box>

                            <Mui.Grid container spacing={4} className="mt-2">
                              <Mui.Grid item xs={12} md>
                                <Mui.Typography
                                  component="h6"
                                  variant="h6"
                                  className="font-weight-normal ml-5 text-color-grey"
                                >
                                  Customer Info
                                </Mui.Typography>

                                <Mui.Box display="flex">
                                  <Mui.Avatar component={Link} to={`/user/view/${data?.userId}`} src="1.jpg" />
                                  <Mui.Typography
                                    component={Link}
                                    to={`/user/view/${data?.userId}`}
                                    variant="body1"
                                    className="font-weight-semi-bold ml-1"
                                  >
                                    {data.firstName || ""} {data.lastName || ""}
                                    <Mui.Typography component="span" variant="body1" className="d-block">
                                      +61 {data.phone || "-"}
                                    </Mui.Typography>
                                  </Mui.Typography>
                                </Mui.Box>
                              </Mui.Grid>

                              <Mui.Grid
                                item
                                xs={12}
                                md
                                className={`d-flex flex-direction-column ${
                                  Mui.isWidthDown("sm", this.props.width) ? "mt-1 mb-1" : "align-items-center"
                                }`}
                              >
                                <Link
                                  to={data?.vehicleTypeId ? `/car/view/${data?.vehicleTypeId}` : "#"}
                                  className={`d-flex flex-direction-column ${
                                    Mui.isWidthDown("sm", this.props.width) ? "" : "align-items-center"
                                  }`}
                                >
                                  <span className="mr-1 svg-color-primary svg-size-medium">
                                    <IconCarFront />
                                  </span>
                                  <Mui.Typography
                                    component="span"
                                    variant="h5"
                                    className="d-block font-weight-semi-bold"
                                  >
                                    {data.vehicleType?.name || "-"}
                                  </Mui.Typography>
                                </Link>
                              </Mui.Grid>

                              <Mui.Grid
                                item
                                xs={12}
                                md
                                className={`${Mui.isWidthDown("md", this.props.width) ? "" : "text-right"}`}
                              >
                                <Mui.Typography
                                  component="h6"
                                  variant="h6"
                                  className={`font-weight-normal text-color-grey ${
                                    Mui.isWidthDown("md", this.props.width) ? "ml-5" : "mr-5"
                                  }`}
                                >
                                  Chauffeur Info
                                </Mui.Typography>

                                <Mui.Box
                                  width="100%"
                                  display="flex"
                                  flexDirection={Mui.isWidthDown("md", this.props.width) ? "row-reverse" : ""}
                                  justifyContent={Mui.isWidthDown("md", this.props.width) ? "" : "end"}
                                >
                                  <Mui.Typography
                                    component="a"
                                    onClick={() =>
                                      data?.driverId
                                        ? this.props.history.push(`/driver/view/${data?.driverId}`)
                                        : this.handleOpenAlert(data)
                                    }
                                    variant="body1"
                                    className={`font-weight-semi-bold ${
                                      Mui.isWidthDown("md", this.props.width) ? "mr-auto ml-1" : "ml-auto mr-1"
                                    }`}
                                  >
                                    {data.driver?.name || "Not Assigned"}
                                    <Mui.Typography component="span" variant="body1" className="d-block">
                                      {data.driver?.phone || ""}
                                    </Mui.Typography>
                                  </Mui.Typography>
                                  <Mui.Avatar
                                    className="pointer"
                                    onClick={() =>
                                      data?.driverId
                                        ? this.props.history.push(`/driver/view/${data?.driverId}`)
                                        : this.handleOpenAlert(data)
                                    }
                                    src="1.jpg"
                                  />
                                </Mui.Box>
                              </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Box className="btn-group">
                              <Mui.Box display="flex" flexWrap="wrap" gridGap="1.6rem">
                                <Mui.Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className="btn-default svg-color-white"
                                  component={Link}
                                  to={`/booking/view/${data.id}`}
                                >
                                  View Detail
                                  <IconChevronRight />
                                </Mui.Button>
                                {data.status !== "cancelled" && data.status !== "completed" ? (
                                  <Mui.Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className={`btn-default svg-color-white`}
                                    onClick={() => this.handleOpenAlert(data)}
                                  >
                                    {data.driverId ? "Reassign Chauffeur" : "Assign Chauffeur"}

                                    <IconDriver />
                                  </Mui.Button>
                                ) : null}
                              </Mui.Box>

                              <span
                                className={`text-transform-capitalize status-bg ml-auto ${
                                  data.status === "pending" ? "warn" : ""
                                } ${data.status === "cancelled" ? "danger" : ""} ${
                                  data.status === "confirmed" ? "success" : ""
                                } ${data.status === "completed" ? "disabled" : ""}`}
                              >
                                {data.status || "-"}
                              </span>
                            </Mui.Box>
                          </Mui.Box>
                        </Mui.Paper>
                      ))}
                    </div>
                  );
                })}

                {this.props.bookingList?.meta?.total > 0 ? (
                  <Mui.Box display="flex" justifyContent="center">
                    <MuiLab.Pagination
                      page={this.state.page}
                      onChange={this.handleChangePage}
                      count={this.props.bookingList?.meta?.pages || 0}
                      variant="outlined"
                      shape="rounded"
                      className="pagination-bottom"
                    />
                  </Mui.Box>
                ) : null}
              </Mui.Paper>
            </Mui.Grid>
          ) : null}
          {this.state.toggle === "calendar" ? (
            <Mui.Grid item xs={12} md={12}>
              <Mui.Card className="custom-fullcalendar shadow-none">
                <Calendar schedules={this.state.schedules} handleDatesSet={this.handleDatesSet} />
              </Mui.Card>
            </Mui.Grid>
          ) : null}
        </Mui.Grid>

        <AssignDriver
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          driverList={this.props.drivers}
          bookingData={this.state.bookingData}
          assign={this.assign}
          reset={this.props.resetBooking}
          handleSearch={this.handleSearch}
          loading={this.props.loading}
          success={this.props.success}
        />
      </>
    );
  }
}
const mapStateToProps = ({ booking, auth, driver, invoice }) => {
  const { user } = auth;
  const { drivers } = driver;
  const { downloading } = invoice;
  const { bookingList, eventList, loading, success, error } = booking;
  return {
    drivers,
    user,
    bookingList,
    eventList,
    loading,
    downloading,
    success,
    error,
  };
};
export default connect(mapStateToProps, {
  getBookingList,
  getEventList,
  getAllDriver,
  assignBookingDriver,
  resetBooking,
})(Mui.withWidth()(Dashboard));
