import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { formatCurrency } from "src/helpers/util";
import moment from "moment";
import {
  IconDriver,
  IconBriefcase,
  IconCalendar,
  IconCarFront,
  IconRoute,
  IconSteering,
  IconTime,
  IconUsers,
  IconChevronLeft,
  IconEdit,
} from "src/components/svg";
import {} from "src/reduxs/actions";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import Rebook from "./rebook";
class BookingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { directions: null, rebookModal: false };
  }

  filter = (arr) => {
    const el = JSON.stringify(arr);
    let newArr = JSON.parse(el);
    newArr = arr.slice(1, -1);
    return newArr.map(({ id, routeId, ...rest }) => ({ ...rest }));
  };

  componentDidUpdate(previousProps) {
    if (previousProps.bookingData !== this.props.bookingData) {
      const directionsService = new window.google.maps.DirectionsService();
      if (this.props.bookingData.pickupLocation) {
        // For distance
        directionsService.route(
          {
            origin: this.props.bookingData.pickupLocation,
            destination: this.props.bookingData.dropOffLocation
              ? this.props.bookingData.dropOffLocation
              : this.props.bookingData.pickupLocation,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
            waypoints:
              this.props.bookingData?.bookingWaypoint?.map(({ id, bookingId, ...rest }) => ({ ...rest })) || [],
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              this.setState({ directions: result });
            } else {
              console.error(`Error fetching directions ${result}`);
            }
          }
        );
      }
      // For flat_rate
      if (this.props.bookingData.routeId) {
        // Set direction, totalDistance and totalTime according to route
        let ol = this.props.routes?.find((x) => x.id === this.props.bookingData.routeId)?.routeWaypoint;
        if (ol?.length > 0 && ol[0].location) {
          directionsService.route(
            {
              origin: ol[0].location,
              destination: ol[ol.length - 1].location,
              travelMode: window.google.maps.TravelMode.DRIVING,
              optimizeWaypoints: true,
              waypoints: this.filter(ol),
              unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                this.setState({ directions: result });
              } else {
                console.error(`Error fetching directions ${result}`);
              }
            }
          );
        }
      }
    }
  }

  render() {
    const MapWithAMarker = withGoogleMap(() => (
      <GoogleMap defaultZoom={7}>
        {this.state.directions && <DirectionsRenderer directions={this.state.directions} />}
      </GoogleMap>
    ));

    return (
      <>
        <Mui.Box width="100%" display="flex" alignItems="center" flexWrap="wrap" mb="4rem">
          <Mui.IconButton
            className="border-1 border-color-grey"
            type="button"
            size="small"
            style={{ padding: 6, marginRight: 15 }}
            onClick={() => this.props.onBack()}
          >
            <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
              <IconChevronLeft />
            </Mui.Box>
          </Mui.IconButton>
          <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
            Booking Detail
          </Mui.Typography>
          {/* 24hrs prior */}
          {this.props.bookingData?.pickupDate &&
          moment.duration(moment(this.props.bookingData.pickupDate).diff(moment(new Date()))).asHours() > 24 &&
          (this.props.bookingData?.status === "pending" || this.props.bookingData?.status === "confirmed") &&
          this.props.bookingData?.payment_status !== "paid" ? (
            <Mui.Button
              variant="outlined"
              color="primary"
              className="btn-default text-color-white border-color-white svg-color-white"
              onClick={() => this.props.onEdit()}
            >
              Modify Booking
              <Mui.Box component="span" ml="1.5rem">
                <IconEdit />
              </Mui.Box>
            </Mui.Button>
          ) : null}
        </Mui.Box>

        <Mui.Grid container spacing={3}>
          <Mui.Grid item xs={12} md={7}>
            <Mui.Typography
              component="h1"
              variant="h1"
              className={`d-flex mb-4 svg-color-primary font-weight-medium ${
                Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : ""
              }`}
            >
              <span className={`${Mui.isWidthDown("xs", this.props.width) ? "mt-1 mb-1" : "mr-2"}`}>
                <Mui.Typography
                  component="small"
                  variant="body2"
                  className="d-block text-color-grey text-transform-uppercase"
                >
                  Booking Number
                </Mui.Typography>
                {this.props.bookingData?.bookingNumber || ""}
              </span>

              <Mui.Typography
                component="span"
                variant="body1"
                className={`status-bg text-transform-uppercase ${
                  this.props.bookingData?.status === "pending" ? "warn" : ""
                } ${this.props.bookingData?.status === "cancelled" ? "danger" : ""} ${
                  this.props.bookingData?.status === "confirmed" ? "success" : ""
                } ${this.props.bookingData?.status === "completed" ? "disabled" : ""} ${
                  Mui.isWidthDown("xs", this.props.width) ? "" : "align-self-end"
                }`}
              >
                {this.props.bookingData?.status || ""}
              </Mui.Typography>
            </Mui.Typography>

            <Mui.Box display="flex" flexDirection="column" className="mb-2">
              <Mui.Typography component="h5" variant="h5" className="mb-3 svg-color-primary font-weight-normal">
                <Mui.Typography
                  component="small"
                  variant="body2"
                  className="d-block text-color-grey text-transform-uppercase"
                >
                  Reference Number
                </Mui.Typography>
                {this.props.bookingData?.referenceNumber || "-"}
              </Mui.Typography>
              {this.props.bookingData?.type !== "flat_rate" ? (
                <>
                  <Mui.Typography component="h5" variant="h5" className="mb-3 svg-color-primary font-weight-normal">
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      Pick-Up Destination
                    </Mui.Typography>
                    {this.props.bookingData?.pickupLocation || "-"}
                  </Mui.Typography>
                  <Mui.Typography component="h5" variant="h5" className="mb-3 svg-color-primary font-weight-normal">
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      Drop-Off Destination
                    </Mui.Typography>
                    {this.props.bookingData?.dropOffLocation || "-"}
                  </Mui.Typography>
                </>
              ) : null}
              {this.props.bookingData?.type === "flat_rate" ? (
                <Mui.Typography component="h5" variant="h5" className="mb-3 svg-color-primary font-weight-normal">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    Route
                  </Mui.Typography>
                  {this.props.bookingData?.route?.name || "-"}
                </Mui.Typography>
              ) : null}
            </Mui.Box>

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3 mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconSteering />
                </span>
                <span className="ml-2 text-transform-capitalize">
                  <Mui.Typography component="small" variant="body2" className="d-block text-color-grey">
                    Service Type
                  </Mui.Typography>
                  {this.props.bookingData?.type === "distance" ? "Point to Point" : null}
                  {this.props.bookingData?.type === "hourly" ? "Hourly" : null}
                  {this.props.bookingData?.type === "flat_rate" ? "Flat Rate" : null}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCalendar />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    Date & Time
                  </Mui.Typography>
                  {moment(this.props.bookingData?.pickupDate).format("DD/MM/YYYY")},{" "}
                  {moment(this.props.bookingData?.pickupTime, "HH:mm:ss").format("HH:mm")}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconDriver />
                </span>
                <span className="ml-2 text-transform-capitalize">
                  <Mui.Typography component="small" variant="body2" className="d-block text-color-grey">
                    Chauffeur
                  </Mui.Typography>
                  {this.props.bookingData?.driver?.name || "-"}
                  {this.props.bookingData?.driver?.phone ? `(+61 ${this.props.bookingData?.driver?.phone})` : ""}
                </span>
              </Mui.Typography>
            </Mui.Box>

            <Mui.Divider light={true} className="mt-1 mb-1" />

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconRoute />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    TOTAL DISTANCE
                  </Mui.Typography>
                  {this.props.bookingData?.totalDistance || 0} km
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconTime />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    APPROX ETA
                  </Mui.Typography>
                  {this.props.bookingData?.totalTime ? (
                    <>
                      {moment(this.props.bookingData.totalTime, "HH:mm:ss").format("H")}h{" "}
                      {moment(this.props.bookingData.totalTime, "HH:mm:ss").format("m")}m
                    </>
                  ) : (
                    "-"
                  )}
                </span>
              </Mui.Typography>
            </Mui.Box>

            <Mui.Divider light={true} className="mt-1 mb-1" />

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3 mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCarFront />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    Vehicle
                  </Mui.Typography>
                  <Mui.Typography
                    component={Link}
                    variant="h5"
                    to={{
                      pathname: `/our-fleet-detail/${this.props.bookingData?.vehicleType?.slug}`,
                      state: { carDetail: this.props.bookingData?.vehicleType },
                    }}
                    className="font-weight-normal text-color-primary"
                  >
                    {this.props.bookingData?.vehicleType.name}
                  </Mui.Typography>
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconUsers />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    NO OF PASSENGER
                  </Mui.Typography>
                  {this.props.bookingData?.noPassenger || 0}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="d-flex mb-3  mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconBriefcase />
                </span>
                <span className="ml-2">
                  <Mui.Typography
                    component="small"
                    variant="body2"
                    className="d-block text-color-grey text-transform-uppercase"
                  >
                    NO OF LUGGAGE
                  </Mui.Typography>
                  {this.props.bookingData?.noLuggage || 0}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Grid>

          <Mui.Grid item xs={12} md={5}>
            <MapWithAMarker
              containerElement={<div className="map-item mb-4" />}
              mapElement={<div className="map-item" style={{ height: "250px" }} />}
            />
            <Mui.List>
              <Mui.ListItem className="mb-1 p-0">
                <Mui.Grid container spacing={2} justifyContent="space-between">
                  <Mui.Grid item xs={6}>
                    <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                      SUBTOTAL
                    </Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid item xs={6}>
                    <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                      {formatCurrency(this.props.bookingData?.subTotal)}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.ListItem>

              {this.props.bookingData?.coupon ? (
                <Mui.ListItem className="mb-1 p-0">
                  <Mui.Grid container spacing={2} justifyContent="space-between">
                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                        COUPON: {this.props.bookingData?.coupon || ""}
                      </Mui.Typography>
                    </Mui.Grid>

                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                        - {formatCurrency(this.props.bookingData?.couponDiscount || 0)}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.ListItem>
              ) : null}

              {this.props.bookingData?.bookingAdditionalCharge?.length > 0 &&
                this.props.bookingData.bookingAdditionalCharge.map((item, index) => (
                  <Mui.ListItem className="mb-1 p-0">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          {this.props.additionalChargeList?.find((x) => x.id === item.additionalChargeId)?.name || ""}
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          {this.props.additionalChargeList?.find((x) => x.id === item.additionalChargeId)?.charge ===
                          "negative"
                            ? "-"
                            : "+"}{" "}
                          {formatCurrency(item?.value)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                ))}

              {this.props.bookingData?.paymentStatus === "paid" ? (
                <Mui.ListItem className="mb-1 p-0">
                  <Mui.Grid container spacing={2} justifyContent="space-between">
                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                        PAID
                      </Mui.Typography>
                    </Mui.Grid>

                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                        {formatCurrency(this.props.bookingData?.total)}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.ListItem>
              ) : null}

              {this.props.bookingData?.paymentStatus === "deposit" ? (
                <>
                  <Mui.ListItem className="mb-1 p-0">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          DEPOSIT
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          - {formatCurrency(this.props.bookingData?.deposit)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                  <Mui.ListItem className="mb-1 p-0">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          REMAINING (80%)
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          {formatCurrency(
                            parseFloat(
                              this.props.bookingData?.total > 0
                                ? parseFloat(this.props.bookingData?.total) -
                                    parseFloat(this.props.bookingData?.deposit)
                                : 0
                            )
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                </>
              ) : null}

              {this.props.bookingData?.paymentStatus === "hold" ? (
                <Mui.ListItem className="mb-1 p-0">
                  <Mui.Grid container spacing={2} justifyContent="space-between">
                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                        PAID
                      </Mui.Typography>
                    </Mui.Grid>

                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                        -
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.ListItem>
              ) : null}
            </Mui.List>

            <Mui.Box>
              {/* Only for previous bookings */}
              {this.props.bookingData?.pickupDate < moment().format("YYYY-MM-DD") ? (
                <Mui.Button
                  type="button"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => this.setState({ rebookModal: true })}
                >
                  Re-Book
                  <span className="ml-2 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              ) : null}
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>

        <Rebook
          open={this.state.rebookModal}
          close={() => {
            this.setState({ rebookModal: false });
          }}
          id={this.props.bookingData?.id || null}
        />
      </>
    );
  }
}
const mapStateToProps = ({ route }) => {
  const { routes } = route;
  return { routes };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(BookingDetail));
