export const GET_ALL_ADDITIONAL_CHARGE = "GET_ALL_ADDITIONAL_CHARGE";
export const GET_ALL_ADDITIONAL_CHARGE_SUCCESS =
  "GET_ALL_ADDITIONAL_CHARGE_SUCCESS";
export const GET_ALL_ADDITIONAL_CHARGE_ERROR =
  "GET_ALL_ADDITIONAL_CHARGE_ERROR";
export const GET_ADDITIONAL_CHARGE_LIST = "GET_ADDITIONAL_CHARGE_LIST";
export const GET_ADDITIONAL_CHARGE_LIST_SUCCESS =
  "GET_ADDITIONAL_CHARGE_LIST_SUCCESS";
export const GET_ADDITIONAL_CHARGE_LIST_ERROR =
  "GET_ADDITIONAL_CHARGE_LIST_ERROR";
export const ADD_ADDITIONAL_CHARGE = "ADD_ADDITIONAL_CHARGE";
export const ADD_ADDITIONAL_CHARGE_SUCCESS = "ADD_ADDITIONAL_CHARGE_SUCCESS";
export const ADD_ADDITIONAL_CHARGE_ERROR = "ADD_ADDITIONAL_CHARGE_ERROR";
export const GET_ADDITIONAL_CHARGE = "GET_ADDITIONAL_CHARGE";
export const GET_ADDITIONAL_CHARGE_SUCCESS = "GET_ADDITIONAL_CHARGE_SUCCESS";
export const GET_ADDITIONAL_CHARGE_ERROR = "GET_ADDITIONAL_CHARGE_ERROR";
export const EDIT_ADDITIONAL_CHARGE = "EDIT_ADDITIONAL_CHARGE";
export const EDIT_ADDITIONAL_CHARGE_SUCCESS = "EDIT_ADDITIONAL_CHARGE_SUCCESS";
export const EDIT_ADDITIONAL_CHARGE_ERROR = "EDIT_ADDITIONAL_CHARGE_ERROR";
export const DELETE_ADDITIONAL_CHARGE = "DELETE_ADDITIONAL_CHARGE";
export const DELETE_ADDITIONAL_CHARGE_SUCCESS =
  "DELETE_ADDITIONAL_CHARGE_SUCCESS";
export const DELETE_ADDITIONAL_CHARGE_ERROR = "DELETE_ADDITIONAL_CHARGE_ERROR";
export const DELETE_MULTIPLE_ADDITIONAL_CHARGE =
  "DELETE_MULTIPLE_ADDITIONAL_CHARGE";
export const DELETE_MULTIPLE_ADDITIONAL_CHARGE_SUCCESS =
  "DELETE_MULTIPLE_ADDITIONAL_CHARGE_SUCCESS";
export const DELETE_MULTIPLE_ADDITIONAL_CHARGE_ERROR =
  "DELETE_MULTIPLE_ADDITIONAL_CHARGE_ERROR";
export const RESET_ADDITIONAL_CHARGE = "RESET_ADDITIONAL_CHARGE";

export const getAllAdditionalCharge = () => ({
  type: GET_ALL_ADDITIONAL_CHARGE,
  payload: {},
});

export const getAllAdditionalChargeSuccess = (additionalCharges) => ({
  type: GET_ALL_ADDITIONAL_CHARGE_SUCCESS,
  payload: additionalCharges,
});

export const getAllAdditionalChargeError = (error) => ({
  type: GET_ALL_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const getAdditionalChargeList = (dbParam) => ({
  type: GET_ADDITIONAL_CHARGE_LIST,
  payload: { dbParam },
});

export const getAdditionalChargeListSuccess = (additionalChargeList) => ({
  type: GET_ADDITIONAL_CHARGE_LIST_SUCCESS,
  payload: additionalChargeList,
});

export const getAdditionalChargeListError = (error) => ({
  type: GET_ADDITIONAL_CHARGE_LIST_ERROR,
  payload: error,
});

export const addAdditionalCharge = (additionalChargeData, history) => ({
  type: ADD_ADDITIONAL_CHARGE,
  payload: { additionalChargeData, history },
});

export const addAdditionalChargeSuccess = (success, message) => ({
  type: ADD_ADDITIONAL_CHARGE_SUCCESS,
  payload: { success, message },
});

export const addAdditionalChargeError = (error) => ({
  type: ADD_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const getAdditionalCharge = (additionalChargeId) => ({
  type: GET_ADDITIONAL_CHARGE,
  payload: { additionalChargeId },
});

export const getAdditionalChargeSuccess = (additionalChargeData) => ({
  type: GET_ADDITIONAL_CHARGE_SUCCESS,
  payload: additionalChargeData,
});

export const getAdditionalChargeError = (error) => ({
  type: GET_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const editAdditionalCharge = (
  additionalChargeId,
  additionalChargeData,
  history
) => ({
  type: EDIT_ADDITIONAL_CHARGE,
  payload: { additionalChargeId, additionalChargeData, history },
});

export const editAdditionalChargeSuccess = (success, message) => ({
  type: EDIT_ADDITIONAL_CHARGE_SUCCESS,
  payload: { success, message },
});

export const editAdditionalChargeError = (error) => ({
  type: EDIT_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const deleteAdditionalCharge = (additionalChargeId) => ({
  type: DELETE_ADDITIONAL_CHARGE,
  payload: { additionalChargeId },
});

export const deleteAdditionalChargeSuccess = (success, message) => ({
  type: DELETE_ADDITIONAL_CHARGE_SUCCESS,
  payload: { success, message },
});

export const deleteAdditionalChargeError = (error) => ({
  type: DELETE_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const deleteMultipleAdditionalCharge = (additionalChargeIds) => ({
  type: DELETE_MULTIPLE_ADDITIONAL_CHARGE,
  payload: { additionalChargeIds },
});

export const deleteMultipleAdditionalChargeSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_ADDITIONAL_CHARGE_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleAdditionalChargeError = (error) => ({
  type: DELETE_MULTIPLE_ADDITIONAL_CHARGE_ERROR,
  payload: error,
});

export const resetAdditionalCharge = () => ({
  type: RESET_ADDITIONAL_CHARGE,
  payload: {},
});
