import Req from "src/interceptors/token-interceptor";

const SharedService = (function () {
  function _deactivate(id) {
    return Req.get("/api/deactivate/" + id);
  }
  function _contactUs(data) {
    return Req.post("/api/contactUs", data);
  }
  function _getSchedular() {
    return Req.get("/api/schedular");
  }
  return {
    deactivate: _deactivate,
    contactUs: _contactUs,
    getSchedular: _getSchedular,
  };
})();
export default SharedService;
