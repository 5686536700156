import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { deactivate, getUser, getUserCard } from "src/reduxs/actions";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/inner-banner-2.jpg";
import PersonalInfo from "./personal-info";
import ChangePassword from "./change-password";
import Booking from "./booking";
// import { Alert } from "src/components/dialog";

const MyAccount = (props) => {
  const params = new URLSearchParams(props.location.search);

  const [tab, setTab] = useState(params.get("tab") ? parseInt(params.get("tab")) : 0);
  // const [openAlert, setOpenAlert] = useState(false);

  const toggle = (event, value) => {
    setTab(value);
  };

  const resetTab = () => {
    setTab(0);
  };

  // const handleCloseAlert = () => {
  //   setOpenAlert(false);
  // };

  // const onDeactivate = () => {
  //   if (!props.loading) {
  //     props.deactivate(JSON.parse(localStorage.getItem("currentUser")).id, props.history);
  //   }
  // };

  useEffect(() => {
    props.getUser(JSON.parse(localStorage.getItem("currentUser")).id);
    props.getUserCard(JSON.parse(localStorage.getItem("currentUser")).id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.location.search) {
      const newParam = new URLSearchParams(props.location.search);
      setTab(newParam.get("tab") ? parseInt(newParam.get("tab")) : 0);
    } else {
      setTab(0);
    }
    // eslint-disable-next-line
  }, [props.location.search]);

  return (
    <WebsiteLayout>
      <PageMeta />

      <section className="profile-banner">
        <figure>
          <img src={bannerImg} alt="" />
        </figure>
      </section>

      <section className="profile-ui">
        <Mui.Container maxWidth="md">
          <Mui.Grid container spacing={6}>
            <Mui.Grid item xs={12} md={4}>
              <Mui.Paper className="profile-side-nav">
                <Mui.Typography component="h3" variant="h3" className="font-weight-medium">
                  My Settings
                </Mui.Typography>
                <Mui.Tabs
                  value={tab}
                  onChange={toggle}
                  orientation={Mui.isWidthDown("sm", props.width) ? "horizontal" : "vertical"}
                  variant="scrollable"
                  scrollButtons={Mui.isWidthDown("sm", props.width) ? "on" : "off"}
                  indicatorColor="primary"
                >
                  <Mui.Tab label="Personal Information" />
                  <Mui.Tab label="My Bookings" />
                  <Mui.Tab label="Settings" />
                </Mui.Tabs>
              </Mui.Paper>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={8}>
              {tab === 0 ? <PersonalInfo /> : null}

              {tab === 1 ? <Booking /> : null}

              {tab === 2 ? (
                <>
                  <ChangePassword resetTab={resetTab} />

                  {/* <Mui.Divider light={true} className="mt-5 mb-4 background-color-white" />
                  <Mui.Typography component="h3" variant="h3" className="mb-3 font-weight-medium">
                    Account Setting
                  </Mui.Typography>

                  <Mui.Button
                    color="secondary"
                    variant="contained"
                    className="btn-default white"
                    type="button"
                    onClick={() => setOpenAlert(true)}
                  >
                    Delete Account
                  </Mui.Button>

                  <Mui.Typography component="p" variant="body1" className="mt-3 text-color-grey">
                    Note: Deleting your account will lose all your data.
                  </Mui.Typography>

                  <Alert
                    open={openAlert}
                    close={handleCloseAlert}
                    action={onDeactivate}
                    title="Delete Account"
                    info="Once you delete your account, there is no going back. Please be certain."
                    awaitingInfo="Thank you for giving us a try! Account is deleting..."
                    actionBtnLabel="Delete"
                    loading={props.loading}
                    success={props.success}
                    reset={() => {}}
                  /> */}
                </>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Container>
      </section>
    </WebsiteLayout>
  );
};

const mapStateToProps = ({ shared }) => {
  const { loading, success, error } = shared;
  return { loading, success, error };
};

export default connect(mapStateToProps, {
  deactivate,
  getUser,
  getUserCard,
})(Mui.withWidth()(MyAccount));
