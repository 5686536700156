import Req from "src/interceptors/token-interceptor";

const CouponService = (function () {
  function _getAllCoupon() {
    return Req.get(`/api/coupons`);
  }
  function _getCoupon(id) {
    return Req.get("/api/coupons/" + id);
  }
  function _addCoupon(data) {
    return Req.post("/api/coupons", data);
  }
  function _editCoupon(data, id) {
    return Req.put("/api/coupons/" + id, data);
  }
  function _deleteCoupon(id) {
    return Req.delete("/api/coupons/" + id);
  }
  function _deleteMultipleCoupon(ids) {
    return Req.post("/api/coupons/delete", { ids: ids });
  }
  return {
    getAllCoupon: _getAllCoupon,
    getCoupon: _getCoupon,
    addCoupon: _addCoupon,
    editCoupon: _editCoupon,
    deleteCoupon: _deleteCoupon,
    deleteMultipleCoupon: _deleteMultipleCoupon,
  };
})();
export default CouponService;
