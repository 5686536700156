import React from "react";

const IconCalendar = () => {
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <path
              d="M279,364c0,22.056,17.944,40,40,40h47c22.056,0,40-17.944,40-40v-47c0-22.056-17.944-40-40-40h-47
            c-22.056,0-40,17.944-40,40V364z M319,317h47l0.025,46.999c0,0-0.007,0.001-0.025,0.001h-47V317z"
            />
            <circle cx="386" cy="210" r="20" />
            <circle cx="299" cy="210" r="20" />
            <path
              d="M492,352c11.046,0,20-8.954,20-20V120c0-44.112-35.888-80-80-80h-26V20c0-11.046-8.954-20-20-20
            c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20
            s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312c0,44.112,35.888,80,80,80h352c44.112,0,80-35.888,80-80
            c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H80c-22.056,0-40-17.944-40-40V120
            c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20
            c11.046,0,20-8.954,20-20V80h91v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v212
            C472,343.046,480.954,352,492,352z"
            />
            <circle cx="125" cy="384" r="20" />
            <circle cx="125" cy="210" r="20" />
            <circle cx="125" cy="297" r="20" />
            <circle cx="212" cy="297" r="20" />
            <circle cx="212" cy="210" r="20" />
            <circle cx="212" cy="384" r="20" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconCalendar;
