import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import { IconCheck, IconClose, IconSearch } from "src/components/svg";
import carPlaceholder from "src/assets/images/car.svg";

const AssignDriver = (props) => {
  const [driverId, setDriverId] = useState(null);
  const [search, setSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const { open, close, driverList, bookingData, assign, handleSearch, reset, loading, success } = props;
  useEffect(() => {
    if (success) {
      close();
      reset();
      setDriverId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onSearch = (event) => {
    setSearch(event.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleSearch(event.target.value);
      }, 1000)
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      close();
    }
  };

  return (
    <Mui.Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      classes={{ paper: "driver-assign-dialog" }}
    >
      <Mui.DialogContent>
        <Mui.Box display="flex" justifyContent="space-between" alignItems="center">
          <Mui.Typography component="h4" variant="h4">
            Assign Chauffeur
          </Mui.Typography>
          <Mui.IconButton size="small" className="svg-size-small svg-color-grey" onClick={close}>
            <IconClose />
          </Mui.IconButton>
        </Mui.Box>
        <Mui.Divider light={true} className="mt-2 mb-4" />

        <Mui.Grid container spacing={6} justifyContent="space-between">
          <Mui.Grid item xs={12} md={5}>
            <Mui.Hidden smDown>
              <Mui.Box width={200}>
                <img
                  src={
                    bookingData?.vehicleType?.vehicleTypePhoto?.[0]?.url
                      ? bookingData.vehicleType?.vehicleTypePhoto?.[0]?.url
                      : carPlaceholder
                  }
                  alt=""
                />
              </Mui.Box>
            </Mui.Hidden>
            <Mui.Typography
              component="h4"
              variant="h4"
              className={`mb-4 ${Mui.isWidthDown("sm", props.width) ? "" : "mt-2"}`}
            >
              {bookingData?.vehicleType?.name || ""}
            </Mui.Typography>

            <Mui.Typography component="h5" variant="h5" className="mb-2 font-weight-medium">
              <Mui.Typography
                component="span"
                variant="body2"
                className="d-block text-transform-uppercase text-color-grey"
              >
                Customer
              </Mui.Typography>
              {bookingData?.firstName} {bookingData?.lastName}
            </Mui.Typography>
            <Mui.Typography component="h5" variant="h5" className="mb-2 font-weight-medium">
              <Mui.Typography
                component="span"
                variant="body2"
                className="d-block text-transform-uppercase text-color-grey"
              >
                Email
              </Mui.Typography>
              {bookingData?.email || "-"}
            </Mui.Typography>

            <Mui.Typography component="h5" variant="h5" className={`mb-2 font-weight-medium`}>
              <Mui.Typography
                component="span"
                variant="body2"
                className="d-block text-transform-uppercase text-color-grey"
              >
                Contact
              </Mui.Typography>
              +61 {bookingData?.phone || ""}
            </Mui.Typography>
          </Mui.Grid>

          <Mui.Divider
            light={true}
            flexItem={Mui.isWidthDown("sm", props.width) ? false : true}
            orientation={Mui.isWidthDown("sm", props.width) ? "horizontal" : "vertical"}
            className={Mui.isWidthDown("sm", props.width) ? "w-100 mt-2 mb-2" : ""}
          />

          <Mui.Grid item xs={12} md={6}>
            <Mui.Typography component="h3" variant="h3">
              Choose Chauffeur
            </Mui.Typography>

            <Mui.Box mt={2} mb={3}>
              <Mui.FormControl variant="outlined" className="default-form-field">
                <Mui.OutlinedInput
                  value={search}
                  onChange={onSearch}
                  placeholder="Search Chauffeur"
                  startAdornment={
                    <Mui.InputAdornment position="start">
                      <IconSearch />
                    </Mui.InputAdornment>
                  }
                />
              </Mui.FormControl>
            </Mui.Box>

            <Mui.List className="driver-list">
              {driverList?.length === 0 ? (
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="font-weight-semi-bold text-center text-color-grey"
                >
                  No Drivers Found.
                </Mui.Typography>
              ) : null}

              {driverList?.map((item, i) => (
                <React.Fragment key={i}>
                  <Mui.ListItem
                    button
                    alignItems="flex-start"
                    className={driverId === item.id ? "selected" : ""}
                    onClick={() => setDriverId(item.id)}
                  >
                    <Mui.ListItemAvatar>
                      <Mui.Avatar alt={item?.name} src={item?.driverPhoto?.[0]?.url || "/static/images/avatar/1.jpg"} />
                    </Mui.ListItemAvatar>
                    <Mui.ListItemText
                      primary={
                        <>
                          <Mui.Typography component="span" variant="h5">
                            {item?.name}
                          </Mui.Typography>
                        </>
                      }
                      secondary={
                        <>
                          <Mui.Typography component="span">Licence: {item?.drivingLicenceNumber}</Mui.Typography>
                        </>
                      }
                    />
                    <span className="icon-check">
                      <IconCheck />
                    </span>
                  </Mui.ListItem>
                  <Mui.Divider light={true} className="mt-1 mb-1" />
                </React.Fragment>
              ))}
            </Mui.List>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.DialogContent>

      {loading ? (
        <Mui.LinearProgress classes={{ root: "mt-4 mb-2 progressing" }} />
      ) : (
        <Mui.Divider light={true} className="mt-4 mb-2" />
      )}

      <Mui.DialogActions>
        <Mui.Button
          onClick={() => {
            if (driverId) {
              assign(bookingData?.id, driverId);
            }
          }}
          className="btn-default"
          variant="contained"
          color="primary"
          disableElevation
          autoFocus
        >
          Confirm Chauffeur
        </Mui.Button>

        <Mui.Button onClick={close} color="primary" className="btn-default" variant="outlined">
          Cancel
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default Mui.withWidth()(AssignDriver);
