import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import InvoiceList from "./list";
import ViewInvoice from "./view";

function Invoice() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/invoice" component={InvoiceList} />
        <ContentRoute path="/invoice/view/:id" component={ViewInvoice} />
      </Switch>
    </Suspense>
  );
}

export default Invoice;
