import Req from "src/interceptors/token-interceptor";

const BookingService = (function () {
  function _getAllBooking(param) {
    return Req.get(`/api/bookings?userId=${param?.userId || ""}&orderBy=pickup_date&sortedBy=desc`);
  }
  function _getEventList(param) {
    return Req.get(`/api/bookings/filter?fromDate=${param?.fromDate || ""}&toDate=${param?.toDate || ""}`);
  }
  function _getBooking(id) {
    return Req.get("/api/bookings/" + id);
  }
  function _getBookingCost(data) {
    return Req.post(`/api/bookings/getBookingCost`, data);
  }
  function _addBooking(data) {
    return Req.post("/api/bookings", data);
  }
  function _editBooking(data, id) {
    return Req.put("/api/bookings/" + id, data);
  }
  function _modifyBooking(data, id) {
    return Req.put("/api/bookings/modify/" + id, data);
  }
  function _changeBookingStatus(data, id) {
    return Req.post("/api/bookings/status/" + id, data);
  }
  function _assignBookingDriver(data, id) {
    return Req.post("/api/bookings/driver/" + id, data);
  }
  function _modifyBookingCost(id, data) {
    return Req.put(`/api/bookings/modifyBookingCost/${id}`, data);
  }
  function _chargeCard(id) {
    return Req.get(`/api/bookings/chargeCardOnFile/${id}`);
  }
  function _sendBookingEmail(data, id) {
    return Req.post("/api/bookings/sendEmail/" + id, data);
  }
  function _deleteBooking(id) {
    return Req.delete("/api/bookings/" + id);
  }
  function _deleteMultipleBooking(ids) {
    return Req.post("/api/bookings/delete", { ids: ids });
  }
  return {
    getAllBooking: _getAllBooking,
    getEventList: _getEventList,
    getBooking: _getBooking,
    getBookingCost: _getBookingCost,
    modifyBookingCost: _modifyBookingCost,
    addBooking: _addBooking,
    editBooking: _editBooking,
    modifyBooking: _modifyBooking,
    changeBookingStatus: _changeBookingStatus,
    assignBookingDriver: _assignBookingDriver,
    chargeCard: _chargeCard,
    sendBookingEmail: _sendBookingEmail,
    deleteBooking: _deleteBooking,
    deleteMultipleBooking: _deleteMultipleBooking,
  };
})();
export default BookingService;
