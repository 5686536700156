import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import ConsolidateInvoiceList from "./list";
import ViewConsolidateInvoice from "./view";

function ConsolidateInvoice() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/consolidate-invoice" component={ConsolidateInvoiceList} />
        <ContentRoute path="/consolidate-invoice/view/:id" component={ViewConsolidateInvoice} />
      </Switch>
    </Suspense>
  );
}

export default ConsolidateInvoice;
