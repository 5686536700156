import Req from "src/interceptors/token-interceptor";

const InvoiceService = (function () {
  function _getAllInvoice(param) {
    return Req.get(`/api/invoices?jobNo=${param?.jobNo || ""}`);
  }
  function _getInvoice(id) {
    return Req.get("/api/invoices/" + id);
  }
  function _addInvoice(data) {
    return Req.post("/api/invoices", data);
  }
  function _downloadInvoice(id) {
    return Req.get(`/api/invoices/download/${id}`, {
      responseType: "arraybuffer",
    });
  }
  function _deleteInvoice(id) {
    return Req.delete("/api/invoices/" + id);
  }
  function _deleteMultipleInvoice(ids) {
    return Req.post("/api/invoices/delete", { ids: ids });
  }
  return {
    getAllInvoice: _getAllInvoice,
    getInvoice: _getInvoice,
    addInvoice: _addInvoice,
    downloadInvoice: _downloadInvoice,
    deleteInvoice: _deleteInvoice,
    deleteMultipleInvoice: _deleteMultipleInvoice,
  };
})();
export default InvoiceService;
