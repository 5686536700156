import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ALL_DRIVER,
  GET_DRIVER_LIST,
  ADD_DRIVER,
  GET_DRIVER,
  EDIT_DRIVER,
  DELETE_DRIVER,
  DELETE_MULTIPLE_DRIVER,
} from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import DriverService from "src/services/driver-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllDriverSuccess,
  getAllDriverError,
  getDriverList,
  getDriverListSuccess,
  getDriverListError,
  addDriverSuccess,
  addDriverError,
  getDriverSuccess,
  getDriverError,
  editDriverSuccess,
  editDriverError,
  deleteDriverSuccess,
  deleteDriverError,
  deleteMultipleDriverSuccess,
  deleteMultipleDriverError,
} from "./action";

export function* watchGetAllDriver() {
  yield takeEvery(GET_ALL_DRIVER, getAllDriver);
}

const getAllDriverAsync = async (param) => {
  return DriverService.getAllDriver(param);
};

function* getAllDriver({ payload }) {
  try {
    const response = yield call(getAllDriverAsync, payload.param);
    if (response.data.success) {
      yield put(getAllDriverSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllDriverError(error.response.data.message));
  }
}

export function* watchGetDriverList() {
  yield takeEvery(GET_DRIVER_LIST, getDriverListAc);
}

const getDriverListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "drivers",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getDriverListAc({ payload }) {
  try {
    const response = yield call(getDriverListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getDriverListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getDriverListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getDriverListError(error.response.data.message));
  }
}

export function* watchAddDriver() {
  yield takeEvery(ADD_DRIVER, addDriver);
}

const addDriverAsync = async (data) => {
  return DriverService.addDriver(data);
};

function* addDriver({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addDriverAsync, payload.driverData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addDriverSuccess(response.data.success, response.data.message));
      history.push(`/driver`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addDriverError(error.response.data.message));
  }
}

export function* watchGetDriver() {
  yield takeEvery(GET_DRIVER, getDriver);
}

const getDriverAsync = async (id) => {
  return DriverService.getDriver(id);
};

function* getDriver({ payload }) {
  try {
    const response = yield call(getDriverAsync, payload.driverId);
    if (response.data.success) {
      yield put(getDriverSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getDriverError(error.response.data.message));
  }
}

export function* watchEditDriver() {
  yield takeEvery(EDIT_DRIVER, editDriver);
}

const editDriverAsync = async (data, id) => {
  return DriverService.editDriver(data, id);
};

function* editDriver({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(editDriverAsync, payload.driverData, payload.driverId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editDriverSuccess(response.data.success, response.data.message));
      history.push(`/driver`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editDriverError(error.response.data.message));
  }
}

export function* watchDeleteDriver() {
  yield takeEvery(DELETE_DRIVER, deleteDriver);
}

const deleteDriverAsync = async (id) => {
  return DriverService.deleteDriver(id);
};

function* deleteDriver({ payload }) {
  try {
    const response = yield call(deleteDriverAsync, payload.driverId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteDriverSuccess(response.data.success, response.data.message));
      // Fetch updated driver list
      yield put(getDriverList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteDriverError(error.response.data.message));
  }
}

export function* watchDeleteMultipleDriver() {
  yield takeEvery(DELETE_MULTIPLE_DRIVER, deleteMultipleDriver);
}

const deleteMultipleDriverAsync = async (ids) => {
  return DriverService.deleteMultipleDriver(ids);
};

function* deleteMultipleDriver({ payload }) {
  try {
    const response = yield call(deleteMultipleDriverAsync, payload.driverIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleDriverSuccess(response.data.success, response.data.message));
      // Fetch updated driver list
      yield put(getDriverList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleDriverError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleDriverError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllDriver),
    fork(watchGetDriverList),
    fork(watchAddDriver),
    fork(watchGetDriver),
    fork(watchEditDriver),
    fork(watchDeleteDriver),
    fork(watchDeleteMultipleDriver),
  ]);
}
