import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/special-events.jpg";
import { IconCarFront } from "src/components/svg";
import specialEventImage1 from "src/assets/images/website/services/special-events-1.jpg";
// import specialEventImage2 from "src/assets/images/website/services/special-events-2.jpg";
import specialEventImage3 from "src/assets/images/website/services/special-events-3.jpg";

class SpecialEvent extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Hire our chauffeured luxury vehicles for big events like the Melbourne Cup, Sydney footy final, Perth Festival or Brisbane Riverfire. Our drivers can also take you to formals, bucks nights, anniversary parties and other special life events" />

        <section className="inner-banner" style={{ height: "42vh" }}>
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Special Events
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Special Events
            </Mui.Typography>
          </Mui.Container>
        </section>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Every major city in Australia has special events that you
                  don’t want to miss, whether it’s Riverfire in Brisbane, the
                  Festival of Perth, the footy final in Sydney or the Melbourne
                  Cup.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Throughout the year, there are fun personal events to attend
                  as well, like school formals, anniversary dinners and bucks
                  nights.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  No matter what big event you’re heading out to, get there in
                  style with Shangri-La Chauffeurs. When you’re dressed to the
                  nines, we’ll match your style with a sleek, luxurious vehicle
                  and well-dressed chauffer. Our drivers are fully licensed,
                  well presented and very professional.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  For a romantic night with your loved one, we’ll even organise
                  flowers and champagne for the journey.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={specialEventImage1}
                  alt="Special Events"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={specialEventImage3}
                  alt="Special Events"
                />
              </Mui.Grid>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We offer a range of vehicles from sporty sedans to stretch
                  limousines and fun people movers. So, if you have a large
                  group, you won’t have to split up into different cars – we’ll
                  find you one vehicle to fit everyone. You won’t need a
                  designated driver!
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  You don’t want the party to start without you, so we’re
                  committed to providing prompt, reliable service every time. We
                  will always be there waiting when you’re ready to go.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Traffic can be crazy for big events, but our local drivers
                  always know the best routes and use apps to keep track of the
                  traffic. So they’ll get you there on time and you won’t have
                  to find a car park.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We’ll pick you up from your home or office doorstep and be
                  ready when you want to be dropped back again. We can even
                  schedule different drop-off locations for different
                  passengers.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  When you contact us to book your vehicle, just tell us your
                  plans and we’ll find you the best vehicle for your needs.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Traffic and parking can take the fun out of a big special
                  event. But, at Shangri-La Chauffeurs, we’ll keep the party
                  going all night
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box display="flex" justifyContent="center" mt={10} mb={10}>
          <Mui.Button
            component={Link}
            // to={"/ride"}
            to="/contact"
            className="btn-default"
            color="primary"
            variant="contained"
            disableElevation
          >
            Book Now
            <span className="ml-1 line-height-null">
              <IconCarFront />
            </span>
          </Mui.Button>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}

export default Mui.withWidth()(SpecialEvent);
