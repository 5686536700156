import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import moment from "moment";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import { Field } from "formik";
import Square from "src/components/square";
import { formatCurrency } from "src/helpers/util";

const Summary = (props) => {
  const [directions, setDirections] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const directionsService = new window.google.maps.DirectionsService();

  const filter = (arr) => {
    const el = JSON.stringify(arr);
    let newArr = JSON.parse(el);
    newArr = arr.slice(1, -1);
    return newArr.map(({ id, routeId, ...rest }) => ({ ...rest }));
  };

  useEffect(() => {
    if (props.values.pickupLocation) {
      directionsService.route(
        {
          origin: props.values.pickupLocation,
          destination: props.values.dropOffLocation ? props.values.dropOffLocation : props.values.pickupLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          waypoints: props.values.waypoint,
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`Error fetching directions ${result}`);
          }
        }
      );
    }
    if (props.values.routeId) {
      // Set direction, totalDistance and totalTime according to route
      let ol = props.routes?.find((x) => x.id === props.values.routeId)?.routeWaypoint;
      if (ol?.length > 0 && ol[0].location) {
        directionsService.route(
          {
            origin: ol[0].location,
            destination: ol[ol.length - 1].location,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
            waypoints: filter(ol),
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error(`Error fetching directions ${result}`);
            }
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.pickupLocation, props.values.dropOffLocation, props.values.waypoint, props.values.routeId]);

  const bookNow = () => {
    props.formikRef?.current?.handleSubmit();
  };

  const MapWithAMarker = withGoogleMap(() => (
    <GoogleMap defaultZoom={13}>{directions && <DirectionsRenderer directions={directions} />}</GoogleMap>
  ));
  return (
    <>
      <Mui.Typography
        component="p"
        variant="h5"
        className="w-80 mt-3 mb-5 ml-auto mr-auto text-color-white font-weight-normal text-center line-height-2x"
      >
        Note: Any additional time exceeded from the booked hours at final drop-off location, additional charges will be
        added and payable on the final invoice.
      </Mui.Typography>

      <Mui.Grid container spacing={6}>
        <Mui.Grid item xs={12} md={4} className={`${Mui.isWidthDown("sm", props.width) ? "mb-4" : ""}`}>
          <Mui.Paper className="default-card-dark-card mb-3 p-2 border-1 border-color-primary-dark shadow-none">
            <Mui.Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                Contact Info
              </Mui.Typography>
              <Mui.Button
                color="primary"
                variant="contained"
                type="button"
                size="small"
                onClick={props.goToStep(2)}
                className="text-color-white text-transform-none"
              >
                Edit
              </Mui.Button>
            </Mui.Box>

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                NAME
              </Mui.Typography>
              {props.values.firstName} {props.values.lastName}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                EMAIL ADDRESS
              </Mui.Typography>
              {props.values.email}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PHONE NUMBER
              </Mui.Typography>
              +61 {props.values.phone}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.passengerNames ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.passengerNames ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PASSENGER NAMES
              </Mui.Typography>
              {props.values.passengerNames}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.passengerPhones ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.passengerPhones ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PASSENGER PHONE NUMBERS
              </Mui.Typography>
              {props.values.passengerPhones}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.alternatePhone ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.alternatePhone ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                ALTERNATE PHONE NUMBER
              </Mui.Typography>
              +61 {props.values?.alternatePhone || ""}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.comment ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.comment ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                EXTRA LUGGAGE COMMENT
              </Mui.Typography>
              {props.values.comment}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.additionalComment ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.additionalComment ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                ADDITIONAL NOTE/COMMENT
              </Mui.Typography>
              {props.values.additionalComment}
            </Mui.Typography>
          </Mui.Paper>

          <Mui.Paper className="default-card-dark-card p-2 border-1 border-color-primary-dark shadow-none">
            <Mui.Box display="flex">
              <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                Apply Coupon
              </Mui.Typography>
            </Mui.Box>
            <Mui.Grid container spacing={3} className="pos-relative">
              {props.loading || props.loading3 ? (
                <div className="w-100 h-100 pos-absolute pos-top-per-0 pos-left-per-0 z-index-10" />
              ) : null}
              <Mui.Grid item xs={8} md={8} className={`form-group-dark`}>
                <Mui.TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Enter Coupon Code"
                  value={couponCode}
                  onChange={(event) => setCouponCode(event.target.value)}
                />
              </Mui.Grid>
              <Mui.Grid item xs={4} md={4}>
                <Mui.Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => {
                    if (couponCode) {
                      props.applyCoupon(couponCode, props.values);
                    }
                  }}
                  className="text-color-white text-transform-none mt-3 pb-1"
                >
                  Apply
                  {props.loading3 && <Mui.CircularProgress color="secondary" className="ml-1" size={15} />}
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Paper>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={4} className={`${Mui.isWidthDown("sm", props.width) ? "mb-4" : ""}`}>
          <Mui.Paper className="default-card-dark-card p-2 border-1 border-color-primary-dark shadow-none">
            <MapWithAMarker
              containerElement={<div className="map-item" />}
              mapElement={<div className="map-item" style={{ height: "250px" }} />}
            />
            <Mui.Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={3}>
              <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                Ride details
              </Mui.Typography>
              <Mui.Button
                color="primary"
                variant="contained"
                type="button"
                size="small"
                onClick={props.goToStep(0)}
                className="text-color-white text-transform-none"
              >
                Edit
              </Mui.Button>
            </Mui.Box>
            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                SERVICE TYPE
              </Mui.Typography>
              {props.values.type === "distance" ? "Point to Point" : null}
              {props.values.type === "hourly" ? "Hourly" : null}
              {props.values.type === "flat_rate" ? "Flat Rate" : null}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                REFERENCE NUMBER
              </Mui.Typography>
              {props.values.referenceNumber || "-"}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP LOCATION
              </Mui.Typography>
              {props.values.pickupLocation}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DROP-OFF LOCATION
              </Mui.Typography>
              {props.values.dropOffLocation}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                props.values.type === "flat_rate" ? "" : "d-none"
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                ROUTE
              </Mui.Typography>
              {props.routes?.find((x) => x.id === props.values.routeId)?.name || ""}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.type === "flat_rate" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP DATE, TIME
              </Mui.Typography>
              {moment(props.values.pickupDate).format("DD/MM/YYYY")},{" "}
              {moment(props.values.pickupTime, "HH:mm:ss").format("HH:mm")}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />
            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.type === "distance" ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                FLIGHT INFORMATION
              </Mui.Typography>
              <span className="text-transform-capitalize">{props.values?.flightType || "-"}</span>{" "}
              {props.values?.flightNumber}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.type === "distance" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${props.values.type === "hourly" ? "" : "d-none"}`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DURATION IN HOUR
              </Mui.Typography>
              {moment(props.values.durationInHour, "HH:mm:ss").format("H")} hour(s)
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${props.values.type === "hourly" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={3}>
              <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                Vehicle details
              </Mui.Typography>
              <Mui.Button
                color="primary"
                variant="contained"
                type="button"
                size="small"
                onClick={props.goToStep(1)}
                className="text-color-white text-transform-none"
              >
                Edit
              </Mui.Button>
            </Mui.Box>
            <Mui.Typography className="mt-3 mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                VEHICLE
              </Mui.Typography>
              {props.carList?.find((x) => x.id === props.values.vehicleTypeId)?.name || ""}
            </Mui.Typography>
          </Mui.Paper>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={4}>
          <Mui.Paper className="default-card-dark-card mb-3 p-2 border-1 border-color-primary-dark shadow-none">
            <Mui.Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                Booking Charge
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1">
              <Mui.Typography className="font-weight-normal mr-1 text-color-white" component="h6" variant="h6">
                TOTAL DISTANCE
              </Mui.Typography>

              <Mui.Typography className="font-weight-normal text-color-white">
                {props.values.totalDistance} km
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1">
              <Mui.Typography className="font-weight-normal mr-1 text-color-white" component="h6" variant="h6">
                APPROX ETA
              </Mui.Typography>

              <Mui.Typography className="font-weight-normal text-color-white">
                {moment(props.values.totalTime, "HH:mm:ss").format("H")}h{" "}
                {moment(props.values.totalTime, "HH:mm:ss").format("m")}m
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1">
              <Mui.Typography className="font-weight-normal mr-1 text-color-white" component="h6" variant="h6">
                SUBTOTAL
              </Mui.Typography>

              <Mui.Typography className="font-weight-normal text-color-white">
                {formatCurrency(props.priceData?.subTotal)}
              </Mui.Typography>
            </Mui.Box>

            {props.couponApplied ? (
              <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1 text-color-success">
                <Mui.Typography className="font-weight-normal mr-1" component="h6" variant="h6">
                  COUPON: {props.priceData?.coupon || ""}
                </Mui.Typography>
                <Mui.Button
                  className="svg-color-white p-0 text-color-danger line-height-null"
                  onClick={() => {
                    props.calculateBookingPrice(props.values);
                    props.resetCouponApplied();
                    setCouponCode("");
                  }}
                >
                  Remove
                </Mui.Button>

                <Mui.Typography className="font-weight-normal ml-auto">
                  - {formatCurrency(props.priceData?.couponDiscount || 0)}
                </Mui.Typography>
              </Mui.Box>
            ) : null}

            <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1">
              <Mui.Typography className="font-weight-normal mr-1 text-color-success" component="h6" variant="h6">
                TOTAL
              </Mui.Typography>

              <Mui.Typography className="font-weight-normal text-color-success">
                {formatCurrency(props.priceData?.total)}
              </Mui.Typography>
            </Mui.Box>
            {!props.userCard?.patron ? (
              <Mui.Box width="100%" display="flex" justifyContent="space-between" className="p-1">
                <Mui.Typography className="font-weight-normal mr-1 text-color-success" component="h6" variant="h6">
                  TO PAY (20% Deposit)
                </Mui.Typography>

                <Mui.Typography className="font-weight-normal text-color-success">
                  {formatCurrency(props.priceData?.total > 0 ? ((20 / 100) * props.priceData?.total).toFixed(2) : 0)}
                </Mui.Typography>
              </Mui.Box>
            ) : null}
          </Mui.Paper>

          {props.values.vehicleTypeId && props.priceData?.total > 0 ? (
            <Mui.Paper className="default-card-dark-card mb-3 p-2 border-1 border-color-primary-dark shadow-none">
              <Mui.Box display="flex" mb={2}>
                <Mui.Typography className="text-color-white font-weight-normal" component="h4" variant="h4">
                  Payment
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box mb={1}>
                <Mui.Typography className="text-color-white font-weight-normal" component="h6" variant="h6">
                  Card on File is created in Square to process remaining payment.
                </Mui.Typography>
              </Mui.Box>

              {props.userCard?.card?.[0]?.cardId ? (
                <>
                  <Mui.Box mb={1}>
                    <Field name="saveCardOnFile">
                      {({ field }) => (
                        <Mui.FormControl className="align-self-start" variant="outlined">
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                className="default-checkbox-dark mr-1"
                                {...field}
                                color="primary"
                                checked={props.values.useCardOnFile ? true : false}
                                onChange={(event) => props.setFieldValue("useCardOnFile", event.target.checked ? 1 : 0)}
                              />
                            }
                            label={"Use existing Card-on-file"}
                            className="text-color-white"
                          />
                        </Mui.FormControl>
                      )}
                    </Field>
                  </Mui.Box>
                  {props.values.useCardOnFile ? (
                    <>
                      <Mui.Card className="virtual-card">
                        <Mui.Typography component="span" variant="body1" className="card-type">
                          {props.userCard?.card?.[0]?.squareCard?.card_brand?.replace("_", " ") || "-"}
                        </Mui.Typography>

                        <Mui.Typography component="h4" variant="h4" className="card-number">
                          **** **** **** {props.userCard?.card?.[0]?.squareCard?.last_4 || "****"}
                        </Mui.Typography>

                        <Mui.Typography component="span" variant="body1" className="card-expiry">
                          {props.userCard?.card?.[0]?.squareCard?.exp_month || "-"}/
                          {props.userCard?.card?.[0]?.squareCard?.exp_year || "-"}
                        </Mui.Typography>

                        <Mui.Typography component="h5" variant="h5" className="card-holder">
                          {props.userCard?.card?.[0]?.squareCard?.cardholder_name || "-"}
                        </Mui.Typography>
                      </Mui.Card>

                      <Mui.Box width="100%" display="flex" justifyContent="center" className="mt-3">
                        <Mui.Button
                          className="w-100 pt-2 pb-2 btn-default flex-shrink-0"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={props.loading}
                        >
                          Book Now
                          {props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                        </Mui.Button>
                      </Mui.Box>
                    </>
                  ) : (
                    <Square setFieldValue={props.setFieldValue} bookNow={bookNow} />
                  )}
                </>
              ) : (
                <Square setFieldValue={props.setFieldValue} bookNow={bookNow} />
              )}

              {props.loading ? (
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="mt-2 font-weight-medium text-color-primary text-center"
                >
                  Please wait booking on-progress...
                </Mui.Typography>
              ) : null}
            </Mui.Paper>
          ) : (
            <Mui.Typography
              component="p"
              variant="h5"
              className="mt-3 text-color-white text-center font-weight-normal line-height-2x"
            >
              All steps must be completed to book a ride.
            </Mui.Typography>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default Mui.withWidth()(Summary);
