import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { IconClose, IconCollpasedMenu, IconCarFront } from "src/components/svg";
import Logo from "src/assets/images/logo.svg";
import CustomerDropdown from "./customer-dropdown";

const BookRideButton = Mui.withStyles((theme) => ({
  root: {
    maxWidth: "55px",
    minWidth: "initial",
    height: "55px",
    position: "fixed",
    bottom: "26px",
    right: "110px",
    border: "1px solid #d5ab53",
    borderRadius: "50%",
    boxShadow: "0 3px 6px rgb(213 171 83 / 49%)",
    background: "linear-gradient(to left, #e7b950 0%, #96702f 100%)",
    color: "#fff",
    textTransform: "none",
    zIndex: 999,
    transition: "600ms ease",

    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },

    "& svg": {
      flexShrink: 0,
    },

    "& small": {
      maxWidth: 0,
      opacity: 0,
      oveflow: "hidden",
    },

    "@media(max-width: 1023px)": {
      "& small": {
        maxWidth: "initial",
        position: "absolute",
        top: "-25px",
        opacity: 1,
        backgroundColor: "#55182f",
        whiteSpace: "nowrap",
        padding: "2px 8px",
        borderRadius: "3px",
        fontSize: "10px",
        fontWeight: "600",
      },
    },

    "&:hover": {
      maxWidth: "800px",
      borderRadius: "16px",
      color: "#fff",

      "& small": {
        maxWidth: "500px",
        marginRight: "10px",
        opacity: 1,
        transition: "width 300ms ease, opacity 500ms ease",
      },
    },
  },
}))(Mui.Button);
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerFixed: false,
      toggleMobileMenu: false,
      anchorEl: null,
    };
  }

  handleScroll = (event) => {
    let scrollTop = window.scrollY;
    if (scrollTop > 30) {
      this.setState({ headerFixed: true });
    } else {
      this.setState({ headerFixed: false });
    }
  };

  toggleMenu = () => {
    const bodySelector = document.querySelector("body");
    bodySelector.classList.toggle("mobile-menu-open");

    this.setState({ toggleMobileMenu: !this.state.toggleMobileMenu });
  };

  closeMobileMenu = () => {
    const bodySelector = document.querySelector("body");
    bodySelector.classList.remove("mobile-menu-open");

    this.setState({ toggleMobileMenu: false, anchorEl: null });
  };

  onOpenSubmenu = (event) => {
    event.preventDefault(0);
    this.setState({ anchorEl: event.currentTarget });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  render() {
    return (
      <header className={`main-header ${this.state.headerFixed ? "fixed" : ""}`}>
        <Mui.Box display="flex" alignItems="center" className="main-header__main">
          <Mui.Container maxWidth="md">
            <Mui.Box
              className="nav-wrapper"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <NavLink className={`logo ${this.state.toggleMobileMenu ? "expand-logo" : ""}`} exact to={"/"}>
                <img src={Logo} alt="" />
              </NavLink>

              <nav className={`main-header__nav ${Mui.isWidthDown("sm", this.props.width) && "mobile-menu"}`}>
                <NavLink exact to={"/"} onClick={() => this.closeMobileMenu()}>
                  Home
                </NavLink>
                <NavLink exact to={"/about"} onClick={() => this.closeMobileMenu()}>
                  About Us
                </NavLink>

                <a href="/" onClick={this.onOpenSubmenu}>
                  Services
                </a>

                <NavLink
                  exact
                  // to={"/ride"}
                  to={"/contact"}
                  onClick={() => this.closeMobileMenu()}
                >
                  Book A Ride
                </NavLink>

                {/* <NavLink
                  to={"/our-fleet"}
                  onClick={() => this.closeMobileMenu()}
                >
                  Our Fleet
                </NavLink> */}
                <NavLink exact to={"/faq"} onClick={() => this.closeMobileMenu()}>
                  FAQs
                </NavLink>
                <NavLink exact to={"/contact"} onClick={() => this.closeMobileMenu()}>
                  Contact
                </NavLink>

                <Mui.Popover
                  classes={{ paper: "submenu-wrapper" }}
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={() => this.setState({ anchorEl: null })}
                >
                  <Mui.MenuItem component={Link} to={"/airport-transfers"} onClick={() => this.closeMobileMenu()}>
                    Airport Transfers
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/business-transfers"} onClick={() => this.closeMobileMenu()}>
                    Business Transfers
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/wedding-vechile"} onClick={() => this.closeMobileMenu()}>
                    Wedding Vehicles
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/tour"} onClick={() => this.closeMobileMenu()}>
                    Tours
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/special-event"} onClick={() => this.closeMobileMenu()}>
                    Special Events
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/terms-condition"} onClick={() => this.closeMobileMenu()}>
                    Terms & Conditions
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={"/privacy-policy"} onClick={() => this.closeMobileMenu()}>
                    Privacy Policy
                  </Mui.MenuItem>
                </Mui.Popover>
              </nav>

              <Mui.Box
                display="flex"
                alignItems="center"
                className={`${Mui.isWidthDown("sm", this.props.width) && "ml-auto"}`}
              >
                {this.props.user ? (
                  <>
                    <CustomerDropdown />

                    {window.location.pathname !== "/ride" ? (
                      <BookRideButton
                        type="button"
                        color="primary"
                        variant="contained"
                        component={Link}
                        // to="/ride"
                        to="/contact"
                        className="svg-color-white"
                      >
                        <small>Book A Ride</small>
                        <IconCarFront />
                      </BookRideButton>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Mui.Button
                      className="btn-default border-rad-10 border-color-white text-color-white"
                      color="primary"
                      variant="outlined"
                      to={"/auth/login"}
                      component={Link}
                      onClick={() => this.closeMobileMenu()}
                    >
                      Sign In
                    </Mui.Button>
                    <Mui.Button
                      className="btn-default ml-2 border-rad-10"
                      color="primary"
                      variant="contained"
                      to={"/auth/register"}
                      component={Link}
                      onClick={() => this.closeMobileMenu()}
                    >
                      Sign Up
                    </Mui.Button>
                  </>
                )}

                <Mui.Hidden mdUp>
                  <Mui.IconButton
                    className="btn-hamburger svg-color-white"
                    size="small"
                    disableRipple
                    onClick={this.toggleMenu}
                  >
                    {this.state.toggleMobileMenu ? <IconClose /> : <IconCollpasedMenu />}
                  </Mui.IconButton>
                </Mui.Hidden>
              </Mui.Box>
            </Mui.Box>
          </Mui.Container>
        </Mui.Box>
      </header>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(Header));
