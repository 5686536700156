import React from "react";
import * as Mui from "@material-ui/core";
import moment from "moment";
import { formatCurrency } from "src/helpers/util";
import carPlaceholder from "src/assets/images/car.svg";
import {
  IconDriver,
  IconArrowRight,
  IconCalendar,
  IconCarFront,
  IconTime,
  IconInfo,
  IconPhone,
} from "src/components/svg";

const BookingCard = (props) => {
  const {
    data,
    enableCarImage = false,
    UI, // normal/outlined
    classes,
    ...other
  } = props;

  return (
    <Mui.Paper className={`booking-list-card ${UI || ""} ${data?.status} ${classes || ""}`} {...other}>
      {enableCarImage && (
        <Mui.Hidden xsDown>
          <figure>
            <img
              src={
                data?.vehicleType?.vehicleTypePhoto?.[0]?.url
                  ? data?.vehicleType?.vehicleTypePhoto?.[0]?.url
                  : carPlaceholder
              }
              alt=""
            />
          </figure>
        </Mui.Hidden>
      )}

      <Mui.Box
        width={Mui.isWidthDown("xs", props.width) || !enableCarImage ? "100%" : "76%"}
        display="flex"
        flexDirection="column"
      >
        <Mui.Box display="flex" flexWrap="wrap">
          <Mui.Chip
            className={`${Mui.isWidthDown("xs", props.width) || !enableCarImage ? "w-100 justify-content-start" : ""}`}
            icon={<IconInfo />}
            label={data?.bookingNumber || ""}
          />
          <Mui.Chip icon={<IconCalendar />} label={moment(data?.pickupDate).format("DD/MM/YYYY")} />
          <Mui.Chip icon={<IconTime />} label={moment(data?.pickupTime, "HH:mm:ss").format("HH:mm")} />

          <Mui.Typography
            component="h3"
            variant="h3"
            className={`font-weight-medium text-color-primary ${
              Mui.isWidthDown("xs", props.width) ? "mt-2 mb-2" : "ml-auto"
            }`}
          >
            {formatCurrency(data?.total)}
          </Mui.Typography>
        </Mui.Box>

        <Mui.Box
          className="booking-list-card__direction"
          display="flex"
          flexDirection={Mui.isWidthDown("xs", props.width) ? "column" : "row"}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Mui.Typography component="p" variant="body1">
            {data?.type === "flat_rate" ? data?.route?.name || "-" : data?.pickupLocation || "-"}
          </Mui.Typography>

          {data?.type !== "flat_rate" ? <IconArrowRight /> : null}

          <Mui.Typography
            component="p"
            variant="body1"
            className={`${Mui.isWidthDown("xs", props.width) ? "" : "text-right"}`}
          >
            {data?.type === "flat_rate" ? "" : data?.dropOffLocation || "-"}
          </Mui.Typography>
        </Mui.Box>

        <Mui.Box display="flex" alignItems="center" className="mt-2 text-color-primary">
          <Mui.Typography component="p" variant="body1" className="d-flex align-items-center">
            <span className="mr-1 svg-color-primary">
              <IconCarFront />
            </span>
            {data?.vehicleType?.name || "-"}
          </Mui.Typography>

          {data?.driver?.name && (
            <Mui.Typography
              component="p"
              variant="body1"
              className={`d-flex align-items-center ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"}`}
            >
              <span className="mr-1 svg-color-primary">
                <IconDriver />
              </span>
              {data?.driver?.name}
            </Mui.Typography>
          )}

          <a
            href={`tel:${data.driver?.phone || ""}`}
            className={`d-flex align-items-center text-color-primary 
            ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"}`}
          >
            <span
              className={`mr-1 svg-size-small svg-color-primary ${UI === "outlined" ? "" : "svg-color-white-hover"}`}
            >
              <IconPhone />
            </span>
          </a>
        </Mui.Box>
      </Mui.Box>

      <span
        className={`text-transform-capitalize status-bg ${data?.status === "pending" ? "warn" : ""} ${
          data?.status === "cancelled" ? "danger" : ""
        } ${data?.status === "confirmed" ? "success" : ""} ${data?.status === "completed" ? "disabled" : ""}`}
      >
        {data?.status || "-"}
      </span>
    </Mui.Paper>
  );
};

export default Mui.withWidth()(BookingCard);
