import React, { Component } from "react";
import PageMeta from "src/components/pages-meta";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import { WebsiteLayout } from "src/layouts";
import { IconCarFront } from "src/components/svg";
import bannerImg from "src/assets/images/website/tour-banner.jpg";
import tourImage1 from "src/assets/images/website/services/tours-1.jpg";
import tourImage2 from "src/assets/images/website/services/tours-2.jpg";
import tourImage3 from "src/assets/images/website/services/tours-3.jpg";

class Tour extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Our comfortable and stylish chauffeured vehicles are perfect for sightseeing and driving long distances. Design your own tour of Perth, Adelaide, Sydney, Melbourne or Brisbane and we will take you there." />

        <section className="inner-banner" style={{ height: "42vh" }}>
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Tours
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Tours
            </Mui.Typography>
          </Mui.Container>
        </section>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  It’s hard to enjoy a sightseeing trip when you’re driving
                  through unfamiliar areas and getting lost on back roads.
                  That’s why, at Shangri-La Chauffeurs, we offer personalised
                  chauffeured tours in every major city in Australia and New
                  Zealand.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Just tell us where you’d like to go and we’ll design a tour
                  for you. There are plenty of options to suit every budget.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our friendly and reliable drivers will take you wherever you
                  want to go. They know every inch of the local area – including
                  which places you must put on your list! During the trip, your
                  driver can tell you about an area’s history and landmarks. But
                  if you’d prefer a quieter drive, they are happy to oblige.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={tourImage1}
                  alt="Tours"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={tourImage2}
                  alt="Tour"
                />
              </Mui.Grid>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  All our drivers are fully licensed and trained to drive their
                  vehicle. Safety is our number one priority, so you’ll always
                  be in good hands with our professional team.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our luxurious and comfortable vehicles are perfect for
                  travelling long distances in style. Whether it’s just yourself
                  or a large group of family or friends, we’ll find you the
                  perfect vehicle for your ride. Treat yourself to a luxury
                  sedan, people mover or even a stretch limousine to really give
                  yourself room to move.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We pride ourselves on our prompt and reliable service, so you
                  tell us your schedule and we will be there every time! The
                  driver will pick you up from your doorstep and drop you back
                  again at the end of the tour.
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  So treat your family to a fun day out in the city or at the
                  beach, or take your loved one on a romantic trip to wine
                  country. The only limit is your imagination.
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Here are some ideas to get you started:
                </Mui.Typography>
                <ul className="mb-2 text-color-grey font-weight-normal line-height-1-5 font-size-md-2x">
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      Perth
                    </strong>
                    : Drive through the Margaret River wine region or head to
                    Fremantle for a beautiful day by the water.
                  </li>
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      Melbourne
                    </strong>
                    : Travel to the scenic Mornington Peninsula, enjoy the Yarra
                    Valley wine region or pop over to Phillip Island to see the
                    penguins.
                  </li>
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      Sydney
                    </strong>
                    : Take a trip up to the Blue Mountains, get lunch in the
                    Northern Beaches or explore the historic city streets around
                    Sydney Harbour.
                  </li>
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      Brisbane
                    </strong>
                    : Go in any direction – west to the Toowoomba Range, south
                    to the Gold Coast beaches or north to the relaxing Sunshine
                    Coast hinterland region.
                  </li>
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      Adelaide
                    </strong>
                    : It’s hard to go past the Barossa Valley and Adelaide Hills
                    regions, and many historic towns within a short drive.
                  </li>
                  <li>
                    <strong className="text-color-primary font-weight-semi-bold">
                      New Zealand
                    </strong>
                    : Learn about Māori culture in Rotorua, experience the
                    gorgeous scenery in Queenstown, or take a Lord of the Rings
                    tour in Matamata.
                  </li>
                </ul>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Just contact us to tell us your idea and we’ll plan the
                  perfect day out for you!
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                cclassName={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={tourImage3}
                  alt="Tour"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box display="flex" justifyContent="center" mt={10} mb={10}>
          <Mui.Button
            component={Link}
            // to={"/ride"}
            to="/contact"
            className="btn-default"
            color="primary"
            variant="contained"
            disableElevation
          >
            Book Now
            <span className="ml-1 line-height-null">
              <IconCarFront />
            </span>
          </Mui.Button>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}

export default Mui.withWidth()(Tour);
