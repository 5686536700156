import React from "react";
import * as Mui from "@material-ui/core";
import { FieldArray } from "formik";
import { IconAdd, IconTrash } from "src/components/svg";
import { InputField, SelectAutocompleteField } from "src/components/form";

const AdditionalCharge = ({ values, setFieldValue, additionalChargeList }) => {
  return (
    <Mui.Card className="default-card mt-3">
      <FieldArray
        name="additionalCharge"
        render={(arrayHelpers) => (
          <>
            <Mui.Box display="flex" alignItems="center" justifyContent="space-between" className="mb-4">
              <Mui.Typography component="h4" variant="h4" className="font-weight-medium">
                4. Enter Additional Charge
              </Mui.Typography>
              <Mui.IconButton
                className="btn-icon-square ml-2"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => {
                  arrayHelpers.push({ additionalChargeId: "", value: "" });
                }}
              >
                <IconAdd />
              </Mui.IconButton>
            </Mui.Box>

            {values?.additionalCharge?.length > 0 &&
              values.additionalCharge.map((item, index) => (
                <React.Fragment key={index}>
                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item xs={12} md={6} className="mt-0 form-group">
                      <SelectAutocompleteField
                        name={`additionalCharge.${index}.additionalChargeId`}
                        options={additionalChargeList}
                        placeholder="Select additional charge"
                        callback={(id) => {
                          setFieldValue(
                            `additionalCharge.${index}.value`,
                            additionalChargeList?.find((x) => x.id === id)?.value || ""
                          );
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={10} md={4} className="mt-0 form-group">
                      <InputField name={`additionalCharge.${index}.value`} placeholder="Enter value" type="number" />
                    </Mui.Grid>
                    <Mui.Grid item xs={2} md={2} className="text-right">
                      <Mui.IconButton
                        size="small"
                        className="mt-1 svg-color-primary"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <IconTrash />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Divider className="mb-2"/>
                </React.Fragment>
              ))}

            {values?.additionalCharge?.length === 0 && (
              <Mui.Box display="flex" alignItems="center" justifyContent="center" className="font-weight-medium">
                <Mui.Typography variant="subtitle2" component="span">
                  No Additional Charges
                </Mui.Typography>
              </Mui.Box>
            )}
          </>
        )}
      />
    </Mui.Card>
  );
};
export default AdditionalCharge;
