import Req from "src/interceptors/token-interceptor";

const PriceService = (function () {
  function _getPriceList() {
    return Req.get(`/api/vehicle_category_prices`);
  }
  function _editPrice(data, id) {
    return Req.put("/api/vehicle_category_prices/" + id, data);
  }
  return {
    getPriceList: _getPriceList,
    editPrice: _editPrice,
  };
})();
export default PriceService;
