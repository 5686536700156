export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_MULTIPLE_USER = "DELETE_MULTIPLE_USER";
export const DELETE_MULTIPLE_USER_SUCCESS = "DELETE_MULTIPLE_USER_SUCCESS";
export const DELETE_MULTIPLE_USER_ERROR = "DELETE_MULTIPLE_USER_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const SET_PATRON = "SET_PATRON";
export const SET_PATRON_SUCCESS = "SET_PATRON_SUCCESS";
export const SET_PATRON_ERROR = "SET_PATRON_ERROR";
export const SET_SHOW_COST = "SET_SHOW_COST";
export const SET_SHOW_COST_SUCCESS = "SET_SHOW_COST_SUCCESS";
export const SET_SHOW_COST_ERROR = "SET_SHOW_COST_ERROR";
export const GET_USER_UPCOMING_BOOKING_LIST = "GET_USER_UPCOMING_BOOKING_LIST";
export const GET_USER_UPCOMING_BOOKING_LIST_SUCCESS = "GET_USER_UPCOMING_BOOKING_LIST_SUCCESS";
export const GET_USER_UPCOMING_BOOKING_LIST_ERROR = "GET_USER_UPCOMING_BOOKING_LIST_ERROR";
export const GET_USER_COMPLETED_BOOKING_LIST = "GET_USER_COMPLETED_BOOKING_LIST";
export const GET_USER_COMPLETED_BOOKING_LIST_SUCCESS = "GET_USER_COMPLETED_BOOKING_LIST_SUCCESS";
export const GET_USER_COMPLETED_BOOKING_LIST_ERROR = "GET_USER_COMPLETED_BOOKING_LIST_ERROR";
export const RESET_USER = "RESET_USER";

export const getAllUser = () => ({
  type: GET_ALL_USER,
  payload: {},
});

export const getAllUserSuccess = (users) => ({
  type: GET_ALL_USER_SUCCESS,
  payload: users,
});

export const getAllUserError = (error) => ({
  type: GET_ALL_USER_ERROR,
  payload: error,
});

export const getUserList = (dbParam) => ({
  type: GET_USER_LIST,
  payload: { dbParam },
});

export const getUserListSuccess = (userList) => ({
  type: GET_USER_LIST_SUCCESS,
  payload: userList,
});

export const getUserListError = (error) => ({
  type: GET_USER_LIST_ERROR,
  payload: error,
});

export const addUser = (userData, history) => ({
  type: ADD_USER,
  payload: { userData, history },
});

export const addUserSuccess = (success, message) => ({
  type: ADD_USER_SUCCESS,
  payload: { success, message },
});

export const addUserError = (error) => ({
  type: ADD_USER_ERROR,
  payload: error,
});

export const getUser = (userId, setLocalStorage = false) => ({
  type: GET_USER,
  payload: { userId, setLocalStorage },
});

export const getUserSuccess = (userData) => ({
  type: GET_USER_SUCCESS,
  payload: userData,
});

export const getUserError = (error) => ({
  type: GET_USER_ERROR,
  payload: error,
});

export const editUser = (userId, userData, history) => ({
  type: EDIT_USER,
  payload: { userId, userData, history },
});

export const editUserSuccess = (success, message) => ({
  type: EDIT_USER_SUCCESS,
  payload: { success, message },
});

export const editUserError = (error) => ({
  type: EDIT_USER_ERROR,
  payload: error,
});

export const deleteUser = (userId) => ({
  type: DELETE_USER,
  payload: { userId },
});

export const deleteUserSuccess = (success, message) => ({
  type: DELETE_USER_SUCCESS,
  payload: { success, message },
});

export const deleteUserError = (error) => ({
  type: DELETE_USER_ERROR,
  payload: error,
});

export const deleteMultipleUser = (userIds) => ({
  type: DELETE_MULTIPLE_USER,
  payload: { userIds },
});

export const deleteMultipleUserSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_USER_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleUserError = (error) => ({
  type: DELETE_MULTIPLE_USER_ERROR,
  payload: error,
});

export const changePassword = (changePasswordData) => ({
  type: CHANGE_PASSWORD,
  payload: { changePasswordData },
});

export const changePasswordSuccess = (success, message) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: { success, message },
});

export const changePasswordError = (error) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
});

export const setPatron = (userId, patronData) => ({
  type: SET_PATRON,
  payload: { userId, patronData },
});

export const setPatronSuccess = (success, message) => ({
  type: SET_PATRON_SUCCESS,
  payload: { success, message },
});

export const setPatronError = (error) => ({
  type: SET_PATRON_ERROR,
  payload: error,
});

export const setShowCost = (userId, costData) => ({
  type: SET_SHOW_COST,
  payload: { userId, costData },
});

export const setShowCostSuccess = (success, message) => ({
  type: SET_SHOW_COST_SUCCESS,
  payload: { success, message },
});

export const setShowCostError = (error) => ({
  type: SET_SHOW_COST_ERROR,
  payload: error,
});

export const getUserUpcomingBookingList = (upcomingParam) => ({
  type: GET_USER_UPCOMING_BOOKING_LIST,
  payload: { upcomingParam },
});

export const getUserUpcomingBookingListSuccess = (upcomingBookingList) => ({
  type: GET_USER_UPCOMING_BOOKING_LIST_SUCCESS,
  payload: upcomingBookingList,
});

export const getUserUpcomingBookingListError = (error) => ({
  type: GET_USER_UPCOMING_BOOKING_LIST_ERROR,
  payload: error,
});

export const getUserCompletedBookingList = (completedParam) => ({
  type: GET_USER_COMPLETED_BOOKING_LIST,
  payload: { completedParam },
});

export const getUserCompletedBookingListSuccess = (completedBookingList) => ({
  type: GET_USER_COMPLETED_BOOKING_LIST_SUCCESS,
  payload: completedBookingList,
});

export const getUserCompletedBookingListError = (error) => ({
  type: GET_USER_COMPLETED_BOOKING_LIST_ERROR,
  payload: error,
});

export const resetUser = () => ({
  type: RESET_USER,
  payload: {},
});
