import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import {
  getUpcomingBookingList,
  getCompletedBookingList,
  getBooking,
  getAllRoute,
  getAllAdditionalCharge,
  getUserInvoiceList,
  downloadInvoice,
} from "src/reduxs/actions";
import {
  IconDriver,
  IconArrowRight,
  IconCalendar,
  IconCarFront,
  IconChevronRight,
  IconTime,
  IconInfo,
  IconPhone,
} from "src/components/svg";
import carPlaceholder from "src/assets/images/car.svg";
import Pagination from "src/components/datatable/pagination";
import Search from "src/components/search";
import DataTable from "src/components/datatable";
import { formatCurrency } from "src/helpers/util";
import BookingDetail from "./booking-detail";
import EditBooking from "./edit-booking";

class MyBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      search: "",
      search1: "",
      page: 1,
      page1: 1,
      pageSize: 10,
      pageSize1: 10,
      typingTimeout: 0,
      typingTimeout1: 0,
      bookingDetail: false,
      editBooking: false,
    };
    this.userId = JSON.parse(localStorage.getItem("currentUser")).id;
    this.searchFields =
      "booking_number:like;type:like;pickup_date:like;pickup_location:like;drop_off_location:like;vehicleType.name:like;route.name:like;driver.name:like;";
    this.searchFields1 = "booking.booking_number:like;invoice_number:like;user_id:like;";
  }

  componentDidMount() {
    this.props.getUpcomingBookingList({});
    this.props.getCompletedBookingList({});
    this.props.getAllRoute();
    this.props.getAllAdditionalCharge();
    this.props.getUserInvoiceList({ userId: this.userId });
  }

  toggle = (event, value) => {
    this.setState({ tab: value });
    if (value === 0) {
      this.props.getUpcomingBookingList({});
    } else if (value === 1) {
      this.props.getCompletedBookingList({});
    } else if (value === 2) {
      this.props.getUserInvoiceList({ userId: this.userId });
    }
  };

  handleChangePage = (page) => {
    this.setState({ page: page });
    this.props.getUpcomingBookingList({
      search: this.state.search,
      searchFields: this.searchFields,
      page: page,
      pageSize: this.state.pageSize,
    });
  };

  handleChangePage1 = (page) => {
    this.setState({ page1: page });
    this.props.getCompletedBookingList({
      search: this.state.search1,
      searchFields: this.searchFields,
      page: page,
      pageSize: this.state.pageSize1,
    });
  };

  handleChangePageSize = (pageSize) => {
    this.setState({ pageSize: pageSize });
    this.props.getUpcomingBookingList({
      search: this.state.search,
      searchFields: this.searchFields,
      page: 1,
      pageSize: pageSize,
    });
  };

  handleChangePageSize1 = (pageSize) => {
    this.setState({ pageSize1: pageSize });
    this.props.getCompletedBookingList({
      search: this.state.search1,
      searchFields: this.searchFields,
      page: 1,
      pageSize: pageSize,
    });
  };

  handleSearch = (event) => {
    let value = event.target.value;
    this.setState({ search: value });
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    let _self = this;
    this.setState((prevState) => ({
      typingTimeout: setTimeout(function () {
        _self.props.getUpcomingBookingList({
          search: value,
          searchFields: _self.searchFields,
          page: prevState.page,
          pageSize: prevState.pageSize,
        });
      }, 1000),
    }));
  };

  handleSearch1 = (event) => {
    let value = event.target.value;
    this.setState({ search1: value });
    if (this.state.typingTimeout1) {
      clearTimeout(this.state.typingTimeout1);
    }
    let _self = this;
    this.setState((prevState) => ({
      typingTimeout: setTimeout(function () {
        _self.props.getCompletedBookingList({
          search: value,
          searchFields: _self.searchFields,
          page: prevState.page1,
          pageSize: prevState.pageSize1,
        });
      }, 1000),
    }));
  };

  navigate = (id) => {
    this.props.getBooking(id);
    this.setState({ bookingDetail: true });
  };

  onChange = (search, sortOrder, page, pageSize, activeCol, fromDate, toDate, jobNo) => {
    this.props.getUserInvoiceList({
      userId: this.userId,
      search: search,
      searchFields: this.searchFields1,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      fromDate: fromDate,
      toDate: toDate,
      jobNo: jobNo,
    });
  };

  onDownload = (id) => {
    if (!this.props.downloading) {
      this.props.downloadInvoice(id);
    }
  };

  render() {
    return (
      <>
        {this.state.bookingDetail ? (
          <BookingDetail
            bookingData={this.props.bookingData}
            additionalChargeList={this.props.additionalCharges}
            onBack={() => this.setState({ bookingDetail: false })}
            onEdit={() => this.setState({ bookingDetail: false, editBooking: true })}
          />
        ) : null}

        {this.state.editBooking ? (
          <EditBooking
            bookingData={this.props.bookingData}
            onBack={() => this.setState({ bookingDetail: true, editBooking: false })}
          />
        ) : null}

        {!this.state.bookingDetail && !this.state.editBooking ? (
          <>
            <Mui.Tabs
              value={this.state.tab}
              onChange={this.toggle}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons={Mui.isWidthUp("md", this.props.width) ? "auto" : "on"}
              className="default-tab-contained"
            >
              <Mui.Tab label={"Upcoming Booking"} />
              <Mui.Tab label={"Previous Booking"} />
              <Mui.Tab label={"Invoices"} />
            </Mui.Tabs>

            {this.state.tab === 0 ? (
              <>
                <Mui.Box width="50%" className="mb-4 ml-auto">
                  <Search fieldTheme="dark" value={this.state.search} onSearch={this.handleSearch} />
                </Mui.Box>

                {this.props.upcomingBookingList?.data?.map((item, index) => {
                  return (
                    <Mui.Paper className="booking-list-card" key={index}>
                      <Mui.Hidden xsDown>
                        <figure>
                          <img
                            src={
                              item.vehicleType?.vehicleTypePhoto?.[0]?.url
                                ? item.vehicleType?.vehicleTypePhoto?.[0]?.url
                                : carPlaceholder
                            }
                            alt=""
                          />
                        </figure>
                      </Mui.Hidden>

                      <Mui.Box
                        width={Mui.isWidthDown("xs", this.props.width) ? "100%" : "76%"}
                        display="flex"
                        flexDirection="column"
                      >
                        <Mui.Box display="flex" flexWrap="wrap">
                          <Mui.Chip
                            className={`${
                              Mui.isWidthDown("xs", this.props.width) ? "w-100 justify-content-start" : ""
                            }`}
                            icon={<IconInfo />}
                            label={item.bookingNumber || ""}
                          />
                          <Mui.Chip icon={<IconCalendar />} label={moment(item.pickupDate).format("DD/MM/YYYY")} />
                          <Mui.Chip icon={<IconTime />} label={moment(item.pickupTime, "HH:mm:ss").format("HH:mm")} />

                          <Mui.Typography
                            component="h3"
                            variant="h3"
                            className={`font-weight-medium text-color-primary ${
                              Mui.isWidthDown("xs", this.props.width) ? "mt-2 mb-2" : "ml-auto"
                            }`}
                          >
                            {formatCurrency(item.total)}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Box
                          className="booking-list-card__direction"
                          display="flex"
                          flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          <Mui.Typography component="p" variant="body1">
                            {item.type === "flat_rate" ? item.route?.name || "-" : item.pickupLocation || "-"}
                          </Mui.Typography>

                          {item.type !== "flat_rate" ? <IconArrowRight /> : null}

                          <Mui.Typography
                            component="p"
                            variant="body1"
                            className={`${Mui.isWidthDown("xs", this.props.width) ? "" : "text-right"}`}
                          >
                            {item.type === "flat_rate" ? "" : item.dropOffLocation || "-"}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Box display="flex" alignItems="center" className="mt-2 text-color-primary">
                          <Mui.Typography component="p" variant="body1" className="d-flex align-items-center">
                            <span className="mr-1 svg-color-primary">
                              <IconCarFront />
                            </span>
                            {item.vehicleType?.name || "-"}
                          </Mui.Typography>

                          <Mui.Typography
                            component="p"
                            variant="body1"
                            className={`d-flex align-items-center ${
                              Mui.isWidthDown("xs", this.props.width) ? "" : "ml-2"
                            }`}
                          >
                            <span className="mr-1 svg-color-primary">
                              <IconDriver />
                            </span>
                            {item.driver?.name || "-"}
                          </Mui.Typography>

                          <a
                            href={`tel:${item.driver?.phone || ""}`}
                            className={`d-flex align-items-center text-color-primary ${
                              Mui.isWidthDown("xs", this.props.width) ? "" : "ml-2"
                            }`}
                          >
                            <span className="mr-1 svg-size-small svg-color-primary svg-color-white-hover">
                              <IconPhone />
                            </span>
                          </a>
                        </Mui.Box>
                      </Mui.Box>

                      <span
                        className={`text-transform-capitalize status-bg ${item.status === "pending" ? "warn" : ""} ${
                          item.status === "cancelled" ? "danger" : ""
                        } ${item.status === "confirmed" ? "success" : ""} ${
                          item.status === "completed" ? "disabled" : ""
                        }`}
                      >
                        {item.status || "-"}
                      </span>
                      {/* 24hrs prior */}
                      {moment.duration(moment(item.pickupDate).diff(moment(new Date()))).asHours() > 24 &&
                      (item.status === "pending" || item.status === "confirmed") &&
                      item.payment_status !== "paid" ? (
                        <Mui.Button
                          variant="contained"
                          color="secondary"
                          className="btn-default pos-right-per-25"
                          onClick={() => {
                            this.navigate(item.id);
                            this.setState({
                              bookingDetail: false,
                              editBooking: true,
                            });
                          }}
                        >
                          Modify Booking
                        </Mui.Button>
                      ) : null}
                      <Mui.Button
                        variant="contained"
                        color="primary"
                        className="btn-default"
                        onClick={() => this.navigate(item.id)}
                      >
                        Show Detail
                        <IconChevronRight />
                      </Mui.Button>
                    </Mui.Paper>
                  );
                })}

                {this.props.upcomingBookingList?.data?.length === 0 ? (
                  <Mui.Typography
                    component="h6"
                    variant="h6"
                    className="text-center font-weight-medium text-color-muted"
                  >
                    No upcoming bookings
                  </Mui.Typography>
                ) : null}

                <Mui.Box className="mt-5">
                  <Pagination
                    page={this.state.page}
                    totalPage={this.props.upcomingBookingList?.meta?.pages || 0}
                    totalItem={this.props.upcomingBookingList?.meta?.total || 0}
                    start={this.props.upcomingBookingList?.meta?.start || 0}
                    end={this.props.upcomingBookingList?.meta?.end || 0}
                    handleChangePage={(event, newPage) => this.handleChangePage(newPage)}
                    handleChangePageSize={(event) => this.handleChangePageSize(parseInt(event.target.value, 10))}
                    pageSize={this.state.pageSize}
                    displayTheme="dark"
                  />
                </Mui.Box>
              </>
            ) : null}

            {this.state.tab === 1 ? (
              <>
                <Mui.Box width="50%" className="mb-4 ml-auto">
                  <Search fieldTheme="dark" value={this.state.search1} onSearch={this.handleSearch1} />
                </Mui.Box>

                {this.props.completedBookingList?.data?.map((item, index) => {
                  return (
                    <Mui.Paper className="booking-list-card" key={index}>
                      <Mui.Hidden xsDown>
                        <figure>
                          <img
                            src={
                              item.vehicleType?.vehicleTypePhoto?.[0]?.url
                                ? item.vehicleType?.vehicleTypePhoto?.[0]?.url
                                : carPlaceholder
                            }
                            alt=""
                          />
                        </figure>
                      </Mui.Hidden>

                      <Mui.Box
                        className="content-wrapper"
                        width={Mui.isWidthDown("xs", this.props.width) ? "100%" : "76%"}
                        display="flex"
                        flexDirection="column"
                      >
                        <Mui.Box display="flex" flexWrap="wrap">
                          <Mui.Chip
                            className={`${
                              Mui.isWidthDown("xs", this.props.width) ? "w-100 justify-content-start" : ""
                            }`}
                            icon={<IconInfo />}
                            label={item.bookingNumber || ""}
                          />
                          <Mui.Chip icon={<IconCalendar />} label={moment(item.pickupDate).format("DD/MM/YYYY")} />
                          <Mui.Chip icon={<IconTime />} label={moment(item.pickupTime, "HH:mm:ss").format("HH:mm")} />

                          <Mui.Typography
                            component="h3"
                            variant="h3"
                            className={`font-weight-medium text-color-primary ${
                              Mui.isWidthDown("xs", this.props.width) ? "mt-2 mb-2" : "ml-auto"
                            }`}
                          >
                            {formatCurrency(item.total)}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Box
                          className="booking-list-card__direction"
                          display="flex"
                          flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          <Mui.Typography component="p" variant="body1">
                            {item.type === "flat_rate" ? item.route?.name || "-" : item.pickupLocation || "-"}
                          </Mui.Typography>

                          {item.type !== "flat_rate" ? <IconArrowRight /> : null}

                          <Mui.Typography
                            component="p"
                            variant="body1"
                            className={`${Mui.isWidthDown("xs", this.props.width) ? "" : "text-right"}`}
                          >
                            {item.type === "flat_rate" ? "" : item?.dropOffLocation || "-"}
                          </Mui.Typography>
                        </Mui.Box>

                        <Mui.Box
                          display="flex"
                          alignItems="center"
                          className="mt-2 content-detail-wrap text-color-primary"
                        >
                          <Mui.Typography component="p" variant="body1" className="d-flex align-items-center">
                            <span className="mr-1 svg-color-primary">
                              <IconCarFront />
                            </span>
                            {item.vehicleType?.name || "-"}
                          </Mui.Typography>

                          <Mui.Typography
                            component="p"
                            variant="body1"
                            className={`d-flex align-items-center ${
                              Mui.isWidthDown("xs", this.props.width) ? "" : "ml-2"
                            }`}
                          >
                            <span className="mr-1 svg-color-primary">
                              <IconDriver />
                            </span>
                            {item.driver?.name || "-"}
                          </Mui.Typography>

                          <a
                            href={`tel:${item.driver?.phone || ""}`}
                            className={`d-flex align-items-center text-color-primary ${
                              Mui.isWidthDown("xs", this.props.width) ? "" : "ml-2"
                            }`}
                          >
                            <span className="mr-1 svg-size-small svg-color-primary svg-color-white-hover">
                              <IconPhone />
                            </span>
                          </a>
                        </Mui.Box>
                      </Mui.Box>

                      <span
                        className={`text-transform-capitalize status-bg ${item.status === "pending" ? "warn" : ""} ${
                          item.status === "cancelled" ? "danger" : ""
                        } ${item.status === "confirmed" ? "success" : ""} ${
                          item.status === "completed" ? "disabled" : ""
                        }`}
                      >
                        {item.status || "-"}
                      </span>

                      <Mui.Button
                        variant="contained"
                        color="primary"
                        className="btn-default"
                        onClick={() => this.navigate(item.id)}
                      >
                        Show Detail
                        <IconChevronRight />
                      </Mui.Button>
                    </Mui.Paper>
                  );
                })}

                {this.props.completedBookingList?.data?.length === 0 ? (
                  <Mui.Typography
                    component="h6"
                    variant="h6"
                    className="text-center font-weight-medium text-color-muted"
                  >
                    No previous bookings
                  </Mui.Typography>
                ) : null}

                <Mui.Box className="mt-5">
                  <Pagination
                    page={this.state.page1}
                    totalPage={this.props.completedBookingList?.meta?.pages || 0}
                    totalItem={this.props.completedBookingList?.meta?.total || 0}
                    start={this.props.completedBookingList?.meta?.start || 0}
                    end={this.props.completedBookingList?.meta?.end || 0}
                    handleChangePage={(event, newPage) => this.handleChangePage1(newPage)}
                    handleChangePageSize={(event) => this.handleChangePageSize1(parseInt(event.target.value, 10))}
                    pageSize={this.state.pageSize1}
                    displayTheme="dark"
                  />
                </Mui.Box>
              </>
            ) : null}

            {this.state.tab === 2 ? (
              <DataTable
                title=""
                headCells={[
                  {
                    id: "invoiceNumber",
                    sortKey: "invoice_number",
                    label: "Invoice No",
                  },
                  {
                    id: "userInvoiceBookingNumber",
                    sortKey: "id",
                    label: "Job No",
                  },
                  {
                    id: "createdAt",
                    sortKey: "created_at",
                    label: "Created Date",
                  },
                ]}
                rows={Object.values(this.props.userInvoiceList?.data || {})?.map((item) => item?.[0]) || []}
                totalPage={this.props.userInvoiceList?.meta?.pages || 0}
                totalItem={this.props.userInvoiceList?.meta?.total || 0}
                start={this.props.userInvoiceList?.meta?.start || 0}
                end={this.props.userInvoiceList?.meta?.end || 0}
                loading={this.props.invoiceLoading}
                permission={{ download: 1 }}
                onChange={this.onChange}
                enableDateRangeFilter={true}
                onDownload={this.onDownload}
                actionStyle="icons"
                tableStyle="default"
                ui="dark"
              />
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = ({ booking, additionalCharge, invoice }) => {
  const { bookingData, upcomingBookingList, completedBookingList, success, message, loading, error } = booking;
  const { additionalCharges } = additionalCharge;
  const { userInvoiceList, loading: invoiceLoading, downloading } = invoice;
  return {
    bookingData,
    upcomingBookingList,
    completedBookingList,
    additionalCharges,
    userInvoiceList,
    invoiceLoading,
    downloading,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getUpcomingBookingList,
  getCompletedBookingList,
  getBooking,
  getAllRoute,
  getAllAdditionalCharge,
  getUserInvoiceList,
  downloadInvoice,
})(Mui.withWidth()(MyBooking));
