import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import Breadcrumb from "src/components/breadcrumb";
import {
  getBooking,
  getAllRoute,
  getAllCar,
  getAllAdditionalCharge,
  getAllInvoice,
  changeBookingStatus,
  chargeCard,
  resetBooking,
  addInvoice,
  resetInvoice,
  sendBookingEmail,
  getAllDriver,
  assignBookingDriver,
  modifyBookingCost
} from "src/reduxs/actions";
import { Link } from "react-router-dom";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import moment from "moment";
import { formatCurrency } from "src/helpers/util";
import { IconChevronLeft, IconMoreKebab } from "src/components/svg";
import { Alert } from "src/components/dialog";
import AssignDriver from "../../dashboard/assign-driver";
import Invoice from "./invoice";
import Status from "./status";
import Cost from "./cost";

class ViewBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      directions: null,
      anchorEl: null,
      openEmailAlert: false,
      statusModal: false,
      invoiceModal: false,
      assignChauffeurModal: false,
      costModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllRoute();
    this.props.getAllCar();
    this.props.getAllAdditionalCharge();
    this.props.getAllInvoice({ jobNo: this.props.match.params.id });
    this.props.getBooking(this.props.match.params.id);
    this.props.getAllDriver({});
  }

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenAlert = () => {
    this.setState({
      openAlert: true,
      anchorEl: null,
    });
  };

  handleCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  handleOpenAlert1 = () => {
    this.setState({
      openEmailAlert: true,
      anchorEl: null,
    });
  };

  handleCloseAlert1 = () => {
    this.setState({
      openEmailAlert: false,
    });
  };

  onChangeStatus = (values) => {
    if (!this.props.loading) {
      this.props.changeBookingStatus(this.props.match.params.id, values);
    }
  };

  onModifyBookingCost = (values) => {
    if (!this.props.loading1) {
      this.props.modifyBookingCost(this.props.match.params.id, values);
    }
  };

  onCharge = () => {
    if (!this.props.loading1) {
      this.props.chargeCard(this.props.match.params.id);
    }
  };

  onSendBookingEmail = () => {
    if (!this.props.loading1) {
      this.props.sendBookingEmail(this.props.match.params.id, { action: "update" });
    }
  };

  onGenerateInvoice = (values) => {
    if (!this.props.generateInvoiceLoading) {
      this.props.addInvoice(values);
    }
  };

  handleSearch = (value) => {
    this.props.getAllDriver({
      search: value,
      searchFields: "name:like;driving_licence_number:like;",
    });
  };

  assign = (id, driverId) => {
    if (!this.props.loading) {
      this.props.assignBookingDriver(id, { driverId: driverId, view: true });
    }
  };

  filter = (arr) => {
    const el = JSON.stringify(arr);
    let newArr = JSON.parse(el);
    newArr = arr.slice(1, -1);
    return newArr.map(({ id, routeId, ...rest }) => ({ ...rest }));
  };

  handleOpenAlert2 = () => {
    this.setState({ openAlert: true });
  };

  componentDidUpdate(previousProps) {
    if (previousProps.bookingData !== this.props.bookingData) {
      const directionsService = new window.google.maps.DirectionsService();
      if (this.props.bookingData.pickupLocation) {
        // For distance & Hourly
        directionsService.route(
          {
            origin: this.props.bookingData.pickupLocation,
            destination: this.props.bookingData.dropOffLocation
              ? this.props.bookingData.dropOffLocation
              : this.props.bookingData.pickupLocation,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
            waypoints:
              this.props.bookingData?.bookingWaypoint?.map(({ id, bookingId, ...rest }) => ({ ...rest })) || [],
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              this.setState({ directions: result });
            } else {
              console.error(`Error fetching directions ${result}`);
            }
          }
        );
      }
      // For flat_rate
      if (this.props.bookingData.routeId) {
        // Set direction, totalDistance and totalTime according to route
        let ol = this.props.routes?.find((x) => x.id === this.props.bookingData.routeId)?.routeWaypoint;
        if (ol?.length > 0 && ol[0].location) {
          directionsService.route(
            {
              origin: ol[0].location,
              destination: ol[ol.length - 1].location,
              travelMode: window.google.maps.TravelMode.DRIVING,
              optimizeWaypoints: true,
              waypoints: this.filter(ol),
              unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                this.setState({ directions: result });
              } else {
                console.error(`Error fetching directions ${result}`);
              }
            }
          );
        }
      }
    }
  }

  render() {
    const MapWithAMarker = withGoogleMap(() => (
      <GoogleMap defaultZoom={7}>
        {this.state.directions && <DirectionsRenderer directions={this.state.directions} />}
      </GoogleMap>
    ));
    return (
      <>
        <Breadcrumb
          title="Booking Management"
          paths={[
            {
              title: "Booking",
              page: `/booking`,
            },
            {
              title: "View",
            },
          ]}
        />

        <Mui.Card className="default-card spacing-lg">
          <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
            <Mui.IconButton
              className="border-1 border-color-grey"
              type="button"
              component={Link}
              to={"/booking"}
              size="small"
              style={{ padding: 6, marginRight: 15 }}
            >
              <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
                <IconChevronLeft />
              </Mui.Box>
            </Mui.IconButton>

            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
              Booking Information
            </Mui.Typography>

            {this.props.bookingData?.id ? (
              <Mui.Box display="flex" flexWrap="wrap" ml="auto" gridGap="1.6rem">
                <Mui.IconButton onClick={this.openMenu}>
                  <IconMoreKebab />
                </Mui.IconButton>

                <Mui.Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  classes={{ root: "kebab-dropdown" }}
                  onClose={this.closeMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {(this.props.bookingData.status === "pending" || this.props.bookingData.status === "confirmed") &&
                  this.props.bookingData.payment_status !== "paid" ? (
                    <Mui.MenuItem component={Link} to={`/booking/edit/${this.props.bookingData.id}`}>
                      <span>Edit</span>
                    </Mui.MenuItem>
                  ) : null}
                  <Mui.MenuItem
                    component={Link}
                    to={{
                      pathname: "/booking/add",
                      state: {
                        returnRideDetail: {
                          referenceNumber: this.props.bookingData?.referenceNumber || "",
                          type: this.props.bookingData?.type || "",
                          pickupDate: this.props.bookingData?.pickupDate || "",
                          pickupTime: this.props.bookingData?.pickupTime || "",
                          pickupLocation: this.props.bookingData?.dropOffLocation || "",
                          bookingWaypoint: this.props.bookingData?.bookingWaypoint?.reverse() || "",
                          dropOffLocation: this.props.bookingData?.pickupLocation || "",
                          bookingAdditionalCharge: this.props.bookingData?.bookingAdditionalCharge || "",
                          durationInHour: this.props.bookingData?.durationInHour || "",
                          routeId: this.props.bookingData?.routeId || "",
                          totalDistance: this.props.bookingData?.totalDistance || "",
                          totalTime: this.props.bookingData?.totalTime || "",
                          subTotal: this.props.bookingData?.subTotal || "",
                          total: this.props.bookingData?.total || "",
                          couponId: this.props.bookingData?.couponId || "",
                          coupon: this.props.bookingData?.coupon || "",
                          couponDiscount: this.props.bookingData?.couponDiscount || "",
                          noPassenger: this.props.bookingData?.noPassenger || "",
                          noLuggage: this.props.bookingData?.noLuggage || "",
                          extraLargeLuggage: this.props.bookingData?.extraLargeLuggage || "",
                          vehicleTypeId: this.props.bookingData?.vehicleTypeId || "",
                          babySeatRearFacing: this.props.bookingData?.babySeatRearFacing || "",
                          childSeatForwardFacing: this.props.bookingData?.childSeatForwardFacing || "",
                          boosterSeat: this.props.bookingData?.boosterSeat || "",
                          other: this.props.bookingData?.other || "",
                          firstName: this.props.bookingData?.firstName || "",
                          lastName: this.props.bookingData?.lastName || "",
                          email: this.props.bookingData?.email || "",
                          phone: this.props.bookingData?.phone || "",
                          passengerNames: this.props.bookingData?.passengerNames || "",
                          passengerPhones: this.props.bookingData?.passengerPhones || "",
                          alternatePhone: this.props.bookingData?.alternatePhone || "",
                          comment: this.props.bookingData?.comment || "",
                          additionalComment: this.props.bookingData?.additionalComment || "",
                          userId: this.props.bookingData?.userId || "",
                          enablePayment: this.props.bookingData?.enablePayment || "",
                          useCardOnFile: this.props.bookingData?.useCardOnFile || "",
                        },
                      },
                    }}
                  >
                    <span>Create Return Trip</span>
                  </Mui.MenuItem>
                  {this.props.bookingData?.status !== "cancelled" && this.props.bookingData?.status !== "completed" ? (
                    <Mui.MenuItem onClick={() => this.setState({ assignChauffeurModal: true, anchorEl: null })}>
                      <span> {this.props.bookingData?.driverId ? "Reassign Chauffeur" : "Assign Chauffeur"}</span>
                    </Mui.MenuItem>
                  ) : null}
                  {this.props.bookingData?.status === "pending" || this.props.bookingData?.status === "confirmed" ? (
                    <Mui.MenuItem onClick={() => this.setState({ statusModal: true, anchorEl: null })}>
                      <span>Change Status</span>
                    </Mui.MenuItem>
                  ) : null}
                  <Mui.MenuItem onClick={() => this.setState({ invoiceModal: true, anchorEl: null })}>
                    <span>Generate Invoice</span>
                  </Mui.MenuItem>
                  <Mui.MenuItem component={Link} to={`/invoice?job=${this.props.bookingData.id}`}>
                    <span> View All Invoices</span>
                  </Mui.MenuItem>
                  {this.props.bookingData && this.props.bookingData?.paymentStatus !== "paid" ? (
                    <Mui.MenuItem onClick={() => this.setState({ costModal: true, anchorEl: null })}>
                      <span> Override SubTotal</span>
                    </Mui.MenuItem>
                  ) : null}
                  {this.props.bookingData && this.props.bookingData?.paymentStatus !== "paid" ? (
                    <Mui.MenuItem onClick={this.handleOpenAlert}>
                      <span> Charge Card on File</span>
                    </Mui.MenuItem>
                  ) : null}
                  {(this.props.bookingData.status === "pending" || this.props.bookingData.status === "confirmed") &&
                  this.props.bookingData.payment_status !== "paid" ? (
                    <Mui.MenuItem onClick={this.handleOpenAlert1}>
                      <span> Send Email to Customer</span>
                    </Mui.MenuItem>
                  ) : null}
                </Mui.Popover>
              </Mui.Box>
            ) : null}
          </Mui.Box>

          <Mui.Box
            display="flex"
            flexDirection={Mui.isWidthDown("sm", this.props.width) ? "column-reverse" : "row"}
            alignItems="flex-start"
            className="mb-5"
            mt={8}
          >
            <Mui.Typography
              component="h1"
              variant="h1"
              className={`svg-color-primary font-weight-medium ${
                Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : ""
              }`}
            >
              <Mui.Typography
                component="small"
                variant="body1"
                className="d-block text-color-grey text-transform-uppercase"
              >
                BOOKING NO
              </Mui.Typography>
              {this.props.bookingData?.bookingNumber || ""}
              <Mui.Typography
                component="span"
                variant="body1"
                className={`status-bg ${this.props.bookingData?.status === "pending" ? "warn" : ""} ${
                  this.props.bookingData?.status === "cancelled" ? "danger" : ""
                } ${this.props.bookingData?.status === "confirmed" ? "success" : ""} ${
                  this.props.bookingData?.status === "completed" ? "disabled" : ""
                } text-transform-uppercase ${Mui.isWidthDown("xs", this.props.width) ? "mt-2" : "ml-2 align-self-end"}`}
              >
                {this.props.bookingData?.status?.toUpperCase() || ""}
              </Mui.Typography>
            </Mui.Typography>
          </Mui.Box>

          <Mui.Grid container spacing={5}>
            <Mui.Grid item xs={12} md>
              <Mui.Paper className="default-card outline border-color-primary">
                <Mui.Typography className="mb-3 font-weight-semi-bold" component="h3" variant="h3">
                  Contact Info
                </Mui.Typography>

                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.userId ? "pointer hover-primary" : ""
                  }`}
                  component="h4"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.userId
                      ? this.props.history.push(`/user/view/${this.props.bookingData?.userId}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    NAME
                  </Mui.Typography>
                  {this.props.bookingData?.firstName || ""} {this.props.bookingData?.lastName || ""}
                </Mui.Typography>

                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.userId ? "pointer  hover-primary" : ""
                  }`}
                  component="h6"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.userId
                      ? this.props.history.push(`/user/view/${this.props.bookingData?.userId}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    EMAIL ADDRESS
                  </Mui.Typography>
                  {this.props.bookingData?.email || ""}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.userId ? "pointer  hover-primary" : ""
                  }`}
                  component="h6"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.userId
                      ? this.props.history.push(`/user/view/${this.props.bookingData?.userId}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PHONE NUMBER
                  </Mui.Typography>
                  +61 {this.props.bookingData?.phone || ""}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.passengerNames ? "" : "d-none"}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.passengerNames ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PASSENGER NAMES
                  </Mui.Typography>
                  {this.props.bookingData?.passengerNames || "-"}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.passengerPhones ? "" : "d-none"}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.passengerPhones ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PASSENGER PHONE NUMBERS
                  </Mui.Typography>
                  {this.props.bookingData?.passengerPhones || "-"}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.alternatePhone ? "" : "d-none"}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.alternatePhone ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    ALTERNATE PHONE NUMBER
                  </Mui.Typography>
                  +61 {this.props.bookingData?.alternatePhone || "-"}
                </Mui.Typography>
                <Mui.Divider light={true} className={`mb-1 ${this.props.bookingData?.comment ? "" : "d-none"}`} />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.comment ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    EXTRA LUGGAGE COMMENT
                  </Mui.Typography>
                  {this.props.bookingData?.comment || ""}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.additionalComment ? "" : "d-none"}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.additionalComment ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    ADDITIONAL NOTE/COMMENT
                  </Mui.Typography>
                  {this.props.bookingData?.additionalComment || ""}
                </Mui.Typography>
              </Mui.Paper>
              <Mui.Paper className="default-card outline border-color-primary mt-3">
                <Mui.Typography className="mb-3 font-weight-semi-bold" component="h3" variant="h3">
                  Chauffeur Info
                </Mui.Typography>

                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.driver?.id ? "pointer  hover-primary" : ""
                  }`}
                  component="h4"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.driver?.id
                      ? this.props.history.push(`/driver/view/${this.props.bookingData?.driver?.id}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    NAME
                  </Mui.Typography>
                  {this.props.bookingData?.driver?.name || "-"}
                </Mui.Typography>

                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.driver?.id ? "pointer  hover-primary" : ""
                  }`}
                  component="h6"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.driver?.id
                      ? this.props.history.push(`/driver/view/${this.props.bookingData?.driver?.id}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    EMAIL ADDRESS
                  </Mui.Typography>
                  {this.props.bookingData?.driver?.email || "-"}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.driver?.id ? "pointer hover-primary" : ""
                  }`}
                  component="h6"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.driver?.id
                      ? this.props.history.push(`/driver/view/${this.props.bookingData?.driver?.id}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PHONE NUMBER
                  </Mui.Typography>
                  {this.props.bookingData?.driver?.phone ? `+61 ${this.props.bookingData?.driver?.phone}` : "-"}
                </Mui.Typography>
              </Mui.Paper>
            </Mui.Grid>

            <Mui.Grid item xs={12} md>
              <Mui.Paper
                className={`default-card outline border-color-primary ${
                  Mui.isWidthDown("sm", this.props.width) ? "mt-4 mb-4" : ""
                }`}
              >
                <Mui.Typography className="mb-3 font-weight-semi-bold" component="h3" variant="h3">
                  Ride details
                </Mui.Typography>

                <MapWithAMarker
                  containerElement={<div className="map-item" />}
                  mapElement={<div className="map-item" style={{ height: "250px" }} />}
                />
                <Mui.Typography className="mt-3 mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    SERVICE TYPE
                  </Mui.Typography>
                  {this.props.bookingData?.type === "distance" ? "Point to Point" : null}
                  {this.props.bookingData?.type === "hourly" ? "Hourly" : null}
                  {this.props.bookingData?.type === "flat_rate" ? "Flat Rate" : null}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />

                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    REFERENCE NUMBER
                  </Mui.Typography>
                  {this.props.bookingData?.referenceNumber || "-"}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />

                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.type === "flat_rate" ? "d-none" : ""
                  }`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PICK-UP LOCATION
                  </Mui.Typography>
                  {this.props.bookingData?.pickupLocation || ""}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "flat_rate" ? "d-none" : ""}`}
                />

                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.type === "flat_rate" ? "d-none" : ""
                  }`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    WAYPOINTS
                  </Mui.Typography>
                  {this.props.bookingData?.bookingWaypoint?.length > 0 ? (
                    <ul className="m-0 pl-1">
                      {this.props.bookingData?.bookingWaypoint?.map((item) => (
                        <li>{item.location}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "flat_rate" ? "d-none" : ""}`}
                />

                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.type === "flat_rate" ? "d-none" : ""
                  }`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    DROP-OFF LOCATION
                  </Mui.Typography>
                  {this.props.bookingData?.dropOffLocation || "-"}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "flat_rate" ? "d-none" : ""}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.type === "flat_rate" ? "" : "d-none"
                  }`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    ROUTE
                  </Mui.Typography>
                  {this.props.routes?.find((x) => x.id === this.props.bookingData?.routeId)?.name || ""}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "flat_rate" ? "" : "d-none"}`}
                />
                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    PICK-UP DATE, TIME
                  </Mui.Typography>
                  {moment(this.props.bookingData?.pickupDate).format("DD/MM/YYYY")},{" "}
                  {moment(this.props.bookingData?.pickupTime, "HH:mm:ss").format("HH:mm")}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${
                    this.props.bookingData?.type === "distance" ? "" : "d-none"
                  }`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    FLIGHT INFORMATION
                  </Mui.Typography>
                  <span className="text-transform-capitalize">{this.props.bookingData?.flightType || "-"}</span>{" "}
                  {this.props.bookingData?.flightNumber}
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "distance" ? "" : "d-none"}`}
                />
                <Mui.Typography
                  className={`mb-1 font-weight-semi-bold ${this.props.bookingData?.type === "hourly" ? "" : "d-none"}`}
                  component="h6"
                  variant="h5"
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    DURATION IN HOUR
                  </Mui.Typography>
                  {moment(this.props.bookingData?.durationInHour, "HH:mm:ss").format("H")} hour(s)
                </Mui.Typography>
                <Mui.Divider
                  light={true}
                  className={`mb-1 ${this.props.bookingData?.type === "hourly" ? "" : "d-none"}`}
                />

                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    TOTAL DISTANCE
                  </Mui.Typography>
                  {this.props.bookingData?.totalDistance || 0} km
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    APPROX ETA
                  </Mui.Typography>
                  {this.props.bookingData?.totalTime ? (
                    <>
                      {moment(this.props.bookingData.totalTime, "HH:mm:ss").format("H")}h{" "}
                      {moment(this.props.bookingData.totalTime, "HH:mm:ss").format("m")}m
                    </>
                  ) : null}
                </Mui.Typography>
              </Mui.Paper>
            </Mui.Grid>

            <Mui.Grid item xs={12} md>
              <Mui.Paper className="default-card outline border-color-primary">
                <Mui.Typography className="mb-3 font-weight-semi-bold" component="h3" variant="h3">
                  Vehicle Info
                </Mui.Typography>

                {/* <figure>
                  <img
                    src={
                      this.props.cars?.find(
                        (x) => x.id === this.props.bookingData?.vehicleTypeId
                      )?.vehicleTypePhoto?.[0]?.url || car
                    }
                    alt={
                      this.props.cars?.find(
                        (x) => x.id === this.props.bookingData?.vehicleTypeId
                      )?.name || ""
                    }
                    className="img-fluid"
                  />
                </figure> */}
                <Mui.Typography
                  className={`mb-1 mt-1 font-weight-semi-bold ${
                    this.props.bookingData?.vehicleTypeId ? "pointer hover-primary" : ""
                  }`}
                  component="h6"
                  variant="h5"
                  onClick={() =>
                    this.props.bookingData?.vehicleTypeId
                      ? this.props.history.push(`/car/view/${this.props.bookingData?.vehicleTypeId}`)
                      : null
                  }
                >
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    VEHICLE
                  </Mui.Typography>
                  {this.props.cars?.find((x) => x.id === this.props.bookingData?.vehicleTypeId)?.name || ""}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    NO OF PASSENGER
                  </Mui.Typography>
                  {this.props.bookingData?.noPassenger || ""}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    NO OF LUGGAGE
                  </Mui.Typography>
                  {this.props.bookingData?.noLuggage || ""}
                </Mui.Typography>
                <Mui.Divider light={true} className="mb-1" />
                <Mui.Typography className="mb-1 font-weight-semi-bold" component="h6" variant="h5">
                  <Mui.Typography className="d-block" component="span" variant="body2">
                    ADDITIONAL CHILD SEAT
                  </Mui.Typography>
                  <ul className="m-0 pl-2">
                    {this.props.bookingData?.babySeatRearFacing === 1 ? (
                      <li className="mb-1">Baby seat Rear facing (ages 0-1)</li>
                    ) : null}
                    {this.props.bookingData?.childSeatForwardFacing === 1 ? (
                      <li className="mb-1">Child seat forward facing (ages 1-3)</li>
                    ) : null}
                    {this.props.bookingData?.boosterSeat === 1 ? <li>Booster seat (ages 3-6)</li> : null}
                  </ul>
                </Mui.Typography>
              </Mui.Paper>

              <Mui.List>
                <Mui.ListItem className="mt-3 mb-1">
                  <Mui.Grid container spacing={2} justifyContent="space-between">
                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                        SUBTOTAL
                      </Mui.Typography>
                    </Mui.Grid>

                    <Mui.Grid item xs={6}>
                      <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                        {formatCurrency(this.props.bookingData?.subTotal)}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.ListItem>

                {this.props.bookingData?.coupon ? (
                  <Mui.ListItem className="mb-1">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          COUPON: {this.props.bookingData?.coupon || ""}
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          - {formatCurrency(this.props.bookingData?.couponDiscount || 0)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                ) : null}

                {this.props.bookingData?.bookingAdditionalCharge?.length > 0 &&
                  this.props.bookingData.bookingAdditionalCharge.map((item, index) => (
                    <Mui.ListItem className="mb-1" key={index}>
                      <Mui.Grid container spacing={2} justifyContent="space-between">
                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                            {this.props.additionalCharges?.find((x) => x.id === item.additionalChargeId)?.name || ""}
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                            {this.props.additionalCharges?.find((x) => x.id === item.additionalChargeId)?.charge ===
                            "negative"
                              ? "-"
                              : "+"}{" "}
                            {formatCurrency(item?.value)}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.ListItem>
                  ))}

                {this.props.bookingData?.paymentStatus === "paid" ? (
                  <Mui.ListItem className="mb-1">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          PAID
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          {formatCurrency(this.props.bookingData?.total)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                ) : null}

                {this.props.bookingData?.paymentStatus === "deposit" ? (
                  <>
                    <Mui.ListItem className="mb-1">
                      <Mui.Grid container spacing={2} justifyContent="space-between">
                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                            DEPOSIT
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                            - {formatCurrency(this.props.bookingData?.deposit)}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.ListItem>
                    <Mui.ListItem className="mb-1">
                      <Mui.Grid container spacing={2} justifyContent="space-between">
                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                            REMAINING
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid item xs={6}>
                          <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                            {formatCurrency(
                              parseFloat(
                                this.props.bookingData?.total > 0
                                  ? parseFloat(this.props.bookingData?.total) -
                                      parseFloat(this.props.bookingData?.deposit)
                                  : 0
                              )
                            )}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.ListItem>
                  </>
                ) : null}

                {this.props.bookingData?.paymentStatus === "hold" ? (
                  <Mui.ListItem className="mb-1">
                    <Mui.Grid container spacing={2} justifyContent="space-between">
                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-normal" component="h6" variant="h6">
                          PAID
                        </Mui.Typography>
                      </Mui.Grid>

                      <Mui.Grid item xs={6}>
                        <Mui.Typography className="font-weight-medium text-right" component="h4" variant="h4">
                          -
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.ListItem>
                ) : null}
              </Mui.List>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onCharge}
          title="Charge Card on File"
          info="Are you sure to perform the action?"
          awaitingInfo="Charging card..."
          actionBtnLabel="Submit"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetBooking}
        />

        <Alert
          open={this.state.openEmailAlert}
          close={this.handleCloseAlert1}
          action={this.onSendBookingEmail}
          title="Send email to customer"
          info="Are you sure to perform the action?"
          awaitingInfo="Sending email..."
          actionBtnLabel="Send"
          loading={this.props.loading1}
          success={this.props.success1}
          reset={this.props.resetBooking}
        />

        <Status
          open={this.state.statusModal}
          close={() => {
            this.setState({ statusModal: false });
          }}
          onSubmit={this.onChangeStatus}
          bookingData={this.props.bookingData}
          loading={this.props.loading}
          success={this.props.success}
          reset={this.props.resetBooking}
        />

        <Invoice
          open={this.state.invoiceModal}
          close={() => {
            this.setState({ invoiceModal: false });
          }}
          invoiceList={this.props.invoices}
          bookingData={this.props.bookingData}
          onSubmit={this.onGenerateInvoice}
          loading={this.props.generateInvoiceLoading}
          success={this.props.generateInvoiceSuccess}
          reset={this.props.resetInvoice}
        />

        <AssignDriver
          open={this.state.assignChauffeurModal}
          close={() => {
            this.setState({ assignChauffeurModal: false });
          }}
          driverList={this.props.drivers}
          bookingData={this.props.bookingData}
          assign={this.assign}
          reset={this.props.resetBooking}
          handleSearch={this.handleSearch}
          loading={this.props.loading}
          success={this.props.success}
        />

        <Cost
          open={this.state.costModal}
          close={() => {
            this.setState({ costModal: false });
          }}
          onSubmit={this.onModifyBookingCost}
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetBooking}
        />
      </>
    );
  }
}

const mapStateToProps = ({ booking, route, driver, car, invoice, additionalCharge }) => {
  const { routes } = route;
  const { cars } = car;
  const { drivers } = driver;
  const { loading: generateInvoiceLoading, success: generateInvoiceSuccess, invoices } = invoice;
  const { bookingData, success, message, loading, loading1, success1, error } = booking;
  const { additionalCharges } = additionalCharge;
  return {
    cars,
    drivers,
    routes,
    bookingData,
    success,
    message,
    loading,
    loading1,
    success1,
    error,
    invoices,
    generateInvoiceLoading,
    generateInvoiceSuccess,
    additionalCharges,
  };
};

export default connect(mapStateToProps, {
  getBooking,
  getAllRoute,
  getAllCar,
  getAllAdditionalCharge,
  getAllInvoice,
  changeBookingStatus,
  chargeCard,
  resetBooking,
  addInvoice,
  resetInvoice,
  sendBookingEmail,
  getAllDriver,
  assignBookingDriver,
  modifyBookingCost
})(Mui.withWidth()(ViewBooking));
