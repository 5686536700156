import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { DEACTIVATE, CONTACT_US, GET_SCHEDULAR } from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import SharedService from "src/services/shared-service";
import { resetCurrentUser } from "src/reduxs/auth/action";
import {
  deactivateSuccess,
  deactivateError,
  contactUsSuccess,
  contactUsError,
  getSchedularSuccess,
  getSchedularError,
} from "./action";

export function* watchDeactivate() {
  yield takeEvery(DEACTIVATE, deactivate);
}

const deactivateAsync = async (id) => {
  return SharedService.deactivate(id);
};

function* deactivate({ payload }) {
  try {
    const response = yield call(deactivateAsync, payload.userId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={"Account deactivated successfully"} />, {
        containerId: "default",
      });
      yield put(deactivateSuccess(response.data.success, response.data.message));
      localStorage.removeItem("token");
      localStorage.removeItem("currentUser");
      yield put(resetCurrentUser());
      payload.history.push("/");
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deactivateError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deactivateError(error.response.data.message));
  }
}

export function* watchContactUs() {
  yield takeEvery(CONTACT_US, contactUs);
}

const contactUsAsync = async (data) => {
  return SharedService.contactUs(data);
};

function* contactUs({ payload }) {
  try {
    const response = yield call(contactUsAsync, payload.param);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, {
        containerId: "default",
        autoClose: 8000,
      });
      yield put(contactUsSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, {
        containerId: "default",
        autoClose: 8000,
      });
      yield put(contactUsError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, {
      containerId: "default",
      autoClose: 8000,
    });
    yield put(contactUsError(error.response.data.message));
  }
}

export function* watchGetSchedular() {
  yield takeEvery(GET_SCHEDULAR, getSchedular);
}

const getSchedularAsync = async () => {
  return SharedService.getSchedular();
};

function* getSchedular() {
  try {
    const response = yield call(getSchedularAsync);
    if (response.data.success) {
      yield put(getSchedularSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getSchedularError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getSchedularError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([fork(watchDeactivate), fork(watchContactUs), fork(watchGetSchedular)]);
}
