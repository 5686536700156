import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import CouponList from "./list";
import EditCoupon from "./edit";

function Coupon() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/coupon" component={CouponList} />
        <ContentRoute path="/coupon/add" component={EditCoupon} />
        <ContentRoute path="/coupon/edit/:id" component={EditCoupon} />
      </Switch>
    </Suspense>
  );
}

export default Coupon;
