import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IconChevronDown } from "src/components/svg";
class CustomerDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <>
        <Mui.Box
          className="customer-dropdown"
          component="button"
          display="flex"
          alignItems="center"
          onClick={this.handleClick}
        >
          <Mui.Avatar alt={this.props.user?.firstName || ""} src="1.jpg" />
          <Mui.Hidden smDown>
            <Mui.Typography component="span" variant="h6" className="mr-1 font-weight-normal">
              {this.props.user?.firstName || ""}
            </Mui.Typography>
          </Mui.Hidden>
          <IconChevronDown />
        </Mui.Box>
        <Mui.Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PopoverClasses={{ root: "customer-dropdown-list" }}
        >
          {this.props.user?.roleId === 1 ? (
            <Mui.MenuItem component={Link} to={"/dashboard"} onClick={this.handleClose}>
              Dashboard
            </Mui.MenuItem>
          ) : (
            <>
              <Mui.MenuItem component={Link} to={"/my-account"} onClick={this.handleClose}>
                My Account
              </Mui.MenuItem>
              <Mui.MenuItem component={Link} to={"/my-account?tab=1"} onClick={this.handleClose}>
                My Booking
              </Mui.MenuItem>
            </>
          )}

          <Mui.MenuItem onClick={this.handleClose} to={"/logout"} component={Link}>
            Logout
          </Mui.MenuItem>
        </Mui.Menu>
      </>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(CustomerDropdown));
