import { combineReducers } from "redux";
import auth from "./auth/reducer";
import media from "./media/reducer";
import user from "./user/reducer";
import client from "./client/reducer";
import car from "./car/reducer";
import booking from "./booking/reducer";
import route from "./route/reducer";
import driver from "./driver/reducer";
import ride from "./ride/reducer";
import shared from "./shared/reducer";
import price from "./price/reducer";
import schedule from "./schedule/reducer";
import coupon from "./coupon/reducer";
import invoice from "./invoice/reducer";
import consolidateInvoice from "./consolidate-invoice/reducer";
import additionalCharge from "./additional-charge/reducer";
const reducers = combineReducers({
  auth,
  media,
  user,
  client,
  car,
  booking,
  route,
  driver,
  ride,
  shared,
  price,
  schedule,
  coupon,
  invoice,
  consolidateInvoice,
  additionalCharge,
});

export default reducers;
