import React from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

export default class Calendar extends React.Component {
  statusColor = (status) => {
    if (status === "pending") {
      return "warn";
    } else if (status === "cancelled") {
      return "warn";
    } else if (status === "confirmed") {
      return "warn";
    } else if (status === "completed") {
      return "warn";
    }
  };

  onEventRenderContent = (info) => {
    return (
      <Mui.Typography
        component={Link}
        to={`/booking/view/${info.event.id}`}
        variant="body1"
        className={`w-100 status-bg flex-direction-column align-items-start border-rad-4 text-decoration-none
        ${this.statusColor(info.event.extendedProps.status)}`}
      >
        <Mui.Typography component="span" variant="body2" className="d-block">
          {info.event.title}
        </Mui.Typography>
        {info.event.extendedProps.name}
      </Mui.Typography>
    );
  };

  render() {
    return (
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        initialView="dayGridMonth"
        weekends={true}
        events={this.props.schedules}
        selectable={false}
        editable={false}
        navLinks={true}
        dayMaxEventRows={true}
        eventContent={this.onEventRenderContent}
        datesSet={this.props.handleDatesSet}
        showNonCurrentDates={true}
      />
    );
  }
}
