import React, { useEffect } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import { Formik, Form, FieldArray } from "formik";
import Map from "src/views/ride/map";
import { modifyBooking, filterCar } from "src/reduxs/actions";
import {
  InputField,
  CheckboxField,
  SelectAutocompleteField,
  TextareaField,
  InputDatepickerField,
  InputTimepickerField,
  InputGoogleField,
} from "src/components/form";
import { IconAdd, IconTrash, IconChevronLeft, IconMapPin, IconRoute } from "src/components/svg";
import { noPassenger, noLuggage, durationHourList } from "src/helpers/util";

const EditBooking = (props) => {
  const schema = Yup.object().shape({
    type: Yup.string().required("Select type").nullable(),
    pickupDate: Yup.string().required("Enter a valid date").nullable(),
    pickupTime: Yup.string()
      .required("Enter a valid time")
      .nullable()
      .when("pickupDate", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-minimum", "Pick-Up Time minimum restriction of 3 hours", function (value) {
          const { pickupDate } = this.parent;
          let el = moment(pickupDate + " " + value, "YYYY-MM-DD HH:mm:ss");
          let now = moment();
          if (el.isSameOrBefore(now)) {
            return false;
          }
          var duration = moment.duration(el.diff(now));
          var hr = duration.asHours();
          return hr >= 3;
        }),
        otherwise: Yup.string().required("Enter a valid time").nullable(),
      }),
    pickupLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    dropOffLocation: Yup.string().when("type", {
      is: (value) => value === "distance" || value === "hourly",
      then: Yup.string().required("Enter a valid location").nullable(),
      otherwise: Yup.string(),
    }),
    durationInHour: Yup.string().when("type", {
      is: (value) => value === "hourly",
      then: Yup.string().required("Select a valid duration").nullable(),
      otherwise: Yup.string(),
    }),
    routeId: Yup.string().when("type", {
      is: (value) => value === "flat_rate",
      then: Yup.string().required("Select valid route").nullable(),
      otherwise: Yup.string(),
    }),
    flightType: Yup.string()
      .nullable()
      .when("pickupLocation", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-valid", "Please provide flight information", function (value) {
          const { type, pickupLocation } = this.parent;
          if (type === "distance" && pickupLocation?.match(/airport/i) && !value) {
            return false;
          }
          return true;
        }),
        otherwise: Yup.string().nullable(),
      }),
    flightNumber: Yup.string().when("flightType", {
      is: (value) => value === "domestic" || value === "international",
      then: Yup.string().required("Enter a valid flight number").nullable(),
      otherwise: Yup.string(),
    }),
    waypoint: Yup.array().of(
      Yup.object().shape({
        location: Yup.string().required("Enter a valid location").nullable(),
      })
    ),
    noPassenger: Yup.string().required("Select a valid value").nullable(),
    noLuggage: Yup.string().required("Select a valid value").nullable(),
    vehicleTypeId: Yup.string().required("Select a car").nullable(),
    firstName: Yup.string()
      .required("Enter your first name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    lastName: Yup.string()
      .required("Enter your last name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .nullable(),
    email: Yup.string().required("Enter a valid email").email("Invalid email address").nullable(),
    phone: Yup.string()
      .required("Enter a valid contact")
      .min(8, "Atleast 8 digits")
      .max(10, "Cannot exceed 10 digits")
      .nullable(),
    alternatePhone: Yup.string().min(8, "Atleast 8 digits").max(10, "Cannot exceed 10 digits").nullable(),
  });

  const onFilter = (passenger, luggage, routeId = null) => {
    props.filterCar({
      passenger: passenger,
      luggage: luggage,
      routeId: routeId,
    });
  };

  const onSubmit = (values) => {
    if (!props.loading) {
      props.modifyBooking(props.bookingData.id, values);
    }
  };

  useEffect(() => {
    if (props.bookingData?.id) {
      props.filterCar({
        passenger: props.bookingData?.noPassenger || 1,
        luggage: props.bookingData?.noLuggage || 1,
        routeId: props.bookingData?.routeId || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookingData]);

  return (
    <>
      <Mui.Box className="text-color-white" width="100%" display="flex" alignItems="center" flexWrap="wrap" mb="3rem">
        <Mui.IconButton
          className="border-1 border-color-grey"
          type="button"
          size="small"
          style={{ padding: 6, marginRight: 15 }}
          onClick={() => props.onBack()}
        >
          <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
            <IconChevronLeft />
          </Mui.Box>
        </Mui.IconButton>

        <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
          Modify Booking Detail
        </Mui.Typography>
      </Mui.Box>

      <Formik
        enableReinitialize={true}
        initialValues={{
          referenceNumber: props.bookingData?.referenceNumber || "",
          type: props.bookingData?.type || "distance",
          pickupDate: props.bookingData?.pickupDate || null,
          pickupTime: props.bookingData?.pickupTime || "",
          pickupLocation: props.bookingData?.pickupLocation || "",
          waypoint: props.bookingData?.waypoint || [],
          additionalCharge: props.bookingData?.bookingAdditionalCharge || [],
          dropOffLocation: props.bookingData?.dropOffLocation || "",
          durationInHour: props.bookingData?.durationInHour || "01:00:00",
          routeId: props.bookingData?.routeId || "",
          flightType: props.bookingData?.flightType || "",
          flightNumber: props.bookingData?.flightNumber || "",
          totalDistance: props.bookingData?.totalDistance || 0,
          totalTime: props.bookingData?.totalTime || "00:00:00",
          coupon: props.bookingData?.coupon || "",
          noPassenger: props.bookingData?.noPassenger || "1",
          noLuggage: props.bookingData?.noLuggage || "1",
          extraLargeLuggage: props.bookingData?.extraLargeLuggage,
          vehicleTypeId: props.bookingData?.vehicleTypeId || "",
          babySeatRearFacing: props.bookingData?.babySeatRearFacing,
          childSeatForwardFacing: props.bookingData?.childSeatForwardFacing,
          boosterSeat: props.bookingData?.boosterSeat,
          other: props.bookingData?.other,
          firstName: props.bookingData?.firstName || "",
          lastName: props.bookingData?.lastName || "",
          email: props.bookingData?.email || "",
          phone: props.bookingData?.phone || "",
          passengerNames: props.bookingData?.passengerNames || "",
          passengerPhones: props.bookingData?.passengerPhones || "",
          alternatePhone: props.bookingData?.alternatePhone || "",
          comment: props.bookingData?.comment || "",
          additionalComment: props.bookingData?.additionalComment || "",
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, errors, resetForm, setFieldValue, setTouched }) => (
          <Form className="default-form">
            <Mui.Grid container spacing={3}>
              <Mui.Grid item xs={12} md={7}>
                <Mui.Card className="default-card-dark-card">
                  <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
                    1. Enter Ride Details
                  </Mui.Typography>

                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item xs={12} md={6} lg={6} className="form-group-dark">
                      <SelectAutocompleteField
                        name="type"
                        label="Select Type*"
                        options={[
                          { id: "distance", name: "Point to Point" },
                          { id: "hourly", name: "Hourly" },
                          // { id: "flat_rate", name: "Flat Rate" },
                        ]}
                        callback={(value) => {
                          setFieldValue("vehicleTypeId", "");
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6} lg={6} className="form-group-dark">
                      <InputField name="referenceNumber" type="text" label="Reference Number" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6} lg={6} className="form-group-dark">
                      <InputDatepickerField name="pickupDate" label="Pick-Up Date*" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6} lg={6} className="time-picker form-group-dark">
                      <InputTimepickerField name="pickupTime" label="Pick-Up Time*" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputGoogleField
                        name="pickupLocation"
                        label="Pick-Up Location*"
                        placeholder="Enter a Location"
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className={`${values.type === "distance" ? "" : "d-none"}`}>
                      <FieldArray
                        name="waypoint"
                        render={(arrayHelpers) => (
                          <>
                            <Mui.Box display="flex" alignItems="center" className="mb-2">
                              <Mui.IconButton
                                className="btn-icon-square mr-2"
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() => {
                                  if (values.waypoint?.length < 5) {
                                    arrayHelpers.push({ location: "" });
                                  }
                                }}
                              >
                                <IconAdd />
                              </Mui.IconButton>
                              <Mui.Typography
                                className="text-color-primary font-weight-normal"
                                component="h5"
                                variant="h5"
                              >
                                Add your waypoint / Add stops
                              </Mui.Typography>
                            </Mui.Box>

                            {values.waypoint?.map((item, index) => (
                              <Mui.Grid container spacing={2} key={index}>
                                <Mui.Grid item xs={12} md={10} className="mt-0 form-group-dark">
                                  <InputGoogleField
                                    name={`waypoint.${index}.location`}
                                    placeholder="Enter a Waypoint"
                                  />
                                </Mui.Grid>
                                <Mui.Grid item xs={12} md={2} className="text-right">
                                  <Mui.IconButton
                                    size="small"
                                    className={`svg-color-primary`}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <IconTrash />
                                  </Mui.IconButton>
                                </Mui.Grid>
                              </Mui.Grid>
                            ))}
                          </>
                        )}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={`form-group-dark ${values.type === "flat_rate" ? "d-none" : ""}`}
                    >
                      <InputGoogleField
                        name="dropOffLocation"
                        label="Drop-Off Location*"
                        placeholder="Enter a Location"
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      className={`form-group-dark ${values.type === "hourly" ? "" : "d-none"}`}
                    >
                      <SelectAutocompleteField
                        name="durationInHour"
                        label="Duration In Hours*"
                        options={durationHourList}
                      />
                    </Mui.Grid>

                    <Mui.List className="list-switch w-100">
                      {values.type === "distance" && values.pickupLocation?.match(/airport/i) ? (
                        <>
                          {errors?.flightType ? (
                            <Mui.Typography
                              component="h5"
                              variant="h5"
                              className="mb-2 text-color-danger font-weight-normal"
                            >
                              {errors?.flightType}
                            </Mui.Typography>
                          ) : null}
                          {/* Domestic Flight */}
                          <Mui.ListItem>
                            <Mui.ListItemIcon>
                              <IconMapPin />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText primary="Domestic Flight" />
                            <Mui.ListItemSecondaryAction>
                              <Mui.Switch
                                edge="end"
                                checked={values.flightType === "domestic" ? true : false}
                                onChange={(event) =>
                                  setFieldValue("flightType", event.target.checked ? "domestic" : "")
                                }
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": "switch-list-label-domestic",
                                }}
                              />
                            </Mui.ListItemSecondaryAction>
                          </Mui.ListItem>
                          <Mui.ListItem className={`${values.flightType === "domestic" ? "" : "d-none"}`}>
                            <Mui.Box className="form-group-dark mt-0 mb-0">
                              <InputField name="flightNumber" type="text" label="" placeholder="Flight Number" />
                            </Mui.Box>
                          </Mui.ListItem>

                          {/* International Flight */}
                          <Mui.ListItem>
                            <Mui.ListItemIcon>
                              <IconRoute />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText primary="International Flight" />
                            <Mui.ListItemSecondaryAction>
                              <Mui.Switch
                                edge="end"
                                checked={values.flightType === "international" ? true : false}
                                onChange={(event) =>
                                  setFieldValue("flightType", event.target.checked ? "international" : "")
                                }
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": "switch-list-label-international",
                                }}
                              />
                            </Mui.ListItemSecondaryAction>
                          </Mui.ListItem>
                          <Mui.ListItem className={`${values.flightType === "international" ? "" : "d-none"}`}>
                            <Mui.Box className="form-group-dark mt-0 mb-0">
                              <InputField name="flightNumber" type="text" label="" placeholder="Flight Number" />
                            </Mui.Box>
                          </Mui.ListItem>
                        </>
                      ) : null}
                    </Mui.List>
                  </Mui.Grid>
                </Mui.Card>

                <Mui.Card className="default-card-dark-card mt-3">
                  <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
                    2. Vehicle Selection
                  </Mui.Typography>

                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item xs={12} md={6} lg={6} className="form-group-dark">
                      <SelectAutocompleteField
                        name="noPassenger"
                        label="Passengers*"
                        options={noPassenger}
                        callback={(value) => {
                          setFieldValue("vehicleTypeId", "");
                          onFilter(value, values.noLuggage, values.routeId || "");
                        }}
                      />
                    </Mui.Grid>

                    <Mui.Grid item xs={12} md={6} lg={6} className="form-group-dark">
                      <SelectAutocompleteField
                        name="noLuggage"
                        label="Luggages*"
                        options={noLuggage}
                        callback={(value) => {
                          setFieldValue("vehicleTypeId", "");
                          onFilter(values.noPassenger, value, values.routeId || "");
                        }}
                      />
                    </Mui.Grid>

                    <Mui.Grid item xs={12} md={6} lg={12} className="form-group-dark">
                      <SelectAutocompleteField name="vehicleTypeId" label="Select Vehicle*" options={props.carList} />
                    </Mui.Grid>

                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark mt-0">
                      <CheckboxField name="extraLargeLuggage" label="Any Extra Large Luggage?" ui="dark" />
                      {values.extraLargeLuggage ? (
                        <InputField name="comment" type="text" placeholder="Extra Luggage Info" />
                      ) : null}
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
                    Additional Child Seat
                  </Mui.Typography>
                  <Mui.Grid container spacing={2} className="space-all">
                    <Mui.Grid item xs={12} md={12} lg={12}>
                      <CheckboxField
                        name="babySeatRearFacing"
                        label="Baby seat Rear facing (ages 0-1) $ 20"
                        ui="dark"
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12}>
                      <CheckboxField
                        name="childSeatForwardFacing"
                        label="Child seat forward facing (ages 1-3) $ 20"
                        ui="dark"
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12}>
                      <CheckboxField name="boosterSeat" label="Booster seat (ages 3-6) $ 20" ui="dark" />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Card>

                <Mui.Card className="default-card-dark-card mt-3">
                  <Mui.Box display="flex" flexWrap="wrap">
                    <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
                      3. Enter Contact Details
                    </Mui.Typography>

                    <CheckboxField name="other" label="Booking for Others?" ui="dark" />
                  </Mui.Box>

                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField name="firstName" type="text" label="First Name*" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField name="lastName" type="text" label="Last Name*" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField name="email" type="text" label="Email Address*" />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField
                        name="phone"
                        type="text"
                        label="Phone Number*"
                        InputProps={{
                          startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                        }}
                      />
                    </Mui.Grid>
                    {values.other ? (
                      <>
                        <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                          <InputField name="passengerNames" type="text" label="Passenger Names" />
                        </Mui.Grid>
                        <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                          <InputField name="passengerPhones" type="text" label="Passenger Phone Numbers" />
                        </Mui.Grid>
                      </>
                    ) : null}
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField
                        name="alternatePhone"
                        type="text"
                        label="Alternate Phone Number"
                        InputProps={{
                          startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <TextareaField name="additionalComment" label="Any Additional Comment" />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Card>

                <Mui.Card className="default-card-dark-card mt-3">
                  <Mui.Box display="flex" flexWrap="wrap">
                    <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
                      4. Coupon Details
                    </Mui.Typography>
                  </Mui.Box>
                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item xs={12} md={12} lg={12} className="form-group-dark">
                      <InputField name="coupon" type="text" label="Coupon" />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Card>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={5}>
                <Mui.Box position="sticky" top={90}>
                  <Map values={values} routes={[]} setFieldValue={setFieldValue} customHeight="250px" />
                </Mui.Box>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Box
              width="100%"
              display="flex"
              flexDirection={Mui.isWidthDown("xs", props.width) ? "column" : "row"}
              className="mt-5"
            >
              <Mui.Button
                className={`btn-default ${Mui.isWidthDown("xs", props.width) ? "mb-3" : "mr-3"}`}
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
                disabled={
                  (values.type === "distance" || values.type === "hourly") && parseFloat(values.totalDistance) >= 100
                    ? true
                    : false
                }
              >
                Save
                {props.loading && <Mui.CircularProgress color="secondary" size={24} />}
              </Mui.Button>
              <Mui.Button
                className="font-weight-normal"
                type="button"
                variant="text"
                disableElevation
                onClick={resetForm}
              >
                Reset
              </Mui.Button>
            </Mui.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = ({ booking, ride }) => {
  const { bookingData, success, message, loading, error } = booking;
  const { carList } = ride;
  return {
    carList,
    bookingData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  modifyBooking,
  filterCar,
})(Mui.withWidth()(EditBooking));
