export const DEACTIVATE = "DEACTIVATE";
export const DEACTIVATE_SUCCESS = "DEACTIVATE_SUCCESS";
export const DEACTIVATE_ERROR = "DEACTIVATE_ERROR";
export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_ERROR = "CONTACT_US_ERROR";
export const GET_SCHEDULAR = "GET_SCHEDULAR";
export const GET_SCHEDULAR_SUCCESS = "GET_SCHEDULAR_SUCCESS";
export const GET_SCHEDULAR_ERROR = "GET_SCHEDULAR_ERROR";

export const deactivate = (userId, history) => ({
  type: DEACTIVATE,
  payload: { userId, history },
});

export const deactivateSuccess = (success, message) => ({
  type: DEACTIVATE_SUCCESS,
  payload: { success, message },
});

export const deactivateError = (error) => ({
  type: DEACTIVATE_ERROR,
  payload: error,
});

export const contactUs = (param) => ({
  type: CONTACT_US,
  payload: { param },
});

export const contactUsSuccess = (success, message) => ({
  type: CONTACT_US_SUCCESS,
  payload: { success, message },
});

export const contactUsError = (error) => ({
  type: CONTACT_US_ERROR,
  payload: error,
});

export const getSchedular = () => ({
  type: GET_SCHEDULAR,
  payload: {},
});

export const getSchedularSuccess = (schedules) => ({
  type: GET_SCHEDULAR_SUCCESS,
  payload: schedules,
});

export const getSchedularError = (error) => ({
  type: GET_SCHEDULAR_ERROR,
  payload: error,
});
