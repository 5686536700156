import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import { contactUs } from "src/reduxs/actions";
import { InputField, TextareaField } from "src/components/form";

const MapWithAMarker = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={18}
    defaultCenter={{ lat: -37.7300036, lng: 145.0030388 }}
    defaultOptions={{
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    }}
  >
    <Marker position={{ lat: -37.7300036, lng: 145.0030388 }} />
  </GoogleMap>
));

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
    this.recaptchaRef = React.createRef();
    this.schema = Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      name: Yup.string()
        .required("Fullname is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      phone: Yup.string()
        .required("Phone Number is required")
        .min(8, "Atleast 8 digits")
        .max(10, "Cannot exceed 10 digits")
        .nullable(),
      message: Yup.string().required("Message is required").nullable(),
      recaptchaToken: Yup.string().required("Captcha required"),
    });
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.contactUs(values);
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.success !== this.props.success) {
      if (this.props.success) {
        this.formikRef?.current?.resetForm();
        this.recaptchaRef?.current?.reset();
      }
    }
  }

  render() {
    return (
      <WebsiteLayout>
        <PageMeta />

        <Mui.Box
          display="flex"
          flexDirection={Mui.isWidthDown("sm", this.props.width) ? "column" : "row"}
          alignItems="center"
          className="section-default contact"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={6} lg={6}>
                <Mui.Breadcrumbs className="text-color-white">
                  <Mui.Link component={Link} to={"/"} className="text-color-white">
                    Home
                  </Mui.Link>
                  <Mui.Link underline="none" className="text-color-white">
                    Contact
                  </Mui.Link>
                </Mui.Breadcrumbs>

                <Mui.Typography component="h1" variant="h1" style={{ fontSize: "8.5rem" }}>
                  Feel Free To Contact Us
                </Mui.Typography>

                {/* <Mui.Box
                  display="flex"
                  className="mt-1"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                >
                  <Mui.Typography component="label" variant="subtitle1">
                    Phone:
                  </Mui.Typography>
                  <Mui.Typography
                    component="h6"
                    variant="subtitle1"
                    className="word-break-all"
                  >
                    +61(3234)34534534
                  </Mui.Typography>
                </Mui.Box>

                <Mui.Box
                  display="flex"
                  className="mt-1"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                >
                  <Mui.Typography component="label" variant="subtitle1">
                    Registered Address:
                  </Mui.Typography>
                  <Mui.Typography
                    component="h6"
                    variant="subtitle1"
                    className="word-break-all"
                  >
                    745 High Street, Preston 3072
                  </Mui.Typography>
                </Mui.Box>

                <Mui.Box
                  display="flex"
                  className="mt-1"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                >
                  <Mui.Typography component="label" variant="subtitle1">
                    Email:
                  </Mui.Typography>
                  <Mui.Typography
                    component="h6"
                    variant="subtitle1"
                    className="word-break-all"
                  >
                    abc@gmail.com
                  </Mui.Typography>
                </Mui.Box>

                <Mui.Box
                  display="flex"
                  className="mt-3"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                >
                  <Mui.Typography component="label" variant="subtitle1">
                    Hours of operations:
                  </Mui.Typography>
                  <Mui.Typography
                    component="h6"
                    variant="subtitle1"
                    className="word-break-all"
                  >
                    Monday – Sunday <br /> 24 hours per day
                  </Mui.Typography>
                </Mui.Box> */}

                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                    recaptchaToken: "",
                  }}
                  innerRef={this.formikRef}
                  validationSchema={this.schema}
                  onSubmit={this.onSubmit}
                >
                  {(props) => (
                    <Form className="default-form">
                      <Mui.Grid container spacing={3}>
                        <Mui.Grid className="form-group-dark" item sm={12}>
                          <InputField name="name" type="text" label="Full Name*" />
                        </Mui.Grid>

                        <Mui.Grid className="form-group-dark" item sm={6}>
                          <InputField name="email" type="email" label="Email*" />
                        </Mui.Grid>

                        <Mui.Grid className="form-group-dark mb-2" item sm={6}>
                          <InputField
                            name="phone"
                            type="number"
                            label="Phone Number*"
                            InputProps={{
                              startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                            }}
                          />
                        </Mui.Grid>

                        <Mui.Grid className="form-group-dark mb-2" item sm={12}>
                          <TextareaField name="message" label="Message*" />
                        </Mui.Grid>

                        <Mui.Grid className="form-group-dark mb-2" item sm={12}>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                            ref={this.recaptchaRef}
                            theme="dark"
                            onChange={(value) => {
                              props.setFieldValue("recaptchaToken", value);
                            }}
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Box width="100%" display="flex" justifyContent="space-between" className="mt-3">
                        <Mui.Button
                          className="btn-default flex-shrink-0"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={this.props.loading}
                        >
                          Contact Us
                          {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                        </Mui.Button>
                      </Mui.Box>
                    </Form>
                  )}
                </Formik>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>

          <div className={`${Mui.isWidthDown("sm", this.props.width) ? "w-100 mt-5" : "floating-img"}`}>
            <MapWithAMarker
              containerElement={<div className="map-item" />}
              mapElement={
                <div
                  className="map-item"
                  style={{
                    height: `${Mui.isWidthDown("sm", this.props.width) ? "25rem" : "90rem"}`,
                  }}
                />
              }
            />
          </div>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}
const mapStateToProps = ({ shared }) => {
  const { loading, success } = shared;
  return { loading, success };
};

export default Mui.withWidth()(connect(mapStateToProps, { contactUs })(ContactView));
