import React, { useEffect } from "react";
import * as Mui from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SelectField, InputDatepickerField } from "src/components/form";

const Invoice = (props) => {
  const { open, close, onSubmit, loading, success, reset } = props;

  const schema = Yup.object().shape({
    title: Yup.string().required("Select invoice").nullable(),
    dueDate: Yup.string().required("Enter due date").nullable(),
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      close();
    }
  };

  useEffect(() => {
    if (success) {
      close();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Mui.Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xs"}
      classes={{ paper: "default-alert-dialog" }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: "",
          dueDate: null,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form className="default-form">
            <Mui.DialogContent>
              <Mui.Typography component="h3" variant="h3" className="default-alert-dialog__title">
                Generate Merged Invoice
              </Mui.Typography>

              <Mui.Box mt={2} display="flex" flexDirection="column">
                <Mui.Box className="form-group">
                  <SelectField
                    name="title"
                    label="Invoice Type*"
                    options={[
                      { id: "Chauffeur Service", name: "Chauffeur Service" },
                      { id: "Taxi Invoice", name: "Taxi Invoice" },
                      { id: "Ground Transfer Service", name: "Ground Transfer Service" },
                    ]}
                  />
                </Mui.Box>
                <Mui.Box className="form-group">
                  <InputDatepickerField name="dueDate" label="Due Date*" />
                </Mui.Box>
              </Mui.Box>
            </Mui.DialogContent>
            <Mui.DialogActions>
              <Mui.Button type="submit" className="btn-default" color="primary" variant="contained" disableElevation>
                Submit
                {loading && <Mui.CircularProgress color="secondary" size={24} />}
              </Mui.Button>

              <Mui.Button onClick={close} color="primary" className="btn-default" variant="outlined">
                Cancel
              </Mui.Button>
            </Mui.DialogActions>
          </Form>
        )}
      </Formik>
    </Mui.Dialog>
  );
};
export default Invoice;
