import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import LogoSmall from "src/assets/images/logo-small.svg";
import {
  IconUrl,
  IconMapPin,
  IconPhone,
  IconFacebook,
  IconGoogle,
  IconLinkedin,
  IconInstagram,
} from "src/components/svg";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <>
        <section id="contact" className="contact-area">
          <Mui.Container maxWidth="md">
            <img className="foot-logo" src={LogoSmall} alt="" />
            <Mui.Grid container spacing={4}>
              <Mui.Grid
                item
                xs={12}
                md={4}
                className={`d-flex align-items-start ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-3" : ""
                }`}
              >
                <span className="svg-color-white svg-size-small-2x mr-3">
                  <IconMapPin />
                </span>

                <Mui.Typography
                  component="h4"
                  variant="h5"
                  className="font-weight-normal text-color-white"
                >
                  <Mui.Typography
                    component="span"
                    variant="h5"
                    className="d-block mb-1 text-color-primary font-weight-normal"
                  >
                    REGISTERED ADDRESS
                  </Mui.Typography>
                  745 High Street, Preston 3072
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={12}
                md={5}
                className={`d-flex align-items-start ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-3" : ""
                }`}
              >
                <span className="svg-color-white svg-size-small-2x mr-3">
                  <IconPhone />
                </span>

                <Mui.Typography
                  component="h4"
                  variant="h4"
                  className="font-weight-normal text-color-white"
                >
                  <Mui.Typography
                    component="span"
                    variant="h5"
                    className="d-block mb-1 text-color-primary font-weight-normal"
                  >
                    CONTACTS
                  </Mui.Typography>

                  <Mui.Typography
                    component="span"
                    variant="h5"
                    className="d-flex mb-1 font-weight-normal"
                  >
                    PHONE:{" "}
                    <span className="ml-1">
                      <a href="tel:+61426292525">+61 426 292 525</a>
                      , <br /> <a href="tel:+61404377511">+61 404 377 511</a>
                    </span>
                  </Mui.Typography>

                  <Mui.Typography
                    component="span"
                    variant="h5"
                    className="d-flex mb-1 font-weight-normal"
                  >
                    EMAIL:{" "}
                    <span className="ml-1 word-break-all">
                      {" "}
                      <a href="mailto:admin@shangrilachauffeurs.com.au">
                        admin@shangrilachauffeurs.com.au
                      </a>
                    </span>
                  </Mui.Typography>
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={12}
                md={3}
                className={`d-flex align-items-start`}
              >
                <span className="svg-color-white svg-size-small-2x mr-3">
                  <IconUrl />
                </span>

                <Mui.Typography
                  component="h4"
                  variant="h4"
                  className="font-weight-medium text-color-white"
                >
                  <Mui.Typography
                    component="span"
                    variant="h5"
                    className="d-block mb-1 text-color-primary font-weight-normal"
                  >
                    IMPORTANT LINKS
                  </Mui.Typography>
                  <Mui.Typography
                    component={Link}
                    variant="h5"
                    to="/faq"
                    className="d-block mb-1 font-weight-medium"
                  >
                    FAQs
                  </Mui.Typography>
                  <Mui.Typography
                    component={Link}
                    variant="h5"
                    to="/terms-condition"
                    className="d-block mb-1 font-weight-medium"
                  >
                    Terms & Conditions
                  </Mui.Typography>
                  <Mui.Typography
                    component={Link}
                    variant="h5"
                    to="/privacy-policy"
                    className="font-weight-medium"
                  >
                    Privacy Policy
                  </Mui.Typography>
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="svg-color-white btn-social"
              mt="5rem"
              mb="-3rem"
            >
              <Mui.IconButton
                target="_blank"
                component={Link}
                to={{
                  pathname: "#",
                }}
              >
                <IconGoogle />
              </Mui.IconButton>
              <Mui.IconButton
                target="_blank"
                component={Link}
                to={{
                  pathname: "https://www.facebook.com/shangrilachauffeurs/",
                }}
              >
                <IconFacebook />
              </Mui.IconButton>
              <Mui.IconButton
                target="_blank"
                component={Link}
                to={{
                  pathname:
                    "https://www.linkedin.com/in/manjil-pradhan-b9587697",
                }}
              >
                <IconLinkedin />
              </Mui.IconButton>
              <Mui.IconButton
                target="_blank"
                component={Link}
                to={{
                  pathname: "https://www.instagram.com/",
                }}
              >
                <IconInstagram />
              </Mui.IconButton>
            </Mui.Box>
          </Mui.Container>
        </section>
        <footer className="main-footer">
          <Mui.Container fixed maxWidth="md">
            <Mui.Box
              display="flex"
              flexDirection={
                Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
              }
              justifyContent={
                Mui.isWidthDown("xs", this.props.width)
                  ? "center"
                  : "space-between"
              }
              alignItems="center"
            >
              <Mui.Typography
                component="p"
                variant="body2"
                className={`text-center ${
                  Mui.isWidthDown("xs", this.props.width) ? "mb-1" : ""
                }`}
              >
                Copyright © 2021 @ SHANGRI-LA CHAUFFEURS. All Rights Reserved.
              </Mui.Typography>

              {/* <Mui.Typography
                component={Link}
                to="/terms-condition"
                className="text-color-primary"
              >
                Terms & Condition
              </Mui.Typography> */}
            </Mui.Box>
          </Mui.Container>
        </footer>
      </>
    );
  }
}

export default Mui.withWidth()(Footer);
