import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { FILTER_CAR, GET_USER_CARD, BOOK_RIDE, REBOOK_RIDE, CALCULATE_PRICE, APPLY_COUPON } from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import RideService from "src/services/ride-service";
import {
  filterCarSuccess,
  filterCarError,
  getUserCardSuccess,
  getUserCardError,
  bookRideSuccess,
  bookRideError,
  rebookRideSuccess,
  rebookRideError,
  calculatePriceSuccess,
  calculatePriceError,
  applyCouponSuccess,
  applyCouponError,
} from "./action";

export function* watchFilterCar() {
  yield takeEvery(FILTER_CAR, filterCar);
}

const filterCarAsync = async (param) => {
  return RideService.filterCar(param);
};

function* filterCar({ payload }) {
  try {
    const response = yield call(filterCarAsync, payload.param);
    if (response.data.success) {
      yield put(filterCarSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(filterCarError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(filterCarError(error.response.data.message));
  }
}

export function* watchGetUserCard() {
  yield takeEvery(GET_USER_CARD, getUserCard);
}

const getUserCardAsync = async (id) => {
  return RideService.getUserCard(id);
};

function* getUserCard({ payload }) {
  try {
    const response = yield call(getUserCardAsync, payload.userId);
    if (response.data.success) {
      yield put(getUserCardSuccess(response.data?.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getUserCardError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getUserCardError(error.response.data.message));
  }
}

export function* watchBookRide() {
  yield takeEvery(BOOK_RIDE, bookRide);
}

const bookRideAsync = async (data) => {
  return RideService.bookRide(data);
};

function* bookRide({ payload }) {
  try {
    const response = yield call(bookRideAsync, payload.rideData);
    if (response.data.success) {
      yield put(bookRideSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(bookRideError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(bookRideError(error.response.data.message));
  }
}

export function* watchRebookRide() {
  yield takeEvery(REBOOK_RIDE, rebookRide);
}

const rebookRideAsync = async (data) => {
  return RideService.rebookRide(data);
};

function* rebookRide({ payload }) {
  try {
    const response = yield call(rebookRideAsync, payload.rebookData);
    if (response.data.success) {
      yield put(rebookRideSuccess(response.data.success, response.data.message));
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(rebookRideError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(rebookRideError(error.response.data.message));
  }
}

export function* watchCalculatePrice() {
  yield takeEvery(CALCULATE_PRICE, calculatePrice);
}

const calculatePriceAsync = async (data) => {
  return RideService.calculatePrice(data);
};

function* calculatePrice({ payload }) {
  try {
    const response = yield call(calculatePriceAsync, payload.rideData);
    if (response.data.success) {
      yield put(calculatePriceSuccess(response.data.data, response.data.success));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(calculatePriceError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(calculatePriceError(error.response.data.message));
  }
}

export function* watchApplyCoupon() {
  yield takeEvery(APPLY_COUPON, applyCoupon);
}

const applyCouponAsync = async (code, data) => {
  return RideService.applyCoupon(code, data);
};

function* applyCoupon({ payload }) {
  try {
    const response = yield call(applyCouponAsync, payload.coupon, payload.rideData);
    if (response.data.success) {
      yield put(applyCouponSuccess(response.data.data, response.data.success));
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(applyCouponError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(applyCouponError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchBookRide),
    fork(watchGetUserCard),
    fork(watchFilterCar),
    fork(watchRebookRide),
    fork(watchCalculatePrice),
    fork(watchApplyCoupon),
  ]);
}
