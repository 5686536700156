import React from "react";
import * as Mui from "@material-ui/core";
import { CheckboxField, SelectAutocompleteField } from "src/components/form";
import Square from "src/components/square";
import { formatCurrency } from "src/helpers/util";
import { IconCloseO } from "src/components/svg";

const useStyles = Mui.makeStyles((theme) => ({
  totalList: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "1rem",
    gap: "1rem",
    textAlign: "right",
    "&:not(:last-child)": {
      borderBottom: "1px solid #eee",
    },
    "& .MuiTypography-root": {
      minWidth: "20rem",
    },
  },
}));

const Payment = ({
  values,
  errors,
  setFieldValue,
  coupons,
  bookingCost,
  userCard,
  formikRef,
  loading,
  additionalChargeList,
}) => {
  const styles = useStyles();
  return (
    <Mui.Card className="default-card mt-3">
      <Mui.Box display="flex" flexWrap="wrap">
        <Mui.Typography component="h4" variant="h4" className="mb-4 mr-auto font-weight-medium">
          5. Payment Details
        </Mui.Typography>

        <CheckboxField name="enablePayment" label="Enable Payment?" />
      </Mui.Box>

      <Mui.Box className={styles.totalList}>
        <Mui.Typography className="font-weight-normal text-color-primary" component="h6" variant="h6">
          SUBTOTAL
        </Mui.Typography>
        <Mui.Typography className="font-weight-normal text-color-primary">
          {bookingCost?.subTotal ? formatCurrency(bookingCost?.subTotal) : formatCurrency(values?.subTotal)}
        </Mui.Typography>
      </Mui.Box>

      <Mui.Box className={styles.totalList}>
        <Mui.Typography className="font-weight-normal text-color-primary" component="h6" variant="h6">
          DEPOSIT
        </Mui.Typography>
        <Mui.Typography className="font-weight-normal text-color-primary">
          - {formatCurrency(values?.deposit)}
        </Mui.Typography>
      </Mui.Box>

      {values.vehicleTypeId ? (
        <Mui.Box className={styles.totalList}>
          <Mui.Typography className="font-weight-normal text-color-primary" component="h6" variant="h6">
            {values.couponId && (
              <Mui.IconButton
                size="small"
                className="svg-color-grey mr-1"
                onClick={() => setFieldValue("couponId", null)}
              >
                <IconCloseO />
              </Mui.IconButton>
            )}
            Coupon
          </Mui.Typography>

          {values.couponId ? (
            <Mui.Typography className="font-weight-normal text-color-primary">
              -{" "}
              {bookingCost?.couponDiscount
                ? formatCurrency(bookingCost?.couponDiscount)
                : formatCurrency(values?.couponDiscount)}
            </Mui.Typography>
          ) : (
            <Mui.Box className="form-group m-0" paddingLeft="1.5rem" style={{ width: "20rem" }}>
              <SelectAutocompleteField
                name="couponId"
                placeholder="Apply Coupon"
                options={coupons}
                disableClearable={false}
              />
            </Mui.Box>
          )}
        </Mui.Box>
      ) : null}

      {values?.additionalCharge?.length > 0 &&
        values.additionalCharge.map((item, index) => (
          <>
            {/* Show only if value is valid */}
            {!errors?.additionalCharge?.[index]?.additionalChargeId && !errors?.additionalCharge?.[index]?.value ? (
              <Mui.Box className={styles.totalList} key={index}>
                <Mui.Typography className="font-weight-normal text-color-primary" component="h6" variant="h6">
                  {additionalChargeList?.find((x) => x.id === item.additionalChargeId)?.name || ""}
                </Mui.Typography>
                <Mui.Typography className="font-weight-normal text-color-primary">
                  {additionalChargeList?.find((x) => x.id === item.additionalChargeId)?.charge === "negative"
                    ? "-"
                    : "+"}{" "}
                  {formatCurrency(item.value)}
                </Mui.Typography>
              </Mui.Box>
            ) : null}
          </>
        ))}

      <Mui.Box className={styles.totalList}>
        <Mui.Typography className="font-weight-bold text-color-primary" component="h6" variant="h6">
          TOTAL
        </Mui.Typography>
        <Mui.Typography className="font-weight-bold text-color-primary">
          {bookingCost?.total
            ? formatCurrency(bookingCost?.total ? parseFloat(bookingCost?.total) - parseFloat(values.deposit) : 0)
            : formatCurrency(values?.total ? parseFloat(values?.total) - parseFloat(values.deposit) : 0)}
        </Mui.Typography>
      </Mui.Box>

      {values.enablePayment === 1 ? (
        <Mui.Box my="1rem">
          {userCard?.card?.[0]?.cardId ? (
            <>
              <Mui.Box mb={1}>
                <CheckboxField name="useCardOnFile" label="Use existing Card-on-file" />
              </Mui.Box>
              {values.useCardOnFile === 1 ? (
                <>
                  <Mui.Card className="virtual-card">
                    <Mui.Typography component="span" variant="body1" className="card-type">
                      {userCard?.card?.[0]?.squareCard?.card_brand?.replace("_", " ") || "-"}
                    </Mui.Typography>

                    <Mui.Typography component="h4" variant="h4" className="card-number">
                      **** **** **** {userCard?.card?.[0]?.squareCard?.last_4 || "****"}
                    </Mui.Typography>

                    <Mui.Typography component="span" variant="body1" className="card-expiry">
                      {userCard?.card?.[0]?.squareCard?.exp_month || "-"}/
                      {userCard?.card?.[0]?.squareCard?.exp_year || "-"}
                    </Mui.Typography>

                    <Mui.Typography component="h5" variant="h5" className="card-holder">
                      {userCard?.card?.[0]?.squareCard?.cardholder_name || "-"}
                    </Mui.Typography>
                  </Mui.Card>

                  <Mui.Box width="100%" display="flex" justifyContent="center" className="mt-3">
                    <Mui.Button
                      className="w-100 pt-2 pb-2 btn-default flex-shrink-0"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={loading}
                    >
                      Save
                      {loading && <Mui.CircularProgress color="secondary" size={24} />}
                    </Mui.Button>
                  </Mui.Box>
                </>
              ) : (
                <Square setFieldValue={setFieldValue} bookNow={() => formikRef?.current?.handleSubmit()} />
              )}
            </>
          ) : (
            <Square setFieldValue={setFieldValue} bookNow={() => formikRef?.current?.handleSubmit()} />
          )}
        </Mui.Box>
      ) : (
        <Mui.Box width="100%" display="flex" justifyContent="center" className="mt-3">
          <Mui.Button
            className="w-100 pt-2 pb-2 btn-default flex-shrink-0"
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
          >
            Save
            {loading && <Mui.CircularProgress color="secondary" size={24} />}
          </Mui.Button>
        </Mui.Box>
      )}
    </Mui.Card>
  );
};
export default Payment;
