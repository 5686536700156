import Req from "src/interceptors/token-interceptor";

const DriverService = (function () {
  function _getAllDriver(param) {
    return Req.get(
      `/api/drivers?search=${param?.search || ""}&searchFields=${
        param?.searchFields || ""
      }`
    );
  }
  function _getDriver(id) {
    return Req.get("/api/drivers/" + id);
  }
  function _addDriver(data) {
    return Req.post("/api/drivers", data);
  }
  function _editDriver(data, id) {
    return Req.put("/api/drivers/" + id, data);
  }
  function _deleteDriver(id) {
    return Req.delete("/api/drivers/" + id);
  }
  function _deleteMultipleDriver(ids) {
    return Req.post("/api/drivers/delete", { ids: ids });
  }
  return {
    getAllDriver: _getAllDriver,
    getDriver: _getDriver,
    addDriver: _addDriver,
    editDriver: _editDriver,
    deleteDriver: _deleteDriver,
    deleteMultipleDriver: _deleteMultipleDriver,
  };
})();
export default DriverService;
