import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import Breadcrumb from "src/components/breadcrumb";
import { getAdditionalCharge, addAdditionalCharge, editAdditionalCharge } from "src/reduxs/actions";
import {
  InputField,
  TextareaField,
  //  StyledSwitch,
  SelectField,
} from "src/components/form";
class EditAdditionalCharge extends Component {
  constructor(props) {
    super(props);
    this.schema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      charge: Yup.string().required("Select charge"),
      value: Yup.string().test("check-price", "Invalid value", function (value) {
        return value > 0;
      }),
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getAdditionalCharge(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editAdditionalCharge(this.props.match.params.id, values, this.props.history);
      } else {
        this.props.addAdditionalCharge(values, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Additional Charge Management"}
          paths={[
            {
              title: "Additional Charge",
              page: `/additional-charge`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: this.props.match.params.id ? this.props.additionalChargeData?.name || "" : "",
            charge: this.props.match.params.id ? this.props.additionalChargeData?.charge || "" : "",
            value: this.props.match.params.id ? this.props.additionalChargeData?.value || "" : "",
            description: this.props.match.params.id ? this.props.additionalChargeData?.description || "" : "",
            // status: this.props.match.params.id ? this.props.additionalChargeData?.status || 1 : 1,
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Grid container spacing={6}>
                <Mui.Grid item xs={12} md={8} lg={6}>
                  <Mui.Card className="default-card">
                    <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                      Additional Charge Information
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="name" type="text" label="Name" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <SelectField
                          name="charge"
                          label="Select Type"
                          options={[
                            { id: "positive", name: "Positive" },
                            { id: "negative", name: "Negative" },
                          ]}
                        />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField
                          name="value"
                          type="number"
                          label="Default Value"
                          InputProps={{
                            startAdornment: <Mui.InputAdornment position="start">$</Mui.InputAdornment>,
                          }}
                        />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={12} md={12} className="form-group">
                        <TextareaField name="description" label="Description" />
                      </Mui.Grid>
                    </Mui.Grid>
                    {/* <Mui.FormControlLabel
                      classes={{
                        root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                        label: "mr-2",
                      }}
                      control={
                        <StyledSwitch
                          value={props.values.status}
                          checked={props.values.status === 1}
                          onChange={(e) => {
                            props.setFieldValue(`status`, e.target.checked ? 1 : 0);
                          }}
                          name={`status`}
                        />
                      }
                      label={"Status"}
                    /> */}
                  </Mui.Card>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5 mb-5"
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/additional-charge"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ additionalCharge }) => {
  const { additionalChargeData, success, message, loading, error } = additionalCharge;
  return {
    additionalChargeData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getAdditionalCharge,
  addAdditionalCharge,
  editAdditionalCharge,
})(Mui.withWidth()(EditAdditionalCharge));
