import React, { useState } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconAdd, IconGear } from "src/components/svg";
import ColumnFilter from "./column-filter";
import Search from "src/components/search";
import BulkSelection from "./bulk-selection";
import { DateFilter, AutocompleteFilter } from "src/components/filter";

const TableToolbar = (props) => {
  const {
    title,
    fieldTheme,
    numSelected,
    enableSearch,
    search,
    fromDateFilter,
    toDateFilter,
    jobFilter,
    handleSearch,
    handleFromDateFilter,
    handleToDateFilter,
    handleJobFilter,
    permission,
    route,
    createLabel,
    handleOpenAlert1,
    headCells,
    enableColumnFilter,
    enableDateRangeFilter,
    enableJobFilter,
    bulkSelectionIcon,
  } = props;
  const [showFilter, setShowFilter] = useState(Mui.isWidthUp("md", props.width));
  return (
    <Mui.Toolbar className="datatable-filter-bar">
      <Mui.Typography
        component="h3"
        variant="h3"
        className={`title ${Mui.isWidthDown("sm", props.width) ? "align-self-start" : "center"}`}
      >
        {title}
      </Mui.Typography>

      <Mui.Box
        width="100%"
        display="flex"
        flexWrap={Mui.isWidthDown("sm", props.width) ? "wrap" : "no-wrap"}
        justifyContent={`${Mui.isWidthDown("xs", props.width) ? "center" : "flex-end"}`}
        alignItems="center"
        className={`filter-bar-right ${Mui.isWidthDown("xs", props.width) ? "mt-2" : ""}`}
      >
        <Mui.Collapse
          in={showFilter || Mui.isWidthUp("md", props.width)}
          className={`${showFilter && !Mui.isWidthUp("md", props.width) ? "w-100" : ""}`}
          classes={{
            wrapperInner: `d-flex justify-content-end ${
              Mui.isWidthDown("xs", props.width) ? "flex-direction-column" : "row flex-wrap"
            }`,
          }}
        >
          {enableSearch ? <Search fieldTheme={fieldTheme} value={search} onSearch={handleSearch} /> : null}

          {enableJobFilter ? (
            <AutocompleteFilter
              label="Job No"
              onFilter={handleJobFilter}
              value={jobFilter}
              options={props.bookings || []}
              placeholder="Select Job No"
              disableClearable={false}
            />
          ) : null}

          {enableDateRangeFilter ? (
            <Mui.Box
              width={Mui.isWidthDown("xs", props.width) ? "100%" : "500px"}
              display="flex"
              flexWrap={"wrap"}
              gridGap="1.6rem"
              ml={Mui.isWidthDown("xs", props.width) ? "" : "1.6rem"}
              mt={Mui.isWidthDown("xs", props.width) ? "1.6rem" : ""}
            >
              <DateFilter
                fieldTheme={fieldTheme}
                label="From"
                filterValue={fromDateFilter}
                onFilter={handleFromDateFilter}
              />
              <DateFilter
                fieldTheme={fieldTheme}
                label="To"
                filterValue={toDateFilter}
                onFilter={handleToDateFilter}
                minDate={fromDateFilter}
              />
            </Mui.Box>
          ) : null}
        </Mui.Collapse>

        <Mui.Hidden smUp>
          <Mui.Divider light={true} className="w-100 mb-1" />
        </Mui.Hidden>

        {/* Bulk Selection Action */}
        {numSelected > 0 ? (
          <BulkSelection icon={bulkSelectionIcon} selected={numSelected} action={() => handleOpenAlert1()} />
        ) : null}

        {numSelected === 0 ? (
          <>
            {permission?.add ? (
              <Mui.Button
                color="primary"
                size="small"
                disableElevation
                component={Link}
                to={`${route}/add`}
                className={`flex-shrink-0 pl-1 ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"}`}
              >
                {createLabel}
                <span className="ml-1 line-height-null">
                  <IconAdd />
                </span>
              </Mui.Button>
            ) : null}

            {enableColumnFilter ? <ColumnFilter headers={headCells} /> : null}
          </>
        ) : null}

        {enableSearch || enableJobFilter || enableDateRangeFilter ? (
          <>
            <Mui.Hidden mdUp>
              <Mui.IconButton
                color="primary"
                variant="outlined"
                className="btn-icon-square ml-2"
                onClick={() => setShowFilter(!showFilter)}
              >
                <IconGear />
              </Mui.IconButton>
            </Mui.Hidden>

            <Mui.Hidden smUp>
              <Mui.Divider light={true} className="w-100 mt-1" />
            </Mui.Hidden>
          </>
        ) : null}
      </Mui.Box>
    </Mui.Toolbar>
  );
};
const mapStateToProps = ({ booking }) => {
  const { bookings } = booking;
  return { bookings };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(TableToolbar));
