import {
  GET_ALL_INVOICE,
  GET_ALL_INVOICE_SUCCESS,
  GET_ALL_INVOICE_ERROR,
  GET_INVOICE_LIST,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_ERROR,
  GET_USER_INVOICE_LIST,
  GET_USER_INVOICE_LIST_SUCCESS,
  GET_USER_INVOICE_LIST_ERROR,
  ADD_INVOICE,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_ERROR,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  DELETE_MULTIPLE_INVOICE,
  DELETE_MULTIPLE_INVOICE_SUCCESS,
  DELETE_MULTIPLE_INVOICE_ERROR,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
  RESET_INVOICE,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  invoices: null,
  invoiceId: null,
  invoiceList: null,
  userInvoiceList: null,
  invoiceData: null,
  invoiceIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  downloading: false,
  error: null,
};

const invoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_INVOICE:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        error: null,
      };
    case GET_ALL_INVOICE_ERROR:
      return {
        ...state,
        invoices: null,
        error: action.payload,
      };
    case GET_INVOICE_LIST:
      return { ...state, loading: true, error: null };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceList: action.payload,
        error: null,
      };
    case GET_INVOICE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        invoiceList: null,
        error: action.payload,
      };
    case GET_USER_INVOICE_LIST:
      return { ...state, loading: true, error: null };
    case GET_USER_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userInvoiceList: action.payload,
        error: null,
      };
    case GET_USER_INVOICE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        userInvoiceList: null,
        error: action.payload,
      };
    case ADD_INVOICE:
      return { ...state, loading: true, error: null };
    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_INVOICE:
      return { ...state, error: null };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload,
        error: null,
      };
    case GET_INVOICE_ERROR:
      return {
        ...state,
        invoiceData: null,
        error: action.payload,
      };
    case DELETE_INVOICE:
      return { ...state, loading1: true, error: null };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_INVOICE:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_INVOICE:
      return { ...state, downloading: true, error: null };
    case DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        downloading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_INVOICE_ERROR:
      return {
        ...state,
        downloading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_INVOICE:
      return {
        ...state,
        loading: false,
        downloading: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default invoiceReducer;
