import React from "react";

const IconCarFront = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      width="22"
      height="22"
      viewBox="0 0 22 15.85"
    >
      <g>
        <path d="M10.97,16.27" />
        <path
          d="M17.82,14.39H4.18c-1.25,0-4.18,0-4.18-2.92v-1.24c0-2.24,1.55-5.49,3.99-5.49c0.41,0,0.75,0.33,0.75,0.75
		c0,0.41-0.33,0.75-0.75,0.75c-1.26,0-2.5,2.29-2.5,3.99v1.24c0,0.97,0.4,1.43,2.69,1.43h13.64c2.28,0,2.69-0.46,2.69-1.43v-1.24
		c0-1.05-0.65-2.75-1.62-3.59c-0.37-0.32-0.73-0.46-1.04-0.41C17.43,6.29,17.06,6,17,5.59c-0.06-0.41,0.22-0.79,0.63-0.84
		c0.76-0.11,1.53,0.15,2.23,0.76C21.06,6.55,22,8.62,22,10.23v1.24C22,14.39,19.07,14.39,17.82,14.39z"
        />
        <path
          d="M18.76,6.11c-0.3,0-0.58-0.18-0.7-0.48c-0.57-1.5-1.49-2.89-2.73-4.14H6.67C5.43,2.74,4.51,4.13,3.94,5.63
		C3.8,6.02,3.36,6.21,2.98,6.06C2.6,5.92,2.4,5.49,2.55,5.1c0.66-1.73,1.72-3.33,3.16-4.75l0.06-0.07C5.91,0.11,6.12,0,6.36,0h9.29
		c0.24,0,0.45,0.11,0.59,0.28l0.07,0.07c1.44,1.42,2.5,3.02,3.16,4.75c0.15,0.39-0.05,0.82-0.43,0.96
		C18.94,6.1,18.85,6.11,18.76,6.11z"
        />
        <path
          d="M4.26,15.85H2.21c-0.41,0-0.75-0.33-0.75-0.75v-1.46c0-0.41,0.33-0.75,0.75-0.75s0.75,0.33,0.75,0.75v0.72
		h0.56v-0.72c0-0.41,0.33-0.75,0.75-0.75c0.41,0,0.75,0.33,0.75,0.75v1.46C5.01,15.52,4.67,15.85,4.26,15.85z"
        />
        <path
          d="M19.79,15.85h-2.05c-0.41,0-0.75-0.33-0.75-0.75v-1.46c0-0.41,0.33-0.75,0.75-0.75s0.75,0.33,0.75,0.75v0.72
		h0.56v-0.72c0-0.41,0.33-0.75,0.75-0.75c0.41,0,0.75,0.33,0.75,0.75v1.46C20.54,15.52,20.21,15.85,19.79,15.85z"
        />
        <path d="M11,14.91" />
        <path
          d="M13.58,11.4H8.55c-0.41,0-0.75-0.33-0.75-0.75c0-0.41,0.33-0.75,0.75-0.75h5.03c0.41,0,0.75,0.33,0.75,0.75
		C14.33,11.07,13.99,11.4,13.58,11.4z"
        />
        <path
          d="M5.6,11.44H3.42c-0.41,0-0.75-0.33-0.75-0.75c0-0.41,0.33-0.75,0.75-0.75H5.6c0.41,0,0.75,0.33,0.75,0.75
		C6.35,11.1,6.02,11.44,5.6,11.44z"
        />
        <path
          d="M18.58,11.44H16.4c-0.41,0-0.75-0.33-0.75-0.75c0-0.41,0.33-0.75,0.75-0.75h2.18c0.41,0,0.75,0.33,0.75,0.75
		C19.32,11.1,18.99,11.44,18.58,11.44z"
        />
        <path
          d="M1.06,5.57c1.02,0.29,4.75,1.24,9.96,1.24c6.02,0,9.84-1.21,9.99-1.26C21.41,5.42,21.63,5,21.5,4.61
		C21.37,4.22,20.95,4,20.56,4.13c-0.04,0.01-3.75,1.19-9.54,1.19c-5.02,0-8.58-0.9-9.55-1.18C1.12,4.04,0.69,4.2,0.53,4.53
		C0.53,4.53,0.28,5.27,1.06,5.57z"
        />
      </g>
    </svg>
  );
};

export default IconCarFront;
