import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GET_CLIENT_LIST } from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import TableDataService from "src/services/table-data-service";
import { getClientListSuccess, getClientListError } from "./action";

export function* watchGetClientList() {
  yield takeEvery(GET_CLIENT_LIST, getClientListAc);
}

const getClientListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "customers",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getClientListAc({ payload }) {
  try {
    const response = yield call(getClientListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getClientListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getClientListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getClientListError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetClientList)]);
}
