import React, { useState, useEffect, useRef } from "react";
import * as Mui from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import {
  getAllRoute,
  filterCar,
  getSchedular,
  applyCoupon,
  calculatePrice,
  bookRide,
  resetPrice,
  resetRide,
  resetCoupon,
  getUserCard,
} from "src/reduxs/actions";
import bannerImg from "src/assets/images/website/feature-image-5.jpg";
import ToastElement from "src/components/toast";
import schema from "./schema";
import Location from "./location";
import Vehicle from "./vehicle";
import Contact from "./contact";
import Summary from "./summary";
import Success from "./success";
import Map from "./map";

const Ride = (props) => {
  // const [activeStep, setActiveStep] = useState(
  //   props.location?.state?.rideDetail?.vehicleTypeId
  //     ? 2
  //     : props.location?.state?.rideDetail?.pickupDate
  //     ? 1
  //     : 0
  // );
  const [activeStep, setActiveStep] = useState(0);
  const formikRef = useRef();

  const checkCompletedStep = (step) => {
    let values = formikRef?.current?.values || {};
    if (step === 0) {
      if (values.pickupDate && values.pickupTime) {
        if (values.type === "distance") {
          if (values.pickupLocation && values.dropOffLocation) {
            return true;
          }
        }
        if (values.type === "hourly") {
          if (values.pickupLocation && values.durationInHour && values.dropOffLocation) {
            return true;
          }
        }
        if (values.type === "flat_rate") {
          if (values.routeId) {
            return true;
          }
        }
      }
    }
    if (step === 1) {
      if (parseFloat(values.totalDistance) < 100 && values.noPassenger && values.noLuggage && values.vehicleTypeId) {
        return true;
      }
    }
    if (step === 2) {
      if (
        parseFloat(values.totalDistance) < 100 &&
        values.firstName &&
        values.lastName &&
        values.email &&
        values.phone
      ) {
        return true;
      }
    }
    if (step === 3) {
      if (activeStep === steps.length) {
        return true;
      }
    }
    return false;
  };

  const getSteps = () => {
    return ["Enter Ride Details", "Vehicle Selection", "Enter Contact Details", "Booking Summary"];
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep - 1);
  };

  const onFilter = (passenger, luggage, routeId = null) => {
    props.filterCar({
      passenger: passenger,
      luggage: luggage,
      routeId: routeId,
    });
  };

  const applyCoupon = (code, values) => {
    if (code) {
      if (props.couponApplied) {
        toast.error(
          <ToastElement type="error" message={"The coupon is already applied once. Multiple discounts not allowed."} />,
          {
            containerId: "default",
            autoClose: 8000,
          }
        );
      } else {
        if (!props.loading3) props.applyCoupon(code, values);
      }
    }
  };

  const calculateBookingPrice = (values) => {
    if (!props.loading2) props.calculatePrice(values);
  };

  const resetCouponApplied = () => {
    props.resetCoupon();
  };

  const resetPriceCalculation = () => {
    props.resetPrice();
    props.resetCoupon();
  };

  const submitForm = async (values) => {
    values.subTotal = props.priceData?.subTotal || 0;
    values.total = props.priceData?.total || 0;
    values.coupon = props.priceData?.coupon || "";
    values.couponId = props.priceData?.couponId || "";
    values.couponDiscount = props.priceData?.couponDiscount || 0;
    props.bookRide(values);
  };

  const handleSubmit = (values, actions) => {
    if (activeStep === getSteps().length - 1) {
      submitForm(values);
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
      actions.setTouched({});
    }
  };

  const handleStep = (step) => () => {
    if (checkCompletedStep(step)) {
      window.scrollTo(0, 0);
      setActiveStep(step);
    }
  };

  const steps = getSteps();

  const currentValidationSchema = schema[activeStep];

  const onScroll = (event) => {
    let bannerHeight = document.querySelector(".inner-banner")?.offsetHeight;
    let scrollTop = window.scrollY;
    if (scrollTop > bannerHeight / 3.5) {
      document.querySelector(".booking-sticky-btn-group")?.classList.add("sticky");
    } else {
      document.querySelector(".booking-sticky-btn-group")?.classList.remove("sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("currentUser"))?.id) {
      props.getUserCard(JSON.parse(localStorage.getItem("currentUser")).id);
    }
    props.getAllRoute();
    props.filterCar({
      passenger: 1,
      luggage: 1,
      routeId: "",
    });
    props.getSchedular();
    // const scrollHeight = window.innerHeight * 0.3;
    // setTimeout(() => {
    //   window.scrollTo({ top: scrollHeight, left: 0, behavior: "smooth" });
    // }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.error) {
      props.resetRide();
    } else {
      if (!props.loading && props.success) {
        setActiveStep(activeStep + 1);
        props.resetRide();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error, props.success, props.loading]);

  return (
    <WebsiteLayout>
      <PageMeta />

      <section className="inner-banner" style={{ height: "42vh" }}>
        <img src={bannerImg} alt="" />

        <Mui.Container maxWidth="md" className="inner-banner__content">
          <Mui.Breadcrumbs className="text-color-white">
            <Mui.Link component={Link} to={"/"} className="text-color-white">
              Home
            </Mui.Link>
            <Mui.Link underline="none" className="text-color-white">
              Book a Ride
            </Mui.Link>
          </Mui.Breadcrumbs>

          <Mui.Typography component="h1" variant="h2">
            Book A Ride
          </Mui.Typography>
        </Mui.Container>
      </section>

      <Mui.Box mt={-8} className="section-default">
        <Mui.Container maxWidth="md" className="pos-relative overflow-visible">
          <div className="w-100">
            <Mui.Stepper nonLinear alternativeLabel activeStep={activeStep} className="booking-step-form-stepper">
              {steps.map((label, index) => {
                return (
                  <Mui.Step key={index} completed={checkCompletedStep(index)}>
                    <Mui.StepButton onClick={handleStep(index)}>{label}</Mui.StepButton>
                  </Mui.Step>
                );
              })}
            </Mui.Stepper>

            {activeStep === steps.length ? (
              <Success history={props.history} />
            ) : (
              <Formik
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={{
                  referenceNumber: "",
                  type: props.location?.state?.rideDetail?.type || "distance",
                  pickupDate: props.location?.state?.rideDetail?.pickupDate || null,
                  pickupTime: props.location?.state?.rideDetail?.pickupTime || "",
                  pickupLocation: props.location?.state?.rideDetail?.pickupLocation || "",
                  waypoint: [],
                  dropOffLocation: props.location?.state?.rideDetail?.dropOffLocation || "",
                  durationInHour: props.location?.state?.rideDetail?.durationInHour || "01:00:00",
                  routeId: props.location?.state?.rideDetail?.routeId || "",
                  flightType: props.location?.state?.rideDetail?.flightType || "",
                  flightNumber: props.location?.state?.rideDetail?.flightNumber || "",
                  totalDistance: props.location?.state?.rideDetail?.totalDistance || 0,
                  totalTime: props.location?.state?.rideDetail?.totalTime || "00:00:00",
                  subTotal: 0,
                  total: 0,
                  coupon: "",
                  couponDiscount: 0,
                  noPassenger: "1",
                  noLuggage: "1",
                  extraLargeLuggage: 0,
                  vehicleTypeId: props.location?.state?.rideDetail?.vehicleTypeId || "",
                  babySeatRearFacing: 0,
                  childSeatForwardFacing: 0,
                  boosterSeat: 0,
                  other: 0,
                  firstName: JSON.parse(localStorage.getItem("currentUser"))?.firstName || "",
                  lastName: JSON.parse(localStorage.getItem("currentUser"))?.lastName || "",
                  email: JSON.parse(localStorage.getItem("currentUser"))?.email || "",
                  phone: JSON.parse(localStorage.getItem("currentUser"))?.phone || "",
                  passengerNames: "",
                  passengerPhones: "",
                  alternatePhone: "",
                  comment: "",
                  additionalComment: "",
                  userId: JSON.parse(localStorage.getItem("currentUser"))?.id || "",
                  paymentToken: "",
                  useCardOnFile: props.userCard?.card?.[0]?.cardId ? 1 : 0,
                }}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form className="default-form pos-relative mt-5">
                    <Mui.Grid container spacing={4} wrap="wrap" className={`${activeStep === 0 ? "" : "d-none"}`}>
                      <Mui.Grid item xs={12} md={6}>
                        <Location
                          touched={touched}
                          errors={errors}
                          values={values}
                          setFieldValue={setFieldValue}
                          routes={props.routes}
                          carList={props.carList}
                          onFilter={onFilter}
                          calculateBookingPrice={calculateBookingPrice}
                          resetPriceCalculation={resetPriceCalculation}
                        />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} md={6}>
                        <Map values={values} routes={props.routes} setFieldValue={setFieldValue} />
                      </Mui.Grid>
                    </Mui.Grid>

                    <div className={`${activeStep === 1 ? "" : "d-none"}`}>
                      <Vehicle
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        carList={props.carList}
                        userCard={props.userCard}
                        onFilter={onFilter}
                        routes={props.routes}
                        loading={props.loading1}
                        priceData={props.priceData}
                        resetPriceCalculation={resetPriceCalculation}
                      />
                    </div>

                    <div className={`${activeStep === 2 ? "" : "d-none"}`}>
                      <Contact
                        values={values}
                        carList={props.carList}
                        userCard={props.userCard}
                        setFieldValue={setFieldValue}
                        routes={props.routes}
                        priceData={props.priceData}
                      />
                    </div>

                    <div className={`${activeStep === 3 ? "" : "d-none"}`}>
                      <Summary
                        values={values}
                        applyCoupon={applyCoupon}
                        calculateBookingPrice={calculateBookingPrice}
                        resetCouponApplied={resetCouponApplied}
                        couponApplied={props.couponApplied}
                        carList={props.carList}
                        userCard={props.userCard}
                        setFieldValue={setFieldValue}
                        routes={props.routes}
                        goToStep={handleStep}
                        formikRef={formikRef}
                        loading={props.loading}
                        loading3={props.loading3}
                        priceData={props.priceData}
                      />
                    </div>

                    <Mui.Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      className="mt-5 booking-sticky-btn-group"
                    >
                      {activeStep !== 0 ? (
                        <Mui.Button
                          className={`btn-default btn-booking ml-2 mr-2`}
                          type="button"
                          color="primary"
                          variant="contained"
                          disableElevation
                          onClick={handleBack}
                          disabled={props.loading}
                        >
                          Back
                        </Mui.Button>
                      ) : null}

                      {activeStep !== 3 ? (
                        <>
                          {/* Hide btn if totalDistance > 100 km */}
                          {activeStep === 0 &&
                          (values.type === "distance" || values.type === "hourly") &&
                          parseFloat(values.totalDistance) >= 100 ? null : (
                            <Mui.Button
                              type="submit"
                              className={` btn-default btn-booking ml-2 mr-2`}
                              color="primary"
                              variant="contained"
                              disableElevation
                            >
                              Continue
                            </Mui.Button>
                          )}
                        </>
                      ) : null}
                    </Mui.Box>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Mui.Container>
      </Mui.Box>
    </WebsiteLayout>
  );
};
const mapStateToProps = ({ ride, route, shared }) => {
  const { routes } = route;
  const { schedules } = shared;
  const {
    carList,
    userCard,
    priceData,
    couponApplied,
    loading,
    loading1,
    loading2,
    loading3,
    success,
    success1,
    success2,
    message,
    error,
    error1,
    error2,
  } = ride;
  return {
    carList,
    userCard,
    priceData,
    couponApplied,
    routes,
    schedules,
    success,
    success1,
    success2,
    message,
    loading,
    loading1,
    loading2,
    loading3,
    error,
    error1,
    error2,
  };
};

export default connect(mapStateToProps, {
  getAllRoute,
  filterCar,
  getSchedular,
  applyCoupon,
  calculatePrice,
  bookRide,
  resetPrice,
  resetRide,
  resetCoupon,
  getUserCard,
})(Mui.withWidth()(Ride));
