import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import Breadcrumb from "src/components/breadcrumb";
import { getCar, editCar, addCar } from "src/reduxs/actions";
import { InputField, TextareaField, SelectField, StyledSwitch } from "src/components/form";
import Dropzone from "src/components/dropzone";
import { vehicleCategory } from "src/configs/constant";
class EditCar extends Component {
  constructor(props) {
    super(props);
    this.schema = Yup.object().shape({
      name: Yup.string()
        .required("Please enter car name")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .nullable(),
      vehicleCategoryId: Yup.string().required("Please select category").nullable(),
      maxPassenger: Yup.string()
        .required("Please enter max passenger limit of car")
        .test("check-price", "Invalid value", function (value) {
          return value >= 1;
        })
        .nullable(),
      maxLuggage: Yup.string()
        .required("Please enter max luggage limit of car")
        .test("check-price", "Invalid value", function (value) {
          return value >= 1;
        })
        .nullable(),
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getCar(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editCar(this.props.match.params.id, values, this.props.history);
      } else {
        this.props.addCar(values, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title="Vehicle Type Management"
          paths={[
            {
              title: "Vehicle Type",
              page: `/car`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: this.props.match.params.id ? this.props.carData?.name || "" : "",
            vehicleCategoryId: this.props.match.params.id ? this.props.carData?.vehicleCategoryId || "" : "",
            description: this.props.match.params.id ? this.props.carData?.description || "" : "",
            maxPassenger: this.props.match.params.id ? this.props.carData?.maxPassenger || "" : "",
            maxLuggage: this.props.match.params.id ? this.props.carData?.maxLuggage || "" : "",
            engine: this.props.match.params.id ? this.props.carData?.engine || "" : "",
            interiorColor: this.props.match.params.id ? this.props.carData?.interiorColor || "" : "",
            exteriorColor: this.props.match.params.id ? this.props.carData?.exteriorColor || "" : "",
            power: this.props.match.params.id ? this.props.carData?.power || "" : "",
            transmission: this.props.match.params.id ? this.props.carData?.transmission || "" : "",
            fuelType: this.props.match.params.id ? this.props.carData?.fuelType || "" : "",
            extra: this.props.match.params.id ? this.props.carData?.extra || "" : "",
            status: this.props.match.params.id ? this.props.carData?.status || 1 : 1,
            vehicleTypePhoto: this.props.match.params.id ? this.props.carData?.vehicleTypePhoto || [] : [],
            vehicleTypeDocument: this.props.match.params.id ? this.props.carData?.vehicleTypeDocument || [] : [],
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Grid container spacing={3} wrap="wrap">
                <Mui.Grid item xs={12} md={6}>
                  <Mui.Card className="default-card">
                    <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                      Basic Information
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="name" type="text" label="Name" />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <SelectField
                          name="vehicleCategoryId"
                          label="Select Vehicle Category"
                          options={vehicleCategory || []}
                        />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="maxPassenger" type="number" label="Max Passenger" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={6} className="form-group">
                        <InputField name="maxLuggage" type="number" label="Max Luggage" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={12} md={12} className="form-group">
                        <TextareaField name="description" label="Description" />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm={12} md={12} className="form-group">
                        <Mui.FormControlLabel
                          classes={{
                            root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                            label: "mr-2",
                          }}
                          control={
                            <StyledSwitch
                              value={props.values.status}
                              checked={props.values.status === 1}
                              onChange={(e) => {
                                props.setFieldValue(`status`, e.target.checked ? 1 : 0);
                              }}
                              name={`status`}
                            />
                          }
                          label={"Status"}
                        />
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Divider light={true} className="mt-3 mb-5" />

                    <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                      Extra Details
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="engine" type="text" label="Engine Info" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="interiorColor" type="text" label="Interior Color" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="exteriorColor" type="text" label="Exterior Color" />
                      </Mui.Grid>

                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="power" type="text" label="Power" />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="transmission" type="text" label="Transmission" />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                        <InputField name="fuelType" type="text" label="Fuel Type" />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm={12} md={12} className="form-group">
                        <TextareaField name="extra" label="Extra Description" />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Card>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={6} className={Mui.isWidthDown("sm", this.props.width) ? "mt-4" : ""}>
                  <Mui.Card className="default-card">
                    <Mui.Typography component="h4" variant="h4" className="mb-2 font-weight-medium">
                      Upload Pictures
                    </Mui.Typography>
                    <Dropzone
                      multiple
                      accept={"image/*"}
                      init
                      markFeature
                      field="vehicleTypePhoto"
                      imgArr={props.values?.vehicleTypePhoto || []}
                      upload={props.setFieldValue}
                    />
                    <Mui.Divider light={true} className="mt-2 mb-3" />

                    <Mui.Typography component="h4" variant="h4" className="mb-2 font-weight-medium">
                      Upload Vehicle Documents
                    </Mui.Typography>
                    <Dropzone
                      multiple
                      accept={"image/*,application/pdf,.doc,.docx"}
                      init
                      field="vehicleTypeDocument"
                      imgArr={props.values?.vehicleTypeDocument || []}
                      upload={props.setFieldValue}
                    />
                  </Mui.Card>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5 mb-5"
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/car"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ car }) => {
  const { carData, success, message, loading, error } = car;
  return {
    carData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getCar,
  editCar,
  addCar,
})(Mui.withWidth()(EditCar));
