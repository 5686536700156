import React, { Component } from "react";
import PageMeta from "src/components/pages-meta";
import * as Mui from "@material-ui/core";
import { WebsiteLayout } from "src/layouts";
import { Link } from "react-router-dom";
import bannerImg from "src/assets/images/website/inner-hero-banner.jpg";
import ceoImg from "src/assets/images/website/ceo.png";
import car1 from "src/assets/images/website/about-hero-section.jpg";
import car2 from "src/assets/images/website/about-hero-section-2.jpg";
import featureImg1 from "src/assets/images/website/feature-image-1.jpg";
import featureImg2 from "src/assets/images/website/feature-image-2.jpg";
import featureImg3 from "src/assets/images/website/feature-image-3.jpg";
import featureImg4 from "src/assets/images/website/feature-image-4.jpg";
import { IconCarFront } from "src/components/svg";
class AboutView extends Component {
  componentDidMount() {
    if (window.location.hash) {
      setTimeout(() => {
        document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }

  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Shangri-La Chauffers provides stylish and comfortable vehicles with professional drivers in every major Australian city. Ideal for corporate transfers, airport transfers, conferences, weddings, special events and tours." />

        <section className="inner-banner">
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                About Us
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              About SHANGRI-LA
            </Mui.Typography>
          </Mui.Container>
        </section>

        <section className="section-default pt-0 text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal text-color-primary">
              What is Shangri-La and what do we want to achieve?
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
              Shangri-La: A remote imaginary place where life approaches perfection.
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
              Our ethos behind our service lies in the meaning behind Shangri-La; here we strive for perfection.
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
              At Shangri-La, we believe the journey to be as important as the destination and as such ensure your
              journey is of superlative experience.
            </Mui.Typography>

            <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
              Established in 2013 with an aim to provide quintessential travel experience. Almost a decade in since our
              start, and Shangri-La has gained a reputation for being second to none on providing professional and
              exceptional services. Operating across all the major cities in Australia with the capacity to provide
              transfers for personal, Large group and sizeable public events. Shangri-La can cater to all!
            </Mui.Typography>
          </Mui.Container>
        </section>

        <section className="section-default about-ceo-section pt-0 text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Box display="block">
              <figure className="pos-relative">
                <img
                  className="w-100 h-100 pos-absolute pos-top-per-0 pos-left-per-0 object-fit-cover object-position-top border-rad-10"
                  src={ceoImg}
                  alt="Manjil Pradhan"
                />
              </figure>
              <div>
                <Mui.Typography
                  component="h2"
                  variant="h2"
                  className={`section-title text-color-white ${
                    Mui.isWidthDown("xs", this.props.width) ? "text-center" : ""
                  }`}
                >
                  <Mui.Typography component="span" variant="h6" className="d-block">
                    CEO
                  </Mui.Typography>
                  Manjil Pradhan
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  I started my journey as a chauffeur in 2010. I loved this industry from my very first day on the job!
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  Being a chauffeur driver allowed me to meet many different types of people from business and industry,
                  as well as travellers.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  The job was a perfect fit for my personality, as I loved both travelling and meeting new people with
                  different backgrounds and experiences.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  I built very strong relationships, both professionally and personally, with many of my customers as a
                  chauffeur driver.
                </Mui.Typography>

                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  At the end of 2013, I decided to start my own chauffeur service. I wanted to offer a luxury,
                  high-quality and personalised experience to every single customer – so Shangrila-La Chauffeurs was
                  born.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  I handpicked only the best chauffeurs who shared my vision, and this amazing, positive team has only
                  grown over the years.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  Shangri-La is now a highly respected chauffeured vehicle service for a wide range of people –
                  including business people, families, tourists and wedding couples – just as I imagined it so many
                  years ago.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  I love running this business, but I also still love travelling to faraway places, experiencing unique
                  cultures and meeting interesting people. I’m also quite a foodie, so I enjoy trying new cuisines
                  wherever I go.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  However, I’m also focused on growing Shangri-La into the number one chauffeured vehicle service in
                  Australia.
                </Mui.Typography>
              </div>
            </Mui.Box>
          </Mui.Container>
        </section>

        {/* Services Sections here */}
        <Mui.Box
          minHeight={Mui.isWidthDown("sm", this.props.width) ? "auto" : "45rem"}
          display="flex"
          alignItems="center"
          component="section"
          className="section-default about-section-1"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={6}>
                <Mui.Typography component="h2" variant="h2">
                  GET THERE IN COMFORT AND STYLE AT{" "}
                  <span className="d-block text-color-primary">SHANGRI-LA CHAUFFEURS</span>
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>

          <figure className="floating-img">
            <img src={car1} alt="CHAUFFEURS CAR BANNER" />
          </figure>
        </Mui.Box>

        <Mui.Box
          id="airport"
          minHeight={Mui.isWidthDown("sm", this.props.width) ? "auto" : "55rem"}
          component="section"
          className="section-default about-section-2 text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={4}>
              <Mui.Grid component="article" item xs={12} md={7}>
                <Mui.Typography component="h2" variant="h2" className="text-color-white">
                  <span className="text-color-primary">Airport</span> Transfers
                </Mui.Typography>
                <Mui.Box
                  width={80}
                  minHeight={4}
                  component="hr"
                  className="mt-2 mb-4 mr-auto ml-0 border-0 background-gradient-primary-fade"
                />
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  At Shangri-La we offer quick and easy Airport transfers for both Corporate and Private travel, so you
                  can travel in peace. Whether you are on tight schedule or new to the city, Our experienced chauffeurs
                  will be there to assist you at every step. Our experienced drivers are well versed incity traffics and
                  know the fastest routes to get you to where you want to be. We take pride in providing reliable and
                  fast services. With timely and trusted drivers, you can always count onus!
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h3"
                  className="mt-3 mb-0-8 text-color-primary font-weight-normal"
                >
                  What we offer in our Airport transfers
                </Mui.Typography>
                <ul className="mb-2 text-color-grey font-weight-normal line-height-1-5 font-size-md-2x">
                  <li>Meet and greet</li>
                  <li>Luggage assistance</li>
                  <li>Complimentary 1 hour wait time</li>
                </ul>

                <Mui.Button
                  component={Link}
                  // to={"/ride"}
                  to="/contact"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                  <span className="ml-1 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>

          <Mui.Hidden smDown>
            <figure className="floating-img">
              <img src={car2} alt="Airport Transfers" />
            </figure>
          </Mui.Hidden>
        </Mui.Box>

        <Mui.Box id="business" component="section" className="section-default p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Hidden smDown>
                <Mui.Grid component="figure" item xs={12} md={5} className="overflow-hidden">
                  <img className="img-fluid-cover border-rad-10" src={featureImg2} alt="Business Transfers" />
                </Mui.Grid>
              </Mui.Hidden>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography component="h2" variant="h2" className="text-color-white">
                  <span className="text-color-primary">Business</span> Transfers
                </Mui.Typography>
                <Mui.Box
                  width={80}
                  minHeight={4}
                  component="hr"
                  className="mt-2 mb-4 mr-auto ml-0 border-0 background-gradient-primary-fade"
                />
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  A core part of a business function is providing results on time. Who better to understand the value of
                  time than a business whose function revolves around time. Here at Shangri-La we are known for our
                  prompt and expeditious services in corporate and business transfers.Our clients value us for valuing
                  their time. We understand the importance of being on schedule and pre-plan your journey so you can sit
                  back and relax. Our diligent drivers are competent in navigating city traffic and are meticulous in
                  their routes, ensuring you are on time, every time.
                </Mui.Typography>

                <Mui.Button
                  component={Link}
                  // to={"/ride"}
                  to="/contact"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                  <span className="ml-1 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box id="wedding" component="section" className="section-default p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid component="article" item xs={12} md={7}>
                <Mui.Typography component="h2" variant="h2" className="text-color-white">
                  <span className="text-color-primary">Wedding</span> Cars
                </Mui.Typography>
                <Mui.Box
                  width={80}
                  minHeight={4}
                  component="hr"
                  className="mt-2 mb-4 mr-auto ml-0 border-0 background-gradient-primary-fade"
                />
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  For your perfect day, Shangri-La will deliver a personalised service for your big day. With our
                  exceptional drivers and VIP door to door service, your day will be nothing less than perfect. We
                  understand how important this day will be for you and our experienced drivers will ensure your travel
                  to the venue will be of comfortable and most importantly memorable one.
                </Mui.Typography>
                <Mui.Button
                  component={Link}
                  // to={"/ride"}
                  to="/contact"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                  <span className="ml-1 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              </Mui.Grid>

              <Mui.Hidden smDown>
                <Mui.Grid component="figure" item xs={12} md={5} className="overflow-hidden">
                  <img className="img-fluid-cover border-rad-10" src={featureImg4} alt="Wedding Cars" />
                </Mui.Grid>
              </Mui.Hidden>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box id="tour" component="section" className="section-default p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Hidden smDown>
                <Mui.Grid component="figure" item xs={12} md={5} className="overflow-hidden">
                  <img className="img-fluid-cover border-rad-10" src={featureImg1} alt=" Tours" />
                </Mui.Grid>
              </Mui.Hidden>
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography component="h2" variant="h2" className="text-color-white">
                   Tours
                </Mui.Typography>
                <Mui.Box
                  width={80}
                  minHeight={4}
                  component="hr"
                  className="mt-2 mb-4 mr-auto ml-0 border-0 background-gradient-primary-fade"
                />
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  We offer chauffeured tours of major cities in Australia and New Zealand. Just tell us where you want
                  to go and we’ll design a tour for you.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  Our stylish and comfortable vehicles are perfect for exploring new areas, including Sydney Harbour,
                  WA’s Margaret River region, Gold Coast beaches, SA’s Barossa Valley or snowy Queenstown in New
                  Zealand.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  Our friendly and reliable drivers will get you there safely. They can even make suggestions and tell
                  you about an area’s history and landmarks.
                </Mui.Typography>
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  We pride ourselves on our prompt and reliable service, so we’ll always be there every time! Whether
                  it’s a fun day at the beach, in the city or in the country, the only limit is your imagination.
                </Mui.Typography>
                <Mui.Button
                  component={Link}
                  // to={"/ride"}
                  to="/contact"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                  <span className="ml-1 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box id="special-events" component="section" className="section-default p-sm text-color-grey">
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid item xs={12} md={7}>
                <Mui.Typography component="h2" variant="h2" className="text-color-white">
                  <span className="text-color-primary">Special</span> Events
                </Mui.Typography>
                <Mui.Box
                  width={80}
                  minHeight={4}
                  component="hr"
                  className="mt-2 mb-4 mr-auto ml-0 border-0 background-gradient-primary-fade"
                />
                <Mui.Typography component="p" variant="h4" className="mb-0-8 font-weight-normal">
                  Your experience with us is our top priority and at Shangri-La we will ensure you’ll have an
                  exceptional time attending your special events. Require a limousine for your birthday? We got you!
                  Hens night? We are there! Treat your special person and take them on a luxurious date night? On it!
                  With a wide range of luxurious fleets to select from and a VIP experience at your service, you are
                  certain to have a splendid time!
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h3"
                  className="mt-3 mb-0-8 text-color-primary font-weight-normal"
                >
                  What we offer for Special events
                </Mui.Typography>
                <ul className="mb-2 text-color-grey font-weight-normal line-height-1-5 font-size-md-2x">
                  <li>Meet and greet</li>
                  <li>Door to door service</li>
                  <li>Uniformed Chauffeurs</li>
                  <li>1* Champagne on the house</li>
                  <li>Personalized music</li>
                </ul>
                <Mui.Typography
                  component="p"
                  variant="h3"
                  className="mt-3 mb-0-8 text-color-primary font-weight-normal"
                >
                  Add ons
                </Mui.Typography>
                <ul className="mb-2 text-color-grey font-weight-normal line-height-1-5 font-size-md-2x">
                  <li>Refreshments</li>
                  <li>Champagne</li>
                  <li>Ribbons</li>
                  <li>Red carpets</li>
                </ul>
                <Mui.Button
                  component={Link}
                  // to={"/ride"}
                  to="/contact"
                  className="btn-default mt-3"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                  <span className="ml-1 line-height-null">
                    <IconCarFront />
                  </span>
                </Mui.Button>
              </Mui.Grid>

              <Mui.Hidden smDown>
                <Mui.Grid item xs={12} md={5} className="overflow-hidden">
                  <img className="img-fluid-cover border-rad-10" src={featureImg3} alt="" />
                </Mui.Grid>
              </Mui.Hidden>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}
export default Mui.withWidth()(AboutView);
