import React from "react";

const IconTrash = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
    >
      <g
        id="Icon_feather-trash-2"
        dataname="Icon feather-trash-2"
        transform="translate(-4 -2.5)"
      >
        <path
          id="Path_2656"
          dataname="Path 2656"
          d="M4.5,9h18"
          transform="translate(0 -2)"
          fill="none"
          stroke="#1c3496"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2657"
          dataname="Path 2657"
          d="M21.5,7V21a2,2,0,0,1-2,2H9.5a2,2,0,0,1-2-2V7m3,0V5a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V7"
          transform="translate(-1 0)"
          fill="none"
          stroke="#1c3496"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2658"
          dataname="Path 2658"
          d="M15,16.5v6"
          transform="translate(-3.5 -4.5)"
          fill="none"
          stroke="#1c3496"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2659"
          dataname="Path 2659"
          d="M21,16.5v6"
          transform="translate(-5.5 -4.5)"
          fill="none"
          stroke="#1c3496"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconTrash;
