import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_ALL_ADDITIONAL_CHARGE,
  GET_ADDITIONAL_CHARGE_LIST,
  ADD_ADDITIONAL_CHARGE,
  GET_ADDITIONAL_CHARGE,
  EDIT_ADDITIONAL_CHARGE,
  DELETE_ADDITIONAL_CHARGE,
  DELETE_MULTIPLE_ADDITIONAL_CHARGE,
} from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import { parseMessage } from "src/helpers/util";
import AdditionalChargeService from "src/services/additional-charge-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllAdditionalChargeSuccess,
  getAllAdditionalChargeError,
  getAdditionalChargeList,
  getAdditionalChargeListSuccess,
  getAdditionalChargeListError,
  addAdditionalChargeSuccess,
  addAdditionalChargeError,
  getAdditionalChargeSuccess,
  getAdditionalChargeError,
  editAdditionalChargeSuccess,
  editAdditionalChargeError,
  deleteAdditionalChargeSuccess,
  deleteAdditionalChargeError,
  deleteMultipleAdditionalChargeSuccess,
  deleteMultipleAdditionalChargeError,
} from "./action";

export function* watchGetAllAdditionalCharge() {
  yield takeEvery(GET_ALL_ADDITIONAL_CHARGE, getAllAdditionalCharge);
}

const getAllAdditionalChargeAsync = async () => {
  return AdditionalChargeService.getAllAdditionalCharge();
};

function* getAllAdditionalCharge() {
  try {
    const response = yield call(getAllAdditionalChargeAsync);
    if (response.data.success) {
      yield put(getAllAdditionalChargeSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllAdditionalChargeError(error.response.data.message));
  }
}

export function* watchGetAdditionalChargeList() {
  yield takeEvery(GET_ADDITIONAL_CHARGE_LIST, getAdditionalChargeListAc);
}

const getAdditionalChargeListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "additional_charges",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getAdditionalChargeListAc({ payload }) {
  try {
    const response = yield call(getAdditionalChargeListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getAdditionalChargeListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAdditionalChargeListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAdditionalChargeListError(error.response.data.message));
  }
}

export function* watchAddAdditionalCharge() {
  yield takeEvery(ADD_ADDITIONAL_CHARGE, addAdditionalCharge);
}

const addAdditionalChargeAsync = async (data) => {
  return AdditionalChargeService.addAdditionalCharge(data);
};

function* addAdditionalCharge({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addAdditionalChargeAsync, payload.additionalChargeData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addAdditionalChargeSuccess(response.data.success, response.data.message));
      history.push(`/additional-charge`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addAdditionalChargeError(error.response.data.message));
  }
}

export function* watchGetAdditionalCharge() {
  yield takeEvery(GET_ADDITIONAL_CHARGE, getAdditionalCharge);
}

const getAdditionalChargeAsync = async (id) => {
  return AdditionalChargeService.getAdditionalCharge(id);
};

function* getAdditionalCharge({ payload }) {
  try {
    const response = yield call(getAdditionalChargeAsync, payload.additionalChargeId);
    if (response.data.success) {
      yield put(getAdditionalChargeSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAdditionalChargeError(error.response.data.message));
  }
}

export function* watchEditAdditionalCharge() {
  yield takeEvery(EDIT_ADDITIONAL_CHARGE, editAdditionalCharge);
}

const editAdditionalChargeAsync = async (data, id) => {
  return AdditionalChargeService.editAdditionalCharge(data, id);
};

function* editAdditionalCharge({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(editAdditionalChargeAsync, payload.additionalChargeData, payload.additionalChargeId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editAdditionalChargeSuccess(response.data.success, response.data.message));
      history.push(`/additional-charge`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editAdditionalChargeError(error.response.data.message));
  }
}

export function* watchDeleteAdditionalCharge() {
  yield takeEvery(DELETE_ADDITIONAL_CHARGE, deleteAdditionalCharge);
}

const deleteAdditionalChargeAsync = async (id) => {
  return AdditionalChargeService.deleteAdditionalCharge(id);
};

function* deleteAdditionalCharge({ payload }) {
  try {
    const response = yield call(deleteAdditionalChargeAsync, payload.additionalChargeId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteAdditionalChargeSuccess(response.data.success, response.data.message));
      // Fetch updated additionalCharge list
      yield put(getAdditionalChargeList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteAdditionalChargeError(error.response.data.message));
  }
}

export function* watchDeleteMultipleAdditionalCharge() {
  yield takeEvery(DELETE_MULTIPLE_ADDITIONAL_CHARGE, deleteMultipleAdditionalCharge);
}

const deleteMultipleAdditionalChargeAsync = async (ids) => {
  return AdditionalChargeService.deleteMultipleAdditionalCharge(ids);
};

function* deleteMultipleAdditionalCharge({ payload }) {
  try {
    const response = yield call(deleteMultipleAdditionalChargeAsync, payload.additionalChargeIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleAdditionalChargeSuccess(response.data.success, response.data.message));
      // Fetch updated additionalCharge list
      yield put(getAdditionalChargeList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleAdditionalChargeError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleAdditionalChargeError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllAdditionalCharge),
    fork(watchGetAdditionalChargeList),
    fork(watchAddAdditionalCharge),
    fork(watchGetAdditionalCharge),
    fork(watchEditAdditionalCharge),
    fork(watchDeleteAdditionalCharge),
    fork(watchDeleteMultipleAdditionalCharge),
  ]);
}
