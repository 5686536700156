import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { changePassword } from "src/reduxs/actions";
import { InputPasswordField } from "src/components/form";
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.schema = Yup.object().shape({
      oldPassword: Yup.string().required("Old password is required"),
      password: Yup.string()
        .required("Please enter new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Password should be at least 8 digits including 1 number, 1 uppercase, 1 lowercase and 1 special character"
        ),
      password_confirmation: Yup.string()
        .required("Please re-enter new password")
        .oneOf(
          [Yup.ref("password"), null],
          "Passsword and Confirm Password didn't match"
        ),
    });
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.changePassword(values);
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.success !== this.props.success) {
      if (this.props.success) {
        this.props.resetTab();
      }
    }
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          oldPassword: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={this.schema}
        onSubmit={this.onSubmit}
      >
        {(props) => (
          <Form className="default-form ">
            <Mui.Typography
              component="h3"
              variant="h3"
              className="mb-3 font-weight-medium"
            >
              Change Password
            </Mui.Typography>
            <Mui.Grid
              container
              direction="column"
              alignItems="flex-start"
              spacing={4}
            >
              <Mui.Grid item xs={12} md={5} className="form-group-dark">
                <InputPasswordField name="oldPassword" label="Old Password" />
              </Mui.Grid>
              <Mui.Grid item xs={12} md={5} className="form-group-dark">
                <InputPasswordField name="password" label="New Password" />
              </Mui.Grid>
              <Mui.Grid item xs={12} md={5} className="form-group-dark">
                <InputPasswordField
                  name="password_confirmation"
                  label="Confirm New Password"
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Box
              display="flex"
              justifyContent="flex-start"
              className="mt-3"
            >
              <Mui.Button
                className="btn-default mr-3"
                color="primary"
                variant="contained"
                type="submit"
                disabled={this.props.loading}
              >
                Update Password
              </Mui.Button>
              <Mui.Button
                className="font-weight-normal"
                type="button"
                variant="text"
                disableElevation
                onClick={props.resetForm}
              >
                Reset
              </Mui.Button>
            </Mui.Box>
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = ({ user }) => {
  const { loading, success, error } = user;
  return { loading, success, error };
};
export default connect(mapStateToProps, { changePassword })(ChangePassword);
