import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { WebsiteLayout } from "src/layouts";
import bannerImg from "src/assets/images/website/inner-hero-banner.jpg";
class NotFound extends Component {
  render() {
    return (
      <WebsiteLayout>
        <section className="inner-banner">
          <img src={bannerImg} alt="" />
          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Typography component="h2" variant="h2" className="text-center mb-1" style={{ fontSize: "4vh" }}>
              SORRY
            </Mui.Typography>
            <Mui.Typography component="p" variant="subtitle1" className="text-center mb-1">
              The requested page was not found.
            </Mui.Typography>
            <Mui.Typography component="p" variant="subtitle2" className="text-center">
              It seems you have ventured onto a broken link, please use the menu bar to navigate to the desired page.
            </Mui.Typography>
          </Mui.Container>
        </section>
      </WebsiteLayout>
    );
  }
}
export default NotFound;
