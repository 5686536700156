import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Field } from "formik";
import moment from "moment";
import { formatCurrency } from "src/helpers/util";
class Contact extends Component {
  render() {
    return (
      <Mui.Grid container spacing={4}>
        <Mui.Grid item xs={12} md={3}>
          <Mui.Paper className="default-card-dark-card mb-3 p-2 border-1 border-color-primary-dark shadow-none">
            <Mui.Typography className="mb-3 text-color-white font-weight-normal" component="h4" variant="h4">
              Summary
            </Mui.Typography>

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                SERVICE TYPE
              </Mui.Typography>
              {this.props.values.type === "distance" ? "Point to Point" : null}
              {this.props.values.type === "hourly" ? "Hourly" : null}
              {this.props.values.type === "flat_rate" ? "Flat Rate" : null}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                REFERENCE NUMBER
              </Mui.Typography>
              {this.props.values.referenceNumber || "-"}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />
            
            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP LOCATION
              </Mui.Typography>
              {this.props.values.pickupLocation}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "d-none" : ""
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DROP-OFF LOCATION
              </Mui.Typography>
              {this.props.values.dropOffLocation}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "d-none" : ""}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "flat_rate" ? "" : "d-none"
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                ROUTE
              </Mui.Typography>
              {this.props.routes?.find((x) => x.id === this.props.values.routeId)?.name || ""}
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "flat_rate" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                PICK-UP DATE, TIME
              </Mui.Typography>
              {moment(this.props.values.pickupDate).format("DD/MM/YYYY")},{" "}
              {moment(this.props.values.pickupTime, "HH:mm:ss").format("HH:mm")}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography
              className={`mb-1 font-weight-normal text-color-white ${
                this.props.values.type === "hourly" ? "" : "d-none"
              }`}
              component="h6"
              variant="h5"
            >
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                DURATION IN HOUR
              </Mui.Typography>
              {moment(this.props.values.durationInHour, "HH:mm:ss").format("H")} hour(s)
            </Mui.Typography>
            <Mui.Divider
              light={true}
              className={`mb-1 ${this.props.values.type === "hourly" ? "" : "d-none"}`}
              style={{ backgroundColor: "#433310" }}
            />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                VEHICLE
              </Mui.Typography>
              {this.props.carList?.find((x) => x.id === this.props.values.vehicleTypeId)?.name || ""}
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                TOTAL DISTANCE
              </Mui.Typography>
              {this.props.values.totalDistance} km
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-white" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-muted" component="span" variant="body2">
                APPROX ETA
              </Mui.Typography>
              {moment(this.props.values.totalTime, "HH:mm:ss").format("H")}h{" "}
              {moment(this.props.values.totalTime, "HH:mm:ss").format("m")}m
            </Mui.Typography>
            <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />

            <Mui.Typography className="mb-1 font-weight-normal text-color-success" component="h6" variant="h5">
              <Mui.Typography className="d-block text-color-success" component="span" variant="body2">
                TOTAL
              </Mui.Typography>
              {formatCurrency(this.props.priceData?.total)}
            </Mui.Typography>
            {!this.props.userCard?.patron ? (
              <>
                <Mui.Divider light={true} className="mb-1" style={{ backgroundColor: "#433310" }} />
                <Mui.Typography className="mb-1 font-weight-normal text-color-success" component="h6" variant="h5">
                  <Mui.Typography className="d-block text-color-success" component="span" variant="body2">
                    TO PAY (20% Deposit)
                  </Mui.Typography>
                  {formatCurrency(
                    this.props.priceData?.total > 0 ? ((20 / 100) * this.props.priceData?.total).toFixed(2) : 0
                  )}
                </Mui.Typography>
              </>
            ) : null}
          </Mui.Paper>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={9}>
          <Mui.Paper className="default-card-dark-card shadow-none">
            <Mui.Typography className="mb-3 text-color-white font-weight-normal" component="h5" variant="h5">
              CONTACT DETAILS
            </Mui.Typography>

            <Mui.Grid container spacing={3}>
              <Mui.Grid item xs={12} md={12} className={`mb-2`}>
                <Field name="other">
                  {({ field, meta }) => (
                    <Mui.FormControlLabel
                      className="default-checkbox-dark d-flex align-items-start mr-0"
                      control={
                        <Mui.Checkbox
                          classes={{ root: "pt-0" }}
                          {...field}
                          checked={this.props.values.other ? true : false}
                          onChange={(event) => {
                            this.props.setFieldValue("other", event.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Mui.Typography component="span" className="text-color-primary">
                          Booking for Others?
                        </Mui.Typography>
                      }
                    />
                  )}
                </Field>
              </Mui.Grid>
              <Mui.Grid item xs={12} md={6} className={`form-group-dark`}>
                <Field name="firstName">
                  {({ field, meta }) => (
                    <Mui.TextField
                      label={"First Name*"}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} className={`form-group-dark`}>
                <Field name="lastName">
                  {({ field, meta }) => (
                    <Mui.TextField
                      label={"Last Name*"}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} className={`form-group-dark`}>
                <Field name="email">
                  {({ field, meta }) => (
                    <Mui.TextField
                      label={"Email Address*"}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} className={`form-group-dark`}>
                <Field name="phone">
                  {({ field, meta }) => (
                    <Mui.TextField
                      type="number"
                      label={"Phone Number*"}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      InputProps={{
                        startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                      }}
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>

              {this.props.values.other ? (
                <>
                  <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
                    <Field name="passengerNames">
                      {({ field, meta }) => (
                        <Mui.TextField
                          label={"Passenger Names"}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          placeholder=""
                          helperText={meta.touched && meta.error ? meta.error : null}
                          error={meta.touched && meta.error ? true : false}
                          {...field}
                        />
                      )}
                    </Field>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
                    <Field name="passengerPhones">
                      {({ field, meta }) => (
                        <Mui.TextField
                          label={"Passenger Phone Numbers"}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          placeholder=""
                          helperText={meta.touched && meta.error ? meta.error : null}
                          error={meta.touched && meta.error ? true : false}
                          {...field}
                        />
                      )}
                    </Field>
                  </Mui.Grid>
                </>
              ) : null}

              <Mui.Grid item xs={12} md={6} className={`form-group-dark`}>
                <Field name="alternatePhone">
                  {({ field, meta }) => (
                    <Mui.TextField
                      type="number"
                      label={"Alternate Phone Number (Optional)"}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      InputProps={{
                        startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                      }}
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
                <Field name="additionalComment">
                  {({ field, meta }) => (
                    <Mui.TextField
                      label={"Any Additional Comment"}
                      multiline
                      rows={1}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder=""
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Paper>
        </Mui.Grid>
      </Mui.Grid>
    );
  }
}

export default Contact;
