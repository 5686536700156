import {
  GET_SCHEDULE_LIST,
  GET_SCHEDULE_LIST_SUCCESS,
  GET_SCHEDULE_LIST_ERROR,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_ERROR,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_ERROR,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  RESET_SCHEDULE,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  scheduleList: null,
  scheduleData: null,
  scheduleId: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const scheduleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHEDULE_LIST:
      return {
        ...state,
        loading: true,
        scheduleData: null,
        scheduleId: null,
        error: null,
      };
    case GET_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleList: action.payload,
        error: null,
      };
    case GET_SCHEDULE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        scheduleList: null,
        error: action.payload,
      };
    case ADD_SCHEDULE:
      return { ...state, loading: true, error: null };
    case ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_SCHEDULE:
      return { ...state, error: null };
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleData: action.payload,
        error: null,
      };
    case GET_SCHEDULE_ERROR:
      return {
        ...state,
        scheduleData: null,
        error: action.payload,
      };
    case EDIT_SCHEDULE:
      return { ...state, loading: true, error: null };
    case EDIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_SCHEDULE:
      return { ...state, loading1: true, error: null };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_SCHEDULE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_SCHEDULE:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default scheduleReducer;
