import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ALL_ROUTE,
  GET_ROUTE_LIST,
  ADD_ROUTE,
  GET_ROUTE,
  EDIT_ROUTE,
  DELETE_ROUTE,
  DELETE_MULTIPLE_ROUTE,
} from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import RouteService from "src/services/route-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllRouteSuccess,
  getAllRouteError,
  getRouteList,
  getRouteListSuccess,
  getRouteListError,
  addRouteSuccess,
  addRouteError,
  getRouteSuccess,
  getRouteError,
  editRouteSuccess,
  editRouteError,
  deleteRouteSuccess,
  deleteRouteError,
  deleteMultipleRouteSuccess,
  deleteMultipleRouteError,
} from "./action";

export function* watchGetAllRoute() {
  yield takeEvery(GET_ALL_ROUTE, getAllRoute);
}

const getAllRouteAsync = async () => {
  return RouteService.getAllRoute();
};

function* getAllRoute() {
  try {
    const response = yield call(getAllRouteAsync);
    if (response.data.success) {
      yield put(getAllRouteSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getAllRouteError(error.response.data.message));
  }
}

export function* watchGetRouteList() {
  yield takeEvery(GET_ROUTE_LIST, getRouteListAc);
}

const getRouteListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "routes",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getRouteListAc({ payload }) {
  try {
    const response = yield call(getRouteListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getRouteListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRouteListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getRouteListError(error.response.data.message));
  }
}

export function* watchAddRoute() {
  yield takeEvery(ADD_ROUTE, addRoute);
}

const addRouteAsync = async (data) => {
  return RouteService.addRoute(data);
};

function* addRoute({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addRouteAsync, payload.routeData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addRouteSuccess(response.data.success, response.data.message));
      history.push(`/route`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )}
      />,
      { containerId: "default" }
    );
    yield put(addRouteError(error.response.data.message));
  }
}

export function* watchGetRoute() {
  yield takeEvery(GET_ROUTE, getRoute);
}

const getRouteAsync = async (id) => {
  return RouteService.getRoute(id);
};

function* getRoute({ payload }) {
  try {
    const response = yield call(getRouteAsync, payload.routeId);
    if (response.data.success) {
      yield put(getRouteSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getRouteError(error.response.data.message));
  }
}

export function* watchEditRoute() {
  yield takeEvery(EDIT_ROUTE, editRoute);
}

const editRouteAsync = async (data, id) => {
  return RouteService.editRoute(data, id);
};

function* editRoute({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(
      editRouteAsync,
      payload.routeData,
      payload.routeId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editRouteSuccess(response.data.success, response.data.message));
      history.push(`/route`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )}
      />,
      { containerId: "default" }
    );
    yield put(editRouteError(error.response.data.message));
  }
}

export function* watchDeleteRoute() {
  yield takeEvery(DELETE_ROUTE, deleteRoute);
}

const deleteRouteAsync = async (id) => {
  return RouteService.deleteRoute(id);
};

function* deleteRoute({ payload }) {
  try {
    const response = yield call(deleteRouteAsync, payload.routeId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteRouteSuccess(response.data.success, response.data.message)
      );
      // Fetch updated route list
      yield put(getRouteList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(deleteRouteError(error.response.data.message));
  }
}

export function* watchDeleteMultipleRoute() {
  yield takeEvery(DELETE_MULTIPLE_ROUTE, deleteMultipleRoute);
}

const deleteMultipleRouteAsync = async (ids) => {
  return RouteService.deleteMultipleRoute(ids);
};

function* deleteMultipleRoute({ payload }) {
  try {
    const response = yield call(deleteMultipleRouteAsync, payload.routeIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleRouteSuccess(response.data.success, response.data.message)
      );
      // Fetch updated route list
      yield put(getRouteList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleRouteError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(deleteMultipleRouteError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllRoute),
    fork(watchGetRouteList),
    fork(watchAddRoute),
    fork(watchGetRoute),
    fork(watchEditRoute),
    fork(watchDeleteRoute),
    fork(watchDeleteMultipleRoute),
  ]);
}
