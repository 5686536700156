import React from "react";
import * as Mui from "@material-ui/core";

const BulkSelection = (props) => {
  const { selected, action, icon } = props;

  return (
    <Mui.Box display="flex" justifyContent="flex-end" flex={1} className="selected-items">
      <Mui.Typography color="inherit" variant="subtitle1" component="span">
        {selected} selected
      </Mui.Typography>

      <Mui.IconButton
        variant="outlined"
        className="btn-icon-square svg-color-danger border-color-danger ml-2"
        onClick={action}
      >
        {icon}
      </Mui.IconButton>
    </Mui.Box>
  );
};

export default BulkSelection;
