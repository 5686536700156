import React, { useEffect } from "react";
import * as Mui from "@material-ui/core";
import { FieldArray } from "formik";
import {
  SelectAutocompleteField,
  InputDatepickerField,
  InputTimepickerField,
  InputGoogleField,
  InputField,
} from "src/components/form";
import { IconAdd, IconTrash } from "src/components/svg";
import { durationHourList } from "src/helpers/util";

const Location = ({
  values,
  errors,
  getFieldMeta,
  setFieldValue,
  routeList,
  onFilter,
  getUserCard,
  calculateBookingPrice,
  resetPriceCalculation,
}) => {
  useEffect(() => {
    if (values.type === "distance") {
      setFieldValue("durationInHour", "01:00:00");
      setFieldValue("routeId", "");
    } else if (values.type === "hourly") {
      setFieldValue("waypoint", []);
      setFieldValue("routeId", "");
    } else if (values.type === "flat_rate") {
      setFieldValue("durationInHour", "01:00:00");
      setFieldValue("waypoint", []);
      setFieldValue("pickupLocation", "");
      setFieldValue("dropOffLocation", "");
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  useEffect(() => {
    if (values.routeId) {
      onFilter(values.noPassenger, values.noLuggage, values.routeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.routeId]);

  useEffect(() => {
    // If update in pick-up time calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("pickupTime")?.value !== getFieldMeta("pickupTime")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.pickupTime]);

  useEffect(() => {
    // If update in durationInHour calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("durationInHour")?.value !== getFieldMeta("durationInHour")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.durationInHour]);

  useEffect(() => {
    // If update in babySeatRearFacing calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("babySeatRearFacing")?.value !== getFieldMeta("babySeatRearFacing")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.babySeatRearFacing]);

  useEffect(() => {
    // If update in childSeatForwardFacing calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("childSeatForwardFacing")?.value !== getFieldMeta("childSeatForwardFacing")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.childSeatForwardFacing]);

  useEffect(() => {
    // If update in boosterSeat calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("boosterSeat")?.value !== getFieldMeta("boosterSeat")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.boosterSeat]);

  useEffect(() => {
    // If update in vehicleTypeId calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("vehicleTypeId")?.value !== getFieldMeta("vehicleTypeId")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.vehicleTypeId]);

  useEffect(() => {
    // If update in coupon calculate cost
    if (
      values.vehicleTypeId &&
      values.pickupTime &&
      getFieldMeta("couponId")?.value !== getFieldMeta("couponId")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.couponId]);

  useEffect(() => {
    // If update in additional charge calculate cost
    if (
      values.vehicleTypeId &&
      values.additionalCharge &&
      getFieldMeta("additionalCharge")?.value !== getFieldMeta("additionalCharge")?.initialValue
    ) {
      calculateBookingPrice(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.additionalCharge]);

  useEffect(() => {
    // If values get user card
    if (values.userId) {
      getUserCard(values.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.userId]);

  return (
    <Mui.Card className="default-card">
      <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
        1. Enter Ride Details
      </Mui.Typography>

      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <SelectAutocompleteField
            name="type"
            label="Select Type*"
            options={[
              { id: "distance", name: "Point to Point" },
              { id: "hourly", name: "Hourly" },
              // { id: "flat_rate", name: "Flat Rate" },
            ]}
            callback={(value) => {
              setFieldValue("vehicleTypeId", "");
              resetPriceCalculation();
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputField name="referenceNumber" type="text" label="Reference Number" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
          <InputDatepickerField name="pickupDate" label="Pick-Up Date*" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={4} className="time-picker form-group">
          <InputTimepickerField name="pickupTime" label="Pick-Up Time*" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={12} lg={12} className="form-group">
          <InputGoogleField name="pickupLocation" label="Pick-Up Location*" placeholder="Enter a Location" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={12} lg={12} className={`${values.type === "distance" ? "" : "d-none"}`}>
          <FieldArray
            name="waypoint"
            render={(arrayHelpers) => (
              <>
                <Mui.Box display="flex" alignItems="center" className="mb-2">
                  <Mui.IconButton
                    className="btn-icon-square mr-2"
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      if (values.waypoint?.length < 5) {
                        arrayHelpers.push({ location: "" });
                      }
                    }}
                  >
                    <IconAdd />
                  </Mui.IconButton>
                  <Mui.Typography className="text-color-primary font-weight-normal" component="h5" variant="h5">
                    Add your waypoint / Add stops
                  </Mui.Typography>
                </Mui.Box>

                {values.waypoint?.map((item, index) => (
                  <Mui.Grid container spacing={2} key={index}>
                    <Mui.Grid item xs={10} className="mt-0 form-group">
                      <InputGoogleField name={`waypoint.${index}.location`} placeholder="Enter a Waypoint" />
                    </Mui.Grid>
                    <Mui.Grid item xs={2} className="text-right">
                      <Mui.IconButton
                        size="small"
                        className={`svg-color-primary`}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <IconTrash />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                ))}
              </>
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={12} lg={12} className={`form-group ${values.type === "flat_rate" ? "d-none" : ""}`}>
          <InputGoogleField name="dropOffLocation" label="Drop-Off Location*" placeholder="Enter a Location" />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={6} className={`form-group ${values.type === "hourly" ? "" : "d-none"}`}>
          <SelectAutocompleteField name="durationInHour" label="Duration In Hours*" options={durationHourList} />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={6} className={`form-group ${values.type === "flat_rate" ? "" : "d-none"}`}>
          <SelectAutocompleteField name="routeId" label="Route*" options={routeList} />
        </Mui.Grid>

        <Mui.List className="list-switch w-100 ml-1">
          {values.type === "distance" && values.pickupLocation?.match(/airport/i) ? (
            <>
              {errors?.flightType ? (
                <Mui.Typography component="h5" variant="h5" className="mb-2 text-color-danger font-weight-normal">
                  {errors?.flightType}
                </Mui.Typography>
              ) : null}
              {/* Domestic Flight */}
              <Mui.ListItem>
                <Mui.ListItemText primary="Domestic Flight" className="text-color-default" />
                <Mui.ListItemSecondaryAction>
                  <Mui.Switch
                    edge="end"
                    checked={values.flightType === "domestic" ? true : false}
                    onChange={(event) => setFieldValue("flightType", event.target.checked ? "domestic" : "")}
                    color="primary"
                    inputProps={{
                      "aria-labelledby": "switch-list-label-domestic",
                    }}
                  />
                </Mui.ListItemSecondaryAction>
              </Mui.ListItem>
              <Mui.ListItem className={`${values.flightType === "domestic" ? "" : "d-none"}`}>
                <Mui.Box className="form-group mt-0 mb-0">
                  <InputField name="flightNumber" type="text" label="" placeholder="Flight Number" />
                </Mui.Box>
              </Mui.ListItem>

              {/* International Flight */}
              <Mui.ListItem>
                <Mui.ListItemText primary="International Flight" className="text-color-default" />
                <Mui.ListItemSecondaryAction>
                  <Mui.Switch
                    edge="end"
                    checked={values.flightType === "international" ? true : false}
                    onChange={(event) => setFieldValue("flightType", event.target.checked ? "international" : "")}
                    color="primary"
                    inputProps={{
                      "aria-labelledby": "switch-list-label-international",
                    }}
                  />
                </Mui.ListItemSecondaryAction>
              </Mui.ListItem>
              <Mui.ListItem className={`${values.flightType === "international" ? "" : "d-none"}`}>
                <Mui.Box className="form-group mt-0 mb-0">
                  <InputField name="flightNumber" type="text" label="" placeholder="Flight Number" />
                </Mui.Box>
              </Mui.ListItem>
            </>
          ) : null}
        </Mui.List>
      </Mui.Grid>
    </Mui.Card>
  );
};
export default Location;
