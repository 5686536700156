import React, { useState, useEffect, useRef } from "react";
import * as Mui from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { Field, FieldArray } from "formik";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import TimePicker from "rc-time-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { IconInfo, IconAdd, IconTrash, IconRoute, IconMapPin } from "src/components/svg";
import GoogleAutocomplete from "src/components/autocomplete";
import { durationHourList } from "src/helpers/util";

const Location = (props) => {
  const isMounted = useRef(false);
  const [datepicker, setDatepicker] = useState(false);
  const [date, setDate] = useState(props.values.pickupDate ? new Date(props.values.pickupDate) : null);
  const [route, setRoute] = useState(
    props.values.routeId ? props.routes?.find((x) => x.id === props.values.routeId) : null
  );
  const [duration, setDuration] = useState(
    props.values.durationInHour
      ? durationHourList?.find((x) => x.id === props.values.durationInHour)
      : { id: "01:00:00", name: "1 hour(s)" }
  );

  useEffect(() => {
    if (props.values.type === "distance") {
      props.setFieldValue("durationInHour", "01:00:00");
      props.setFieldValue("routeId", "");
      setDuration({ id: "01:00:00", name: "1 hour(s)" });
      setRoute(null);
    } else if (props.values.type === "hourly") {
      props.setFieldValue("waypoint", []);
      props.setFieldValue("routeId", "");
      setRoute(null);
    } else if (props.values.type === "flat_rate") {
      props.setFieldValue("durationInHour", "01:00:00");
      props.setFieldValue("waypoint", []);
      props.setFieldValue("pickupLocation", "");
      props.setFieldValue("dropOffLocation", "");
      setDuration({ id: "01:00:00", name: "1 hour(s)" });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.type]);

  useEffect(() => {
    if (props.values.routeId && isMounted.current) {
      props.onFilter(props.values.noPassenger, props.values.noLuggage, props.values.routeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.routeId]);

  useEffect(() => {
    // If update in pick-up time calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.pickupTime]);

  useEffect(() => {
    // If update in durationInHour calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.durationInHour]);

  useEffect(() => {
    // If update in babySeatRearFacing calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.babySeatRearFacing]);

  useEffect(() => {
    // If update in childSeatForwardFacing calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.childSeatForwardFacing]);

  useEffect(() => {
    // If update in boosterSeat calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.boosterSeat]);

  useEffect(() => {
    // If update in boosterSeat calculate cost
    if (props.values.vehicleTypeId && isMounted.current) {
      props.calculateBookingPrice(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.vehicleTypeId]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <Mui.Card className="default-card-dark-card pos-relative pb-5">
      <Mui.Box
        display="flex"
        // justifyContent="space-between"
        className="dark-tab mb-2"
      >
        <Mui.Button
          onClick={() => {
            props.setFieldValue("type", "distance");
            props.setFieldValue("vehicleTypeId", "");
            props.resetPriceCalculation();
          }}
          className={`w-100 font-weight-semi-bold ${props.values.type === "distance" ? "active" : null}`}
        >
          Point to Point
        </Mui.Button>
        <Mui.Button
          onClick={() => {
            props.setFieldValue("type", "hourly");
            props.setFieldValue("vehicleTypeId", "");
            props.resetPriceCalculation();
          }}
          className={`w-100 font-weight-semi-bold ${props.values.type === "hourly" ? "active" : null}`}
        >
          Hourly
        </Mui.Button>
        {/* <Mui.Button
          onClick={() => {
            props.setFieldValue("type", "flat_rate");
            props.setFieldValue("vehicleTypeId", "");
            props.resetPriceCalculation();
          }}
          className={`w-100 font-weight-semi-bold ${
            props.values.type === "flat_rate" ? "active" : null
          }`}
        >
          Flat Rate
        </Mui.Button> */}
      </Mui.Box>
      <Mui.Typography className="text-color-white font-weight-normal mb-2" component="h5" variant="h5">
        Ride Details
      </Mui.Typography>
      <Mui.Grid container spacing={3}>
        <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
          <Field name="referenceNumber">
            {({ field, meta }) => (
              <Mui.TextField
                label={"Reference Number"}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder=""
                helperText={meta.touched && meta.error ? meta.error : null}
                error={meta.touched && meta.error ? true : false}
                {...field}
              />
            )}
          </Field>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6} className="form-group-dark">
          <Field name="pickupDate">
            {({ field, meta }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="date-time-picker dark"
                  InputProps={{ readOnly: true }}
                  autoOk
                  disableToolbar
                  variant="inline"
                  fullWidth
                  minDate={new Date()}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  onClick={() => setDatepicker(true)}
                  onClose={() => setDatepicker(false)}
                  open={datepicker}
                  label={
                    <>
                      Pick-Up Date{" "}
                      <Mui.Tooltip
                        className="tooltip-default"
                        arrow
                        placement="bottom-start"
                        title={"The date when your journey will start"}
                      >
                        <span>
                          <IconInfo />
                        </span>
                      </Mui.Tooltip>
                    </>
                  }
                  value={date}
                  onChange={(date) => {
                    setDate(date);
                    props.setFieldValue("pickupDate", moment(date).format("YYYY-MM-DD"));
                  }}
                  keyboardIcon={null}
                  helperText={meta.touched && meta.error ? meta.error : null}
                  error={meta.touched && meta.error ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
          </Field>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6} className="time-picker form-group-dark">
          <Field name="pickupTime">
            {({ field, meta }) => (
              <Mui.FormControl error={meta.touched && meta.error ? true : false} variant={"outlined"}>
                <Mui.InputLabel shrink={true}>
                  <>
                    Pick-Up Time{" "}
                    <Mui.Tooltip
                      className="tooltip-default"
                      arrow
                      placement="bottom-start"
                      title={"The time when your journey will start"}
                    >
                      <span>
                        <IconInfo />
                      </span>
                    </Mui.Tooltip>
                  </>
                </Mui.InputLabel>
                <TimePicker
                  showSecond={false}
                  allowEmpty={false}
                  className=""
                  popupClassName=""
                  onChange={(value) => {
                    props.setFieldValue("pickupTime", value.format("HH:mm:ss"));
                  }}
                  value={
                    field.value
                      ? moment(moment().format("YYYY-MM-DD") + " " + field.value, "YYYY-MM-DD HH:mm:ss")
                      : null
                  }
                  format={"HH:mm"}
                  minuteStep={5}
                  inputReadOnly
                />
                {meta.touched && meta.error ? (
                  <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
                ) : null}
              </Mui.FormControl>
            )}
          </Field>
        </Mui.Grid>

        <Mui.Grid
          item
          xs={12}
          md={12}
          className={`form-group-dark ${props.values.type === "flat_rate" ? "d-none" : ""}`}
        >
          <Field name="pickupLocation">
            {({ field, meta }) => (
              <GoogleAutocomplete
                label={
                  <>
                    Pick-Up Location{" "}
                    <Mui.Tooltip
                      className="tooltip-default"
                      arrow
                      placement="bottom-start"
                      title={"The address where your journey will start"}
                    >
                      <span>
                        <IconInfo />
                      </span>
                    </Mui.Tooltip>
                  </>
                }
                variant={"outlined"}
                placeholder="Enter a Location"
                defaultValue={props.values?.pickupLocation || ""}
                touched={meta.touched}
                error={meta.error}
                callback={(description) => {
                  props.setFieldValue("pickupLocation", description || "");
                }}
              />
            )}
          </Field>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={12} className={`w-100 ${props.values.type === "distance" ? "" : "d-none"}`}>
          <FieldArray
            name="waypoint"
            render={(arrayHelpers) => (
              <>
                <Mui.Box display="flex" alignItems="center" className="mb-2">
                  <Mui.IconButton
                    className="btn-icon-square mr-2"
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      if (props.values.waypoint?.length < 5) {
                        arrayHelpers.push({ location: "" });
                      }
                    }}
                  >
                    <IconAdd />
                  </Mui.IconButton>
                  <Mui.Typography className="text-color-white font-weight-normal" component="h5" variant="h5">
                    Add your waypoint / Add stops
                  </Mui.Typography>
                </Mui.Box>

                {props.values.waypoint?.map((item, index) => (
                  <Mui.Grid container spacing={3} key={index}>
                    <Mui.Grid item xs={12} md={10} className="mt-0 form-group-dark">
                      <Field name={`waypoint.${index}.location`}>
                        {({ field, meta }) => (
                          <GoogleAutocomplete
                            variant={"outlined"}
                            placeholder="Enter a Waypoint"
                            touched={props.touched?.waypoint?.[index]?.location}
                            error={props.errors?.waypoint?.[index]?.location}
                            callback={(description) => {
                              props.setFieldValue(`waypoint.${index}.location`, description || "");
                            }}
                          />
                        )}
                      </Field>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={2} className="text-right">
                      <Mui.IconButton
                        size="small"
                        className={`svg-color-white`}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <IconTrash />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                ))}
              </>
            )}
          />
        </Mui.Grid>

        <Mui.Grid
          item
          xs={12}
          md={12}
          className={`form-group-dark ${props.values.type === "flat_rate" ? "d-none" : ""}`}
        >
          <Field name="dropOffLocation">
            {({ field, meta }) => (
              <GoogleAutocomplete
                label={
                  <>
                    Drop-Off Location{" "}
                    <Mui.Tooltip
                      className="tooltip-default"
                      arrow
                      placement="bottom-start"
                      title={"The address where your journey will end"}
                    >
                      <span>
                        <IconInfo />
                      </span>
                    </Mui.Tooltip>
                  </>
                }
                variant={"outlined"}
                placeholder="Enter a Location"
                defaultValue={props.values?.dropOffLocation || ""}
                touched={meta.touched}
                error={meta.error}
                callback={(description) => {
                  props.setFieldValue("dropOffLocation", description || "");
                }}
              />
            )}
          </Field>

          <Mui.Typography
            component="h5"
            variant="h5"
            className={`mt-1 text-color-white font-weight-normal ${props.values.type === "hourly" ? "" : "d-none"}`}
          >
            Note : Price for time exceeded will be charged accordingly.
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={12} className={`form-group-dark ${props.values.type === "hourly" ? "" : "d-none"}`}>
          <Field name="durationInHour">
            {({ field, meta }) => (
              <>
                <Autocomplete
                  options={durationHourList}
                  autoHighlight
                  autoComplete={true}
                  disableClearable={true}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  value={duration}
                  onChange={(event, selectedOption) => {
                    setDuration(selectedOption);

                    props.setFieldValue("durationInHour", selectedOption?.id || "");
                  }}
                  renderOption={(option, { selected }) => (
                    <div className="select-item">{option ? option.name : ""}</div>
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      error={meta.touched && meta.error ? true : false}
                      placeholder={""}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "nofill",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant={"outlined"}
                      label={
                        <>
                          Duration In Hours{" "}
                          <Mui.Tooltip
                            className="tooltip-default"
                            arrow
                            placement="bottom-start"
                            title={"Duration of the journey"}
                          >
                            <span>
                              <IconInfo />
                            </span>
                          </Mui.Tooltip>
                        </>
                      }
                    />
                  )}
                />
                {meta.touched && meta.error ? (
                  <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
                ) : null}
              </>
            )}
          </Field>
        </Mui.Grid>

        <Mui.Grid
          item
          xs={12}
          md={12}
          className={`form-group-dark ${props.values.type === "flat_rate" ? "" : "d-none"}`}
        >
          <Field name="routeId">
            {({ field, meta }) => (
              <>
                <Autocomplete
                  options={props.routes || []}
                  autoHighlight
                  autoComplete={true}
                  disableClearable={true}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  value={route}
                  onChange={(event, selectedOption) => {
                    setRoute(selectedOption);

                    props.setFieldValue("routeId", selectedOption?.id || "");
                  }}
                  renderOption={(option, { selected }) => (
                    <div className="select-item">{option ? option.name : ""}</div>
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      error={meta.touched && meta.error ? true : false}
                      placeholder={""}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "nofill",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant={"outlined"}
                      label={
                        <>
                          Route{" "}
                          <Mui.Tooltip className="tooltip-default" arrow placement="bottom-start" title={"Route"}>
                            <span>
                              <IconInfo />
                            </span>
                          </Mui.Tooltip>
                        </>
                      }
                    />
                  )}
                />
                {meta.touched && meta.error ? (
                  <Mui.FormHelperText error>{meta.touched && meta.error ? meta.error : null}</Mui.FormHelperText>
                ) : null}
              </>
            )}
          </Field>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.List className="list-switch">
        {/* {props.values.type === "distance" || props.values.type === "hourly" ? (
          <>
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <IconMapPin />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary="One Way Trip" />
              <Mui.ListItemSecondaryAction>
                <Mui.Switch
                  edge="end"
                  checked={props.values.ride === "one_way" ? true : false}
                  onChange={(event) =>
                    props.setFieldValue(
                      "ride",
                      event.target.checked ? "one_way" : ""
                    )
                  }
                  color="primary"
                  inputProps={{
                    "aria-labelledby": "switch-list-label-one-way",
                  }}
                />
              </Mui.ListItemSecondaryAction>
            </Mui.ListItem>

            <Mui.ListItem>
              <Mui.ListItemIcon>
                <IconRoute />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary="Round Trip" />
              <Mui.ListItemSecondaryAction>
                <Mui.Switch
                  edge="end"
                  checked={props.values.ride === "round_trip" ? true : false}
                  onChange={(event) =>
                    props.setFieldValue(
                      "ride",
                      event.target.checked ? "round_trip" : ""
                    )
                  }
                  color="primary"
                  inputProps={{
                    "aria-labelledby": "switch-list-label-round-trip",
                  }}
                />
              </Mui.ListItemSecondaryAction>
            </Mui.ListItem>

            <Mui.ListItem
              className={`p-2 border-1 border-color-dark-grey border-rad-4 ${
                props.values.ride === "round_trip" ? "" : "d-none"
              }`}
            >
              <Mui.Grid container spacing={3}>
                <Mui.Grid item xs={12} md={6} className="form-group-dark">
                  <Field name="pickupDate">
                    {({ field, meta }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="date-time-picker dark"
                          InputProps={{ readOnly: true }}
                          autoOk
                          disableToolbar
                          variant="inline"
                          fullWidth
                          minDate={new Date()}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          onClick={() => setDatepicker(true)}
                          onClose={() => setDatepicker(false)}
                          open={datepicker}
                          label={
                            <>
                              Pick-Up Date{" "}
                              <Mui.Tooltip
                                className="tooltip-default"
                                arrow
                                placement="bottom-start"
                                title={"The date when your journey will start"}
                              >
                                <span>
                                  <IconInfo />
                                </span>
                              </Mui.Tooltip>
                            </>
                          }
                          value={date}
                          onChange={(date) => {
                            setDate(date);
                            props.setFieldValue(
                              "pickupDate",
                              moment(date).format("YYYY-MM-DD")
                            );
                          }}
                          keyboardIcon={null}
                          helperText={
                            meta.touched && meta.error ? meta.error : null
                          }
                          error={meta.touched && meta.error ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Field>
                </Mui.Grid>

                <Mui.Grid
                  item
                  xs={12}
                  md={6}
                  className="time-picker form-group-dark"
                >
                  <Field name="pickupTime">
                    {({ field, meta }) => (
                      <Mui.FormControl
                        error={meta.touched && meta.error ? true : false}
                        variant={"outlined"}
                      >
                        <Mui.InputLabel shrink={true}>
                          <>
                            Pick-Up Time{" "}
                            <Mui.Tooltip
                              className="tooltip-default"
                              arrow
                              placement="bottom-start"
                              title={"The time when your journey will start"}
                            >
                              <span>
                                <IconInfo />
                              </span>
                            </Mui.Tooltip>
                          </>
                        </Mui.InputLabel>
                        <TimePicker
                          showSecond={false}
                          allowEmpty={false}
                          className=""
                          popupClassName=""
                          onChange={(value) => {
                            props.setFieldValue(
                              "pickupTime",
                              value.format("HH:mm:ss")
                            );
                          }}
                          value={
                            field.value
                              ? moment(
                                  moment().format("YYYY-MM-DD") +
                                    " " +
                                    field.value,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : null
                          }
                          format={"HH:mm"}
                          minuteStep={5}
                          inputReadOnly
                        />
                        {meta.touched && meta.error ? (
                          <Mui.FormHelperText error>
                            {meta.touched && meta.error ? meta.error : null}
                          </Mui.FormHelperText>
                        ) : null}
                      </Mui.FormControl>
                    )}
                  </Field>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
                  <Field name="pickupLocation">
                    {({ field, meta }) => (
                      <GoogleAutocomplete
                        label={
                          <>
                            Pick-Up Location{" "}
                            <Mui.Tooltip
                              className="tooltip-default"
                              arrow
                              placement="bottom-start"
                              title={
                                "The address where your journey will start"
                              }
                            >
                              <span>
                                <IconInfo />
                              </span>
                            </Mui.Tooltip>
                          </>
                        }
                        variant={"outlined"}
                        placeholder="Enter a Location"
                        defaultValue={props.values?.pickupLocation || ""}
                        touched={meta.touched}
                        error={meta.error}
                        callback={(description) => {
                          props.setFieldValue(
                            "pickupLocation",
                            description || ""
                          );
                        }}
                      />
                    )}
                  </Field>
                </Mui.Grid>

                <Mui.Grid
                  item
                  xs={12}
                  md={12}
                  className={`w-100 ${
                    props.values.type === "distance" ? "" : "d-none"
                  }`}
                >
                  <FieldArray
                    name="waypoint"
                    render={(arrayHelpers) => (
                      <>
                        <Mui.Box
                          display="flex"
                          alignItems="center"
                          className="mb-2"
                        >
                          <Mui.IconButton
                            className="btn-icon-square mr-2"
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={() => {
                              if (props.values.waypoint?.length < 5) {
                                arrayHelpers.push({ location: "" });
                              }
                            }}
                          >
                            <IconAdd />
                          </Mui.IconButton>
                          <Mui.Typography
                            className="text-color-white font-weight-normal"
                            component="h5"
                            variant="h5"
                          >
                            Add your waypoint / Add stops
                          </Mui.Typography>
                        </Mui.Box>

                        {props.values.waypoint?.map((item, index) => (
                          <Mui.Grid container spacing={3} key={index}>
                            <Mui.Grid
                              item
                              xs={12}
                              md={10}
                              className="mt-0 form-group-dark"
                            >
                              <Field name={`waypoint.${index}.location`}>
                                {({ field, meta }) => (
                                  <GoogleAutocomplete
                                    variant={"outlined"}
                                    placeholder="Enter a Waypoint"
                                    touched={props.touched?.waypoint?.[index]?.location}
                                    error={props.errors?.waypoint?.[index]?.location}
                                    callback={(description) => {
                                      props.setFieldValue(
                                        `waypoint.${index}.location`,
                                        description || ""
                                      );
                                    }}
                                  />
                                )}
                              </Field>
                            </Mui.Grid>
                            <Mui.Grid
                              item
                              xs={12}
                              md={2}
                              className="text-right"
                            >
                              <Mui.IconButton
                                size="small"
                                className={`svg-color-white`}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <IconTrash />
                              </Mui.IconButton>
                            </Mui.Grid>
                          </Mui.Grid>
                        ))}
                      </>
                    )}
                  />
                </Mui.Grid>

                <Mui.Grid item xs={12} md={12} className={`form-group-dark`}>
                  <Field name="dropOffLocation">
                    {({ field, meta }) => (
                      <GoogleAutocomplete
                        label={
                          <>
                            Drop-Off Location{" "}
                            <Mui.Tooltip
                              className="tooltip-default"
                              arrow
                              placement="bottom-start"
                              title={"The address where your journey will end"}
                            >
                              <span>
                                <IconInfo />
                              </span>
                            </Mui.Tooltip>
                          </>
                        }
                        variant={"outlined"}
                        placeholder="Enter a Location"
                        defaultValue={props.values?.dropOffLocation || ""}
                        touched={meta.touched}
                        error={meta.error}
                        callback={(description) => {
                          props.setFieldValue(
                            "dropOffLocation",
                            description || ""
                          );
                        }}
                      />
                    )}
                  </Field>
                  <Mui.Typography
                    component="h5"
                    variant="h5"
                    className={`mt-1 text-color-white font-weight-normal ${
                      props.values.type === "hourly" ? "" : "d-none"
                    }`}
                  >
                    Note : Price for time exceeded will be charged accordingly.
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid
                  item
                  xs={12}
                  md={12}
                  className={`form-group-dark ${
                    props.values.type === "hourly" ? "" : "d-none"
                  }`}
                >
                  <Field name="durationInHour">
                    {({ field, meta }) => (
                      <>
                        <Autocomplete
                          options={durationHourList}
                          autoHighlight
                          autoComplete={true}
                          disableClearable={true}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          value={duration}
                          onChange={(event, selectedOption) => {
                            setDuration(selectedOption);

                            props.setFieldValue(
                              "durationInHour",
                              selectedOption?.id || ""
                            );
                          }}
                          renderOption={(option, { selected }) => (
                            <div className="select-item">
                              {option ? option.name : ""}
                            </div>
                          )}
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              error={meta.touched && meta.error ? true : false}
                              placeholder={""}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "nofill",
                              }}
                              InputLabelProps={{ shrink: true }}
                              variant={"outlined"}
                              label={
                                <>
                                  Duration In Hours{" "}
                                  <Mui.Tooltip
                                    className="tooltip-default"
                                    arrow
                                    placement="bottom-start"
                                    title={"Duration of the journey"}
                                  >
                                    <span>
                                      <IconInfo />
                                    </span>
                                  </Mui.Tooltip>
                                </>
                              }
                            />
                          )}
                        />
                        {meta.touched && meta.error ? (
                          <Mui.FormHelperText error>
                            {meta.touched && meta.error ? meta.error : null}
                          </Mui.FormHelperText>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.ListItem>
          </>
        ) : null} */}

        {props.values.type === "distance" && props.values.pickupLocation?.match(/airport/i) ? (
          <>
            {props.errors?.flightType ? (
              <Mui.Typography component="h5" variant="h5" className="mb-2 text-color-danger font-weight-normal">
                {props.errors?.flightType}
              </Mui.Typography>
            ) : null}
            {/* Domestic Flight */}
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <IconMapPin />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary="Domestic Flight" />
              <Mui.ListItemSecondaryAction>
                <Mui.Switch
                  edge="end"
                  checked={props.values.flightType === "domestic" ? true : false}
                  onChange={(event) => props.setFieldValue("flightType", event.target.checked ? "domestic" : "")}
                  color="primary"
                  inputProps={{
                    "aria-labelledby": "switch-list-label-domestic",
                  }}
                />
              </Mui.ListItemSecondaryAction>
            </Mui.ListItem>
            <Mui.ListItem className={`${props.values.flightType === "domestic" ? "" : "d-none"}`}>
              <Mui.Box className="form-group-dark mt-0 mb-0">
                <Field name="flightNumber">
                  {({ field, meta }) => (
                    <Mui.TextField
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder="Flight Number"
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Box>
            </Mui.ListItem>

            {/* International Flight */}
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <IconRoute />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary="International Flight" />
              <Mui.ListItemSecondaryAction>
                <Mui.Switch
                  edge="end"
                  checked={props.values.flightType === "international" ? true : false}
                  onChange={(event) => props.setFieldValue("flightType", event.target.checked ? "international" : "")}
                  color="primary"
                  inputProps={{
                    "aria-labelledby": "switch-list-label-international",
                  }}
                />
              </Mui.ListItemSecondaryAction>
            </Mui.ListItem>
            <Mui.ListItem className={`${props.values.flightType === "international" ? "" : "d-none"}`}>
              <Mui.Box className="form-group-dark mt-0 mb-0">
                <Field name="flightNumber">
                  {({ field, meta }) => (
                    <Mui.TextField
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder="Flight Number"
                      helperText={meta.touched && meta.error ? meta.error : null}
                      error={meta.touched && meta.error ? true : false}
                      {...field}
                    />
                  )}
                </Field>
              </Mui.Box>
            </Mui.ListItem>
            <Mui.Typography component="h5" variant="h5" className={`mt-1 text-color-white font-weight-normal`}>
              * Parking charges for domestic & international airport pick-up.{" "}
              <Mui.Tooltip
                className="tooltip-default svg-color-primary svg-size-small"
                arrow
                placement="bottom-start"
                title={"$5 for 30min, $10 up-to 60min & $35 from 60min to 3hr of waiting."}
              >
                <span>
                  <IconInfo />
                </span>
              </Mui.Tooltip>
            </Mui.Typography>
          </>
        ) : null}
      </Mui.List>
    </Mui.Card>
  );
};
export default Mui.withWidth()(Location);
