import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import ClientList from "./list";

function Client() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/client" component={ClientList} />
      </Switch>
    </Suspense>
  );
}

export default Client;
