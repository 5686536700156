import React, { Component } from "react";
import { connect } from "react-redux";
import { getClientList } from "src/reduxs/actions";
import DataTable from "src/components/datatable";
import Breadcrumb from "src/components/breadcrumb";

class ClientList extends Component {
  constructor(props) {
    super(props);
    this.searchFields = "email:like;first_name:like;last_name:like;phone:like;address:like;";
  }

  componentDidMount() {
    this.props.getClientList({});
  }

  onChange = (search, sortOrder, page, pageSize, activeCol) => {
    this.props.getClientList({
      search: search,
      searchFields: this.searchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
    });
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Client Management"}
          paths={[
            {
              title: "Client",
            },
          ]}
        />

        <DataTable
          title="Client"
          headCells={[
            {
              id: "fullname",
              sortKey: "first_name",
              label: "Name",
            },
            {
              id: "email",
              sortKey: "email",
              label: "Email",
            },
            {
              id: "phone",
              sortKey: "phone",
              label: "Contact",
            },
            {
              id: "address",
              sortKey: "address",
              label: "Address",
            },
            {
              id: "accountNo",
              sortKey: "user_id",
              label: "Account No",
            },
            {
              id: "createdAt",
              sortKey: "created_at",
              label: "Created Date",
            },
          ]}
          rows={this.props.clientList?.data || []}
          totalPage={this.props.clientList?.meta?.pages || 0}
          totalItem={this.props.clientList?.meta?.total || 0}
          start={this.props.clientList?.meta?.start || 0}
          end={this.props.clientList?.meta?.end || 0}
          loading={this.props.loading}
          route={"/client"}
          history={this.props.history}
          createLabel=""
          onChange={this.onChange}
          hideAction={true}
        />
      </>
    );
  }
}
const mapStateToProps = ({ client }) => {
  const { clientList, loading, success, error } = client;
  return { clientList, loading, success, error };
};

export default connect(mapStateToProps, {
  getClientList,
})(ClientList);
