import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { IconCarFront } from "src/components/svg";

class Success extends Component {
  componentDidMount() {
    this.interval = setInterval(() => this.props.history.push("/"), 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <Mui.Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="mt-5"
      >
        <span
          className="d-flex justify-content-center align-items-center svg-color-primary svg-size-medium border-1 border-color-primary border-rad-50"
          style={{ width: "10rem", height: "10rem" }}
        >
          <IconCarFront />
        </span>
        <Mui.Typography
          className="mb-4 mt-4 font-weight-normal text-center"
          component="h3"
          variant="h3"
        >
          Thank you for your booking. <br />
          Please be patient while system admin approves your booking.
        </Mui.Typography>
      </Mui.Box>
    );
  }
}

export default Success;
