import React, { useRef, useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import TimePicker from "rc-time-picker";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { IconInfo, IconClose } from "src/components/svg";
import { rebookRide, resetRide } from "src/reduxs/actions";
import Square from "src/components/square";

const Rebook = (props) => {
  const [datepicker, setDatepicker] = useState(false);
  const [date, setDate] = useState(null);
  const formikRef = useRef();

  const schema = Yup.object().shape({
    pickupDate: Yup.string().required("Enter a valid date").nullable(),
    pickupTime: Yup.string()
      .required("Enter a valid time")
      .nullable()
      .when("pickupDate", {
        is: (value) => value?.length > 0,
        then: Yup.string().test("is-minimum", "Pick-Up Time minimum restriction of 3 hours", function (value) {
          const { pickupDate } = this.parent;
          let el = moment(pickupDate + " " + value, "YYYY-MM-DD HH:mm:ss");
          let now = moment();
          if (el.isSameOrBefore(now)) {
            return false;
          }
          var duration = moment.duration(el.diff(now));
          var hr = duration.asHours();
          return hr >= 3;
        }),
        otherwise: Yup.string().required("Enter a valid time").nullable(),
      }),
  });

  const onSubmit = (values) => {
    if (!props.loading) {
      props.rebookRide(values);
    }
  };

  const bookNow = () => {
    formikRef?.current?.handleSubmit();
  };

  const onClose = () => {
    props.close();
    setDatepicker(false);
    setDate(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    if (props.error) {
      props.resetRide();
    } else {
      if (!props.loading && props.success) {
        props.close();
        setDatepicker(false);
        setDate(null);
        props.resetRide();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error, props.success, props.loading]);

  return (
    <Mui.Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth={false}
      classes={{
        paper: `get-quote-dialog`,
      }}
    >
      <Mui.IconButton onClick={onClose} color="primary" className="btn-close">
        <IconClose />
      </Mui.IconButton>
      <Mui.Typography component="h2" variant="h2" className="gradent-title" style={{ left: "-2vh" }}>
        Re-Book
      </Mui.Typography>
      <div className={`animate-from-bottom`}>
        <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium text-center">
          Easy to Re-book!!
        </Mui.Typography>

        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            id: props.id,
            pickupDate: null,
            pickupTime: "",
            paymentToken: "",
            useCardOnFile: props.userCard?.card?.[0]?.cardId ? 1 : 0,
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="default-form">
              <Mui.Grid container spacing={4}>
                <Mui.Grid item xs={12} sm={6} className="form-group-dark">
                  <Field name="pickupDate">
                    {({ field, meta }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="date-time-picker"
                          InputProps={{ readOnly: true }}
                          autoOk
                          disableToolbar
                          variant="inline"
                          fullWidth
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDate={new Date()}
                          onClick={() => setDatepicker(true)}
                          onClose={() => setDatepicker(false)}
                          open={datepicker}
                          label={
                            <>
                              Pick-Up Date{" "}
                              <Mui.Tooltip
                                className="tooltip-default"
                                arrow
                                placement="bottom-start"
                                title={"The date when your journey will start"}
                              >
                                <span>
                                  <IconInfo />
                                </span>
                              </Mui.Tooltip>
                            </>
                          }
                          value={date}
                          onChange={(date) => {
                            setDate(date);
                            setFieldValue("pickupDate", moment(date).format("YYYY-MM-DD"));
                          }}
                          keyboardIcon={null}
                          helperText={meta.touched && meta.error ? meta.error : null}
                          error={meta.touched && meta.error ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Field>
                </Mui.Grid>

                <Mui.Grid item xs={12} sm={6} className="time-picker form-group-dark">
                  <Field name="pickupTime">
                    {({ field, meta }) => (
                      <Mui.FormControl error={meta.touched && meta.error ? true : false} variant={"outlined"}>
                        <Mui.InputLabel shrink={true}>
                          <>
                            Pick-Up Time{" "}
                            <Mui.Tooltip
                              className="tooltip-default"
                              arrow
                              placement="bottom-start"
                              title={"The time when your journey will start"}
                            >
                              <span>
                                <IconInfo />
                              </span>
                            </Mui.Tooltip>
                          </>
                        </Mui.InputLabel>
                        <TimePicker
                          showSecond={false}
                          allowEmpty={false}
                          className=""
                          popupClassName=""
                          onChange={(value) => {
                            setFieldValue("pickupTime", value.format("HH:mm:ss"));
                          }}
                          format={"HH:mm"}
                          minuteStep={5}
                          inputReadOnly
                        />
                        {meta.touched && meta.error ? (
                          <Mui.FormHelperText error>
                            {meta.touched && meta.error ? meta.error : null}
                          </Mui.FormHelperText>
                        ) : null}
                      </Mui.FormControl>
                    )}
                  </Field>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={12}>
                  <Mui.Typography className="text-color-white font-weight-normal" component="h6" variant="h6">
                    Card on File is created in Square to process remaining payment.
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12} sm={12} className="form-group-dark">
                  {props.userCard?.card?.[0]?.cardId ? (
                    <>
                      <Mui.Box mb={1}>
                        <Field name="saveCardOnFile">
                          {({ field }) => (
                            <Mui.FormControl className="align-self-start" variant="outlined">
                              <Mui.FormControlLabel
                                control={
                                  <Mui.Checkbox
                                    className="default-checkbox-dark mr-1"
                                    {...field}
                                    color="primary"
                                    checked={values.useCardOnFile ? true : false}
                                    onChange={(event) => setFieldValue("useCardOnFile", event.target.checked ? 1 : 0)}
                                  />
                                }
                                label={"Use existing Card-on-file"}
                                className="text-color-white"
                              />
                            </Mui.FormControl>
                          )}
                        </Field>
                      </Mui.Box>
                      {values.useCardOnFile ? (
                        <>
                          <Mui.Card className="virtual-card">
                            <Mui.Typography component="span" variant="body1" className="card-type">
                              {props.userCard?.card?.[0]?.squareCard?.card_brand?.replace("_", " ") || "-"}
                            </Mui.Typography>

                            <Mui.Typography component="h4" variant="h4" className="card-number">
                              **** **** **** {props.userCard?.card?.[0]?.squareCard?.last_4 || "****"}
                            </Mui.Typography>

                            <Mui.Typography component="span" variant="body1" className="card-expiry">
                              {props.userCard?.card?.[0]?.squareCard?.exp_month || "-"}/
                              {props.userCard?.card?.[0]?.squareCard?.exp_year || "-"}
                            </Mui.Typography>

                            <Mui.Typography component="h5" variant="h5" className="card-holder">
                              {props.userCard?.card?.[0]?.squareCard?.cardholder_name || "-"}
                            </Mui.Typography>
                          </Mui.Card>

                          <Mui.Box width="100%" display="flex" justifyContent="center" className="mt-3">
                            <Mui.Button
                              className="w-100 pt-2 pb-2 btn-default flex-shrink-0"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              disabled={props.loading}
                            >
                              Book Now
                              {props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                            </Mui.Button>
                          </Mui.Box>
                        </>
                      ) : (
                        <Square setFieldValue={setFieldValue} bookNow={bookNow} />
                      )}
                    </>
                  ) : (
                    <Square setFieldValue={setFieldValue} bookNow={bookNow} />
                  )}

                  {props.loading ? (
                    <Mui.Typography
                      component="h5"
                      variant="h5"
                      className="mt-3 font-weight-normal text-color-primary text-center"
                    >
                      Please wait booking on-progress...
                    </Mui.Typography>
                  ) : null}
                </Mui.Grid>
              </Mui.Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Mui.Dialog>
  );
};
const mapStateToProps = ({ ride }) => {
  const { userCard, success, message, loading, error } = ride;
  return {
    userCard,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  rebookRide,
  resetRide,
})(Mui.withWidth()(Rebook));
