import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllBooking,
  getInvoiceList,
  downloadInvoice,
  deleteInvoice,
  deleteMultipleInvoice,
  resetInvoice,
} from "src/reduxs/actions";
import DataTable from "src/components/datatable";
import Breadcrumb from "src/components/breadcrumb";
import { Alert } from "src/components/dialog";

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      deleteIds: null,
      openAlert: false,
      openAlert1: false,
    };
    this.searchFields = "booking.booking_number:like;invoice_number:like;user_id:like;";
    this.urlSearchParams = new URLSearchParams(props.location.search);
  }

  componentDidMount() {
    this.props.getAllBooking();
    this.props.getInvoiceList({ jobNo: this.urlSearchParams.get("job") || "" });
  }

  onDelete = () => {
    if (!this.props.loading1) {
      this.props.deleteInvoice(this.state.deleteId);
    }
  };

  onDeleteAll = () => {
    if (!this.props.loading1) {
      this.props.deleteMultipleInvoice(this.state.deleteIds);
    }
  };

  handleOpenAlert = (id) => {
    this.setState({ openAlert: true, deleteId: id });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, deleteId: null });
  };

  handleOpenAlert1 = (ids) => {
    this.setState({ openAlert1: true, deleteIds: ids });
  };

  handleCloseAlert1 = () => {
    this.setState({ openAlert1: false, deleteIds: null });
  };

  onChange = (search, sortOrder, page, pageSize, activeCol, fromDate, toDate, jobNo) => {
    this.props.getInvoiceList({
      search: search,
      searchFields: this.searchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      fromDate: fromDate,
      toDate: toDate,
      jobNo: jobNo,
    });
  };

  onDownload = (id) => {
    if (!this.props.downloading) {
      this.props.downloadInvoice(id);
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Invoice Management"}
          paths={[
            {
              title: "Invoice",
            },
          ]}
        />

        <DataTable
          title="Invoice"
          headCells={[
            {
              id: "invoiceNumber",
              sortKey: "invoice_number",
              label: "Invoice No",
            },
            {
              id: "invoiceBookingNumber",
              sortKey: "id",
              label: "Job No",
            },
            {
              id: "total",
              sortKey: "total",
              label: "Amount",
            },
            {
              id: "accountNo",
              sortKey: "user_id",
              label: "Account No",
            },
            {
              id: "createdAt",
              sortKey: "created_at",
              label: "Created Date",
            },
          ]}
          rows={this.props.invoiceList?.data || []}
          totalPage={this.props.invoiceList?.meta?.pages || 0}
          totalItem={this.props.invoiceList?.meta?.total || 0}
          start={this.props.invoiceList?.meta?.start || 0}
          end={this.props.invoiceList?.meta?.end || 0}
          loading={this.props.loading}
          route={"/invoice"}
          permission={{ download: 1, delete: 1 }}
          onChange={this.onChange}
          handleOpenAlert={this.handleOpenAlert}
          handleOpenAlert1={this.handleOpenAlert1}
          enableDateRangeFilter={true}
          enableJobFilter={true}
          history={this.props.history}
          onDownload={this.onDownload}
          actionStyle="icons"
          urlSearchParamsJob={this.urlSearchParams.get("job") || ""}
        />

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onDelete}
          title="Invoice Delete"
          info="Are you sure to permanently delete this invoice?"
          awaitingInfo="Invoice is deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetInvoice}
        />

        <Alert
          open={this.state.openAlert1}
          close={this.handleCloseAlert1}
          action={this.onDeleteAll}
          title="Invoices Delete"
          info="Are you sure to permanently delete these invoices?"
          awaitingInfo="Invoices are deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetInvoice}
        />
      </>
    );
  }
}
const mapStateToProps = ({ invoice }) => {
  const { invoiceList, loading1, loading, downloading, success, error } = invoice;
  return { invoiceList, loading1, loading, downloading, success, error };
};

export default connect(mapStateToProps, {
  getAllBooking,
  getInvoiceList,
  downloadInvoice,
  deleteInvoice,
  deleteMultipleInvoice,
  resetInvoice,
})(InvoiceList);
