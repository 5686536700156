import React, { Component } from "react";
import { connect } from "react-redux";
import { getDriverList, deleteDriver, deleteMultipleDriver, resetDriver } from "src/reduxs/actions";
import DataTable from "src/components/datatable";
import Breadcrumb from "src/components/breadcrumb";
import { Alert } from "src/components/dialog";
class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      deleteIds: null,
      openAlert: false,
      openAlert1: false,
    };
    this.searchFields = "email:like;name:like;driving_licence_number:like;phone:like;";
  }

  componentDidMount() {
    this.props.getDriverList({});
  }

  onDelete = () => {
    if (!this.props.loading1) {
      this.props.deleteDriver(this.state.deleteId);
    }
  };

  onDeleteAll = () => {
    if (!this.props.loading1) {
      this.props.deleteMultipleDriver(this.state.deleteIds);
    }
  };

  handleOpenAlert = (id) => {
    this.setState({ openAlert: true, deleteId: id });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, deleteId: null });
  };

  handleOpenAlert1 = (ids) => {
    this.setState({ openAlert1: true, deleteIds: ids });
  };

  handleCloseAlert1 = () => {
    this.setState({ openAlert1: false, deleteIds: null });
  };

  onChange = (search, sortOrder, page, pageSize, activeCol) => {
    this.props.getDriverList({
      search: search,
      searchFields: this.searchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
    });
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Chauffeur Management"}
          paths={[
            {
              title: "Chauffeur",
            },
          ]}
        />

        <DataTable
          title="Chauffeur"
          headCells={[
            {
              id: "userAvatar",
              sortKey: "name",
              label: "Name",
            },
            {
              id: "phone",
              sortKey: "phone",
              label: "Contact",
            },
            {
              id: "createdAt",
              sortKey: "created_at",
              label: "Created Date",
            },
          ]}
          rows={this.props.driverList?.data || []}
          totalPage={this.props.driverList?.meta?.pages || 0}
          totalItem={this.props.driverList?.meta?.total || 0}
          start={this.props.driverList?.meta?.start || 0}
          end={this.props.driverList?.meta?.end || 0}
          loading={this.props.loading}
          route={"/driver"}
          createLabel="Add Chauffeur"
          handleOpenAlert={this.handleOpenAlert}
          handleOpenAlert1={this.handleOpenAlert1}
          onChange={this.onChange}
          permission={{ add: 1, edit: 1, view: 1, delete: 1 }}
          history={this.props.history}
          enableRowClick={true}
        />

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onDelete}
          title="Chauffeur Delete"
          info="Are you sure to permanently delete this driver?"
          awaitingInfo="Chauffeur is deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetDriver}
        />

        <Alert
          open={this.state.openAlert1}
          close={this.handleCloseAlert1}
          action={this.onDeleteAll}
          title="Chauffeurs Delete"
          info="Are you sure to permanently delete these chauffeurs?"
          awaitingInfo="Chauffeurs are deleting..."
          actionBtnLabel="Delete"
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetDriver}
        />
      </>
    );
  }
}
const mapStateToProps = ({ driver }) => {
  const { driverList, loading1, loading, success, error } = driver;
  return { driverList, loading1, loading, success, error };
};

export default connect(mapStateToProps, {
  getDriverList,
  deleteDriver,
  deleteMultipleDriver,
  resetDriver,
})(DriverList);
