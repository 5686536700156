import {
  GET_ALL_DRIVER,
  GET_ALL_DRIVER_SUCCESS,
  GET_ALL_DRIVER_ERROR,
  GET_DRIVER_LIST,
  GET_DRIVER_LIST_SUCCESS,
  GET_DRIVER_LIST_ERROR,
  ADD_DRIVER,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_ERROR,
  GET_DRIVER,
  GET_DRIVER_SUCCESS,
  GET_DRIVER_ERROR,
  EDIT_DRIVER,
  EDIT_DRIVER_SUCCESS,
  EDIT_DRIVER_ERROR,
  DELETE_DRIVER,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_ERROR,
  DELETE_MULTIPLE_DRIVER,
  DELETE_MULTIPLE_DRIVER_SUCCESS,
  DELETE_MULTIPLE_DRIVER_ERROR,
  RESET_DRIVER,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  drivers: null,
  driverList: null,
  driverData: null,
  driverId: null,
  driverIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const driverReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DRIVER:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        error: null,
      };
    case GET_ALL_DRIVER_ERROR:
      return {
        ...state,
        drivers: null,
        error: action.payload,
      };
    case GET_DRIVER_LIST:
      return {
        ...state,
        loading: true,
        driverData: null,
        driverId: null,
        driverIds: null,
        error: null,
      };
    case GET_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        driverList: action.payload,
        error: null,
      };
    case GET_DRIVER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        driverList: null,
        error: action.payload,
      };
    case ADD_DRIVER:
      return { ...state, loading: true, error: null };
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_DRIVER:
      return { ...state, error: null };
    case GET_DRIVER_SUCCESS:
      return {
        ...state,
        driverData: action.payload,
        error: null,
      };
    case GET_DRIVER_ERROR:
      return {
        ...state,
        driverData: null,
        error: action.payload,
      };
    case EDIT_DRIVER:
      return { ...state, loading: true, error: null };
    case EDIT_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_DRIVER:
      return { ...state, loading1: true, error: null };
    case DELETE_DRIVER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_DRIVER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_DRIVER:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_DRIVER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_DRIVER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_DRIVER:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default driverReducer;
