import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import PageMeta from "src/components/pages-meta";
import { WebsiteLayout } from "src/layouts";
import { IconCarFront } from "src/components/svg";
import bannerImg from "src/assets/images/website/wedding.jpg";
import weddingImage1 from "src/assets/images/website/services/wedding-1.jpg";
import weddingImage2 from "src/assets/images/website/services/wedding-2.jpg";
import weddingImage3 from "src/assets/images/website/services/wedding-3.jpg";

class WeddingVehicles extends Component {
  render() {
    return (
      <WebsiteLayout>
        <PageMeta description="Our stylish, chauffeured wedding cars with professionally presented drivers will make your wedding day extra special. We’re here to help in Sydney, Melbourne, Adelaide, Perth, Brisbane and the Gold Coast." />

        <section className="inner-banner" style={{ height: "42vh" }}>
          <img src={bannerImg} alt="" />

          <Mui.Container maxWidth="md" className="inner-banner__content">
            <Mui.Breadcrumbs className="text-color-white">
              <Mui.Link component={Link} to={"/"} className="text-color-white">
                Home
              </Mui.Link>
              <Mui.Link underline="none" className="text-color-white">
                Wedding Vehicles
              </Mui.Link>
            </Mui.Breadcrumbs>

            <Mui.Typography component="h1" variant="h2">
              Wedding Vehicles
            </Mui.Typography>
          </Mui.Container>
        </section>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Your wedding is your special day, so you don’t want to spend
                  it worrying about whether your wedding cars will arrive on
                  time – or at all! At Shangri-La Chauffeurs, we go above and
                  beyond to make sure your wedding transport is seamless and
                  drama free.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our sleek, stylish vehicles are spacious, very clean and
                  comfortable, allowing you to travel in luxury. We have a wide
                  range of chauffeured wedding cars, whether you prefer a
                  limousine, sporty sedan or large people mover (for relatives
                  or the bridal party).
                </Mui.Typography>
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  Our drivers are professional, respectful and impeccably
                  dressed from their hat and gloves to shiny shoes. You will be
                  proud to be ferried to your destination by our chauffers. And
                  every driver is fully licensed, trained and local to the area.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={weddingImage1}
                  alt="Wedding Vehicles"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid container spacing={10}>
              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                className={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={weddingImage3}
                  alt="Wedding Vechile"
                />
              </Mui.Grid>

              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We know that arriving on time is not negotiable on your
                  wedding day. So give us your schedule and we’ll be there –
                  ready when you are. (And if you’re running a bit behind, we
                  completely understand. We’ll do our best to accommodate you!)
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  You can hire a driver for just the wedding or keep them for
                  the entire day. We can take you wherever you need to go,
                  including photography locations, the reception and your
                  accommodation at the end of the day.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  You can also hire vehicles for the bridal party and relatives,
                  and we’ll ‘dress’ the cars to match each other. Out-of-town
                  guests can also use our services to ensure they don’t get lost
                  in unfamiliar surrounds.
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box
          id="business"
          component="section"
          className="section-default pb-1 p-sm text-color-grey"
        >
          <Mui.Container maxWidth="md">
            <Mui.Grid
              container
              spacing={10}
              direction={
                Mui.isWidthDown("sm", this.props.width)
                  ? "column-reverse"
                  : "row"
              }
            >
              <Mui.Grid
                component="article"
                item
                xs={12}
                md={7}
                className="d-flex flex-direction-column align-items-start"
              >
                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  When you book, we’ll discuss all your plans with you,
                  including your schedule, how many people are coming and what
                  type of vehicle you would prefer. Also, tell us about
                  additions you would like, such as ribbon for the car and
                  champagne for the ride.
                </Mui.Typography>

                <Mui.Typography
                  component="p"
                  variant="h4"
                  className="text-color-grey font-weight-normal mb-0-8"
                >
                  We would be proud to be a part of your day, working in the
                  background to provide seamless wedding transportation while
                  you focus on getting married. We can’t wait to help bring your
                  vision to life.
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                component="figure"
                item
                xs={12}
                md={5}
                cclassName={`overflow-hidden ${
                  Mui.isWidthDown("sm", this.props.width) ? "mb-4" : ""
                }`}
              >
                <img
                  className="img-fluid-cover border-rad-10"
                  src={weddingImage2}
                  alt="Wedding Vechile"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Container>
        </Mui.Box>

        <Mui.Box display="flex" justifyContent="center" mt={10} mb={10}>
          <Mui.Button
            component={Link}
            // to={"/ride"}
            to="/contact"
            className="btn-default"
            color="primary"
            variant="contained"
            disableElevation
          >
            Book Now
            <span className="ml-1 line-height-null">
              <IconCarFront />
            </span>
          </Mui.Button>
        </Mui.Box>
      </WebsiteLayout>
    );
  }
}

export default Mui.withWidth()(WeddingVehicles);
