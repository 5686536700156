import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { sideNavConfig } from "src/configs/constant";

const Menu = (props) => {
  const [currentPath, setCurrentPath] = useState("");
  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    setCurrentPath(window.location.pathname.split("/")[1] || window.location.pathname);
    setActivePath(props.item?.path.split("/")[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  return (
    <Mui.Box
      component="li"
      className={`submenu-parent ${
        sideNavConfig.hoverSubMenu ? "hover-menu" : ""
      }`}
    >
      <Mui.ListItem
        component={Link}
        to={props.item?.path}
        className={
          currentPath && activePath && currentPath === activePath
            ? "active"
            : ""
        }
        onClick={() => {
          Mui.isWidthDown("md", props.width) && props.closeDrawer();
        }}
      >
        <span className="icon-menu">{props.item.icon}</span>

        <span>{props.item.title}</span>

        <span className="ml-auto pl-1 flex-shrink-0">
          {props.item?.submenu?.length > 0 &&
          currentPath &&
          activePath &&
          currentPath === activePath ? (
            <ExpandLess />
          ) : props.item?.submenu?.length > 0 ? (
            <ExpandMore />
          ) : null}
        </span>
      </Mui.ListItem>

      {props.item?.submenu?.length > 0 ? (
        <Mui.Collapse
          in={
            currentPath && activePath && currentPath === activePath
              ? true
              : false
          }
          className="cms-drawer__list__submenu"
        >
          <Mui.List>
            {props.item.submenu?.map((val, j) => {
              return (
                <Mui.ListItem
                  key={j}
                  onClick={() => {
                    Mui.isWidthDown("md", props.width) && props.closeDrawer();
                  }}
                >
                  <NavLink to={val.path}>
                    <figure>{val.icon}</figure>
                    <span>{val.title}</span>
                  </NavLink>
                </Mui.ListItem>
              );
            })}
          </Mui.List>
        </Mui.Collapse>
      ) : null}
    </Mui.Box>
  );
};

export default Mui.withWidth()(Menu);
