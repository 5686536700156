import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Breadcrumb from "src/components/breadcrumb";
import { getUser, addUser, editUser } from "src/reduxs/actions";
import { InputField, InputHiddenField, InputPasswordField, StyledSwitch } from "src/components/form";
import FileUpload from "src/components/file-upload";
import GoogleAutocomplete from "src/components/autocomplete";
class EditUser extends Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id) {
      this.editUserSchema = Yup.object().shape({
        firstName: Yup.string()
          .required("Please provide first name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
        lastName: Yup.string()
          .required("Please provide last name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        phone: Yup.string()
          .required("Contact No is required")
          .min(8, "Atleast 8 digits")
          .max(10, "Cannot exceed 10 digits"),
        address: Yup.string().required("Enter a valid address"),
      });
    } else {
      this.addUserschema = Yup.object().shape({
        firstName: Yup.string()
          .required("Please provide first name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
        lastName: Yup.string()
          .required("Please provide last name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        phone: Yup.string()
          .required("Contact No is required")
          .min(8, "Atleast 8 digits")
          .max(10, "Cannot exceed 10 digits"),
        address: Yup.string().required("Enter a valid address"),
        password: Yup.string()
          .required("Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password should be at least 8 digits including 1 number, 1 uppercase, 1 lowercase and 1 special character"
          ),
        password_confirmation: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password"), null], "Passsword and Confirm Password didn't match"),
      });
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getUser(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editUser(this.props.match.params.id, values, this.props.history);
      } else {
        this.props.addUser(values, this.props.history);
      }
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Customer Management"}
          paths={[
            {
              title: "Customer",
              page: `/user`,
            },
            {
              title: this.props.match.params.id ? "Edit" : "Add",
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: this.props.match.params.id ? this.props.userData?.firstName || "" : "",
            lastName: this.props.match.params.id ? this.props.userData?.lastName || "" : "",
            email: this.props.match.params.id ? this.props.userData?.email || "" : "",
            phone: this.props.match.params.id ? this.props.userData?.phone || "" : "",
            address: this.props.match.params.id ? this.props.userData?.address || "" : "",
            userPhoto: this.props.match.params.id ? this.props.userData?.userPhoto || [] : [],
            isVerified: this.props.match.params.id ? this.props.userData?.isVerified || 0 : 0,
            patron: this.props.match.params.id ? this.props.userData?.patron || 0 : 0,
            showCost: this.props.match.params.id ? this.props.userData?.showCost || 0 : 0,
            password: "",
            password_confirmation: "",
            roleId: "2",
          }}
          validationSchema={this.props.match.params.id ? this.editUserSchema : this.addUserschema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Card className="default-card">
                <FileUpload field="userPhoto" imgArr={props.values.userPhoto} upload={props.setFieldValue} />

                <Mui.Typography component="h4" variant="h4" className="mb-4 font-weight-medium">
                  Customer Information
                </Mui.Typography>

                <Mui.Grid container spacing={2}>
                  <InputHiddenField name="role_id" />

                  <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
                    <InputField name="firstName" type="text" label="First Name" />
                  </Mui.Grid>

                  <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
                    <InputField name="lastName" type="text" label="Last Name" />
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
                    <InputField name="email" type="text" label="Email" />
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
                    <InputField
                      name="phone"
                      type="number"
                      label="Contact"
                      InputProps={{
                        startAdornment: <Mui.InputAdornment position="start">+61</Mui.InputAdornment>,
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={4} className="form-group">
                    <GoogleAutocomplete
                      label={"Address"}
                      variant={"outlined"}
                      touched={props.touched?.address}
                      error={props.errors?.address}
                      defaultValue={props.values?.address}
                      filterState={false}
                      callback={(description) => {
                        props.setFieldValue(`address`, description || "");
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid className="form-group" item xs={12} md={6} lg={4}></Mui.Grid>
                  {!this.props.match.params.id ? (
                    <>
                      <Mui.Grid className="form-group" item xs={12} md={6} lg={4}>
                        <InputPasswordField name="password" label="Password*" />
                      </Mui.Grid>
                      <Mui.Grid className="form-group" item xs={12} md={6} lg={4}>
                        <InputPasswordField name="password_confirmation" label="Confirm Password*" />
                      </Mui.Grid>
                    </>
                  ) : null}
                </Mui.Grid>
                <Mui.Box className="form-group" display="flex" flexWrap="wrap" gridGap="1.67rem">
                  <Mui.FormControlLabel
                    classes={{
                      root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                      label: "mr-2",
                    }}
                    control={
                      <StyledSwitch
                        value={props.values.isVerified}
                        checked={props.values.isVerified === 1}
                        onChange={(e) => {
                          props.setFieldValue(`isVerified`, e.target.checked ? 1 : 0);
                        }}
                        name={`isVerified`}
                      />
                    }
                    label={"Is Verified"}
                  />
                  <Mui.FormControlLabel
                    classes={{
                      root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                      label: "mr-2",
                    }}
                    control={
                      <StyledSwitch
                        value={props.values.patron}
                        checked={props.values.patron === 1}
                        onChange={(e) => {
                          props.setFieldValue(`patron`, e.target.checked ? 1 : 0);
                        }}
                        name={`patron`}
                      />
                    }
                    label={"Special Customer?"}
                  />
                  <Mui.FormControlLabel
                    classes={{
                      root: "d-flex flex-direction-row-reverse justify-content-end mt-2 ml-0",
                      label: "mr-2",
                    }}
                    control={
                      <StyledSwitch
                        value={props.values.showCost}
                        checked={props.values.showCost === 1}
                        onChange={(e) => {
                          props.setFieldValue(`showCost`, e.target.checked ? 1 : 0);
                        }}
                        name={`showCost`}
                      />
                    }
                    label={"Display Booking Cost?"}
                  />
                </Mui.Box>
              </Mui.Card>

              <Mui.Box
                width="100%"
                display="flex"
                flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}
                className="mt-5"
              >
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Save
                  {this.props.loading && <Mui.CircularProgress color="secondary" size={24} />}
                </Mui.Button>
                <Mui.Button
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"}`}
                  color="primary"
                  type="button"
                  variant="outlined"
                  disableElevation
                  component={Link}
                  to={"/user"}
                >
                  Back
                </Mui.Button>
                <Mui.Button
                  className="font-weight-normal"
                  type="button"
                  variant="text"
                  disableElevation
                  onClick={props.resetForm}
                >
                  Reset
                </Mui.Button>
              </Mui.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  const { userData, success, message, loading, error } = user;
  return {
    userData,
    success,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getUser,
  addUser,
  editUser,
})(Mui.withWidth()(EditUser));
