import {
  DEACTIVATE,
  DEACTIVATE_SUCCESS,
  DEACTIVATE_ERROR,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_ERROR,
  GET_SCHEDULAR,
  GET_SCHEDULAR_SUCCESS,
  GET_SCHEDULAR_ERROR,
} from "src/reduxs/actions";

const INIT_STATE = {
  userId: null,
  param: null,
  schedules: null,
  success: false,
  message: null,
  loading: false,
  error: null,
};

const sharedReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEACTIVATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEACTIVATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DEACTIVATE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case CONTACT_US:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_SCHEDULAR:
      return {
        ...state,
        error: null,
      };
    case GET_SCHEDULAR_SUCCESS:
      return {
        ...state,
        schedules: action.payload,
        error: null,
      };
    case GET_SCHEDULAR_ERROR:
      return {
        ...state,
        schedules: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default sharedReducer;
