import React from "react";

const IconInvoice = () => {
  return (
    <svg viewBox="0 0 128 128">
      <g>
        <path d="M96,0.6l-10,10l-10-10l-10,10l-10-10l-10,10l-10-10l-10,10l-10-10L2.2,14.4V108c0,10.4,8.5,18.8,18.8,18.8h93   c6.5,0,11.8-5.3,11.8-11.8V39h-7.7v76c0,2.3-1.9,4.2-4.2,4.2s-4.2-1.9-4.2-4.2V14.4L96,0.6z M21,119.2c-6.2,0-11.2-5-11.2-11.2   V17.6l6.2-6.2l10,10l10-10l10,10l10-10l10,10l10-10l10,10l10-10l6.2,6.2V115c0,1.5,0.3,2.9,0.8,4.2H21z" />
        <path d="M60,32h-8v10.8L51.4,43c-5.7,2.3-9.4,7.7-9.4,13.8c0,4.5,2,8.7,5.6,11.6l11.9,9.5c1.6,1.3,2.6,3.2,2.6,5.3   c0,3.8-3.1,6.8-6.8,6.8H42v8h10v10h8V97.2l0.6-0.3c5.7-2.3,9.4-7.7,9.4-13.8c0-4.5-2-8.7-5.6-11.6l-11.9-9.5   c-1.6-1.3-2.6-3.2-2.6-5.3c0-3.8,3.1-6.8,6.8-6.8H70v-8H60V32z" />
      </g>
    </svg>
  );
};

export default IconInvoice;
