import {
  FILTER_CAR,
  FILTER_CAR_SUCCESS,
  FILTER_CAR_ERROR,
  GET_USER_CARD,
  GET_USER_CARD_SUCCESS,
  GET_USER_CARD_ERROR,
  BOOK_RIDE,
  BOOK_RIDE_SUCCESS,
  BOOK_RIDE_ERROR,
  REBOOK_RIDE,
  REBOOK_RIDE_SUCCESS,
  REBOOK_RIDE_ERROR,
  CALCULATE_PRICE,
  CALCULATE_PRICE_SUCCESS,
  CALCULATE_PRICE_ERROR,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_ERROR,
  RESET_RIDE,
  RESET_PRICE,
  RESET_COUPON,
  RESET_USER_CARD
} from "src/reduxs/actions";

const INIT_STATE = {
  param: null,
  userId: null,
  userCard: null,
  carList: null,
  rideData: null,
  rebookData: null,
  coupon: null,
  priceData: null,
  couponApplied: null,
  success: false,
  success1: false,
  success2: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
  error1: null,
  error2: null,
};

const rideReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BOOK_RIDE:
      return { ...state, loading: true, error: null };
    case BOOK_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case BOOK_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case REBOOK_RIDE:
      return { ...state, loading: true, error: null };
    case REBOOK_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case REBOOK_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_USER_CARD:
      return {
        ...state,
        error: null,
      };
    case GET_USER_CARD_SUCCESS:
      return {
        ...state,
        userCard: action.payload,
        error: null,
      };
    case GET_USER_CARD_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FILTER_CAR:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case FILTER_CAR_SUCCESS:
      return {
        ...state,
        loading1: false,
        carList: action.payload,
        error: null,
      };
    case FILTER_CAR_ERROR:
      return {
        ...state,
        loading1: false,
        carList: null,
        error: action.payload,
      };
    case CALCULATE_PRICE:
      return { ...state, loading2: true, error1: null };
    case CALCULATE_PRICE_SUCCESS:
      return {
        ...state,
        loading2: false,
        priceData: action.payload.priceData,
        success1: action.payload.success1,
        error1: null,
      };
    case CALCULATE_PRICE_ERROR:
      return {
        ...state,
        loading2: false,
        error1: action.payload,
      };
    case APPLY_COUPON:
      return { ...state, loading3: true, error2: null };
    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loading3: false,
        priceData: action.payload.priceData,
        success2: action.payload.success2,
        couponApplied: true,
        error2: null,
      };
    case APPLY_COUPON_ERROR:
      return {
        ...state,
        loading3: false,
        success2: false,
        couponApplied: null,
        error2: action.payload,
      };
    case RESET_PRICE:
      return {
        ...state,
        priceData: null,
      };
    case RESET_COUPON:
      return {
        ...state,
        couponApplied: null,
      };
    case RESET_USER_CARD:
      return {
        ...state,
        userCard: null,
    };
    case RESET_RIDE:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        success1: false,
        success2: false,
        message: null,
        error: null,
        error1: null,
        error2: null,
      };
    default:
      return { ...state };
  }
};
export default rideReducer;
