import React from "react";

const IconRoute = () => {
  return (
    <svg version="1.1" viewBox="0 0 512 512" width="22" height="22">
      <g>
        <g>
          <g>
            <path
              d="M426.667,0c-47.052,0-85.333,38.281-85.333,85.333c0,40.814,60.469,123.419,74.637,142.22
            c-0.004,6.073,0,12.036-0.147,17.509c-0.156,5.885,4.49,10.792,10.385,10.948c0.094,0,0.188,0,0.292,0
            c5.75,0,10.5-4.594,10.656-10.385c0.147-5.616,0.143-11.746,0.147-17.992C451.378,208.961,512,126.195,512,85.333
            C512,38.281,473.719,0,426.667,0z M426.667,206.146c-23.448-32.26-64-93.656-64-120.813c0-35.292,28.708-64,64-64
            s64,28.708,64,64C490.667,112.49,450.115,173.885,426.667,206.146z"
            />
            <path
              d="M426.667,64c-11.76,0-21.333,9.573-21.333,21.333c0,11.76,9.573,21.333,21.333,21.333S448,97.094,448,85.333
            C448,73.573,438.427,64,426.667,64z"
            />
            <path
              d="M128,234.667c-35.292,0-64,28.708-64,64c0,35.292,28.708,64,64,64s64-28.708,64-64
            C192,263.375,163.292,234.667,128,234.667z M128,341.333c-23.531,0-42.667-19.135-42.667-42.667S104.469,256,128,256
            s42.667,19.135,42.667,42.667S151.531,341.333,128,341.333z"
            />
            <path
              d="M289.573,384.438c-5.521-2.146-11.667,0.594-13.802,6.094c-4.781,12.354-10.76,26.76-18.823,39.958
            c-3.073,5.031-1.479,11.594,3.542,14.667c1.74,1.063,3.656,1.563,5.552,1.563c3.594,0,7.104-1.813,9.115-5.104
            c8.938-14.646,15.385-30.146,20.51-43.375C297.792,392.75,295.063,386.573,289.573,384.438z"
            />
            <path
              d="M229.427,462.281c-10.688,8.469-22.833,14.844-37.146,19.5c-5.604,1.823-8.667,7.844-6.844,13.448
            c1.469,4.5,5.646,7.365,10.146,7.365c1.094,0,2.208-0.167,3.302-0.521c16.531-5.375,31.26-13.135,43.792-23.063
            c4.615-3.667,5.396-10.375,1.74-14.99C240.729,459.417,234.031,458.594,229.427,462.281z"
            />
            <path
              d="M326.5,307.427c-17.604,9.99-27.385,27.333-33.219,39.5c-2.552,5.313-0.313,11.688,5,14.229
            c1.49,0.719,3.063,1.052,4.604,1.052c3.979,0,7.792-2.229,9.625-6.052c7.406-15.448,14.969-24.75,24.531-30.188
            c5.115-2.906,6.917-9.417,4-14.542C338.146,306.302,331.656,304.5,326.5,307.427z"
            />
            <path
              d="M422.708,281.021c-4.844-3.281-11.5-2.021-14.813,2.854c-3.51,5.188-10.698,12.323-32.438,14.271
            c-5.865,0.531-10.198,5.708-9.667,11.583c0.5,5.542,5.156,9.708,10.615,9.708c0.323,0,0.646-0.01,0.969-0.042
            c23.094-2.073,38.854-9.781,48.188-23.563C428.865,290.958,427.583,284.323,422.708,281.021z"
            />
            <path
              d="M153.275,490.805C186.932,454.913,256,372.341,256,298.667c0-71.771-56.229-128-128-128s-128,56.229-128,128
            c0,95.26,115.75,205.76,120.677,210.417c2.052,1.948,4.688,2.917,7.323,2.917c7.771,0,15.156-0.271,22.156-0.802
            c5.875-0.448,10.281-5.563,9.833-11.438C159.669,495.556,156.948,492.277,153.275,490.805z M128,486.292
            c-25.198-25.708-106.667-114.5-106.667-187.625C21.333,238.854,68.188,192,128,192s106.667,46.854,106.667,106.667
            C234.667,371.792,153.198,460.583,128,486.292z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconRoute;
