import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import RouteList from "./list";
import EditRoute from "./edit";
import ViewRoute from "./view";

function Route() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/route" component={RouteList} />
        <ContentRoute path="/route/add" component={EditRoute} />
        <ContentRoute path="/route/edit/:id" component={EditRoute} />
        <ContentRoute path="/route/view/:id" component={ViewRoute} />
      </Switch>
    </Suspense>
  );
}

export default Route;
