import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import Breadcrumb from "src/components/breadcrumb";
import { getDriver, downloadMedia } from "src/reduxs/actions";
import { Link } from "react-router-dom";
import {
  IconChevronLeft,
  IconMail,
  IconPhone,
  IconSteering,
  IconDownload,
  IconCurrencyDolor,
} from "src/components/svg";
import fileIcon from "src/assets/images/icon-file.svg";
import moment from "moment";
class ViewDriver extends Component {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getDriver(this.props.match.params.id);
    }
  }

  render() {
    return (
      <>
        <Breadcrumb
          title={"Chauffeur Management"}
          paths={[
            {
              title: "Chauffeur",
              page: `/driver`,
            },
            {
              title: "View",
            },
          ]}
        />

        <Mui.Card className="default-card spacing-md pb-5 mb-4">
          <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
            <Mui.IconButton
              className="border-1 border-color-grey"
              type="button"
              component={Link}
              to={"/driver"}
              size="small"
              style={{ padding: 6, marginRight: 15 }}
            >
              <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
                <IconChevronLeft />
              </Mui.Box>
            </Mui.IconButton>

            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
              Personal Information
            </Mui.Typography>

            <Mui.Button
              className={`btn-default ${Mui.isWidthDown("sm", this.props.width) ? "mt-2" : "ml-2"}`}
              color="primary"
              type="button"
              variant="outlined"
              disableElevation
              component={Link}
              to={`/driver/edit/${this.props.match.params.id}`}
            >
              Edit
            </Mui.Button>
          </Mui.Box>

          <Mui.Box display="flex" flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}>
            <Mui.Avatar
              className={`img-size-xl ${Mui.isWidthDown("xs", this.props.width) ? "mb-5" : "mr-5"}`}
              src={
                this.props.driverData?.driverPhoto?.[0]?.url
                  ? this.props.driverData?.driverPhoto?.[0]?.url
                  : "/static/images/avatar/1.jpg"
              }
              alt={this.props.driverData?.name || ""}
            />

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h1"
                variant="h1"
                className={`mb-3 svg-color-primary font-weight-medium ${
                  Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : ""
                }`}
              >
                {this.props.driverData?.name || "-"}
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="w-100 d-flex mb-2  svg-color-primary text-color-grey font-weight-normal"
              >
                <span className="flex-shrink-0 mr-2">
                  <IconSteering />
                </span>
                {this.props.driverData?.drivingLicenceNumber || "-"}
              </Mui.Typography>

              <Mui.Box display="flex" flexDirection={Mui.isWidthDown("sm", this.props.width) ? "column" : "row"}>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0 mr-2">
                    <IconMail />
                  </span>
                  {this.props.driverData?.email || "-"}
                </Mui.Typography>

                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0 mr-2">
                    <IconPhone />
                  </span>
                  +61 {this.props.driverData?.phone || "-"}
                </Mui.Typography>

                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0 mr-2">
                    <IconCurrencyDolor />
                  </span>
                  {this.props.driverData?.payment || "-"}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          </Mui.Box>
        </Mui.Card>
        {this.props.driverData?.driverDocument?.length > 0 ? (
          <Mui.Card className="default-card spacing-md pb-5">
            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium mb-3 mt-2">
              Chauffeur Documents
            </Mui.Typography>
            <Mui.TableContainer className="default-datatable">
              <Mui.Table size={"medium"}>
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell className="font-weight-normal ">S.N</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal "></Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal ">File</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal ">Created Date</Mui.TableCell>
                    <Mui.TableCell className="font-weight-normal text-left">Download</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {this.props.driverData?.driverDocument?.map((item, index) => (
                    <Mui.TableRow key={index}>
                      <Mui.TableCell className="text-transform-capitalize">{index + 1}</Mui.TableCell>
                      <Mui.TableCell>
                        <figure className="svg-size-compact" style={{ width: 40, height: 40 }}>
                          {item.mimeType.includes("image") ? (
                            <img width="100%" className="img-fluid-cover" src={item.url} alt="" />
                          ) : (
                            <img width="100%" className="img-fluid-contain" src={fileIcon} alt="" />
                          )}
                        </figure>
                      </Mui.TableCell>
                      <Mui.TableCell>{item.fileName}</Mui.TableCell>
                      <Mui.TableCell>{moment(item.createdAt).format("Do MMM YYYY HH:mm")}</Mui.TableCell>
                      <Mui.TableCell className="text-left">
                        <Mui.IconButton
                          onClick={() => this.props.downloadMedia(item.id, item.fileName, item.mimeType)}
                          color="primary"
                          className="btn-close"
                        >
                          <IconDownload />
                        </Mui.IconButton>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
          </Mui.Card>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = ({ driver }) => {
  const { driverData, loading, error } = driver;
  return { driverData, loading, error };
};
export default connect(mapStateToProps, { getDriver, downloadMedia })(Mui.withWidth()(ViewDriver));
