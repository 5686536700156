import Req from "src/interceptors/token-interceptor";

const ConsolidateInvoiceService = (function () {
  function _getAllConsolidateInvoice() {
    return Req.get(`/api/consolidate/invoices`);
  }
  function _getConsolidateInvoice(id) {
    return Req.get("/api/consolidate/invoices/" + id);
  }
  function _addConsolidateInvoice(data) {
    return Req.post("/api/consolidate/invoices", data);
  }
  function _downloadConsolidateInvoice(id) {
    return Req.get(`/api/consolidate/invoices/download/${id}`, {
      responseType: "arraybuffer",
    });
  }
  function _deleteConsolidateInvoice(id) {
    return Req.delete("/api/consolidate/invoices/" + id);
  }
  function _deleteMultipleConsolidateInvoice(ids) {
    return Req.post("/api/consolidate/invoices/delete", { ids: ids });
  }
  function _mergeInvoice(data) {
    return Req.post(`/api/consolidate/invoices/merge`, data, {
      responseType: "arraybuffer",
    });
  }
  return {
    getAllConsolidateInvoice: _getAllConsolidateInvoice,
    getConsolidateInvoice: _getConsolidateInvoice,
    addConsolidateInvoice: _addConsolidateInvoice,
    downloadConsolidateInvoice: _downloadConsolidateInvoice,
    deleteConsolidateInvoice: _deleteConsolidateInvoice,
    deleteMultipleConsolidateInvoice: _deleteMultipleConsolidateInvoice,
    mergeInvoice: _mergeInvoice,
  };
})();
export default ConsolidateInvoiceService;
