import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import {
  GET_ALL_INVOICE,
  GET_INVOICE_LIST,
  GET_USER_INVOICE_LIST,
  ADD_INVOICE,
  GET_INVOICE,
  DELETE_INVOICE,
  DELETE_MULTIPLE_INVOICE,
  DOWNLOAD_INVOICE,
} from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import { parseMessage } from "src/helpers/util";
import InvoiceService from "src/services/invoice-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllInvoiceSuccess,
  getAllInvoiceError,
  getInvoiceList,
  getInvoiceListSuccess,
  getInvoiceListError,
  getUserInvoiceListSuccess,
  getUserInvoiceListError,
  addInvoiceSuccess,
  addInvoiceError,
  getInvoiceSuccess,
  getInvoiceError,
  deleteInvoiceSuccess,
  deleteInvoiceError,
  deleteMultipleInvoiceSuccess,
  deleteMultipleInvoiceError,
  downloadInvoiceSuccess,
  downloadInvoiceError,
} from "./action";

export function* watchGetAllInvoice() {
  yield takeEvery(GET_ALL_INVOICE, getAllInvoice);
}

const getAllInvoiceAsync = async (param) => {
  return InvoiceService.getAllInvoice(param);
};

function* getAllInvoice({ payload }) {
  try {
    const response = yield call(getAllInvoiceAsync, payload.param);
    if (response.data.success) {
      yield put(getAllInvoiceSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllInvoiceError(error.response.data.message));
  }
}

export function* watchGetInvoiceList() {
  yield takeEvery(GET_INVOICE_LIST, getInvoiceListAc);
}

const getInvoiceListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "invoices",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.fromDate || "",
    dbParam?.toDate || "",
    dbParam?.jobNo || ""
  );
};

function* getInvoiceListAc({ payload }) {
  try {
    const response = yield call(getInvoiceListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getInvoiceListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getInvoiceListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getInvoiceListError(error.response.data.message));
  }
}

export function* watchGetUserInvoiceList() {
  yield takeEvery(GET_USER_INVOICE_LIST, getUserInvoiceListAc);
}

const getUserInvoiceListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    `invoices/users/${dbParam.userId}`,
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.fromDate || "",
    dbParam?.toDate || "",
    dbParam?.jobNo || ""
  );
};

function* getUserInvoiceListAc({ payload }) {
  try {
    const response = yield call(getUserInvoiceListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getUserInvoiceListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getUserInvoiceListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getUserInvoiceListError(error.response.data.message));
  }
}

export function* watchAddInvoice() {
  yield takeEvery(ADD_INVOICE, addInvoice);
}

const addInvoiceAsync = async (data) => {
  return InvoiceService.addInvoice(data);
};

function* addInvoice({ payload }) {
  try {
    const response = yield call(addInvoiceAsync, payload.invoiceData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addInvoiceSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addInvoiceError(error.response.data.message));
  }
}

export function* watchGetInvoice() {
  yield takeEvery(GET_INVOICE, getInvoice);
}

const getInvoiceAsync = async (id) => {
  return InvoiceService.getInvoice(id);
};

function* getInvoice({ payload }) {
  try {
    const response = yield call(getInvoiceAsync, payload.invoiceId);
    if (response.data.success) {
      yield put(getInvoiceSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getInvoiceError(error.response.data.message));
  }
}

export function* watchDeleteInvoice() {
  yield takeEvery(DELETE_INVOICE, deleteInvoice);
}

const deleteInvoiceAsync = async (id) => {
  return InvoiceService.deleteInvoice(id);
};

function* deleteInvoice({ payload }) {
  try {
    const response = yield call(deleteInvoiceAsync, payload.invoiceId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteInvoiceSuccess(response.data.success, response.data.message));
      // Fetch updated invoice list
      yield put(getInvoiceList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteInvoiceError(error.response.data.message));
  }
}

export function* watchDeleteMultipleInvoice() {
  yield takeEvery(DELETE_MULTIPLE_INVOICE, deleteMultipleInvoice);
}

const deleteMultipleInvoiceAsync = async (ids) => {
  return InvoiceService.deleteMultipleInvoice(ids);
};

function* deleteMultipleInvoice({ payload }) {
  try {
    const response = yield call(deleteMultipleInvoiceAsync, payload.invoiceIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleInvoiceSuccess(response.data.success, response.data.message));
      // Fetch updated invoice list
      yield put(getInvoiceList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleInvoiceError(error.response.data.message));
  }
}

export function* watchDownloadInvoice() {
  yield takeEvery(DOWNLOAD_INVOICE, downloadInvoiceAction);
}

const downloadInvoiceAsync = async (id) => {
  return InvoiceService.downloadInvoice(id);
};

function* downloadInvoiceAction({ payload }) {
  const customToastId = toast.info(<ToastElement type="info" message={"Downloading Invoice"} />, {
    containerId: "custom",
  });
  try {
    const response = yield call(downloadInvoiceAsync, payload.invoiceId);
    if (response && response.data) {
      toast.dismiss(customToastId);
      yield put(downloadInvoiceSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, `${payload.invoiceId}.pdf` || "Invoice.pdf");
      toast.success(<ToastElement type="success" message={"Invoice downloaded successfully."} />, {
        containerId: "default",
      });
    } else {
      toast.dismiss(customToastId);
      toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
      yield put(downloadInvoiceSuccess("error"));
    }
  } catch (error) {
    toast.dismiss(customToastId);
    toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
    yield put(downloadInvoiceError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllInvoice),
    fork(watchGetInvoiceList),
    fork(watchGetUserInvoiceList),
    fork(watchAddInvoice),
    fork(watchGetInvoice),
    fork(watchDownloadInvoice),
    fork(watchDeleteInvoice),
    fork(watchDeleteMultipleInvoice),
  ]);
}
