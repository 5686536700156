import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GET_SCHEDULE_LIST, ADD_SCHEDULE, GET_SCHEDULE, EDIT_SCHEDULE, DELETE_SCHEDULE } from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import ScheduleService from "src/services/schedule-service";
import TableDataService from "src/services/table-data-service";
import {
  getScheduleList,
  getScheduleListSuccess,
  getScheduleListError,
  addScheduleSuccess,
  addScheduleError,
  getScheduleSuccess,
  getScheduleError,
  editScheduleSuccess,
  editScheduleError,
  deleteScheduleSuccess,
  deleteScheduleError,
} from "./action";

export function* watchGetScheduleList() {
  yield takeEvery(GET_SCHEDULE_LIST, getScheduleListAc);
}

const getScheduleListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "schedules",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getScheduleListAc({ payload }) {
  try {
    const response = yield call(getScheduleListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getScheduleListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getScheduleListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getScheduleListError(error.response.data.message));
  }
}

export function* watchAddSchedule() {
  yield takeEvery(ADD_SCHEDULE, addSchedule);
}

const addScheduleAsync = async (data) => {
  return ScheduleService.addSchedule(data);
};

function* addSchedule({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addScheduleAsync, payload.scheduleData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addScheduleSuccess(response.data.success, response.data.message));
      history.push(`/schedule`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addScheduleError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addScheduleError(error.response.data.message));
  }
}

export function* watchGetSchedule() {
  yield takeEvery(GET_SCHEDULE, getSchedule);
}

const getScheduleAsync = async (id) => {
  return ScheduleService.getSchedule(id);
};

function* getSchedule({ payload }) {
  try {
    const response = yield call(getScheduleAsync, payload.scheduleId);
    if (response.data.success) {
      yield put(getScheduleSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getScheduleError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getScheduleError(error.response.data.message));
  }
}

export function* watchEditSchedule() {
  yield takeEvery(EDIT_SCHEDULE, editSchedule);
}

const editScheduleAsync = async (data, id) => {
  return ScheduleService.editSchedule(data, id);
};

function* editSchedule({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(editScheduleAsync, payload.scheduleData, payload.scheduleId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(editScheduleSuccess(response.data.success, response.data.message));
      history.push(`/schedule`);
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(editScheduleError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(editScheduleError(error.response.data.message));
  }
}

export function* watchDeleteSchedule() {
  yield takeEvery(DELETE_SCHEDULE, deleteSchedule);
}

const deleteScheduleAsync = async (id) => {
  return ScheduleService.deleteSchedule(id);
};

function* deleteSchedule({ payload }) {
  try {
    const response = yield call(deleteScheduleAsync, payload.scheduleId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteScheduleSuccess(response.data.success, response.data.message));
      // Fetch updated schedule list
      yield put(getScheduleList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteScheduleError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteScheduleError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetScheduleList),
    fork(watchAddSchedule),
    fork(watchGetSchedule),
    fork(watchEditSchedule),
    fork(watchDeleteSchedule),
  ]);
}
