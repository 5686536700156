import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import Breadcrumb from "src/components/breadcrumb";
import { getUser, setPatron, setShowCost } from "src/reduxs/actions";
import { IconChevronLeft, IconMail, IconPhone, IconPin, IconStar, IconCurrencyDolor } from "src/components/svg";
import { StyledSwitch } from "src/components/form";
import Booking from "./booking";

class ViewUser extends Component {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getUser(this.props.match.params.id);
    }
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.setPatron(values.id, values);
    }
  };

  onSet = (values) => {
    if (!this.props.loading) {
      this.props.setShowCost(values.id, values);
    }
  };

  render() {
    return (
      <>
        <Breadcrumb
          title={"Customer Management"}
          paths={[
            {
              title: "Customer",
              page: `/user`,
            },
            {
              title: "View",
            },
          ]}
        />

        <Mui.Card className="default-card spacing-md pb-5">
          <Mui.Box className="mb-4" width="100%" display="flex" alignItems="center" flexWrap="wrap">
            <Mui.IconButton
              className="border-1 border-color-grey"
              type="button"
              component={Link}
              to={"/user"}
              size="small"
              style={{ padding: 6, marginRight: 15 }}
            >
              <Mui.Box width="1.2rem" className="svg-color-grey svg-size-flexible line-height-null">
                <IconChevronLeft />
              </Mui.Box>
            </Mui.IconButton>

            <Mui.Typography component="h5" variant="h5" className="mr-auto font-weight-medium">
              Personal Information
            </Mui.Typography>
          </Mui.Box>

          <Mui.Box display="flex" flexDirection={Mui.isWidthDown("xs", this.props.width) ? "column" : "row"}>
            <Mui.Avatar
              className={`img-size-xl ${Mui.isWidthDown("xs", this.props.width) ? "mb-5" : "mr-5"}`}
              src={
                this.props.userData?.userPhoto?.[0]?.url
                  ? this.props.userData?.userPhoto?.[0]?.url
                  : "/static/images/avatar/1.jpg"
              }
              alt={this.props.userData?.firstName || ""}
            />

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h1"
                variant="h1"
                className={`mb-3 svg-color-primary font-weight-medium ${
                  Mui.isWidthDown("xs", this.props.width) ? "flex-direction-column" : ""
                }`}
              >
                {this.props.userData?.firstName || ""} {this.props.userData?.lastName || ""}
                <Mui.Typography
                  component="span"
                  variant="body1"
                  className={`status-bg success text-transform-uppercase ${
                    Mui.isWidthDown("xs", this.props.width) ? "mt-2" : "ml-2 align-self-end"
                  }`}
                >
                  {this.props.userData ? (this.props.userData?.isVerified ? "Verified" : "Not verified") : "-"}
                </Mui.Typography>
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="w-100 d-flex mb-2  svg-color-primary text-color-grey font-weight-normal"
              >
                <span className="flex-shrink-0 mr-2">
                  <IconPin />
                </span>
                {this.props.userData?.address || ""}
              </Mui.Typography>

              <Mui.Box display="flex" flexDirection={Mui.isWidthDown("sm", this.props.width) ? "column" : "row"}>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0 mr-2">
                    <IconMail />
                  </span>
                  {this.props.userData?.email || "-"}
                </Mui.Typography>

                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0 mr-2">
                    <IconPhone />
                  </span>
                  +61 {this.props.userData?.phone || "-"}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="w-100 d-flex mb-2  svg-color-primary text-color-grey font-weight-normal"
              >
                <span className="flex-shrink-0 mr-2">
                  <IconStar />
                </span>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: this.props.userData?.id || "",
                    patron: this.props.userData?.patron || 0,
                  }}
                  onSubmit={this.onSubmit}
                >
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Form>
                      <Mui.FormControlLabel
                        classes={{
                          root: "flex-direction-row-reverse justify-content-end ml-0",
                          label: "mr-2",
                        }}
                        control={
                          <StyledSwitch
                            value={values.patron}
                            checked={values.patron === 1}
                            onChange={(e) => {
                              setFieldValue("patron", e.target.checked ? 1 : 0);
                              handleSubmit();
                            }}
                            name={`patron`}
                          />
                        }
                        label={"Special Customer"}
                      />
                    </Form>
                  )}
                </Formik>
              </Mui.Typography>
              <Mui.Typography
                component="h5"
                variant="h5"
                className="w-100 d-flex mb-2  svg-color-primary text-color-grey font-weight-normal"
              >
                <span className="flex-shrink-0 mr-2">
                  <IconCurrencyDolor />
                </span>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: this.props.userData?.id || "",
                    showCost: this.props.userData?.showCost || 0,
                  }}
                  onSubmit={this.onSet}
                >
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Form>
                      <Mui.FormControlLabel
                        classes={{
                          root: "flex-direction-row-reverse justify-content-end ml-0",
                          label: "mr-2",
                        }}
                        control={
                          <StyledSwitch
                            value={values.showCost}
                            checked={values.showCost === 1}
                            onChange={(e) => {
                              setFieldValue("showCost", e.target.checked ? 1 : 0);
                              handleSubmit();
                            }}
                            name={`showCost`}
                          />
                        }
                        label={"Display Booking Cost?"}
                      />
                    </Form>
                  )}
                </Formik>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Card>

        <Mui.Card className="default-card spacing-md mt-5">
          <Booking userId={this.props.match.params.id} />
        </Mui.Card>
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  const { userData, loading, error } = user;
  return { userData, loading, error };
};
export default connect(mapStateToProps, { getUser, setPatron, setShowCost })(Mui.withWidth()(ViewUser));
