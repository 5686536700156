import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as Mui from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const DateFilter = (props) => {
  const [datepicker, setDatepicker] = useState(false);

  const onClear = (e) => {
    e.stopPropagation();
    props.onFilter(null);
  };

  return (
    <Mui.FormControl
      variant="outlined"
      className={`${props.fieldTheme === "dark" ? "form-group-dark" : "default-form-field"}  tbl-filter-field`}
      style={{ maxWidth: "47%" }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={`date-time-picker ${props.fieldTheme === "dark" ? "dark" : ""}`}
          autoOk
          disableToolbar
          variant="inline"
          fullWidth
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          minDate={props.minDate ? new Date(props.minDate) : new Date("1990-01-01")}
          onClick={() => setDatepicker(true)}
          onClose={() => setDatepicker(false)}
          open={datepicker}
          label=""
          value={props.filterValue}
          onChange={props.onFilter}
          keyboardIcon={
            props.filterValue ? (
              <div className={`${props.fieldTheme === "dark" ? "svg-color-white" : ""}`} onClick={onClear}>
                <ClearIcon />
              </div>
            ) : (
              <></>
            )
          }
          KeyboardButtonProps={{ size: "small", className: "opacity-5" }}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <Mui.InputAdornment
                className={`${props.fieldTheme === "dark" ? "text-color-white" : ""}`}
                position="start"
              >
                {props.label}:
              </Mui.InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </Mui.FormControl>
  );
};

export default Mui.withWidth()(DateFilter);
