import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import usePlacesAutocomplete from "use-places-autocomplete";
import { useField } from "formik";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const InputGoogleField = ({ label, placeholder, shrinkLabel = true, ...props }) => {
  const [field, meta, handler] = useField(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperWidth, setPopperWidth] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!popperWidth) {
      setPopperWidth(event.currentTarget.getBoundingClientRect().width);
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: {
        country: "au",
      },
    },
    debounce: 200,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    let val = e.target.value;
    setValue(val);
    handleClick(e);
  };

  const clickAwayHandler = () => {
    clearSuggestions();
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      handler.setValue(description);
      clearSuggestions();
    };

  useEffect(() => {
    field?.value ? setValue(field.value, false) : setValue("", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const renderSuggestions = () =>
    data
      ?.filter((suggestion) => suggestion.description.includes("VIC")) // Filters VIC State
      ?.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <Mui.MenuItem key={place_id} onClick={handleSelect(suggestion)}>
            <Mui.Box display="flex" alignItems="flex-start" component="div">
              <Mui.Box component="div" className="flex-shrink-0 mr-1">
                <LocationOnIcon />
              </Mui.Box>
              <Mui.Box component="div">
                <Mui.Typography
                  variant="body1"
                  component="span"
                  className="d-block font-weight-medium"
                  style={{ whiteSpace: "normal" }}
                >
                  {main_text}
                </Mui.Typography>
                <Mui.Typography
                  variant="body2"
                  color="textSecondary"
                  component="small"
                  className="d-block font-weight-medium"
                  style={{ whiteSpace: "normal" }}
                >
                  {secondary_text}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          </Mui.MenuItem>
        );
      });

  return (
    <>
      <Mui.TextField
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder || null}
        label={label}
        variant={"outlined"}
        InputLabelProps={shrinkLabel ? { shrink: true } : {}}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
        fullWidth
        {...props}
      />
      {status === "OK" && (
        <Mui.ClickAwayListener onClickAway={clickAwayHandler}>
          <Mui.Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            className="popper-dropdown"
            style={{ width: popperWidth || "auto" }}
          >
            {renderSuggestions()}
          </Mui.Popper>
        </Mui.ClickAwayListener>
      )}
    </>
  );
};

export default InputGoogleField;
