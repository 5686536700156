export const GET_ALL_INVOICE = "GET_ALL_INVOICE";
export const GET_ALL_INVOICE_SUCCESS = "GET_ALL_INVOICE_SUCCESS";
export const GET_ALL_INVOICE_ERROR = "GET_ALL_INVOICE_ERROR";
export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_ERROR = "GET_INVOICE_LIST_ERROR";
export const GET_USER_INVOICE_LIST = "GET_USER_INVOICE_LIST";
export const GET_USER_INVOICE_LIST_SUCCESS = "GET_USER_INVOICE_LIST_SUCCESS";
export const GET_USER_INVOICE_LIST_ERROR = "GET_USER_INVOICE_LIST_ERROR";
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_ERROR = "ADD_INVOICE_ERROR";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";
export const DELETE_MULTIPLE_INVOICE = "DELETE_MULTIPLE_INVOICE";
export const DELETE_MULTIPLE_INVOICE_SUCCESS = "DELETE_MULTIPLE_INVOICE_SUCCESS";
export const DELETE_MULTIPLE_INVOICE_ERROR = "DELETE_MULTIPLE_INVOICE_ERROR";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const DOWNLOAD_INVOICE_ERROR = "DOWNLOAD_INVOICE_ERROR";
export const RESET_INVOICE = "RESET_INVOICE";

export const getAllInvoice = (param) => ({
  type: GET_ALL_INVOICE,
  payload: { param },
});

export const getAllInvoiceSuccess = (invoices) => ({
  type: GET_ALL_INVOICE_SUCCESS,
  payload: invoices,
});

export const getAllInvoiceError = (error) => ({
  type: GET_ALL_INVOICE_ERROR,
  payload: error,
});

export const getInvoiceList = (dbParam) => ({
  type: GET_INVOICE_LIST,
  payload: { dbParam },
});

export const getInvoiceListSuccess = (invoiceList) => ({
  type: GET_INVOICE_LIST_SUCCESS,
  payload: invoiceList,
});

export const getInvoiceListError = (error) => ({
  type: GET_INVOICE_LIST_ERROR,
  payload: error,
});

export const getUserInvoiceList = (dbParam) => ({
  type: GET_USER_INVOICE_LIST,
  payload: { dbParam },
});

export const getUserInvoiceListSuccess = (userInvoiceList) => ({
  type: GET_USER_INVOICE_LIST_SUCCESS,
  payload: userInvoiceList,
});

export const getUserInvoiceListError = (error) => ({
  type: GET_USER_INVOICE_LIST_ERROR,
  payload: error,
});

export const addInvoice = (invoiceData) => ({
  type: ADD_INVOICE,
  payload: { invoiceData },
});

export const addInvoiceSuccess = (success, message) => ({
  type: ADD_INVOICE_SUCCESS,
  payload: { success, message },
});

export const addInvoiceError = (error) => ({
  type: ADD_INVOICE_ERROR,
  payload: error,
});

export const getInvoice = (invoiceId) => ({
  type: GET_INVOICE,
  payload: { invoiceId },
});

export const getInvoiceSuccess = (invoiceData) => ({
  type: GET_INVOICE_SUCCESS,
  payload: invoiceData,
});

export const getInvoiceError = (error) => ({
  type: GET_INVOICE_ERROR,
  payload: error,
});

export const deleteInvoice = (invoiceId) => ({
  type: DELETE_INVOICE,
  payload: { invoiceId },
});

export const deleteInvoiceSuccess = (success, message) => ({
  type: DELETE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const deleteInvoiceError = (error) => ({
  type: DELETE_INVOICE_ERROR,
  payload: error,
});

export const deleteMultipleInvoice = (invoiceIds) => ({
  type: DELETE_MULTIPLE_INVOICE,
  payload: { invoiceIds },
});

export const deleteMultipleInvoiceSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_INVOICE_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleInvoiceError = (error) => ({
  type: DELETE_MULTIPLE_INVOICE_ERROR,
  payload: error,
});

export const downloadInvoice = (invoiceId) => ({
  type: DOWNLOAD_INVOICE,
  payload: { invoiceId },
});

export const downloadInvoiceSuccess = (success, message) => ({
  type: DOWNLOAD_INVOICE_SUCCESS,
  payload: { success, message },
});

export const downloadInvoiceError = (error) => ({
  type: DOWNLOAD_INVOICE_ERROR,
  payload: error,
});

export const resetInvoice = () => ({
  type: RESET_INVOICE,
  payload: {},
});
