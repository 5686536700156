import React from "react";

const IconUrl = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24">
      <path
        d="M16.8,23.7H3.6c-1.9,0-3.4-1.5-3.4-3.4V7.2c0-1.9,1.5-3.4,3.4-3.4h7.2c0.6,0,1,0.4,1,1s-0.4,1-1,1H3.6
      c-0.8,0-1.4,0.6-1.4,1.4v13.1c0,0.8,0.6,1.4,1.4,1.4h13.1c0.8,0,1.4-0.6,1.4-1.4v-7.2c0-0.6,0.4-1,1-1s1,0.4,1,1v7.2
      C20.2,22.2,18.6,23.7,16.8,23.7z M23.7,8.4V1.3c0-0.6-0.4-1-1-1h-7.2c-0.6,0-1,0.4-1,1s0.4,1,1,1h6.2v6.2c0,0.6,0.4,1,1,1
      S23.7,9,23.7,8.4z M10.3,15.1L23.5,2c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L8.9,13.7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3
      S10.1,15.3,10.3,15.1z"
      />
    </svg>
  );
};

export default IconUrl;
