export const GET_ALL_ROUTE = "GET_ALL_ROUTE";
export const GET_ALL_ROUTE_SUCCESS = "GET_ALL_ROUTE_SUCCESS";
export const GET_ALL_ROUTE_ERROR = "GET_ALL_ROUTE_ERROR";
export const GET_ROUTE_LIST = "GET_ROUTE_LIST";
export const GET_ROUTE_LIST_SUCCESS = "GET_ROUTE_LIST_SUCCESS";
export const GET_ROUTE_LIST_ERROR = "GET_ROUTE_LIST_ERROR";
export const ADD_ROUTE = "ADD_ROUTE";
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS";
export const ADD_ROUTE_ERROR = "ADD_ROUTE_ERROR";
export const GET_ROUTE = "GET_ROUTE";
export const GET_ROUTE_SUCCESS = "GET_ROUTE_SUCCESS";
export const GET_ROUTE_ERROR = "GET_ROUTE_ERROR";
export const EDIT_ROUTE = "EDIT_ROUTE";
export const EDIT_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const EDIT_ROUTE_ERROR = "EDIT_ROUTE_ERROR";
export const DELETE_ROUTE = "DELETE_ROUTE";
export const DELETE_ROUTE_SUCCESS = "DELETE_ROUTE_SUCCESS";
export const DELETE_ROUTE_ERROR = "DELETE_ROUTE_ERROR";
export const DELETE_MULTIPLE_ROUTE = "DELETE_MULTIPLE_ROUTE";
export const DELETE_MULTIPLE_ROUTE_SUCCESS = "DELETE_MULTIPLE_ROUTE_SUCCESS";
export const DELETE_MULTIPLE_ROUTE_ERROR = "DELETE_MULTIPLE_ROUTE_ERROR";
export const RESET_ROUTE = "RESET_ROUTE";

export const getAllRoute = () => ({
  type: GET_ALL_ROUTE,
  payload: {},
});

export const getAllRouteSuccess = (routes) => ({
  type: GET_ALL_ROUTE_SUCCESS,
  payload: routes,
});

export const getAllRouteError = (error) => ({
  type: GET_ALL_ROUTE_ERROR,
  payload: error,
});

export const getRouteList = (dbParam) => ({
  type: GET_ROUTE_LIST,
  payload: { dbParam },
});

export const getRouteListSuccess = (routeList) => ({
  type: GET_ROUTE_LIST_SUCCESS,
  payload: routeList,
});

export const getRouteListError = (error) => ({
  type: GET_ROUTE_LIST_ERROR,
  payload: error,
});

export const addRoute = (routeData, history) => ({
  type: ADD_ROUTE,
  payload: { routeData, history },
});

export const addRouteSuccess = (success, message) => ({
  type: ADD_ROUTE_SUCCESS,
  payload: { success, message },
});

export const addRouteError = (error) => ({
  type: ADD_ROUTE_ERROR,
  payload: error,
});

export const getRoute = (routeId) => ({
  type: GET_ROUTE,
  payload: { routeId },
});

export const getRouteSuccess = (routeData) => ({
  type: GET_ROUTE_SUCCESS,
  payload: routeData,
});

export const getRouteError = (error) => ({
  type: GET_ROUTE_ERROR,
  payload: error,
});

export const editRoute = (routeId, routeData, history) => ({
  type: EDIT_ROUTE,
  payload: { routeId, routeData, history },
});

export const editRouteSuccess = (success, message) => ({
  type: EDIT_ROUTE_SUCCESS,
  payload: { success, message },
});

export const editRouteError = (error) => ({
  type: EDIT_ROUTE_ERROR,
  payload: error,
});

export const deleteRoute = (routeId) => ({
  type: DELETE_ROUTE,
  payload: { routeId },
});

export const deleteRouteSuccess = (success, message) => ({
  type: DELETE_ROUTE_SUCCESS,
  payload: { success, message },
});

export const deleteRouteError = (error) => ({
  type: DELETE_ROUTE_ERROR,
  payload: error,
});

export const deleteMultipleRoute = (routeIds) => ({
  type: DELETE_MULTIPLE_ROUTE,
  payload: { routeIds },
});

export const deleteMultipleRouteSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_ROUTE_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleRouteError = (error) => ({
  type: DELETE_MULTIPLE_ROUTE_ERROR,
  payload: error,
});

export const resetRoute = () => ({
  type: RESET_ROUTE,
  payload: {},
});
