export const GET_UPCOMING_BOOKING_LIST = "GET_UPCOMING_BOOKING_LIST";
export const GET_UPCOMING_BOOKING_LIST_SUCCESS = "GET_UPCOMING_BOOKING_LIST_SUCCESS";
export const GET_UPCOMING_BOOKING_LIST_ERROR = "GET_UPCOMING_BOOKING_LIST_ERROR";
export const GET_COMPLETED_BOOKING_LIST = "GET_COMPLETED_BOOKING_LIST";
export const GET_COMPLETED_BOOKING_LIST_SUCCESS = "GET_COMPLETED_BOOKING_LIST_SUCCESS";
export const GET_COMPLETED_BOOKING_LIST_ERROR = "GET_COMPLETED_BOOKING_LIST_ERROR";
export const GET_ALL_BOOKING = "GET_ALL_BOOKING";
export const GET_ALL_BOOKING_SUCCESS = "GET_ALL_BOOKING_SUCCESS";
export const GET_ALL_BOOKING_ERROR = "GET_ALL_BOOKING_ERROR";
export const GET_BOOKING_LIST = "GET_BOOKING_LIST";
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS";
export const GET_BOOKING_LIST_ERROR = "GET_BOOKING_LIST_ERROR";
export const GET_EVENT_LIST = "GET_EVENT_LIST";
export const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export const GET_EVENT_LIST_ERROR = "GET_EVENT_LIST_ERROR";
export const ADD_BOOKING = "ADD_BOOKING";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_ERROR = "ADD_BOOKING_ERROR";
export const GET_BOOKING = "GET_BOOKING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";
export const EDIT_BOOKING_ERROR = "EDIT_BOOKING_ERROR";
export const MODIFY_BOOKING = "MODIFY_BOOKING";
export const MODIFY_BOOKING_SUCCESS = "MODIFY_BOOKING_SUCCESS";
export const MODIFY_BOOKING_ERROR = "MODIFY_BOOKING_ERROR";
export const CHANGE_BOOKING_STATUS = "CHANGE_BOOKING_STATUS";
export const CHANGE_BOOKING_STATUS_SUCCESS = "CHANGE_BOOKING_STATUS_SUCCESS";
export const CHANGE_BOOKING_STATUS_ERROR = "CHANGE_BOOKING_STATUS_ERROR";
export const ASSIGN_BOOKING_DRIVER = "ASSIGN_BOOKING_DRIVER";
export const ASSIGN_BOOKING_DRIVER_SUCCESS = "ASSIGN_BOOKING_DRIVER_SUCCESS";
export const ASSIGN_BOOKING_DRIVER_ERROR = "ASSIGN_BOOKING_DRIVER_ERROR";
export const CHARGE_CARD = "CHARGE_CARD";
export const CHARGE_CARD_SUCCESS = "CHARGE_CARD_SUCCESS";
export const CHARGE_CARD_ERROR = "CHARGE_CARD_ERROR";
export const GET_BOOKING_COST = "GET_BOOKING_COST";
export const GET_BOOKING_COST_SUCCESS = "GET_BOOKING_COST_SUCCESS";
export const GET_BOOKING_COST_ERROR = "GET_BOOKING_COST_ERROR";
export const MODIFY_BOOKING_COST = "MODIFY_BOOKING_COST";
export const MODIFY_BOOKING_COST_SUCCESS = "MODIFY_BOOKING_COST_SUCCESS";
export const MODIFY_BOOKING_COST_ERROR = "MODIFY_BOOKING_COST_ERROR";
export const SEND_BOOKING_EMAIL = "SEND_BOOKING_EMAIL";
export const SEND_BOOKING_EMAIL_SUCCESS = "SEND_BOOKING_EMAIL_SUCCESS";
export const SEND_BOOKING_EMAIL_ERROR = "SEND_BOOKING_EMAIL_ERROR";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const DELETE_MULTIPLE_BOOKING = "DELETE_MULTIPLE_BOOKING";
export const DELETE_MULTIPLE_BOOKING_SUCCESS = "DELETE_MULTIPLE_BOOKING_SUCCESS";
export const DELETE_MULTIPLE_BOOKING_ERROR = "DELETE_MULTIPLE_BOOKING_ERROR";
export const RESET_BOOKING_COST = "RESET_BOOKING_COST";
export const RESET_BOOKING = "RESET_BOOKING";

export const getUpcomingBookingList = (upcomingParam) => ({
  type: GET_UPCOMING_BOOKING_LIST,
  payload: { upcomingParam },
});

export const getUpcomingBookingListSuccess = (upcomingBookingList) => ({
  type: GET_UPCOMING_BOOKING_LIST_SUCCESS,
  payload: upcomingBookingList,
});

export const getUpcomingBookingListError = (error) => ({
  type: GET_UPCOMING_BOOKING_LIST_ERROR,
  payload: error,
});

export const getCompletedBookingList = (completedParam) => ({
  type: GET_COMPLETED_BOOKING_LIST,
  payload: { completedParam },
});

export const getCompletedBookingListSuccess = (completedBookingList) => ({
  type: GET_COMPLETED_BOOKING_LIST_SUCCESS,
  payload: completedBookingList,
});

export const getCompletedBookingListError = (error) => ({
  type: GET_COMPLETED_BOOKING_LIST_ERROR,
  payload: error,
});

export const getAllBooking = (param) => ({
  type: GET_ALL_BOOKING,
  payload: { param },
});

export const getAllBookingSuccess = (bookings) => ({
  type: GET_ALL_BOOKING_SUCCESS,
  payload: bookings,
});

export const getAllBookingError = (error) => ({
  type: GET_ALL_BOOKING_ERROR,
  payload: error,
});

export const getBookingList = (dbParam) => ({
  type: GET_BOOKING_LIST,
  payload: { dbParam },
});

export const getBookingListSuccess = (bookingList) => ({
  type: GET_BOOKING_LIST_SUCCESS,
  payload: bookingList,
});

export const getBookingListError = (error) => ({
  type: GET_BOOKING_LIST_ERROR,
  payload: error,
});

export const getEventList = (eventParam) => ({
  type: GET_EVENT_LIST,
  payload: { eventParam },
});

export const getEventListSuccess = (eventList) => ({
  type: GET_EVENT_LIST_SUCCESS,
  payload: eventList,
});

export const getEventListError = (error) => ({
  type: GET_EVENT_LIST_ERROR,
  payload: error,
});

export const addBooking = (bookingData) => ({
  type: ADD_BOOKING,
  payload: { bookingData },
});

export const addBookingSuccess = (success, message, responseBookingId) => ({
  type: ADD_BOOKING_SUCCESS,
  payload: { success, message, responseBookingId },
});

export const addBookingError = (error) => ({
  type: ADD_BOOKING_ERROR,
  payload: error,
});

export const getBooking = (bookingId) => ({
  type: GET_BOOKING,
  payload: { bookingId },
});

export const getBookingSuccess = (bookingData) => ({
  type: GET_BOOKING_SUCCESS,
  payload: bookingData,
});

export const getBookingError = (error) => ({
  type: GET_BOOKING_ERROR,
  payload: error,
});

export const editBooking = (bookingId, bookingData) => ({
  type: EDIT_BOOKING,
  payload: { bookingId, bookingData },
});

export const editBookingSuccess = (success, message, responseBookingId) => ({
  type: EDIT_BOOKING_SUCCESS,
  payload: { success, message, responseBookingId },
});

export const editBookingError = (error) => ({
  type: EDIT_BOOKING_ERROR,
  payload: error,
});

export const modifyBooking = (bookingId, bookingData) => ({
  type: MODIFY_BOOKING,
  payload: { bookingId, bookingData },
});

export const modifyBookingSuccess = (success, message) => ({
  type: MODIFY_BOOKING_SUCCESS,
  payload: { success, message },
});

export const modifyBookingError = (error) => ({
  type: MODIFY_BOOKING_ERROR,
  payload: error,
});

export const changeBookingStatus = (bookingId, statusData) => ({
  type: CHANGE_BOOKING_STATUS,
  payload: { bookingId, statusData },
});

export const changeBookingStatusSuccess = (success, message) => ({
  type: CHANGE_BOOKING_STATUS_SUCCESS,
  payload: { success, message },
});

export const changeBookingStatusError = (error) => ({
  type: CHANGE_BOOKING_STATUS_ERROR,
  payload: error,
});

export const assignBookingDriver = (bookingId, driverData) => ({
  type: ASSIGN_BOOKING_DRIVER,
  payload: { bookingId, driverData },
});

export const assignBookingDriverSuccess = (success, message) => ({
  type: ASSIGN_BOOKING_DRIVER_SUCCESS,
  payload: { success, message },
});

export const assignBookingDriverError = (error) => ({
  type: ASSIGN_BOOKING_DRIVER_ERROR,
  payload: error,
});

export const chargeCard = (bookingId) => ({
  type: CHARGE_CARD,
  payload: { bookingId },
});

export const chargeCardSuccess = (success, message) => ({
  type: CHARGE_CARD_SUCCESS,
  payload: { success, message },
});

export const chargeCardError = (error) => ({
  type: CHARGE_CARD_ERROR,
  payload: error,
});

export const getBookingCost = (bookingData) => ({
  type: GET_BOOKING_COST,
  payload: { bookingData },
});

export const getBookingCostSuccess = (bookingCost) => ({
  type: GET_BOOKING_COST_SUCCESS,
  payload: bookingCost,
});

export const getBookingCostError = (error) => ({
  type: GET_BOOKING_COST_ERROR,
  payload: error,
});

export const modifyBookingCost = (bookingId, costParam) => ({
  type: MODIFY_BOOKING_COST,
  payload: { bookingId, costParam },
});

export const modifyBookingCostSuccess = (success, message) => ({
  type: MODIFY_BOOKING_COST_SUCCESS,
  payload: { success, message },
});

export const modifyBookingCostError = (error) => ({
  type: MODIFY_BOOKING_COST_ERROR,
  payload: error,
});

export const sendBookingEmail = (bookingId, emailParam) => ({
  type: SEND_BOOKING_EMAIL,
  payload: { bookingId, emailParam },
});

export const sendBookingEmailSuccess = (success1, message) => ({
  type: SEND_BOOKING_EMAIL_SUCCESS,
  payload: { success1, message },
});

export const sendBookingEmailError = (error) => ({
  type: SEND_BOOKING_EMAIL_ERROR,
  payload: error,
});

export const deleteBooking = (bookingId) => ({
  type: DELETE_BOOKING,
  payload: { bookingId },
});

export const deleteBookingSuccess = (success, message) => ({
  type: DELETE_BOOKING_SUCCESS,
  payload: { success, message },
});

export const deleteBookingError = (error) => ({
  type: DELETE_BOOKING_ERROR,
  payload: error,
});

export const deleteMultipleBooking = (bookingIds) => ({
  type: DELETE_MULTIPLE_BOOKING,
  payload: { bookingIds },
});

export const deleteMultipleBookingSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_BOOKING_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleBookingError = (error) => ({
  type: DELETE_MULTIPLE_BOOKING_ERROR,
  payload: error,
});

export const resetBookingCost = () => ({
  type: RESET_BOOKING_COST,
  payload: {},
});

export const resetBooking = () => ({
  type: RESET_BOOKING,
  payload: {},
});
