import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import {
  GET_ALL_CONSOLIDATE_INVOICE,
  GET_CONSOLIDATE_INVOICE_LIST,
  ADD_CONSOLIDATE_INVOICE,
  GET_CONSOLIDATE_INVOICE,
  DELETE_CONSOLIDATE_INVOICE,
  DELETE_MULTIPLE_CONSOLIDATE_INVOICE,
  DOWNLOAD_CONSOLIDATE_INVOICE,
  MERGE_INVOICE,
} from "src/reduxs/actions";
import ToastElement from "src/components/toast";
import { parseMessage } from "src/helpers/util";
import ConsolidateInvoiceService from "src/services/consolidate-invoice-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllConsolidateInvoiceSuccess,
  getAllConsolidateInvoiceError,
  getConsolidateInvoiceList,
  getConsolidateInvoiceListSuccess,
  getConsolidateInvoiceListError,
  addConsolidateInvoiceSuccess,
  addConsolidateInvoiceError,
  getConsolidateInvoiceSuccess,
  getConsolidateInvoiceError,
  deleteConsolidateInvoiceSuccess,
  deleteConsolidateInvoiceError,
  deleteMultipleConsolidateInvoiceSuccess,
  deleteMultipleConsolidateInvoiceError,
  downloadConsolidateInvoiceSuccess,
  downloadConsolidateInvoiceError,
  mergeInvoiceSuccess,
  mergeInvoiceError,
} from "./action";

export function* watchGetAllConsolidateInvoice() {
  yield takeEvery(GET_ALL_CONSOLIDATE_INVOICE, getAllConsolidateInvoice);
}

const getAllConsolidateInvoiceAsync = async () => {
  return ConsolidateInvoiceService.getAllConsolidateInvoice();
};

function* getAllConsolidateInvoice({ payload }) {
  try {
    const response = yield call(getAllConsolidateInvoiceAsync);
    if (response.data.success) {
      yield put(getAllConsolidateInvoiceSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getAllConsolidateInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getAllConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchGetConsolidateInvoiceList() {
  yield takeEvery(GET_CONSOLIDATE_INVOICE_LIST, getConsolidateInvoiceListAc);
}

const getConsolidateInvoiceListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "consolidate/invoices",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.fromDate || "",
    dbParam?.toDate || ""
  );
};

function* getConsolidateInvoiceListAc({ payload }) {
  try {
    const response = yield call(getConsolidateInvoiceListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getConsolidateInvoiceListSuccess(response.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getConsolidateInvoiceListError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getConsolidateInvoiceListError(error.response.data.message));
  }
}

export function* watchAddConsolidateInvoice() {
  yield takeEvery(ADD_CONSOLIDATE_INVOICE, addConsolidateInvoice);
}

const addConsolidateInvoiceAsync = async (data) => {
  return ConsolidateInvoiceService.addConsolidateInvoice(data);
};

function* addConsolidateInvoice({ payload }) {
  try {
    const response = yield call(addConsolidateInvoiceAsync, payload.consolidateInvoiceData);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(addConsolidateInvoiceSuccess(response.data.success, response.data.message));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(addConsolidateInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(error.response.data.error ? error.response.data.error : error.response.data.message)}
      />,
      { containerId: "default" }
    );
    yield put(addConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchGetConsolidateInvoice() {
  yield takeEvery(GET_CONSOLIDATE_INVOICE, getConsolidateInvoice);
}

const getConsolidateInvoiceAsync = async (id) => {
  return ConsolidateInvoiceService.getConsolidateInvoice(id);
};

function* getConsolidateInvoice({ payload }) {
  try {
    const response = yield call(getConsolidateInvoiceAsync, payload.consolidateInvoiceId);
    if (response.data.success) {
      yield put(getConsolidateInvoiceSuccess(response.data.data));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(getConsolidateInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(getConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchDeleteConsolidateInvoice() {
  yield takeEvery(DELETE_CONSOLIDATE_INVOICE, deleteConsolidateInvoice);
}

const deleteConsolidateInvoiceAsync = async (id) => {
  return ConsolidateInvoiceService.deleteConsolidateInvoice(id);
};

function* deleteConsolidateInvoice({ payload }) {
  try {
    const response = yield call(deleteConsolidateInvoiceAsync, payload.consolidateInvoiceId);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteConsolidateInvoiceSuccess(response.data.success, response.data.message));
      // Fetch updated consolidateInvoice list
      yield put(getConsolidateInvoiceList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteConsolidateInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchDeleteMultipleConsolidateInvoice() {
  yield takeEvery(DELETE_MULTIPLE_CONSOLIDATE_INVOICE, deleteMultipleConsolidateInvoice);
}

const deleteMultipleConsolidateInvoiceAsync = async (ids) => {
  return ConsolidateInvoiceService.deleteMultipleConsolidateInvoice(ids);
};

function* deleteMultipleConsolidateInvoice({ payload }) {
  try {
    const response = yield call(deleteMultipleConsolidateInvoiceAsync, payload.consolidateInvoiceIds);
    if (response.data.success) {
      toast.success(<ToastElement type="success" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleConsolidateInvoiceSuccess(response.data.success, response.data.message));
      // Fetch updated consolidateInvoice list
      yield put(getConsolidateInvoiceList({}));
    } else {
      toast.error(<ToastElement type="error" message={response.data.message} />, { containerId: "default" });
      yield put(deleteMultipleConsolidateInvoiceError(response.data.message));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={error.response.data.message} />, { containerId: "default" });
    yield put(deleteMultipleConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchDownloadConsolidateInvoice() {
  yield takeEvery(DOWNLOAD_CONSOLIDATE_INVOICE, downloadConsolidateInvoiceAction);
}

const downloadConsolidateInvoiceAsync = async (id) => {
  return ConsolidateInvoiceService.downloadConsolidateInvoice(id);
};

function* downloadConsolidateInvoiceAction({ payload }) {
  const customToastId = toast.info(<ToastElement type="info" message={"Downloading Invoice"} />, {
    containerId: "custom",
  });
  try {
    const response = yield call(downloadConsolidateInvoiceAsync, payload.consolidateInvoiceId);
    if (response && response.data) {
      toast.dismiss(customToastId);
      yield put(downloadConsolidateInvoiceSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, `${payload.consolidateInvoiceId}.pdf` || "Invoice.pdf");
      toast.success(<ToastElement type="success" message={"Invoice downloaded successfully."} />, {
        containerId: "default",
      });
    } else {
      toast.dismiss(customToastId);
      toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
      yield put(downloadConsolidateInvoiceSuccess("error"));
    }
  } catch (error) {
    toast.dismiss(customToastId);
    toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
    yield put(downloadConsolidateInvoiceError(error.response.data.message));
  }
}

export function* watchMergeInvoice() {
  yield takeEvery(MERGE_INVOICE, mergeInvoiceAction);
}

const mergeInvoiceAsync = async (data) => {
  return ConsolidateInvoiceService.mergeInvoice(data);
};

function* mergeInvoiceAction({ payload }) {
  try {
    const response = yield call(mergeInvoiceAsync, payload.mergeInvoiceData);
    if (response && response.data) {
      yield put(mergeInvoiceSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, "Invoice.pdf");
      toast.success(<ToastElement type="success" message={"Invoice downloaded successfully."} />, {
        containerId: "default",
      });
    } else {
      toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
      yield put(mergeInvoiceError("error"));
    }
  } catch (error) {
    toast.error(<ToastElement type="error" message={"Error. Please try again!"} />, { containerId: "default" });
    yield put(mergeInvoiceError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllConsolidateInvoice),
    fork(watchGetConsolidateInvoiceList),
    fork(watchAddConsolidateInvoice),
    fork(watchGetConsolidateInvoice),
    fork(watchDownloadConsolidateInvoice),
    fork(watchMergeInvoice),
    fork(watchDeleteConsolidateInvoice),
    fork(watchDeleteMultipleConsolidateInvoice),
  ]);
}
