import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ALL_CAR,
  GET_CAR_LIST,
  ADD_CAR,
  GET_CAR,
  EDIT_CAR,
  DELETE_CAR,
  DELETE_MULTIPLE_CAR,
} from "src/reduxs/actions";
import { parseMessage } from "src/helpers/util";
import ToastElement from "src/components/toast";
import CarService from "src/services/car-service";
import TableDataService from "src/services/table-data-service";
import {
  getAllCarSuccess,
  getAllCarError,
  getCarList,
  getCarListSuccess,
  getCarListError,
  addCarSuccess,
  addCarError,
  getCarSuccess,
  getCarError,
  editCarSuccess,
  editCarError,
  deleteCarSuccess,
  deleteCarError,
  deleteMultipleCarSuccess,
  deleteMultipleCarError,
} from "./action";

export function* watchGetAllCar() {
  yield takeEvery(GET_ALL_CAR, getAllCar);
}

const getAllCarAsync = async () => {
  return CarService.getAllCar();
};

function* getAllCar() {
  try {
    const response = yield call(getAllCarAsync);
    if (response.data.success) {
      yield put(getAllCarSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getAllCarError(error.response.data.message));
  }
}

export function* watchGetCarList() {
  yield takeEvery(GET_CAR_LIST, getCarListAc);
}

const getCarListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "vehicle_types",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getCarListAc({ payload }) {
  try {
    const response = yield call(getCarListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getCarListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCarListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getCarListError(error.response.data.message));
  }
}

export function* watchAddCar() {
  yield takeEvery(ADD_CAR, addCar);
}

const addCarAsync = async (data) => {
  return CarService.addCar(data);
};

function* addCar({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addCarAsync, payload.carData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addCarSuccess(response.data.success, response.data.message));
      history.push(`/car`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )}
      />,
      { containerId: "default" }
    );
    yield put(addCarError(error.response.data.message));
  }
}

export function* watchGetCar() {
  yield takeEvery(GET_CAR, getCar);
}

const getCarAsync = async (id) => {
  return CarService.getCar(id);
};

function* getCar({ payload }) {
  try {
    const response = yield call(getCarAsync, payload.carId);
    if (response.data.success) {
      yield put(getCarSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(getCarError(error.response.data.message));
  }
}

export function* watchEditCar() {
  yield takeEvery(EDIT_CAR, editCar);
}

const editCarAsync = async (data, id) => {
  return CarService.editCar(data, id);
};

function* editCar({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(editCarAsync, payload.carData, payload.carId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editCarSuccess(response.data.success, response.data.message));
      history.push(`/car`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )}
      />,
      { containerId: "default" }
    );
    yield put(editCarError(error.response.data.message));
  }
}

export function* watchDeleteCar() {
  yield takeEvery(DELETE_CAR, deleteCar);
}

const deleteCarAsync = async (id) => {
  return CarService.deleteCar(id);
};

function* deleteCar({ payload }) {
  try {
    const response = yield call(deleteCarAsync, payload.carId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteCarSuccess(response.data.success, response.data.message));
      // Fetch updated car list
      yield put(getCarList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(deleteCarError(error.response.data.message));
  }
}

export function* watchDeleteMultipleCar() {
  yield takeEvery(DELETE_MULTIPLE_CAR, deleteMultipleCar);
}

const deleteMultipleCarAsync = async (ids) => {
  return CarService.deleteMultipleCar(ids);
};

function* deleteMultipleCar({ payload }) {
  try {
    const response = yield call(deleteMultipleCarAsync, payload.carIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleCarSuccess(response.data.success, response.data.message)
      );
      // Fetch updated car list
      yield put(getCarList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleCarError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default" }
    );
    yield put(deleteMultipleCarError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllCar),
    fork(watchGetCarList),
    fork(watchAddCar),
    fork(watchGetCar),
    fork(watchEditCar),
    fork(watchDeleteCar),
    fork(watchDeleteMultipleCar),
  ]);
}
