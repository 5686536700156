import {
  GET_ALL_ADDITIONAL_CHARGE,
  GET_ALL_ADDITIONAL_CHARGE_SUCCESS,
  GET_ALL_ADDITIONAL_CHARGE_ERROR,
  GET_ADDITIONAL_CHARGE_LIST,
  GET_ADDITIONAL_CHARGE_LIST_SUCCESS,
  GET_ADDITIONAL_CHARGE_LIST_ERROR,
  ADD_ADDITIONAL_CHARGE,
  ADD_ADDITIONAL_CHARGE_SUCCESS,
  ADD_ADDITIONAL_CHARGE_ERROR,
  GET_ADDITIONAL_CHARGE,
  GET_ADDITIONAL_CHARGE_SUCCESS,
  GET_ADDITIONAL_CHARGE_ERROR,
  EDIT_ADDITIONAL_CHARGE,
  EDIT_ADDITIONAL_CHARGE_SUCCESS,
  EDIT_ADDITIONAL_CHARGE_ERROR,
  DELETE_ADDITIONAL_CHARGE,
  DELETE_ADDITIONAL_CHARGE_SUCCESS,
  DELETE_ADDITIONAL_CHARGE_ERROR,
  DELETE_MULTIPLE_ADDITIONAL_CHARGE,
  DELETE_MULTIPLE_ADDITIONAL_CHARGE_SUCCESS,
  DELETE_MULTIPLE_ADDITIONAL_CHARGE_ERROR,
  RESET_ADDITIONAL_CHARGE,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  additionalCharges: null,
  additionalChargeList: null,
  additionalChargeData: null,
  additionalChargeId: null,
  additionalChargeIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const additionalChargeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ADDITIONAL_CHARGE:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        additionalCharges: action.payload,
        error: null,
      };
    case GET_ALL_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        additionalCharges: null,
        error: action.payload,
      };
    case GET_ADDITIONAL_CHARGE_LIST:
      return {
        ...state,
        loading: true,
        additionalChargeData: null,
        additionalChargeId: null,
        additionalChargeIds: null,
        error: null,
      };
    case GET_ADDITIONAL_CHARGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        additionalChargeList: action.payload,
        error: null,
      };
    case GET_ADDITIONAL_CHARGE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        additionalChargeList: null,
        error: action.payload,
      };
    case ADD_ADDITIONAL_CHARGE:
      return { ...state, loading: true, error: null };
    case ADD_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_ADDITIONAL_CHARGE:
      return { ...state, error: null };
    case GET_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        additionalChargeData: action.payload,
        error: null,
      };
    case GET_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        additionalChargeData: null,
        error: action.payload,
      };
    case EDIT_ADDITIONAL_CHARGE:
      return { ...state, loading: true, error: null };
    case EDIT_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_ADDITIONAL_CHARGE:
      return { ...state, loading1: true, error: null };
    case DELETE_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_ADDITIONAL_CHARGE:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_ADDITIONAL_CHARGE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_ADDITIONAL_CHARGE:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default additionalChargeReducer;
