import React, { Component } from "react";
import { connect } from "react-redux";

class ViewInvoice extends Component {
  render() {
    return <></>;
  }
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(ViewInvoice);
