import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "src/configs/core";
import { WebsiteLayout } from "src/layouts";
import map from "src/assets/images/map.jpg";
import car from "src/assets/images/website/Mercedes-GLC-1.png";
import RegisterView from "./register";
import VerifyView from "./verify";
import LoginView from "./login";
import ForgotPasswordView from "./forgot-password";
import ResetPasswordView from "./reset-password";
import VerifyEmailView from "./verify-email";

class Auth extends Component {
  render() {
    return (
      <WebsiteLayout>
        <div className="auth-wrap">
          <img className="map" src={map} alt="" />
          <img className="cars" src={car} alt="" />

          <Switch>
            <ContentRoute path="/auth/register" component={RegisterView} />
            <ContentRoute path="/auth/verify/:token" component={VerifyView} />
            <ContentRoute
              path="/auth/verify-email/:token"
              component={VerifyEmailView}
            />
            <ContentRoute path="/auth/login" component={LoginView} />
            <ContentRoute
              path="/auth/forgot-password"
              component={ForgotPasswordView}
            />
            <ContentRoute
              path="/auth/reset-password/:token"
              component={ResetPasswordView}
            />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
      </WebsiteLayout>
    );
  }
}

export default Auth;
