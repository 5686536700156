export const GET_SCHEDULE_LIST = "GET_SCHEDULE_LIST";
export const GET_SCHEDULE_LIST_SUCCESS = "GET_SCHEDULE_LIST_SUCCESS";
export const GET_SCHEDULE_LIST_ERROR = "GET_SCHEDULE_LIST_ERROR";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_ERROR = "ADD_SCHEDULE_ERROR";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_ERROR = "EDIT_SCHEDULE_ERROR";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";
export const RESET_SCHEDULE = "RESET_SCHEDULE";

export const getScheduleList = (dbParam) => ({
  type: GET_SCHEDULE_LIST,
  payload: { dbParam },
});

export const getScheduleListSuccess = (scheduleList) => ({
  type: GET_SCHEDULE_LIST_SUCCESS,
  payload: scheduleList,
});

export const getScheduleListError = (error) => ({
  type: GET_SCHEDULE_LIST_ERROR,
  payload: error,
});

export const addSchedule = (scheduleData, history) => ({
  type: ADD_SCHEDULE,
  payload: { scheduleData, history },
});

export const addScheduleSuccess = (success, message) => ({
  type: ADD_SCHEDULE_SUCCESS,
  payload: { success, message },
});

export const addScheduleError = (error) => ({
  type: ADD_SCHEDULE_ERROR,
  payload: error,
});

export const getSchedule = (scheduleId) => ({
  type: GET_SCHEDULE,
  payload: { scheduleId },
});

export const getScheduleSuccess = (scheduleData) => ({
  type: GET_SCHEDULE_SUCCESS,
  payload: scheduleData,
});

export const getScheduleError = (error) => ({
  type: GET_SCHEDULE_ERROR,
  payload: error,
});

export const editSchedule = (scheduleId, scheduleData, history) => ({
  type: EDIT_SCHEDULE,
  payload: { scheduleId, scheduleData, history },
});

export const editScheduleSuccess = (success, message) => ({
  type: EDIT_SCHEDULE_SUCCESS,
  payload: { success, message },
});

export const editScheduleError = (error) => ({
  type: EDIT_SCHEDULE_ERROR,
  payload: error,
});

export const deleteSchedule = (scheduleId) => ({
  type: DELETE_SCHEDULE,
  payload: { scheduleId },
});

export const deleteScheduleSuccess = (success, message) => ({
  type: DELETE_SCHEDULE_SUCCESS,
  payload: { success, message },
});

export const deleteScheduleError = (error) => ({
  type: DELETE_SCHEDULE_ERROR,
  payload: error,
});

export const resetSchedule = () => ({
  type: RESET_SCHEDULE,
  payload: {},
});
