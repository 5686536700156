/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./reduxs/store";
import { LayoutSplashScreen } from "./configs/core";
import { BrowserRouter } from "react-router-dom";
import theme from "./configs/theme";
import Route from "./Route";
import ScrollTop from "./components/scroll-top";

export default function App() {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {/* Render routes with provided `Layout`. */}
            <ScrollTop />
            <Route /> 
          </ThemeProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}
