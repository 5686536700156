import {
  GET_ALL_CONSOLIDATE_INVOICE,
  GET_ALL_CONSOLIDATE_INVOICE_SUCCESS,
  GET_ALL_CONSOLIDATE_INVOICE_ERROR,
  GET_CONSOLIDATE_INVOICE_LIST,
  GET_CONSOLIDATE_INVOICE_LIST_SUCCESS,
  GET_CONSOLIDATE_INVOICE_LIST_ERROR,
  ADD_CONSOLIDATE_INVOICE,
  ADD_CONSOLIDATE_INVOICE_SUCCESS,
  ADD_CONSOLIDATE_INVOICE_ERROR,
  GET_CONSOLIDATE_INVOICE,
  GET_CONSOLIDATE_INVOICE_SUCCESS,
  GET_CONSOLIDATE_INVOICE_ERROR,
  DELETE_CONSOLIDATE_INVOICE,
  DELETE_CONSOLIDATE_INVOICE_SUCCESS,
  DELETE_CONSOLIDATE_INVOICE_ERROR,
  DELETE_MULTIPLE_CONSOLIDATE_INVOICE,
  DELETE_MULTIPLE_CONSOLIDATE_INVOICE_SUCCESS,
  DELETE_MULTIPLE_CONSOLIDATE_INVOICE_ERROR,
  DOWNLOAD_CONSOLIDATE_INVOICE,
  DOWNLOAD_CONSOLIDATE_INVOICE_SUCCESS,
  DOWNLOAD_CONSOLIDATE_INVOICE_ERROR,
  MERGE_INVOICE,
  MERGE_INVOICE_SUCCESS,
  MERGE_INVOICE_ERROR,
  RESET_CONSOLIDATE_INVOICE,
} from "src/reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  consolidateInvoices: null,
  consolidateInvoiceId: null,
  consolidateInvoiceList: null,
  consolidateInvoiceData: null,
  consolidateInvoiceIds: null,
  mergeInvoiceData: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  downloading: false,
  error: null,
};

const consolidateInvoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CONSOLIDATE_INVOICE:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        consolidateInvoices: action.payload,
        error: null,
      };
    case GET_ALL_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        consolidateInvoices: null,
        error: action.payload,
      };
    case GET_CONSOLIDATE_INVOICE_LIST:
      return { ...state, loading: true, error: null };
    case GET_CONSOLIDATE_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        consolidateInvoiceList: action.payload,
        error: null,
      };
    case GET_CONSOLIDATE_INVOICE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        consolidateInvoiceList: null,
        error: action.payload,
      };
    case ADD_CONSOLIDATE_INVOICE:
      return { ...state, loading: true, error: null };
    case ADD_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_CONSOLIDATE_INVOICE:
      return { ...state, error: null };
    case GET_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        consolidateInvoiceData: action.payload,
        error: null,
      };
    case GET_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        consolidateInvoiceData: null,
        error: action.payload,
      };
    case DELETE_CONSOLIDATE_INVOICE:
      return { ...state, loading1: true, error: null };
    case DELETE_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_CONSOLIDATE_INVOICE:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_CONSOLIDATE_INVOICE:
      return { ...state, downloading: true, error: null };
    case DOWNLOAD_CONSOLIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        downloading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_CONSOLIDATE_INVOICE_ERROR:
      return {
        ...state,
        downloading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case MERGE_INVOICE:
      return { ...state, downloading: true, error: null };
    case MERGE_INVOICE_SUCCESS:
      return {
        ...state,
        downloading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case MERGE_INVOICE_ERROR:
      return {
        ...state,
        downloading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_CONSOLIDATE_INVOICE:
      return {
        ...state,
        loading: false,
        downloading: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default consolidateInvoiceReducer;
