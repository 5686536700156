import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyEmail } from "src/reduxs/actions";
import { LayoutSplashScreen } from "src/configs/core";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.props.verifyEmail(this.props.match.params.token, this.props.history);
  }

  render() {
    return <LayoutSplashScreen />;
  }
}

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { verifyEmail })(VerifyEmail);
