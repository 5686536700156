import React, { Component } from "react";
import { connect } from "react-redux";
import * as Mui from "@material-ui/core";
import { getPriceList } from "src/reduxs/actions";
import Breadcrumb from "src/components/breadcrumb";
class CarPrice extends Component {
  componentDidMount() {
    this.props.getPriceList();
  }

  render() {
    return (
      <>
        <Breadcrumb
          title={"Vehicle Price Management"}
          paths={[
            {
              title: "Price",
            },
          ]}
        />
        <Mui.Box className={`table-wrapper card`} elevation={0}>
          <Mui.Toolbar className="datatable-filter-bar mb-3">
            <Mui.Typography
              component="h3"
              variant="h3"
              className={`title ${Mui.isWidthDown("sm", this.props.width) ? "align-self-start" : "center"}`}
            >
              Vehicle Category-wise Price Information
            </Mui.Typography>
          </Mui.Toolbar>
          <Mui.TableContainer className="default-datatable">
            <Mui.Table size={"medium"}>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell width="50">Category</Mui.TableCell>
                  <Mui.TableCell>Per Hour</Mui.TableCell>
                  <Mui.TableCell>5km</Mui.TableCell>
                  <Mui.TableCell>10km</Mui.TableCell>
                  <Mui.TableCell>15km</Mui.TableCell>
                  <Mui.TableCell>20km</Mui.TableCell>
                  <Mui.TableCell>25km</Mui.TableCell>
                  <Mui.TableCell>30km</Mui.TableCell>
                  <Mui.TableCell>35km</Mui.TableCell>
                  <Mui.TableCell>40km</Mui.TableCell>
                  <Mui.TableCell>45km</Mui.TableCell>
                  <Mui.TableCell>50km</Mui.TableCell>
                  <Mui.TableCell>55km</Mui.TableCell>
                  <Mui.TableCell>60km</Mui.TableCell>
                  <Mui.TableCell>70km</Mui.TableCell>
                  <Mui.TableCell>80km</Mui.TableCell>
                  <Mui.TableCell>90km</Mui.TableCell>
                  <Mui.TableCell>100km</Mui.TableCell>
                  {/* <Mui.TableCell align={"left"} padding={"default"}>
                    ACTIONS
                  </Mui.TableCell> */}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {this.props.loading ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan="12" className="nodata">
                      <Mui.CircularProgress color="inherit" style={{ margin: "10rem 0" }} />
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  <>
                    {this.props.priceList &&
                      this.props.priceList?.map((row, index) => {
                        return (
                          <Mui.TableRow hover key={row.id}>
                            <Mui.TableCell>{row?.vehicleCategory?.name || ""}</Mui.TableCell>
                            <Mui.TableCell>${row?.perHour || 0}</Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["5KmPrice"] || 0} ({row?.["5KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["10KmPrice"] || 0} ({row?.["10KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["15KmPrice"] || 0} ({row?.["15KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["20KmPrice"] || 0} ({row?.["20KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["25KmPrice"] || 0} ({row?.["25KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["30KmPrice"] || 0} ({row?.["30KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["35KmPrice"] || 0} ({row?.["35KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["40KmPrice"] || 0} ({row?.["40KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["45KmPrice"] || 0} ({row?.["45KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["50KmPrice"] || 0} ({row?.["50KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["55KmPrice"] || 0} ({row?.["55KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["60KmPrice"] || 0} ({row?.["60KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["70KmPrice"] || 0} ({row?.["70KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["80KmPrice"] || 0} ({row?.["80KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["90KmPrice"] || 0} ({row?.["90KmIncrement"]})
                            </Mui.TableCell>
                            <Mui.TableCell>
                              ${row?.["100KmPrice"] || 0} ({row?.["100KmIncrement"]})
                            </Mui.TableCell>
                            {/* <Mui.TableCell></Mui.TableCell> */}
                          </Mui.TableRow>
                        );
                      })}
                  </>
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Box>
      </>
    );
  }
}
const mapStateToProps = ({ price }) => {
  const { priceList, loading1, loading, success, error } = price;
  return { priceList, loading1, loading, success, error };
};

export default connect(mapStateToProps, { getPriceList })(CarPrice);
