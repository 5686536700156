import React from "react";
import { Helmet } from "react-helmet";

const PageMeta = ({ ...customMeta }) => {
  const meta = {
    title: "Shangri-la Chauffeurs",
    description: `Use Shangri-La to book your rides for weddings, airport, corporate transfer and special events with well-equipped drivers. We provide luxury & pleasant travel with best customer service. Book online by entering your ride details and selecting your vehicle:`,
    image: "/logo.png",
    type: "website",
    ...customMeta,
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="robots" content="follow, index" />
      <meta content={meta.description} name="description" />
      <meta
        property="og:url"
        content={`https://shangrilachauffeurs.com.au/${window.location.pathname}`}
      />
      <link
        rel="canonical"
        href={`https://shangrilachauffeurs.com.au/${window.location.pathname}`}
      />
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content="Shangri-la Chauffeurs" />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Shangri-laChauffeurs" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      {meta.date && (
        <meta property="article:published_time" content={meta.date} />
      )}
    </Helmet>
  );
};

export default PageMeta;
