import React from "react";

const IconUser = () => {
  return (
    <svg
      id="Bust"
      xmlns="http://www.w3.org/2000/svg"
      width="18.469"
      height="25.678"
      viewBox="0 0 18.469 25.678"
    >
      <path
        id="Path_2250"
        dataname="Path 2250"
        d="M27.438,14.922a.4.4,0,0,0-.1-.229,14.761,14.761,0,0,0-6-3.8.771.771,0,0,1-.412-.342.4.4,0,0,0-.027-.091.467.467,0,0,1,.163-.562,5.637,5.637,0,0,0,2.083-4.443A5.2,5.2,0,0,0,18.236.025a5.2,5.2,0,0,0-4.91,5.433,5.554,5.554,0,0,0,2.148,4.473.453.453,0,0,1,.162.522l-.011.023a.714.714,0,0,1-.42.393,14.806,14.806,0,0,0-6.07,3.823.4.4,0,0,0-.1.229A8.921,8.921,0,0,0,12.194,22.2a2.2,2.2,0,0,0,.909.489,21.4,21.4,0,0,0,10.288,0,2.146,2.146,0,0,0,.875-.473A8.923,8.923,0,0,0,27.438,14.922Zm-3.709,6.7a1.333,1.333,0,0,1-.541.293,20.562,20.562,0,0,1-9.884,0,1.391,1.391,0,0,1-.573-.308,8.168,8.168,0,0,1-2.909-6.476,14.111,14.111,0,0,1,5.648-3.5,1.519,1.519,0,0,0,.877-.8c.007-.012.013-.025.019-.038a1.257,1.257,0,0,0-.42-1.511,4.746,4.746,0,0,1-1.816-3.823A4.4,4.4,0,0,1,18.236.828a4.4,4.4,0,0,1,4.108,4.631,4.825,4.825,0,0,1-1.762,3.8,1.253,1.253,0,0,0-.466,1.393.4.4,0,0,0,.032.134,1.577,1.577,0,0,0,.924.866,14.063,14.063,0,0,1,5.578,3.478A8.169,8.169,0,0,1,23.73,21.617Z"
        transform="translate(-9.002 -0.025)"
      />
      <path
        id="Path_2251"
        dataname="Path 2251"
        d="M30.644,62.075H27.431a.382.382,0,0,0,0,.763h3.212a.382.382,0,0,0,0-.763Z"
        transform="translate(-19.803 -37.16)"
      />
    </svg>
  );
};

export default IconUser;
