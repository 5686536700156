import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";
import AdditionalChargeList from "./list";
import EditAdditionalCharge from "./edit";

function AdditionalCharge() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact={true} path="/additional-charge" component={AdditionalChargeList} />
        <ContentRoute path="/additional-charge/add" component={EditAdditionalCharge} />
        <ContentRoute path="/additional-charge/edit/:id" component={EditAdditionalCharge} />
      </Switch>
    </Suspense>
  );
}

export default AdditionalCharge;
